import React, { useState, useEffect } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useStatus, useStatusMsg } from '../../reducers'
import { ProgramSettings } from '../../components/settings'
import { actions as acts } from '../../constants'
import Container from './Container'

const Settings = () => {
  const { osid } = useParams()
  const program = useSelector(s => s.currentProgram)

  return (
    <Container>
      <h1>Settings</h1>
      <ProgramSettings program={program} />
    </Container>
  )
}

export default Settings
