import { Editor as TinyEditor } from '@tinymce/tinymce-react'
import { tinyMceApiKey } from '../config'

const Editor = ({ initialValue, value, onChange }) => {
  return (
    <TinyEditor
      apiKey={tinyMceApiKey}
      initialValue={initialValue}
      value={value}
      init={{
        branding: false,
        height: 200,
        menubar: false,
        plugins: [
          'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'print', 'preview', 'anchor',
          'searchreplace', 'visualblocks', 'code', 'fullscreen',
          'insertdatetime', 'media', 'table', 'paste', 'code', 'help', 'wordcount',
        ],
        default_link_target: '_blank',
        toolbar: 'bold italic link h1 h2 h3',
        directionality: 'ltr',
      }}
      onEditorChange={onChange}
    />
  )
}

export default Editor
