import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Button } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import get from 'lodash.get'
import { paths } from '../constants'
import { Link } from 'react-router-dom'

const Club = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgb(204, 204, 204);
  padding: .25em .5em;
  background-color: white;
  margin-bottom: 1em;
  max-width: 25em;
`

// Returns a link to a club if found on provided object
// expects only one prop object to be populated
export const ClubLink = ({ children, club, program, ...rest }) => {
  let osid = null
  let clubId = null
  if (program) {
    // TODO remove fields with "_" in them once we remove usage of sequelize
    clubId = get(program, 'club.id', get(program, 'club_id', null))
    osid = get(program, 'org.id', get(program, 'org_id', program.org)) // program.org may not have been expanded
  } else if (club) {
    clubId = club.id
    osid = get(club, 'org.id', club.org) // club.org may not have been expanded
  }

  if (!clubId || !osid) {
    return children
  }

  return (
    <Link to={paths.org.CLUB(osid, clubId)} {...rest}>
      {children}
    </Link>
  )
}

export const ClubList = ({ clubs, onRemove }) => {
  const removable = typeof onRemove === 'function'
  let display = (
    <i>No clubs</i>
  )
  if (Array.isArray(clubs) && clubs.length > 0) {
    display = clubs.map(clb => (
      <Club key={clb.id}>
        <ClubLink club={clb}>{clb.name}</ClubLink>
        { removable && <Button type="link" onClick={() => onRemove(clb)}>Remove</Button> }
      </Club>
    ))
  }
  return (
    <div>
      {display}
    </div>
  )
}

