import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { CloseOutlined } from '@ant-design/icons'
import { useStatus, useStatusMsg } from '../../../reducers'
import { Editor } from '@tinymce/tinymce-react'
import { tinyMceApiKey } from '../../../config'
import { useDropzone } from 'react-dropzone'
import * as urlUtils from '../../../util/url'
import { formatName } from '../../../util/user'
import { actions as acts } from '../../../constants'
import * as actions from '../../../actions/admin'
import { clearStatus } from '../../../actions/status'
import Attr from '../../../components/Attr'
import Dropzone from '../../../components/Dropzone'
import ImagePreview from '../../../components/ImagePreview'
import { Input, Modal, Form } from 'antd'
import { v4 as uuid } from 'uuid'

const thumbStyles = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
}

const imgStyles = {
  display: 'block',
  width: 'auto',
  height: '100%',
}

const AddShopItemModal = ({ complete, pro, existingItem, isEdit=false }) => {
  const dispatch = useDispatch()
  const [name, setName] = useState(existingItem && existingItem.name)
  const [description, setDescription] = useState(existingItem && existingItem.description)
  const [link, setLink] = useState(existingItem && existingItem.link)
  const [images, setImages] = useState(existingItem && existingItem.images || [])
  const [deletedImages, setDeletedImages] = useState([])
  const status = {
    addItem: useStatus(acts.ADD_PRO_SHOP_ITEM),
    updateItem: useStatus(acts.UPDATE_PRO_SHOP_ITEM),
    addItemImages: useStatus(acts.ADD_PRO_SHOP_ITEM_IMAGES),
    deleteItemImage: useStatus(acts.DELETE_PRO_SHOP_ITEM_IMAGE),
  }

  useStatusMsg(status.addItem, {
    pending: 'Adding item...',
    error: 'Failed to add item',
    success: 'Item added',
  })

  useStatusMsg(status.updateItem, {
    pending: 'Updating item...',
    error: 'Failed to update item',
    success: 'Item updated',
  })

  useStatusMsg(status.addItemImages, {
    pending: 'Adding images...',
    error: 'Failed to add images',
    success: 'Images added',
  })

  useStatusMsg(status.deleteItemImage, {
    pending: 'Deleting image...',
    error: 'Failed to delete image',
    success: 'Image deleted',
  })

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.ADD_PRO_SHOP_ITEM))
      dispatch(clearStatus(acts.UPDATE_PRO_SHOP_ITEM))
      dispatch(clearStatus(acts.DELETE_PRO_SHOP_ITEM_IMAGE))
      dispatch(clearStatus(acts.ADD_PRO_SHOP_ITEM_IMAGES))
    }
  }, [])

  const formComplete = [
    name && name.length > 0,
    description && description.length > 0,
    link && link.length > 0,
  ].reduce((acc, curr) => curr && acc, true)

  const updateImages = async item => {
    // add images
    const toAdd = images.filter(i => !!i.preview)
    let newImages = []
    if (toAdd.length > 0) {
      newImages = await dispatch(actions.addProShopItemImages(pro.page.id, item.id, toAdd))
    }
    if (newImages.length > 0) {
      item.images.push(...newImages)
    }
    // delete images
    if (deletedImages.length > 0) {
      await Promise.all(deletedImages.map(img =>
        dispatch(actions.deleteProShopItemImage(pro.page.id, item.id, img.id))
      ))
      const deletedIds = deletedImages.map(i => i.id)
      item.images = item.images.filter(i => !deletedIds.includes(i.id))
    }
  }

  const onOk = async () => {
    if (isEdit) {
      const updatedItem = await dispatch(
        actions.updateProShopItem(pro.page.id, existingItem.id, name, description, link)
      )
      await updateImages(updatedItem)
      complete(updatedItem)
    } else {
      const item = await dispatch(
        actions.addProShopItem(pro.page.id, name, description, link)
      )
      await updateImages(item)
      complete(item)
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg,image/png',
    multiple: true,
    disabled: status.deleteItemImage.pending || status.addItemImages.pending,
    onDrop: acceptedFiles => {
      setImages(prev => {
        const newImages = acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file),
          id: uuid(),
        }))
        if (prev && Array.isArray(prev)) {
          return prev.concat(newImages)
        }
        return newImages
      })
    }
  })

  const removeImage = image => {
    setImages(prev => prev.filter(i => i.id !== image.id))
    if (image.preview) {
      URL.revokeObjectURL(image.preview)
    } else {
      setDeletedImages(prev => prev.concat(image))
    }
  }

  return (
    <Modal
      title={`${isEdit ? `Update` : `Create`} shop item`}
      visible
      okText={isEdit ? 'Save' : 'Create'}
      okButtonProps={{disabled: !formComplete}}
      onOk={onOk}
      onCancel={() => complete()}
      confirmLoading={status.addItem.pending || status.updateItem.pending || false}
    >
      <Form>
        <Attr name="Name">
          <Input
            placeholder="Wilson Racquet"
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </Attr>
        <Attr name="Link">
          <Input
            placeholder="Affiliate or purchase link"
            value={link}
            onChange={e => setLink(e.target.value)}
          />
        </Attr>
        <Attr name="Description">
          <Editor
            apiKey={tinyMceApiKey}
            value={description}
            init={{
              placeholder: 'Item description',
              branding: false,
              height: 300,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount',
              ],
              default_lin_target: '_blank',
              toolbar: 'undo redo | bold italic | link',
            }}
            onEditorChange={content => setDescription(content)}
          />
        </Attr>
        <Attr name="Images">
          <div>
            <Dropzone {...getRootProps()}>
              <input {...getInputProps()} />
              Select item images for upload
            </Dropzone>
            <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: '1em'}}>
              {images && images.length > 0 && images.map(i => (
                <div key={i.id} style={thumbStyles}>
                  {i.preview &&
                    <img
                      src={i.preview}
                      height="100px"
                      style={imgStyles}
                    />
                  }
                  {!i.preview &&
                    <ImagePreview asset={i} width="auto" height="100px" />
                  }
                  <CloseOutlined
                    style={{cursor: 'pointer'}}
                    onClick={() => removeImage(i)}
                  />
                </div>
              ))}
            </div>
          </div>
        </Attr>
      </Form>
    </Modal>
  )
}

export default AddShopItemModal
