import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import Gallery from 'react-photo-gallery'
import { s3Asset } from '../util/url'
import { useDropzone } from 'react-dropzone'
import { arrayMoveImmutable } from 'array-move'
import { v4 as uuid } from 'uuid'
import Dropzone from './Dropzone'

const imgWithClick = { cursor: 'pointer' }

const CloseBtn = styled.button`
  background: #ffffff;
  border: 0;
  border-radius: 50%;
  color: #da615c;
  position: absolute;
  top: -12px;
  font-size: 20px;
  line-height: 20px;
  padding: 0 5px;
  right: -10px;
  outline: none;
  cursor: pointer;
  z-index: 2;
  border: 1px solid #da615c;
  font-family: 'BTLFontRegular';
`

const thumb = {
  height: '100px',
  width: '100px',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginTop: 20,
  marginLeft: 12,
  padding: 8,
  boxSizing: 'border-box',
  position: 'relative',
  cursor: 'move',
  backgroundColor: '#ffffff',
  zIndex: 1,
}

const Photo = ({
  index,
  onClick,
  photo,
  margin,
  direction,
  top,
  left,
  handleDelete,
}) => {
  const imgStyle = { objectFit: 'cover' }
  if (direction === 'column') {
    imgStyle.position = 'absolute'
    imgStyle.left = left
    imgStyle.top = top
  }

  const handleClick = event => {
    onClick(event, { photo, index })
  }

  // this contains legacy assumptions. the expected 'asset' attribute the old ImageUpload
  // step when creating a program. 'preview' is for newly creatd images, and when we pass the 
  // photo itself, we're assuming that the entire photo object is an asset.
  // we need to make that configurable, or a getter
  let src;
  if (photo.hasOwnProperty('asset')) {
    src = s3Asset(photo.asset)
  } else if (photo.hasOwnProperty('preview')) {
    src = photo.preview
  } else {
    src = s3Asset(photo)
  }

  return (
    <div style={thumb}>
      <CloseBtn onClick={e => handleDelete(e, photo)}>x</CloseBtn>
      <img
        style={onClick ? { ...imgStyle, ...imgWithClick } : imgStyle}
        src={src}
        width={'100%'}
        height={'100%'}
        onClick={onClick ? handleClick : null}
        alt="img"
      />
    </div>
  )
}

const thumbsContainer = {
  width: '100%',
  overflow: 'hidden',
  paddingRight: '12px',
}

const SortablePhoto = SortableElement(item => {
  return <Photo {...item} />
})
const SortableGallery = SortableContainer(({ items, handleDelete }) => (
  <aside style={thumbsContainer}>
    <Gallery
      photos={items}
      renderImage={props => (
        <SortablePhoto {...props} handleDelete={handleDelete} />
      )}
    />
  </aside>
))

// caller must revokeObjectURL for each new image.preview after tearing this component down
export const MultiImageUpload = ({ images, onChange }) => {
  const [selectedImages, setSelectedImages] = useState(Array.isArray(images) ? images.filter(Boolean) : []) // filter out null inputted images
  const changer = typeof onChange === 'function' ? onChange : () => {}

  useEffect(() => {
    changer(selectedImages)
  }, [selectedImages])

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    multiple: true,
    onDrop: acceptedFiles => {
      const newImages = acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          id: uuid(),
          isNew: true,
        })
      )
      setSelectedImages(selectedImages.concat(newImages))
    },
  })

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setSelectedImages(arrayMoveImmutable(selectedImages, oldIndex, newIndex))
  }

  const handleDelete = (e, image) => {
    e.preventDefault()
    setSelectedImages(selectedImages.filter(i => i.id !== image.id))
  }

  return (
    <div style={{width: '100%'}}>
      <Dropzone {...getRootProps()}>
        <input {...getInputProps()} />
        <p style={{ marginBottom: '0' }}>
          Select images
        </p>
      </Dropzone>
      <SortableGallery
        items={selectedImages}
        onSortEnd={onSortEnd}
        handleDelete={handleDelete}
        axis={'xy'}
      />
    </div>
  )
}
