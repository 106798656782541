import React, { useState, useEffect, useReducer } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { Button, Tag, Select, Form, Input } from 'antd'
import get from 'lodash.get'
import PageHeading from '../../../components/admin/PageHeading'
import PageTitle from '../../../components/admin/PageTitle'
import Attr from '../../../components/Attr'
import { PROGRAM } from '../../../constants/marketingLabels'
import { actions as acts } from '../../../constants'
import { useStatus, useStatusMsg } from '../../../reducers'
import {
  fetchUpcomingPrograms,
  generateEmailPreview,
  sendEmail,
} from '../../../actions/admin'
import capitalize from 'capitalize'
import pluralize from 'pluralize'
import UserSelect from '../../../components/UserSelect'
import { ProgramSelect } from '../../../components/program'
import {
  TextArea,
  SetScoreSelect,
  UpcomingSelects,
  Container,
  emailState,
  reducer,
} from './common'

const initState = emailState(
  {
    program: null,
    user: null,
    set0: {
      player: null,
      opponent: null,
    },
    set1: {
      player: null,
      opponent: null,
    },
    set2: {
      player: null,
      opponent: null,
    },
    gameStyle: {
      name: '',
      description: '',
    },
    personality: {
      name: '',
      description: '',
    },
    practiceTip: '',
  },{
    subject: 'Congrats! Check Out Your Highlights',
  }
)

const CompetitionReport = () => {
  const dispatch = useDispatch()
  const [upcomingPrograms, setUpcomingPrograms] = useState([])
  const status = {
    upcoming: useStatus(acts.FETCH_UPCOMING_PROGRAMS),
    preview: useStatus(acts.GENERATE_EMAIL_PREVIEW),
    send: useStatus(acts.SEND_EMAIL),
  }
  const [preview, setPreview] = useState(null)
  const [formComplete, setFormComplete] = useState(false)
  const [state, dp] = useReducer(reducer, initState)
  const numSets = 3

  useStatusMsg(status.preview, {
    pending: 'Generating preview...',
    error: 'Failed to generate email preview',
    success: 'Preview generated',
  })

  useStatusMsg(status.send, {
    pending: 'Sending email...',
    error: 'Failed to send email',
    success: 'Email sent',
  })

  useEffect(() => {
    if (state.to) {
      setFormComplete(true)
    }
  }, [state])

  const onPreview = async () => {
    const res = await dispatch(generateEmailPreview('break-report-competition', state))
    if (res.html) {
      setPreview({ __html: res.html })
    }
  }

  const onSend = () => dispatch(
    sendEmail('break-report-competition', state)
  )

  return (
    <>
      <PageHeading>
        <PageTitle>Competition Break Report Email</PageTitle>
      </PageHeading>
      <Container>
        <Form name="competition-report-email" layout="vertical">
          <Form.Item name="program" label={capitalize(PROGRAM)}>
            <ProgramSelect onChange={e => dp({ type: 'data', key: 'program', val: JSON.parse(e) })}/>
          </Form.Item>
          <Form.Item name="user" label="User">
            <UserSelect onChange={user => {
              dp({ type: 'data', key: 'user', val: user })
              dp({ type: 'to', val: user.email })
            }} />
          </Form.Item>
          <Attr name="Score" description="First score in each set is for this player; second score is for their opponent.">
            <Form.Item name={`set-0`} label={`Set 1`}>
              <div style={{ display: 'flex', alignItems: 'center'}}>
                <SetScoreSelect
                  name={`set-0-score-1`}
                  style={{width: '4em'}}
                  onChange={val => dp({ type: 'data', key: `set0.player`, val })}
                  value={get(state, `set0.player`)}
                />
                <span style={{margin: '1em'}}>-</span>
                <SetScoreSelect
                  name={`set-0-score-2`}
                  style={{width: '4em'}}
                  onChange={val => dp({ type: 'data', key: `set0.opponent`, val })}
                  value={get(state, `set0.opponent`)}
                />
              </div>
            </Form.Item>
            <Form.Item name={`set-1`} label={`Set 2`}>
              <div style={{ display: 'flex', alignItems: 'center'}}>
                <SetScoreSelect
                  name={`set-1-score-1`}
                  style={{width: '4em'}}
                  onChange={val => dp({ type: 'data', key: `set1.player`, val })}
                  value={get(state, `set1.player`)}
                />
                <span style={{margin: '1em'}}>-</span>
                <SetScoreSelect
                  name={`set-1-score-2`}
                  style={{width: '4em'}}
                  onChange={val => dp({ type: 'data', key: `set1.opponent`, val })}
                  value={get(state, `set1.opponent`)}
                />
              </div>
            </Form.Item>
            <Form.Item name={`set-2`} label={`Set 3`}>
              <div style={{ display: 'flex', alignItems: 'center'}}>
                <SetScoreSelect
                  name={`set-2-score-1`}
                  style={{width: '4em'}}
                  onChange={val => dp({ type: 'data', key: `set2.player`, val })}
                  value={get(state, `set2.player`)}
                />
                <span style={{margin: '1em'}}>-</span>
                <SetScoreSelect
                  name={`set-2-score-2`}
                  style={{width: '4em'}}
                  onChange={val => dp({ type: 'data', key: `set2.opponent`, val })}
                  value={get(state, `set2.opponent`)}
                />
              </div>
            </Form.Item>
          </Attr>
          <Attr name="Game Style">
            <Form.Item name="game-style-name" label="Name">
              <Input
                placeholder="e.g. Big Server"
                style={{width: '20em'}}
                onChange={e => dp({ type: 'data', key: 'gameStyle.name', val: e.target.value })}
              />
            </Form.Item>
            <Form.Item name="game-style-desc" label="Description">
              <TextArea
                onChange={e => dp({ type: 'data', key: 'gameStyle.description', val: e.target.value })}
              />
            </Form.Item>
          </Attr>
          <Attr name="On-Court Personality">
            <Form.Item name="on-court-personality-name" label="Name">
              <Input
                placeholder="e.g. Shot Maker"
                style={{width: '20em'}}
                onChange={e => dp({ type: 'data', key: 'personality.name', val: e.target.value })}
              />
            </Form.Item>
            <Form.Item name="on-court-personality-desc" label="Description">
              <TextArea
                onChange={e => dp({ type: 'data', key: 'personality.description', val: e.target.value })}
              />
            </Form.Item>
          </Attr>
          <Form.Item name="practice-tip" label="Practice Tip">
            <TextArea
              rows={4}
              recommendedLength={200}
              onChange={e => dp({ type: 'data', key: 'practiceTip', val: e.target.value }) }
            />
          </Form.Item>
          <Attr
            name={`Upcoming ${pluralize(capitalize(PROGRAM))}`}
            description={`By default the three most recent bookable ${pluralize(PROGRAM)} are listed`}
          >
            <Form.Item>
              <UpcomingSelects dp={dp} />
            </Form.Item>
          </Attr>
          <div style={{display: 'flex', justifyContent: 'space-between' }}>
            <Button
              type="secondary"
              onClick={onPreview}
              loading={status.preview.pending}
            >
              Preview
            </Button>
            <Button
              type="primary"
              onClick={onSend}
              disabled={!formComplete}
            >
              Send
            </Button>
          </div>
        </Form>
        <div style={{marginTop: '2em'}}>
          { preview && <div dangerouslySetInnerHTML={preview}></div> }
        </div>
      </Container>
    </>
  )
}

export default CompetitionReport
