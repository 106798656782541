import React, { useReducer, useEffect } from 'react'
import { Radio, Button } from 'antd'
import { InputGroup, SelectContainer, Title, Desc } from './step'
import { SponsorSelect, SponsorList } from '../../../components/sponsor'
import { typeLabel } from '../../../util/program'
import { lower } from '../../../util/string'
import { Reorderable } from '../../../components/Reorderable'
import { Sponsor, SponsorItem } from '../../../components/sponsor'

// Determines the value of info.hasSponsors
// Protects against cases where info.hasSponsors does not exist
// (i.e. when program is no longer a draft)
const hasSponsorValue = program => {
  if (program.draft && program.info) {
    return program.info.hasSponsors
  } else {
    if (Array.isArray(program.sponsors) && program.sponsors.length > 0) {
      return true
    }
    return false
  }
  return null
}

// flattens any program-sponsor to a sponsor if they exist
const normalizeSponsors = sponsors =>
  sponsors
    .sort((a, b) => (a.order && b.order ? a.order - b.order : 0))
    .map(s => (s.sponsor ? s.sponsor : s))

const initState = program => {
  return {
    sponsors: Array.isArray(program.sponsors) ? normalizeSponsors(program.sponsors) : [],
    info: {
      hasSponsors: hasSponsorValue(program),
    },
  }
}

const reducer = (state, action) => ({ ...state, ...action })

const Sponsors = ({ clinic, onChange }) => {
  const program = clinic
  const [state, dp] = useReducer(reducer, initState(program))

  useEffect(() => {
    if (typeof onChange === 'function') {
      const data = {
        ...state,
        sponsors: state.sponsors,
      }
      if (program.draft) {
        onChange(data)
      } else {
        onChange({ ...data, info: undefined })
      }
    }
  }, [state])

  const onSelectChange = e => {
    const update = {
      info: { hasSponsors: e.target.value },
    }
    if (!e.target.value) {
      update.sponsors = []
    }
    dp(update)
  }

  let sponsorDisplay = null

  if (state.info.hasSponsors) {
    let sponsorList = <i>No sponsors</i>
    if (Array.isArray(state.sponsors) && state.sponsors.length > 0) {
      sponsorList = (
        <SponsorList
          sponsors={normalizeSponsors(state.sponsors)}
          onRemove={spn => dp({ sponsors: state.sponsors.filter(s => s.id !== spn.id) })}
        />
      )
    }

    const onRemove = spn => dp({ sponsors: state.sponsors.filter(s => s.id !== spn.id) })
    const onSelectSponsor = sponsor => dp({ sponsors: [...state.sponsors, sponsor] })
    const onReorderSponsor = spn => dp({ sponsors: [...spn] })
    sponsorDisplay = (
      <div>
        <SponsorSelect
          placeholder="Select a sponsor"
          style={{ width: '100%', marginBottom: '1em' }}
          value={null}
          onChange={onSelectSponsor}
        />
        <div>
          <Reorderable
            items={state.sponsors}
            keyExtractor={s => s.id}
            renderItem={s => (
              <Sponsor key={s.id}>
                <span>{s.name}</span>
                <Button type="link" onClick={() => onRemove(s)}>
                  Remove
                </Button>
              </Sponsor>
            )}
            onChange={onReorderSponsor}
          />
        </div>
      </div>
    )
  }

  return (
    <>
      <InputGroup>
        <Title>Sponsors</Title>
        <Desc>
          <p>
            Are there sponsors for this {lower(typeLabel(program))}?
          </p>
        </Desc>
        <SelectContainer>
          <Radio.Group onChange={onSelectChange} value={state.info.hasSponsors}>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </SelectContainer>
      </InputGroup>
      {sponsorDisplay}
    </>
  )
}

const Viewer = ({ program }) => {
  if (!Array.isArray(program.sponsors) || program.sponsors.length === 0) {
    return <i>No sponsors</i>
  }
  return (
    <div>
      <SponsorList sponsors={normalizeSponsors(program.sponsors)} link />
    </div>
  )
}

const step = Sponsors

const arrayEmpty = arr => !(Array.isArray(arr) && arr.length > 0)

const complete = ({ draft, info, sponsors }) => {
  if (draft) {
    if (!info || (typeof info.hasSponsors !== 'boolean' || (info.hasSponsors && arrayEmpty(sponsors)))) {
      return false
    }
  }
  return true
}

const applicable = () => true

export default { step, Viewer, complete, applicable }
