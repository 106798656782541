import React, { useContext, useEffect, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { useParams, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { message, Progress, Radio, Tabs, Input, Modal, Typography, Menu, Dropdown, Tag, Tooltip, Table } from 'antd'
import { Noner, Cta as CtaButton, Button } from '../../components/common'
import { ExportOutlined, ExclamationCircleOutlined, SyncOutlined, PlusOutlined, EllipsisOutlined, InfoCircleOutlined } from '@ant-design/icons'
import {
  changeBookingNote,
  cancelBooking,
  addToWaitlist,
  updateWaitlistUser,
  removeFromWaitlist,
  downloadProgramBookingsCsv,
} from '../../actions/class'
import { downloadDataAsFile } from '../../util/download'
import { validate } from 'uuid'
import { createManualBooking } from '../../actions/org'
import { clearStatus } from '../../actions/status'
import { useStatus, useStatusMsg } from '../../reducers'
import { formatDateTimeTz } from '../../util/time'
import programUtils from '../../util/program'
import programConsts from '../../constants/program'
import { actions as acts, paths } from '../../constants'
import Container from './Container'
import BookStatusTag from '../../components/BookStatusTag'
import UserSelect from '../../components/UserSelect'
import PlayerLevel from '../../components/PlayerLevel'
import BadgeTitle from '../../components/BadgeTitle'
import Attr from '../../components/Attr'
import * as userUtils from '../../util/user'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)

const { TabPane } = Tabs

const Participant = ({ booking }) => {
  const theme = useContext(ThemeContext)
  const purchaser = booking.purchaser
  const participantName = programUtils.participantNameFromBooking(booking)
  const purchaserSame = booking.participant?.id === purchaser.id

  let nameElem = participantName ? participantName : <i>No participant info</i>
  if (booking.participant) {
    nameElem = <Link to={paths.admin.USER(booking.participant.id)}>{participantName}</Link>
  }
  return (
    <>
      <div>{nameElem}</div>
      <small>{programUtils.participantEmailFromBooking(booking)}</small>
      <pre><small>{booking.id}</small></pre>
      { !purchaserSame &&
        <small style={{color: theme.font.color.secondary}}>
          <div>Purchased by</div> <Link style={{display: 'inline'}} to={paths.admin.USER(purchaser.id)}>{userUtils.formatName(purchaser)}</Link> {purchaser.email}
        </small>
      }
    </>
  )
}

const bookingColumns = [
  {
    title: 'Participant',
    dataIndex: 'participant',
    key: 'participant',
    width: 200,
    sorter: (a, b) => programUtils.participantNameFromBooking(a).localeCompare(programUtils.participantNameFromBooking(b)),
    render: (val, record) => {
      return <Participant booking={record} />
    }
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: 50,
    render: (val, record) => (
      <BookStatusTag status={val} />
    )
  },
  {
    title: 'Benefit',
    dataIndex: 'benefitBooking',
    key: 'benefitBooking',
    width: 100,
    render: (val, record) => {
      if (!val) {
        return <Noner>{null}</Noner>
      }
      return (
        <Tag color={val.benefit.color}>{val.benefit.name}</Tag>
      )
    },
  },
  {
    title: 'Booking time',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 150,
    defaultSortOrder: 'descend',
    sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    render: (val, record) => (
      formatDateTimeTz(record.createdAt)
    )
  },
  {
    title: () => (
      <div style={{display: 'flex', alignItems: 'center'}}>
        <span style={{marginRight: '.25em'}}>Note</span>
        <Tooltip title="Notes are not visible to the user"><InfoCircleOutlined /></Tooltip>
      </div>
    ),
    dataIndex: 'note',
    key: 'note',
    width: 200,
    render: (val, record) => val
  },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
    width: 10,
    render: (val, record) => (
      <Dropdown overlay={<BookingActionsMenu record={record} />} trigger={['click']}>
        <EllipsisOutlined className="ant-dropdown-link" style={{fontSize: '1.75em', fontWeight: 'bold'}} />
      </Dropdown>
    )
  },
]

const waitlistColumns = [
  {
    title: 'Player',
    dataIndex: 'user',
    key: 'user',
    width: 200,
    sorter: (a, b) => a.user.name.localeCompare(b.user.name),
    render: (val, record) => (
      <>
        <div>
          <Link to={paths.admin.USER(val.id)}>{userUtils.formatName(val)}</Link>
        </div>
        <small>{val.email}</small>
        <div>
          <PlayerLevel user={val} />
        </div>
      </>
    )
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 150,
    defaultSortOrder: 'descend',
    sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    render: (val, record) => (
      formatDateTimeTz(record.createdAt)
    )
  },
  {
    title: () => (
      <div style={{display: 'flex', alignItems: 'center'}}>
        <span style={{marginRight: '.25em'}}>Notes</span>
        <Tooltip title="Notes are not visible to the user"><InfoCircleOutlined /></Tooltip>
      </div>
    ),
    dataIndex: 'notes',
    key: 'notes',
    width: 200,
    render: (val, record) => val
  },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
    width: 10,
    render: (val, record) => (
      <Dropdown overlay={<WaitlistActionsMenu record={record} />} trigger={['click']}>
        <EllipsisOutlined className="ant-dropdown-link" style={{fontSize: '1.75em', fontWeight: 'bold'}} />
      </Dropdown>
    )
  },
]

const WaitlistNotesModal = ({ waitlistUser, complete }) => {
  const { osid } = useParams()
  const dispatch = useDispatch()
  const status = useStatus(acts.UPDATE_WAITLIST_USER)
  const program = useSelector(s => s.currentProgram)
  const [notes, setNotes] = useState(waitlistUser.notes)
  const { user } = waitlistUser

  const onOk = () => {
    dispatch(updateWaitlistUser({ osid, programId: program.id, userId: user.id, notes }))
      .then(() => {
        if (typeof complete === 'function') {
          complete()
        }
      })
  }
  const onCancel = () => {
    if (typeof complete === 'function') {
      complete()
    }
  }

  return (
    <Modal
      title={`Waitlist notes for ${userUtils.formatName(user)}`}
      visible={true}
      onOk={onOk}
      confirmLoading={status.pending}
      onCancel={onCancel}
    >
      <Input.TextArea
        rows={4}
        defaultValue={notes}
        value={notes}
        onChange={e => setNotes(e.target.value)}
      />
    </Modal>
  )
}

const NoteModal = ({ setChangingNote, booking }) => {
  const { osid } = useParams()
  const dispatch = useDispatch()
  const status = useStatus(acts.CHANGE_BOOKING_NOTE)
  const program = useSelector(s => s.currentProgram)
  const [note, setNote] = useState(booking.note)

  const onOk = async () => {
    await dispatch(changeBookingNote(osid, program.id, booking.id, note))
    setChangingNote(false)
  }
  const onCancel = () => setChangingNote(false)

  const participantName = programUtils.participantNameFromBooking(booking)
  return (
    <Modal
      title={`Booking note ${participantName ? `for ${participantName}` : ''}`}
      visible={true}
      onOk={onOk}
      confirmLoading={status.pending}
      onCancel={onCancel}
    >
      <Input.TextArea
        rows={4}
        defaultValue={booking.note}
        value={note}
        onChange={e => setNote(e.target.value)}
      />
    </Modal>
  )
}

const SingleUserSelect = ({ user, onChange }) => {
  return (
    <UserSelect
      placeholder="Search users by name"
      style={{width: '100%'}}
      value={user}
      onChange={onChange}
    />
  )
}

const MultiUserSelect = ({ onChange }) => {
  const changer = typeof onChange === 'function' ? onChange : () => {}
  const update = e => {
    const input = e.target.value
    if (input) {
      const parts = input.split(',').map(i => i.trim().toLowerCase()).filter(Boolean)
      changer([...new Set(parts)]) // remove duplicates
    } else {
      changer([])
    }
  }
  return (
    <div>
      <Input.TextArea onChange={update} />
    </div>
  )
}

const MultiUserDisplay = ({ users, usersStatusMap, onRemove }) => {
  let elems = null
  if (Array.isArray(users)) {
    elems = users.map(u => {
      let color = 'default'
      if (usersStatusMap.hasOwnProperty(u)) {
        color = usersStatusMap[u].status
      }
      let icon = null
      if (color === 'error') {
        icon = <ExclamationCircleOutlined />
      } else if (color === 'processing') {
        icon = <SyncOutlined spin />
      }
      let tooltip = null
      if (color === 'error') {
        tooltip = usersStatusMap[u].message
      }
      return (
        <Tooltip title={tooltip}>
          <Tag
            key={u}
            closable={typeof onRemove === 'function'}
            onClose={() => onRemove(u)}
            color={color}
            icon={icon}
          >
            {u}
          </Tag>
        </Tooltip>
      )
    })
  }
  const finishedUsers = Object.values(usersStatusMap).reduce((acc, curr) => {
    if (curr.status !== 'processing') {
      return acc + 1
    }
    return acc
  }, 0)

  const percentComplete = (!Array.isArray(users) || users.length === 0) ? 0 : ((finishedUsers / users.length) * 100).toFixed(0)
  return (
    <div>
      <Progress percent={percentComplete} />
      {elems}
    </div>
  )
}

const CreateBookingModal = ({ complete }) => {
  const { osid } = useParams()
  const dispatch = useDispatch()
  const status = useStatus(acts.CREATE_MANUAL_BOOKING)
  const program = useSelector(s => s.currentProgram)
  // map that tracks booking creation status of users in multi mode
  // looks like { status: '', message: '' }
  // status can be one of 'processing', 'success', or 'error'. they match the antd <Tag> color values
  const [usersStatusMap, setUsersStatusMap] = useState({})
  const [mode, setMode] = useState('single')
  const [user, setUser] = useState(null)
  const [users, setUsers] = useState(null)
  const [bookingDate, setBookingDate] = useState('today') // values are: today, program-date
  const [addingMultiUsers, setAddingMultiUsers] = useState(false)
  const completer = typeof complete === 'function' ? complete : () => {}

  useEffect(() => {
    return () => dispatch(clearStatus(acts.CREATE_MANUAL_BOOKING))
  }, [])

  const onOk = async () => {
    const formComplete = (mode === 'single' && user !== null) ||
      (mode === 'multi' && (Array.isArray(users) && users.length > 0))
    if (!formComplete) {
      message.error('At least one user must be specified')
      return
    }

    setUsersStatusMap({})

    const params = { osid, programId: program.id }
    if (bookingDate === 'program-date') {
      params.bookingDate = dayjs.tz(program.date, "YYYY-MM-DD", program.tz).startOf('day').toISOString()
    }
    if (mode === 'multi') {
      setAddingMultiUsers(true)
      // a reducer updates the list of bookings after these requests.
      // because we don't know the order, of multiple requests, we could get an inconsistent view of the number of bookings
      // in a program. a TODO fix would be get and update all bookings after all the requests have finished
      Promise.all(users.map(u => {
        let act = null
        if (validate(u)) {
          act = createManualBooking({ ...params, userId: u })
        } else {
          act = createManualBooking({ ...params, email: u })
        }
        setUsersStatusMap(prev => ({
          ...prev,
          [u]: { ...prev[u], status: 'processing' }
        }))
        return dispatch(act)
          .then(() => {
            setUsersStatusMap(prev => ({
              ...prev,
              [u]: { ...prev[u], status: 'success' }
            }))
          })
          .catch(e => {
            console.log(e)
            setUsersStatusMap(prev => ({
              ...prev,
              [u]: { ...prev[u], status: 'error', message: e.message },
            }))
          })
      }))
      .then(() => {
        setAddingMultiUsers(false)
      })
    } else {
      const bookings = await dispatch(createManualBooking({ ...params, userId: user.id }))
      completer(bookings)
    }
  }

  const onCancel = () => {
    completer()
  }

  const onUserRemove = toRemove => {
    setUsers(prev => prev.filter(u => u !== toRemove))
    setUsersStatusMap(prev =>
      Object.keys(prev).reduce((acc, curr) => {
        if (curr !== toRemove) {
          acc[curr] = prev[curr]
        }
        return acc
      }, {})
    )
  }

  const onChangeMode = e => {
    setUser(null)
    setUsers(null)
    setMode(e.target.value)
  }
  const onChangeBookingDate = e => {
    setBookingDate(e.target.value)
  }

  return (
    <Modal
      title="Create booking"
      okText="Create"
      visible
      onOk={onOk}
      confirmLoading={status.pending}
      onCancel={onCancel}
    >
      <div style={{marginBottom: '1em'}}>
        <Radio.Group onChange={onChangeMode} value={mode}>
          <Radio value="single">Single</Radio>
          <Radio value="multi">Multiple</Radio>
        </Radio.Group>
      </div>
      <Attr
        name={mode === 'single' ? 'User' : 'Users'}
        description={mode === 'multi' ? 'Enter user emails or ids separated by a comma' : null}
      >
        <div style={{marginTop: '.5em'}}>
          { mode === 'single' && <SingleUserSelect user={user} onChange={u => setUser(u)} /> }
          { mode === 'multi' &&
            <>
              <MultiUserSelect onChange={usrs => setUsers(usrs)} />
              <div style={{marginTop: '1em'}}>
                <MultiUserDisplay
                  users={users}
                  usersStatusMap={usersStatusMap}
                  onRemove={addingMultiUsers ? null : onUserRemove}
                />
              </div>
            </>
          }
        </div>
      </Attr>
      <Attr name="Booking date" description="Select the date booking(s) should be recorded for">
        <div>
          <Radio.Group onChange={onChangeBookingDate} value={bookingDate}>
            <Radio value="today">Today</Radio>
            <Radio value="program-date">Event date ({program.date})</Radio>
          </Radio.Group>
        </div>
      </Attr>
    </Modal>
  )
}

const AddToWaitlistModal = ({ complete }) => {
  const { osid } = useParams()
  const dispatch = useDispatch()
  const status = useStatus(acts.ADD_TO_PROGRAM_WAITLIST)
  const program = useSelector(s => s.currentProgram)
  const [user, setUser] = useState(null)
  const [notes, setNotes] = useState(null)

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.ADD_TO_PROGRAM_WAITLIST))
    }
  }, [])

  useStatusMsg(status, {
    pending: 'Adding to waitlist...',
    error: 'Failed to add to waitlist',
    success: 'Added user to waitlist',
  })

  const onOk = async () => {
    await dispatch(addToWaitlist({
      osid, programId: program.id, userId: user.id, notes
    }))
    if (typeof complete === 'function') {
      complete()
    }
  }

  const onCancel = () => {
    if (typeof complete === 'function') {
      complete()
    }
  }

  return (
    <Modal
      title="Add to waitlist"
      visible
      onOk={onOk}
      confirmLoading={status.pending}
      onCancel={onCancel}
    >
      <Attr name="User">
        <div>
          <UserSelect
            placeholder="Search users by name"
            style={{width: '100%'}}
            value={user}
            onChange={e => setUser(e)}
          />
        </div>
      </Attr>
      <Attr name="Notes">
        <div>
          <Input.TextArea
            rows={4}
            value={notes}
            onChange={e => setNotes(e.target.value)}
          />
        </div>
      </Attr>
    </Modal>
  )
}

const WaitlistActionsMenu = record => {
  const { osid } = useParams()
  const dispatch = useDispatch()
  const status = {
    update: useStatus(acts.UPDATE_WAITLIST_USER),
    remove: useStatus(acts.REMOVE_FROM_PROGRAM_WAITLIST),
  }
  const program = useSelector(s => s.currentProgram)
  const [changingNotes, setChangingNotes] = useState(false)
  const { id, user } = record.record

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.REMOVE_FROM_PROGRAM_WAITLIST))
      dispatch(clearStatus(acts.UPDATE_WAITLIST_USER))
    }
  }, [])

  useStatusMsg(status.update, {
    pending: 'Updating...',
    error: 'Failed to update notes',
    success: 'Updated notes',
  })

  useStatusMsg(status.remove, {
    pending: 'Removing...',
    error: 'Failed to remove user from waitlist',
    success: 'User removed from waitlist',
  })


  if (changingNotes) {
    return (
      <WaitlistNotesModal
        waitlistUser={record.record}
        complete={() => setChangingNotes(false)}
      />
    )
  }

  const handleMenu = e => {
    if (e.key === 'edit') {
      setChangingNotes(true)
    } else if (e.key === 'remove') {
      Modal.confirm({
        title: 'Warning',
        okText: 'Remove user',
        okButtonProps: { type: 'danger' },
        content: (
          <div>
            <p>Remove user from waitlist?</p>
          </div>
        ),
        onOk: async () => await dispatch(removeFromWaitlist({
          osid, programId: program.id, userId: user.id
        })),
      })
    }
  }

  return (
    <Menu onClick={handleMenu}>
      <Menu.Item key="edit">
        Edit notes
      </Menu.Item>
      <Menu.Item key="remove">
        <Typography.Text type="danger">Remove</Typography.Text>
      </Menu.Item>
    </Menu>
  )
}

const BookingActionsMenu = record => {
  const { osid } = useParams()
  const dispatch = useDispatch()
  const cancelStatus = useStatus(acts.CANCEL_PROGRAM_BOOKING)
  const program = useSelector(s => s.currentProgram)
  const [changingNote, setChangingNote] = useState(false)
  const { id, note, status, invoice } = record.record

  useEffect(() => {
    dispatch(clearStatus(acts.CANCEL_PROGRAM_BOOKING))
    return () => {
      dispatch(clearStatus(acts.CANCEL_PROGRAM_BOOKING))
    }
  }, [])

  useStatusMsg(cancelStatus, {
    pending: 'Canceling booking',
    error: 'Failed to cancel booking',
    success: 'Booking canceled',
  })

  const warning = () => {
    Modal.confirm({
      title: 'Warning',
      okText: 'Cancel Booking',
      okButtonProps: { type: 'danger' },
      content: (
        <div>
          <p>The user will not be refunded</p>
        </div>
      ),
      onOk: () => dispatch(cancelBooking(osid, program.id, id)),
    })
  }

  if (changingNote) {
    return <NoteModal booking={record.record} setChangingNote={setChangingNote} />
  }

  return (
    <Menu>
      { invoice &&
        <Menu.Item key="0">
          <Link to={paths.admin.billing.INVOICE(invoice.id)}>View invoice</Link>
        </Menu.Item>
      }
      <Menu.Item key="1">
        <a onClick={() => setChangingNote(true)}>
          {note && note.length > 0 ? 'Edit note' : 'Add note'}
        </a>
      </Menu.Item>
      { status !== programConsts.bookingStatusTypes.CANCELED.name &&
        <Menu.Item key="2">
          <a onClick={warning}>
            <Typography.Text type="danger">Cancel booking</Typography.Text>
          </a>
        </Menu.Item>
      }
    </Menu>
  )
}

const Bookings = () => {
  const { osid } = useParams()
  const theme = useContext(ThemeContext)
  const dispatch = useDispatch()
  const program = useSelector(s => s.currentProgram)
  const [creatingBooking, setCreatingBooking] = useState(false)
  const [addingToWaitlist, setAddingToWaitlist] = useState(false)

  const onExportBookings = () => {
    dispatch(downloadProgramBookingsCsv({ osid, programId: program.id })).then(data => {
      downloadDataAsFile({
        data,
        filename: `${program.name}-bookings-${dayjs().format('YYYY-MM-DD')}.csv`,
        filetype: 'text/csv;charset=utf-8;',
      })
    })
  }

  return (
    <Container>
      <Tabs defaultActiveKey="bookings">
        <TabPane tab={<BadgeTitle color="white" backgroundColor="gray" title="Bookings" count={`${programUtils.countBookings(program.bookings)} booked`} />} key="bookings">
          <h2>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <span>Bookings</span>
              <div>
                <Button
                  style={{marginRight: theme.spacing[2]}}
                  onClick={onExportBookings}
                >
                  <ExportOutlined style={{ fontSize: '14px' }} /> Export
                </Button>
                <CtaButton onClick={() => setCreatingBooking(true)}><PlusOutlined /> New booking</CtaButton>
              </div>
            </div>
          </h2>
          <Table
            rowKey="id"
            columns={bookingColumns}
            dataSource={program.bookings}
            pagination={false}
          />
        </TabPane>
        <TabPane tab={<BadgeTitle color="white" backgroundColor="gray" title="Waitlist" count={program.waitlist.length} />} key="waitlist">
          <h2>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <span>Waitlist</span>
              <CtaButton onClick={() => setAddingToWaitlist(true)}><PlusOutlined /> Add to waitlist</CtaButton>
            </div>
          </h2>
          <Table
            rowKey="id"
            columns={waitlistColumns}
            dataSource={program.waitlist}
            pagination={false}
          />
        </TabPane>
      </Tabs>
      { addingToWaitlist &&
          <AddToWaitlistModal
            complete={booking => setAddingToWaitlist(false)}
          />
      }
      { creatingBooking &&
          <CreateBookingModal
            complete={booking => setCreatingBooking(false)}
          />
      }
    </Container>
  )
}

export default Bookings
