import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { message } from 'antd'
import { useStatus, useStatusMsg } from '../../reducers'
import { createProduct } from '../../actions/org'
import { FocusHeader } from '../../components/header'
import { Focus as FocusContainer } from '../../components/containers'
import { Cta, Button } from '../../components/common'
import { clearStatus } from '../../actions/status'
import { paths, actions as acts } from '../../constants'
import * as productConsts from '../../constants/product'
import { ProductEditor } from './editors'

const NewProduct = () => {
  const { osid } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const [product, setProduct] = useState(null)
  const status = useStatus(acts.CREATE_PRODUCT)

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.CREATE_PRODUCT))
    }
  }, [])

  useStatusMsg(status, {
    pending: 'Creating...',
    error: 'Failed to create product',
    success: res => {
      return (
        <>
          Product created. <a href={paths.org.PRODUCT(osid, res.id)}>View</a>
        </>
      )
    },
  })

  const missingFields = () => {
    const missing = ['type', 'name'].filter(k => {
      if (!product[k]) {
        return true
      }
      return false
    })
    if (missing.length > 0) {
      const plural = missing.length > 1
      const msg = `${missing.join(', ')} ${plural ? 'are' : 'is'} missing or ${plural ? 'have' : 'has'} invalid value${plural ? 's' : ''}`
      message.error(`${msg.charAt(0).toUpperCase()}${msg.substr(1)}`)
      return true
    }
    if (
      product.type !== productConsts.types.WALMART.name && // walmart products don't require an image
      (!product.images || (Array.isArray(product.images) && product.images.length === 0))
    ) {
      message.error('At least one product image is required')
      return true
    }

    if (product.type === productConsts.types.WALMART.name && !product.walmartItemId) {
      message.error('Walmart item ID is required on Walmart products')
      return true
    }

    if (product.type === productConsts.types.ONSITE.name && typeof product.priceCents !== 'number') {
      message.error('A price is required for onsite products')
      return true
    }

    return false
  }

  const submitProduct = createAnother => () => {
    if (missingFields()) {
      return
    }
    dispatch(createProduct({ osid, ...product }))
      .then(prd =>  {
        if (createAnother) {
          setProduct(null)
        } else {
          history.push(paths.org.PRODUCT(osid, prd.id))
        }
      })
      .catch(() => {})
  }

  return (
    <>
      <FocusHeader
        title="Create a product"
        actions={
          <>
            <Button
              style={{marginRight: '.5em'}}
              onClick={submitProduct(true)}
              size="small"
              disabled={status.pending ? true : false}
            >
              Submit and create another
            </Button>
            <Cta
              disabled={status.pending ? true : false}
              onClick={submitProduct(false)}
            >
              {status.pending ? 'Creating product...' : 'Create product'}
            </Cta>
          </>
        }
      />
      <FocusContainer style={{paddingTop: '20px', paddingBottom: '20px'}}>
        <ProductEditor product={product} onChange={s => setProduct(s)} />
      </FocusContainer>
    </>
  )
}

export default NewProduct
