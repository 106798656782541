import { actions as acts } from '../constants'

export const initState = {
  id: null,
  type: null,
  name: null,
  firstName: null,
  lastName: null,
  email: null,
  number: null,
  createdAt: null,
  updatedAt: null,
}

const user = (state=initState, { type, payload }) => {
  switch(type) {
    case acts.FETCH_USER_SUCCESS:
      return payload
    default:
      return state
  }
}

export default user
