import styled from 'styled-components'

export const Section = styled.div`
  margin-bottom: ${props => props.theme.spacing[4]};
`

export const SectionHead = styled.div`
  margin-bottom: ${props => props.theme.spacing[3]};
`

export const SectionTitle = styled.h2`
  display: inline;
  font-size: ${props => props.theme.font.size[5]};
`
export const SectionIcon = styled.span`
  font-size: ${props => props.theme.font.size[6]};
  margin-left: ${props => props.theme.spacing[2]};
  color: ${props => props.theme.color.gray[3]};
`

export const Label = styled.label`
  display: inline-block;
  font-weight: ${props => props.theme.font.weight[6]};
  margin-bottom: ${props => props.theme.spacing[2]};
`

export const Group = styled.div`
  margin-bottom: ${props => props.theme.spacing[3]};
`

export const Footer = styled.div`
  margin-bottom: ${props => props.theme.spacing[3]};
  float: right;
`

export const Inline = styled.div`
  display: flex;

  &&& > * {
    margin-right: ${props => props.theme.spacing[2]};
  }

  &&& > :last-child {
    margin-right: 0;
  }
`
