import { useContext, useEffect, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { Link, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Cta, Head, Title, PaddedContainer } from '../../components/common'
import { EllipsisOutlined, LoadingOutlined, DownOutlined } from '@ant-design/icons'
import { Menu, Button, Tag, Breadcrumb, Dropdown } from 'antd'
import { fetchOrder } from '../../actions/org'
import { urls, actions as acts, paths, features } from '../../constants'
import { useStatus, useStatusMsg } from '../../reducers'
import { Invoice } from '../../containers/admin/invoice/Invoice'
import { MiniInfo, MediumInfo, Divider } from '../../components/info'
import { Noner } from '../../components/common'
import JSONModal from '../../components/json-modal'

//const ProductItem = ({ item }) => {
//  return (
//    <div>
//        {item}
//    </div>
//  )
//}

const OrderItem = ({ item }) => {
  const [showInfo, setShowInfo] = useState(false)
  const handleAction = e => {
    if (e.key === "info") {
      setShowInfo(true)
      return
    }
  }
  const actionMenu = (
    <Menu onClick={handleAction}>
      <Menu.Item key="info">More info</Menu.Item>
    </Menu>
  )
  return (
    <>
      <div style={{marginBottom: '1em', display: 'flex', alignItems: 'center'}}>
        <small><pre style={{marginBottom: 0, marginRight: '1em'}}>{item.id}</pre></small>
        <Tag>{item.type}</Tag>
        <Dropdown overlay={actionMenu} trigger={['click']}>
          <Button size="small">
            <EllipsisOutlined />
          </Button>
        </Dropdown>
      </div>
      { showInfo && <JSONModal title={item.id} json={item.info} complete={() => setShowInfo(false)} /> }
    </>
  )
}

const OrderItems = ({ order }) => {
  const elems = order.items.map(oi => (
    <OrderItem key={oi.id} item={oi} />
  ))
  return (
    <div>
      {elems}
    </div>
  )
}

const ShippingInformation = ({ order }) => {
  if (!order.shippingAddress) {
    return <Noner none="No shipping information">{null}</Noner>
  }
  const {
    recipientName,
    companyName,
    phoneNumber,
    line1,
    line2,
    city,
    state,
    postcode,
    country,
  } = order.shippingAddress

  return (
    <div>
      <div>{recipientName}</div>
      <div>{companyName ? companyName : null}</div>
      <div>{phoneNumber}</div>
      <div>{line1}</div>
      <div>{line2}</div>
      <div>{city}, {state} {postcode}</div>
      <div>{country}</div>
    </div>
  )
}

const Order = () => {
  const { osid, orderId } = useParams()
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const [order, setOrder] = useState(null)
  const [fulfilling, setFulfilling] = useState(false)
  const status = useStatus(acts.FETCH_ORDER)

  useEffect(() => {
    dispatch(fetchOrder({ osid, orderId }))
      .then(o => setOrder(o)) 
  }, [])

  useStatusMsg(status, {
    error: 'Failed to load order',
  })

  const handleActionMenu = e => {
    if (e.key === 'fulfill') {
      setFulfilling(true)
    }
  }

  const actionMenu = (
    <Menu onClick={handleActionMenu}>
      <Menu.Item key="fulfill">Mark as fulfilled</Menu.Item>
    </Menu>
  )

  let display;
  if (status.pending || !order) {
    display = <LoadingOutlined />
  } else {
    display = (
      <>
        <Head>
          <Title>Order</Title>
          <div style={{ flexGrow: 1 }}></div>
          <div style={{ display: 'flex' }}>
            <Dropdown overlay={actionMenu} trigger={['click']} placement="bottomRight">
              <Cta>
                Actions <DownOutlined />
              </Cta>
            </Dropdown>
          </div>
        </Head>
        <div style={{display: 'flex', marginBottom: theme.spacing[2]}}>
          <MiniInfo title="Status" info={<Tag>{order.status}</Tag>} style={{marginLeft: 0}} />
          <Divider />
          <MiniInfo title="User" info={order.user.name} />
          <Divider />
          <MiniInfo title="Note" info={<Noner>{order.note}</Noner>} />
          <Divider />
          <MiniInfo title="Created" info={order.createdAt} />
        </div>
        <MediumInfo title="Items">
          <OrderItems order={order} />
        </MediumInfo>
        <MediumInfo title="Shipping information">
          <ShippingInformation order={order} />
        </MediumInfo>
        <MediumInfo title="Invoice">
          <div style={{maxWidth: '1024px'}}>
            <Invoice id={order.invoice} />
          </div>
        </MediumInfo>
      </>
    )
  }
  return (
    <PaddedContainer>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={paths.org.ORDERS(osid)}>{features.order.name.plural}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{orderId}</Breadcrumb.Item>
      </Breadcrumb>
      {display}
    </PaddedContainer>
  )
}

export default Order
