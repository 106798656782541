import React from 'react'
import { useParams, Redirect } from 'react-router-dom'
import { paths } from '../../constants'
import { isLoggedIn } from '../../util/auth'

const Home = () => {
  const { osid } = useParams()
  if (isLoggedIn()) {
    return (
      <Redirect to={paths.org.PROGRAMS(osid)} />
    )
  }
  return (
    <Redirect to={paths.partner.LOGIN()} />
  )
}

export default Home
