import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { message, Button } from 'antd'
import { useStatus, useStatusMsg } from '../../reducers'
import { createTag } from '../../actions/org'
import { FocusHeader } from '../../components/header'
import { Focus as FocusContainer } from '../../components/containers'
import { Cta } from '../../components/common'
import { clearStatus } from '../../actions/status'
import { paths, actions as acts } from '../../constants'
import TagEditor from './TagEditor'

const NewOrgTag = () => {
  const { osid } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const [tag, setTag] = useState(null)
  const status = useStatus(acts.CREATE_ORG_TAG)

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.CREATE_ORG_TAG))
    }
  }, [])

  useStatusMsg(status, {
    pending: 'Creating...',
    error: 'Failed to create tag',
    success: res => {
      return (
        <>
          Tag created. <a href={paths.org.ORG_TAG(osid, res.name)}>View</a>
        </>
      )
    },
  })

  const missingFields = () => {
    const missing = ['name'].filter(k => {
      if (!tag[k]) {
        return true
      }
      return false
    })
    if (missing.length > 0) {
      const msg = `${missing.join(', ')} ${missing.length > 1 ? 'are' : 'is'} required`
      message.error(`${msg.charAt(0).toUpperCase()}${msg.substr(1)}`)
      return true
    }
    return false
  }

  const submitTag = createAnother => () => {
    if (missingFields()) {
      return
    }
    dispatch(createTag({ osid, ...tag }))
      .then(tg =>  {
        if (createAnother) {
          setTag(null)
        } else {
          history.push(paths.org.TAG(osid, tg.id))
        }
      })
      .catch(() => {})
  }

  return (
    <>
      <FocusHeader
        title="Create a tag"
        actions={
          <>
            <Button
              style={{marginRight: '.5em'}}
              onClick={submitTag(true)}
              size="small"
              disabled={status.pending ? true : false}
            >
              Submit and create another
            </Button>
            <Cta
              disabled={status.pending ? true : false}
              onClick={submitTag(false)}
            >
              {status.pending ? 'Submitting tag...' : 'Submit tag'}
            </Cta>
          </>
        }
      />
      <FocusContainer style={{paddingTop: '20px', paddingBottom: '20px'}}>
        <TagEditor tag={tag} onChange={t => setTag(t)} />
      </FocusContainer>
    </>
  )
}

export default NewOrgTag
