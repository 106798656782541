import { descConst as dc } from './util'

export const types = {
  ...dc('ADMIN', 'Admin', ''),
  ...dc('PLAYER', 'Player', ''),
  ...dc('PRO', 'Pro', ''),
}

export const genders = {
  ...dc('male', 'Male', ''),
  ...dc('female', 'Female', ''),
  ...dc('other', 'Other', ''),
}

export const playerLevels = {
  ...dc('BEGINNER', 'Beginner', ''),
  ...dc('BEGINNER_PLUS', 'Beginner+', ''),
  ...dc('INTERMEDIATE', 'Intermediate', ''),
  ...dc('INTERMEDIATE_PLUS', 'Intermediate+', ''),
  ...dc('ADVANCED', 'Advanced', ''),
  ...dc('ADVANCED_PLUS', 'Advanced+', ''),
}
