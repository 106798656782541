import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { fetchBenefits, fetchBeneficiaryGroups } from '../actions/org'
import * as adminActs from '../actions/admin'
import { clearStatus } from '../actions/status'
import { useStatus, useStatusMsg } from '../reducers'
import { actions as acts } from '../constants'
import { Select, Checkbox } from 'antd'

const { Option } = Select

const CheckboxStyler = styled.div`
  .ant-checkbox-wrapper {
    margin-left: 0;
  }
`

export const BeneficiaryGroupCheckbox = ({ benefitId, value, onChange }) => {
  const { osid } = useParams()
  const dispatch = useDispatch()
  const [beneficiaryGroups, setBeneficiaryGroups] = useState(null)
  const [selectedGroupIds, setSelectedGroupIds] = useState(value)
  const changer = typeof onChange === 'function' ? onChange : () => {}

  useEffect(() => {
    dispatch(fetchBeneficiaryGroups({ osid, benefitId }))
      .then(bgs =>
        setBeneficiaryGroups(bgs.sort((a, b) => a.name.localeCompare(b.name)))
      )
  }, [osid, benefitId])

  if (!beneficiaryGroups) {
    return 'Loading...'
  }
  const onCheck = gids => {
    setSelectedGroupIds(gids)
    changer(gids)
  }

  const options = beneficiaryGroups.map(bg => ({
    label: bg.name,
    value: bg.id,
    checked: selectedGroupIds?.includes(bg.id)
  }))

  return (
    <CheckboxStyler>
      <Checkbox.Group
        options={options}
        onChange={onCheck}
        value={selectedGroupIds}
        style={{display: 'flex', flexDirection: 'column'}}
      />
    </CheckboxStyler>
  )
}

const TemplateBenefitSelect = ({ fetcher, action, value, onChange, allowEmpty, emptyLabel='None', ...props }) => {
  const dispatch = useDispatch()
  const [benefits, setBenefits] = useState({}) // map of benefit id to benefit object
  const [val, setVal] = useState(null)
  const status = useStatus(action)

  useEffect(() => {
    dispatch(fetcher)
      .then(bnfs => {
        setBenefits(
          bnfs.reduce((acc, curr) => {
            acc[curr.id] = curr
            return acc
          }, {})
        )
      })
  }, [fetcher])

  useEffect(() => {
    return () => dispatch(clearStatus(action))
  }, [])

  useEffect(() => {
    // convert value to benefit id when benefit list or value changes
    if (benefits && Object.keys(benefits).length > 0 && value) {
      setVal(value.id)
    }
  }, [benefits, value])

  useStatusMsg(status, { error: 'Failed to fetch benefits' })

  if (!status.success) {
    return 'Loading...'
  }

  let opts = Object.keys(benefits).map(id => {
    const b = benefits[id]
    return (
      <Option key={b.id} value={b.id}>
        {b.name} <small>{b.sid}</small>
      </Option>
    )
  })
  if (allowEmpty) {
    opts = [<Option key={null} value={null}>{emptyLabel}</Option>, ...opts]
  }

  const handleChange = bid => {
    setVal(bid)
    if (typeof onChange === 'function') {
      onChange(benefits[bid])
    }
  }

  return (
    <Select
      value={val}
      onChange={handleChange}
      showSearch
      filterOption={(input, option) => { 
        const inp = input.toLowerCase()
        const value = option.children
        // value[0] references the sponsor's name
        const matchesOption = value[0]?.toLowerCase().indexOf(inp) >= 0
        return matchesOption || option.value?.toLowerCase().indexOf(inp) >= 0
      }}
      {...props}
    >
      {opts}
    </Select>
  )
}

export const AdminBenefitSelect = ({ ...props }) => {
  return <TemplateBenefitSelect fetcher={adminActs.fetchBenefits()} action={acts.FETCH_ADMIN_BENEFITS} {...props} />
}

export const BenefitSelect = ({ ...props }) => {
  const { osid } = useParams()
  return <TemplateBenefitSelect fetcher={fetchBenefits({ osid })} action={acts.FETCH_BENEFITS} {...props} />
}

export default BenefitSelect
