import Attr from '../../components/Attr'
import formatUtils from '../../util/format'

const replaceWmtUrlPub = (url) => {
  // replacing with any numbers works for now
  return url.replace(/\|PUBID\|/, '1235')
}

export const WalmartProductPreview = ({ name, description, product }) => {
  let productDisplay;
  if (product) {
    productDisplay = (
      <div>
        { product.stock &&
          <Attr name="Stock">
            <div>{product.stock}</div>
          </Attr>
        }
        { product.msrp && 
          <Attr name="MSRP">
            <div>{formatUtils.dollars(product.msrp)}</div>
          </Attr>
        }
        { product.salePrice && 
          <Attr name="Sale price">
            <div>{formatUtils.dollars(product.salePrice)}</div>
          </Attr>
        }
        <Attr name="Links">
          <div>
            <a target="_blank" href={replaceWmtUrlPub(product.productTrackingUrl)}>Product page</a>
          </div>
          <div>
            <a target="_blank" href={replaceWmtUrlPub(product.affiliateAddToCartUrl)}>Add to cart</a>
          </div>
        </Attr>
        <Attr name="Primary image">
          <div>
            <img src={product.largeImage} height="300px" />
          </div>
        </Attr>
      </div>
    )
  }
  return (
    <div>
      <h3>Product preview</h3>
      <Attr name="Name">
        <div>{name}</div>
      </Attr>
      <Attr name="Description">
        <div>{description}</div>
      </Attr>
      {productDisplay}
    </div>
  )
}

