import React, { useEffect, useState } from 'react'
import { message, Button } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useStatus, useStatusMsg } from '../../reducers'
import { createClubVideo } from '../../actions/org'
import { clearStatus } from '../../actions/status'
import { FocusHeader } from '../../components/header'
import { Cta } from '../../components/common'
import { Focus as FocusContainer } from '../../components/containers'
import { paths, actions as acts } from '../../constants'
import { VideoEditor } from '../../components/videos'

const NewClubVideo = () => {
  const { osid, id } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const [video, setVideo] = useState(null)

  const [products, setProducts] = useState([])
  const status = {
    create: useStatus(acts.CREATE_CLUB_VIDEO),
  }

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.CREATE_CLUB_VIDEO))
    }
  }, [])

  useStatusMsg(status.create, {
    pending: 'Creating...',
    error: 'Failed to create video',
    success: 'Video created',
  })

  const missingFields = () => {
    const missing = ['title', 'description', 'videoUrl'].filter(k => {
      if (!video[k]) {
        return true
      }
      return false
    })
    if (missing.length > 0) {
      const msg = `${missing.join(', ')} ${missing.length > 1 ? 'are' : 'is'} required`
      message.error(`${msg.charAt(0).toUpperCase()}${msg.substr(1)}`)
      return true
    }
    return false
  }

  const submitClubVideo = createAnother => () => {
    if (missingFields()) {
      return
    }
    const newVideo = video
    newVideo.products = products
    dispatch(createClubVideo({ osid, clubId: id, video: newVideo }))
      .then(v => {
        if (createAnother) {
          setVideo(null)
          setProducts([])
        } else {
          history.push(paths.org.CLUB_VIDEOS(osid, id))
        }
      })
      .catch(() => {})
  }

  return (
    <>
      <FocusHeader
        title="Create a club video"
        actions={
          <>
            <Button style={{ marginRight: '.5em' }} onClick={submitClubVideo(true)} size="small">
              Submit and create another
            </Button>
            <Cta onClick={submitClubVideo(false)}>Submit</Cta>
          </>
        }
      />
      <FocusContainer style={{ paddingTop: '20px', paddingBottom: '20px' }}>
        <VideoEditor
          video={video}
          onChange={e => setVideo(e)}
          products={products}
          setProducts={p => setProducts(p)}
        />
      </FocusContainer>
    </>
  )
}

export default NewClubVideo
