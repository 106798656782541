export const deserializePage = page => {
  const data = page.data
  const nextCursor = page.next_cursor
  let hasMore = true
  if (Array.isArray(data) && data.length === 0 && nextCursor == null) {
    hasMore = false
  }
  return { data, nextCursor, hasMore }
}

export const numberOfPages = (totalItems, itemsPerPage) =>
  totalItems % itemsPerPage > 0
    ? Math.floor(totalItems / itemsPerPage) + 1
    : Math.floor(totalItems / itemsPerPage)
