import React, { useContext, useEffect, useState } from 'react'
import { useTheme, ThemeContext } from 'styled-components'
import { Select, Typography, Checkbox, Form, Modal, Tag, Menu, Dropdown, Table, Breadcrumb, Collapse } from 'antd'
import { PlusOutlined, EllipsisOutlined, LoadingOutlined, DownOutlined } from '@ant-design/icons'
import { Link, useParams, useHistory } from 'react-router-dom'
import * as userUtils from '../../../util/user'
import { useDispatch } from 'react-redux'
import { paths, actions as acts } from '../../../constants'
import { programTypes } from '../../../constants/program'
import { beneficiaryStatuses } from '../../../constants/benefit'
import {
  fetchUser,
  fetchUserProgramBookings,
  fetchUserPackages,
  fetchUserClubMemberships,
  fetchPermitsForUser,
  fetchUserCustomers,
  fetchUserBeneficiaries,
  fetchUserWaitlists,
  fetchUserOrgTeamMemberships,
  fetchUserVenueTeamMemberships,
  removeUserVenueTeamMembership,
  addUserVenueTeamMembership,
  removeUserOrgTeamMembership,
  addUserOrgTeamMembership,
  fetchProgram,
  userCancelResReservationBookings,
  userCancelProgramBookings,
  generateUserPasswordResetLink,
  updateBeneficiary,
} from '../../../actions/admin'
import { formatDateTimeTz, formatWallTime, formatDate, tzByName } from '../../../util/time'
import { useStatus, useStatusMsg } from '../../../reducers'
import urls from '../../../util/url'
import billingUtils from '../../../util/billing'
import programUtils from '../../../util/program'
import Attr from '../../../components/Attr'
import { Label, Noner, ID, CountHeader, Button } from '../../../components/common'
import ImagePreview from '../../../components/ImagePreview'
import BookStatusTag from '../../../components/BookStatusTag'
import PlayerLevel from '../../../components/PlayerLevel'
import { AdminVenueSelect } from '../../../components/venue'
import { AdminProgramLink } from '../../../components/program'
import {
  RedemptionTerm,
  AdminPackageLink,
  AdminPackageSetLink,
  UserPackageInvoiceLink,
} from '../../../components/package'
import { OrgSelect, AdminOrgLink } from '../../../components/org'
import { AdminVenueLink } from '../../../components/venue'
import EditUserModal from './EditUserModal'
import { clearStatus } from '../../../actions/status'

const { Panel } = Collapse
const { Text } = Typography

const paymentMethodColumns = [
  {
    title: 'Card',
    dataIndex: 'id',
    key: 'id',
    render: (val, record) => (
      <div>
        <div>{billingUtils.cardBrandLabel(record.cardBrand)} •••• {record.cardLast4}</div>
        <div><small>Expires {record.cardExpMonth}/{record.cardExpYear}</small></div>
        {record.deleted ? <div><Tag>Deleted</Tag></div> : null}
      </div>
    )
  },
  {
    title: 'Name on Card',
    dataIndex: 'nameOnCard',
    key: 'nameOnCard',
  },
  {
    title: 'Stripe card ID',
    dataIndex: 'stripeCardId',
    key: 'stripeCardId',
  },
  {
    title: 'Billing address',
    dataIndex: 'billingAddress',
    key: 'id',
    render: (val, record) => {
      const { line1, line2, city, state, postcode, country } = record.billingAddress
      return (
        <div>
          <div>{line1}</div>
          {line2 ? <div>{line2}</div> : null}
          <div>{city}, {state} {postcode}</div>
          <div>{country}</div>
        </div>
      )
    }
  },
  {
    title: 'Created',
    key: 'createdAt',
    dataIndex: 'createdAt',
    defaultSortOrder: 'descend',
    sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    render: (val, record) => (
      <div>{formatDateTimeTz(val)}</div>
    )
  },
]

const permitColumns = [
  {
    title: 'Permit',
    key: 'permitInfo',
    dataIndex: 'permitInfo',
    render: (val, record) => (
      <Link to={paths.admin.USER_PERMIT(record.id)}>{val.name}</Link>
    )
  },
  {
    title: 'Application status',
    dataIndex: 'applicationStatus',
    key: 'applicationStatus',
  },
  {
    title: 'Payment status',
    dataIndex: 'paymentStatus',
    key: 'paymentStatus',
  },
  {
    title: 'Scraper status',
    dataIndex: 'scraperStatus',
    key: 'scraperStatus',
  },
  {
    title: 'Fulfillment status',
    dataIndex: 'fulfillmentStatus',
    key: 'fulfillmentStatus',
  },
  {
    title: 'Notes',
    dataIndex: 'internalNotes',
    key: 'internalNotes',
  },
  {
    title: 'Created',
    key: 'createdAt',
    dataIndex: 'createdAt',
    defaultSortOrder: 'descend',
    sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    render: (val, record) => (
      <div>{formatDateTimeTz(val)}</div>
    )
  },
]

const membershipColumns = [
  {
    title: 'Club',
    key: 'club',
    dataIndex: 'club',
    render: (val, record) => (
      <Link to={paths.org.CLUB(val.org, val.sid)}>{val.name}</Link>
    )
  },
  {
    title: 'Joined',
    key: 'createdAt',
    dataIndex: 'createdAt',
    defaultSortOrder: 'descend',
    sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    render: (val, record) => (
      <div>{formatDateTimeTz(val)}</div>
    )
  },
]

const waitlistColumns = () => ([
  {
    title: 'Program',
    key: 'program',
    dataIndex: 'program',
    sorter: (a, b) => a.program.name.localeCompare(b.program.name),
    render: (val, record) => {
      const tz = tzByName(val.tz)
      return (
        <div>
          <Link to={paths.admin.PROGRAM(val.id)}>{val.name}</Link>
          <div>
            <small>{formatDate(val.date)} {val.startTime}-{val.endTime} {tz ? tz.abbreviation : null}</small>
            <div>
              <small><Link to={paths.org.HOME(val.org.id)}>{val.org.name}</Link></small>
            </div>
          </div>
        </div>
      )
    },
  },
  {
    title: 'Date joined waitlist',
    key: 'createdAt',
    dataIndex: 'createdAt',
    defaultSortOrder: 'descend',
    sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    render: (val, record) => <div>{formatDate(val)}</div>,
  },
])

const OrgTeamMembershipActionsMenu = ({ membership, onDelete }) => {
  const dispatch = useDispatch()
  const removeStatus = useStatus(acts.REMOVE_USER_ORG_TEAM_MEMBERSHIP)

  useStatusMsg(removeStatus, {
    pending: 'Removing user from org team...',
    error: 'Failed to remove user from org team',
    success: 'User removed from org team',
  })

  useEffect(() => () => dispatch(clearStatus(acts.ORG_USER_VENUE_TEAM_MEMBERSHIP)), [])

  const handleActionsMenu = e => {
    if (e.key === 'remove') {
      Modal.confirm({
        title: 'Remove user from org team?',
        okText: 'Remove',
        okButtonProps: {
          danger: true,
          loading: removeStatus.pending || false,
          disabled: removeStatus.pending || false,
        },
        content: (
          <div>
            <p>Are you sure you want to remove this user from the org team?</p>
          </div>
        ),
        onOk: () => {
          return dispatch(removeUserOrgTeamMembership({ userId: membership.user, membershipId: membership.id })).then(() => {
            if (typeof onDelete === 'function') {
              onDelete(membership)
            }
          })
        },
      })
    }
  }

  return (
    <Menu onClick={handleActionsMenu}>
      <Menu.Item key="remove" danger>Remove</Menu.Item>
    </Menu>
  )
}

const orgTeamMembershipsColumns = ({ onDelete }) => ([
  {
    title: 'Org',
    key: 'org',
    dataIndex: 'org',
    sorter: (a, b) => a.name.localeCompare(b.name),
    render: (val, record) => (
      <div>
        <Link to={paths.admin.ORG(val.id)}>{val.name}</Link>
      </div>
    ),
  },
  {
    title: 'Role',
    key: 'role',
    dataIndex: 'role',
  },
  {
    title: 'Joined ',
    key: 'createdAt',
    dataIndex: 'createdAt',
    defaultSortOrder: 'descend',
    sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    render: (val, record) => <div>{formatDate(val)}</div>,
  },
  {
    title: '',
    key: 'actions',
    dataIndex: 'actions',
    render: (val, record) => {
      return (
        <div style={{textAlign: 'center'}}>
          <Dropdown
            overlay={<OrgTeamMembershipActionsMenu membership={record} onDelete={onDelete} />}
            trigger={['click']}
          >
            <Button>
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        </div>
      )
    }
  },
])

const AddOrgTeamMembershipModal = ({ user, complete }) => {
  const dispatch = useDispatch()
  const theme = useTheme(ThemeContext)
  const completer = typeof complete === 'function' ? complete : () => {}
  const [org, setOrg] = useState(null)
  const status = useStatus(acts.ADD_USER_ORG_TEAM_MEMBERSHIP)

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.ADD_USER_ORG_TEAM_MEMBERSHIP))
    }
  }, [])

  useStatusMsg(status, { error: 'Failed to add user to org team' })

  const onOk = () => {
    dispatch(addUserOrgTeamMembership({ userId: user.id, orgId: org.id }))
      .then(res => completer(res))
      .catch(() => {})
  }

  return (
    <Modal
      title={'Add org team membership'}
      visible
      okText={'Add'}
      okButtonProps={{ disabled: !Boolean(org) }}
      onOk={onOk}
      onCancel={() => completer()}
      confirmLoading={status.pending || false}
    >
      <OrgSelect
        id="org"
        onChange={o => setOrg(o)}
        value={org}
        style={{ width: theme.width[5] }}
        placeholder="Select an org"
      />
    </Modal>
  )
}

const VenueTeamMembershipActionsMenu = ({ membership, onDelete }) => {
  const dispatch = useDispatch()
  const removeStatus = useStatus(acts.REMOVE_USER_VENUE_TEAM_MEMBERSHIP)

  useStatusMsg(removeStatus, {
    pending: 'Removing user from venue team...',
    error: 'Failed to remove user from venue team',
    success: 'User removed from venue team',
  })

  useEffect(() => () => dispatch(clearStatus(acts.REMOVE_USER_VENUE_TEAM_MEMBERSHIP)), [])

  const handleActionsMenu = e => {
    if (e.key === 'remove') {
      Modal.confirm({
        title: 'Remove user from venue team?',
        okText: 'Remove',
        okButtonProps: {
          danger: true,
          loading: removeStatus.pending || false,
          disabled: removeStatus.pending || false,
        },
        content: (
          <div>
            <p>Are you sure you want to remove this user from the venue team?</p>
          </div>
        ),
        onOk: () => {
          return dispatch(removeUserVenueTeamMembership({ userId: membership.user, membershipId: membership.id })).then(() => {
            if (typeof onDelete === 'function') {
              onDelete(membership)
            }
          })
        },
      })
    }
  }

  return (
    <Menu onClick={handleActionsMenu}>
      <Menu.Item key="remove" danger>Remove</Menu.Item>
    </Menu>
  )
}

const venueTeamMembershipsColumns = ({ onDelete }) => ([
  {
    title: 'Venue',
    key: 'venue',
    dataIndex: 'venue',
    sorter: (a, b) => a.name.localeCompare(b.name),
    render: (val, record) => {
      return (
        <div>
          <small><Link to={paths.admin.ORG(val.org.id)}>{val.org.name}</Link></small>
          <div>{val.name}</div>
        </div>
      )
    },
  },
  {
    title: 'Joined',
    key: 'createdAt',
    dataIndex: 'createdAt',
    defaultSortOrder: 'descend',
    sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    render: (val, record) => <div>{formatDate(val)}</div>,
  },
  {
    title: '',
    key: 'actions',
    dataIndex: 'actions',
    render: (val, record) => {
      return (
        <div style={{textAlign: 'center'}}>
          <Dropdown
            overlay={<VenueTeamMembershipActionsMenu membership={record} onDelete={onDelete} />}
            trigger={['click']}
          >
            <Button>
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        </div>
      )
    }
  },
])

const AddVenueTeamMembershipModal = ({ user, complete }) => {
  const dispatch = useDispatch()
  const theme = useTheme(ThemeContext)
  const completer = typeof complete === 'function' ? complete : () => {}
  const [venue, setVenue] = useState(null)
  const status = useStatus(acts.ADD_USER_VENUE_TEAM_MEMBERSHIP)

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.ADD_USER_VENUE_TEAM_MEMBERSHIP))
    }
  }, [])

  useStatusMsg(status, { error: 'Failed to add user to venue team' })

  const onOk = () => {
    dispatch(addUserVenueTeamMembership({ userId: user.id, venueId: venue.id }))
      .then(res => completer(res))
      .catch(() => {})
  }

  return (
    <Modal
      title={'Add venue team membership'}
      visible
      okText={'Add'}
      okButtonProps={{ disabled: !Boolean(venue) }}
      onOk={onOk}
      onCancel={() => completer()}
      confirmLoading={status.pending || false}
    >
      <AdminVenueSelect
        id="venue"
        onChange={v => setVenue(v)}
        value={venue}
        style={{ width: theme.width[5] }}
        placeholder="Select a venue"
      />
    </Modal>
  )
}

const ProgramDescriptor = ({ program, linked=true, showLocation=false }) => {
  const orgExpanded = Boolean(program.org) && typeof program.org !== 'string'
  const hasLocation = Boolean(program.location) && typeof program.location !== 'string'
  const bookingsPresent = Array.isArray(program.bookings)
  const orgDisplay = (
    <small>
      {orgExpanded && linked && <><b>ORG </b><Link to={paths.admin.ORG(program.org.id)}>{program.org.name}</Link></>}
      {!orgExpanded && linked && <Link to={paths.admin.ORG(program.org)}><b>ORG</b></Link>}
      {orgExpanded && !linked && <><b>ORG </b>{program.org.name}</>}
      {!orgExpanded && !linked && <><b>ORG </b><ID>{program.org}</ID></>}
    </small>
  )
  const nameDisplay = (
    <div>
      {linked && <AdminProgramLink program={program} />}
      {!linked && program.name}
    </div>
  )
  const locationDisplay = (
      <span>
        <Noner none="No location">
          {hasLocation ? `${program.location.address.name}, ${program.location.address.city}` : null}
        </Noner>
      </span>
  )
  return (
    <div>
      {orgDisplay}
      {nameDisplay}
      {showLocation && locationDisplay}
      <small>
        <div>{formatDate(program.date)}</div>
        <div>{program.startTime}-{program.endTime} {tzByName(program.tz)?.abbreviation}</div>
      </small>
      {bookingsPresent &&
        <div>
          {program.bookings.filter(programUtils.isBooked).length} / {program.maxParticipants} participants
        </div>
      }
    </div>
  )
}

const ResReservationDescriptor = ({ resourceReservation, showDate=false }) => {
  let reservationDisplay = null
  const rr = resourceReservation
  const { id, dateStart, dateEnd, wallStart, wallEnd, resource } = rr
  return (
    <div>
      {showDate && formatDate(dateStart)} {formatWallTime(wallStart)}-{formatWallTime(wallEnd)} <Tag style={{marginRight: 0}}>{resource.name}</Tag> {resource.venue.name}
    </div>
  )
}

const bookingColumns = ({ onCancel }) => ([
  {
    title: 'Program',
    key: 'program',
    dataIndex: 'program',
    render: (val, record) => {
      return (
        <ProgramDescriptor program={val} />
      )
    },
  },
  {
    title: 'Program location',
    key: ['program', 'location', 'id'],
    dataIndex: 'program',
    render: (val, record) => {
      let programLocationDisplay = <Noner />
      if (val.location && val.location.address) {
        programLocationDisplay = <span>{val.location.address.name}, {val.location.address.city}</span>
      }
      let reservationDisplay = <Noner />
      if (Array.isArray(val.resourceReservations) && val.resourceReservations.length > 0) {
        reservationDisplay = val.resourceReservations.map(rr => {
          return (
            <ResReservationDescriptor
              key={rr.id}
              resourceReservation={rr}
              showDate={rr.dateStart !== val.date}
            />
          )
        })
      }
      return (
        <div>
          <div style={{marginBottom: '.75em'}}>
            <div><small><b>Location</b></small></div>
            {programLocationDisplay}
          </div>
          <div>
            <div><small><b>Reservations</b></small></div>
            {reservationDisplay}
          </div>
        </div>
      )
    },
  },
  {
    title: 'Participant',
    dataIndex: 'participant',
    key: 'participant',
    sorter: (a, b) => programUtils.participantNameFromBooking(a).localeCompare(programUtils.participantNameFromBooking(b)),
    render: (val, record) => {
      return (
        <ParticipantDescriptor booking={record} linked />
      )
    }
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    render: (val, record) => <BookStatusTag status={val} />,
  },
  {
    title: 'Note',
    key: 'note',
    dataIndex: 'note',
    render: (val, record) => <div>{val}</div>,
  },
  {
    title: 'Booking time',
    key: 'createdAt',
    dataIndex: 'createdAt',
    defaultSortOrder: 'descend',
    sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    render: (val, record) => <div>{formatDateTimeTz(val)}</div>,
  },
  {
    title: 'Actions',
    key: 'invoice',
    render: (val, record) => {
      return (
        <div style={{textAlign: 'center'}}>
          <Dropdown
            overlay={<ProgramBookingsActionsMenu booking={record} onCancel={onCancel} />}
            trigger={['click']}
          >
            <Button>
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        </div>
      )
    },
  },
])

// expects orderItems.order to be exapanded on userPackage
const PackageActionsMenu = ({ userPackage }) => {
  let items = [
    {
      label: (
        <UserPackageInvoiceLink orderItems={userPackage.orderItems}>
          View invoice
        </UserPackageInvoiceLink>
      ),
      key: 'invoice',
    },
  ]

  return (
    <Menu onClick={() => {}} items={items} />
  )
}

const packageColumns = ({ currentUser }) => ([
  {
    title: 'User package',
    key: 'name',
    dataIndex: 'name',
    render: (val, record) => {
      return (
        <div>
          <Link to={paths.admin.USER_PACKAGE(record.id)}>{val}</Link>
          <small><pre>{record.id}</pre></small>
        </div>
      )
    },
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    render: (val, record) => <Tag>{val}</Tag>,
  },
  {
    title: 'Redemption Term',
    key: 'redemptionTermDays',
    dataIndex: 'redemptionTermDays',
    render: (val, record) => (
      <RedemptionTerm {...record} />
    ),
  },
  {
    title: 'Package',
    key: 'package',
    dataIndex: 'package',
    render: (val, record) => {
      return (
        <div>
          <AdminPackageLink pkg={val}>{val.name}</AdminPackageLink>
          <small><pre>{val.id}</pre></small>
        </div>
      )
    },
  },
  {
    title: 'Package set',
    key: 'package',
    dataIndex: 'package',
    render: (val, record) => {
      if (!val.set) {
        return <Noner none="No set" />
      }
      return (
        <div>
          <AdminPackageSetLink set={val.set}>{val.set.name}</AdminPackageSetLink>
          <small><pre>{val.set.id}</pre></small>
        </div>
      )
    },
  },
  {
    title: 'Org',
    key: 'org',
    dataIndex: 'org',
    render: (val, record) => {
      if (!val) {
        return <Noner none="No org" />
      }
      return (
        <AdminOrgLink org={val} />
      )
    },
  },
  {
    title: 'Venue',
    key: 'venue',
    dataIndex: 'venue',
    render: (val, record) => {
      if (!val) {
        return <Noner none="No venue" />
      }
      return (
        <AdminVenueLink venue={val} />
      )
    },
  },
  {
    title: 'Created',
    key: 'createdAt',
    dataIndex: 'createdAt',
    defaultSortOrder: 'descend',
    sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    render: (val, record) => <div>{formatDateTimeTz(val)}</div>,
  },
  {
    title: 'Actions',
    render: (val, record) => {
      return (
        <div style={{textAlign: 'center'}}>
          <Dropdown
            overlay={<PackageActionsMenu userPackage={record} />}
            trigger={['click']}
          >
            <Button>
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        </div>
      )
    },
  },
])

const ParticipantDescriptor = ({ booking, linked=true }) => {
  const { participant, purchaser } = booking
  const hasParticipantUser = Boolean(participant) && typeof participant !== 'string'
  const hasPurchaserUser = Boolean(purchaser) && typeof purchaser !== 'string'
  const participantName = programUtils.participantNameFromBooking(booking)
  let nameElem = participantName ? participantName : <Noner none="No participant info" />
  if (linked && hasParticipantUser) { // only show a link if participant exists and is expanded
    nameElem = <Link to={paths.admin.USER(participant.id)}>{participantName}</Link>
  }
  let purchaserDisplay = null
  if (purchaser?.id !== participant?.id) {
    let purchaserNameElem = userUtils.formatName(purchaser)
    if (linked && hasPurchaserUser) {
      purchaserNameElem = <Link to={paths.admin.USER(purchaser.id)}>{purchaserNameElem}</Link>
    }
    purchaserDisplay = (
      <div style={{color: 'gray'}}>
        <small>
          <div>Purchased by</div>
          <div>{purchaserNameElem} {purchaser.email}</div>
        </small>
      </div>
    )
  }
  return (
    <>
      <div>
        {nameElem}
      </div>
      <small>{programUtils.participantEmailFromBooking(booking)}</small>
      <div>
        <PlayerLevel user={participant} />
      </div>
      {purchaserDisplay}
    </>
  )
}

const initResReservationFormState = {
  bookings: [],
  resReservations: [],
}

const CancelResReservationForm = ({ booking, cancellations, onChange, showOnlyCurrentBooking }) => {
  const dispatch = useDispatch()
  const changer = typeof onChange === 'function' ? onChange : () => {}
  const [program, setProgram] = useState(null)
  const [bookingCancelCount, setBookingCancelCount] = useState(null)
  const [resCancelCount, setResCancelCount] = useState(null)
  const status = useStatus(acts.FETCH_PROGRAM)

  useEffect(() => {
    dispatch(fetchProgram(booking.program.id))
      .then(p => setProgram(p))
  }, [])

  useEffect(() => () => dispatch(clearStatus(acts.FETCH_PROGRAM)), [])

  useStatusMsg(status, { error: 'Failed to fetch program' })

  useEffect(() => {
    if (cancellations) {
      setBookingCancelCount(Array.isArray(cancellations.bookings) ? cancellations.bookings.length : 0)
      setResCancelCount(Array.isArray(cancellations.resReservations) ? cancellations.resReservations.length : 0)
    }
  }, [cancellations])

  const change = update => {
    changer({ ...cancellations, ...update })
  }

  if (!status.success || status.pending || !program) {
    return <LoadingOutlined />
  }

  let defaultBookingsChecked = [], defaultResChecked = [];
  const cancelableBookings = program.bookings.filter(b => {
    if (programUtils.isBooked(b)) {
      return showOnlyCurrentBooking ? b.id === booking.id : true
    }
    return false
  })
  const cancelableRes = program.resourceReservations

  if (!cancellations) {
    cancelableBookings.map(b => {
      // default check bookings which have the same purchaser or participant as the current booking
      if (b.purchaser.id === booking.purchaser.id || b.participant?.id === booking.purchaser.id) {
        defaultBookingsChecked.push(b.id)
      }
    })

    cancelableRes.map(rr => defaultResChecked.push(rr.id))

    change({
      bookings: defaultBookingsChecked,
      resReservations: defaultResChecked,
    })
    return
  }

  const bookingOptions = cancelableBookings.map(b => (
    <Checkbox
      key={b.id}
      value={b.id}
      style={{margin: 0, marginBottom: '.5em'}}
    >
      <div style={{}}>
        <div style={{display: 'flex', alignItems: 'start'}}>
          <div><ParticipantDescriptor booking={b} linked={false} /></div>
          <div>{b.id === booking.id && <small><Text type="danger">*</Text> Current booking</small>}</div>
        </div>
      </div>
    </Checkbox>
  ))

  const rrOptions = cancelableRes.map(rr => (
    <Checkbox
      key={rr.id}
      value={rr.id}
      style={{margin: 0, marginBottom: '.5em'}}
    >
      <ResReservationDescriptor resourceReservation={rr} showDate />
    </Checkbox>
  ))


  return (
    <div>
      <Attr name="Program info">
        <Collapse>
          <Panel header={booking.program.name} key="program-info">
            <ProgramDescriptor program={program} linked={false} showLocation />
          </Panel>
        </Collapse>
      </Attr>
      <Attr
        name="Bookings to cancel"
        label={`${bookingCancelCount} / ${cancelableBookings.length}`}
      >
        <Checkbox.Group
          onChange={e => change({ bookings: e })}
          style={{display: 'flex', flexDirection: 'column'}}
          defaultValue={defaultBookingsChecked}
          value={cancellations.bookings}
        >
          {bookingOptions}
        </Checkbox.Group>
      </Attr>
      <Attr
        name="Reservations to cancel"
        label={`${resCancelCount} / ${cancelableRes.length}`}
      >
        <Checkbox.Group
          onChange={e => change({ resReservations: e })}
          style={{display: 'flex', flexDirection: 'column'}}
          defaultValue={defaultResChecked}
          value={cancellations.resReservations}
        >
          {rrOptions}
        </Checkbox.Group>
      </Attr>
    </div>
  )
}

// if showOnlyCurrentBooking is set, this will only show the current booking as available to cancel
const CancelProgramForm = ({ booking, cancellations, onChange, showOnlyCurrentBooking=false }) => {
  const dispatch = useDispatch()
  const changer = typeof onChange === 'function' ? onChange : () => {}
  const [program, setProgram] = useState(null)
  const [bookingCancelCount, setBookingCancelCount] = useState(null)
  const status = useStatus(acts.FETCH_PROGRAM)

  useEffect(() => {
    dispatch(fetchProgram(booking.program.id))
      .then(p => setProgram(p))
  }, [])

  useStatusMsg(status, { error: 'Failed to fetch program' })

  useEffect(() => {
    if (cancellations) {
      setBookingCancelCount(Array.isArray(cancellations.bookings) ? cancellations.bookings.length : 0)
    }
  }, [cancellations])

  const change = update => {
    changer({ ...cancellations, ...update })
  }

  if (!status.success || status.pending || !program) {
    return <LoadingOutlined />
  }

  let defaultBookingsChecked = []
  const cancelableBookings = program.bookings.filter(b => {
    if (programUtils.isBooked(b)) {
      return showOnlyCurrentBooking ? b.id === booking.id : true
    }
    return false
  })

  if (!cancellations) {
    cancelableBookings.map(b => {
      // default check bookings which have the same purchaser or participant as the current booking
      if (b.purchaser.id === booking.purchaser.id || b.participant?.id === booking.purchaser.id) {
        defaultBookingsChecked.push(b.id)
      }
    })

    change({ bookings: defaultBookingsChecked })
    return
  }

  const bookingOptions = cancelableBookings.map(b => (
    <Checkbox
      key={b.id}
      value={b.id}
      style={{margin: 0, marginBottom: '.5em'}}
    >
      <div>
        <div style={{display: 'flex', alignItems: 'start'}}>
          <div><ParticipantDescriptor booking={b} linked={false} /></div>
          <div>{b.id === booking.id && <small><Text type="danger">*</Text> Current booking</small>}</div>
        </div>
      </div>
    </Checkbox>
  ))

  return (
    <div>
      <Attr name="Program info">
        <Collapse>
          <Panel header={booking.program.name} key="program-info">
            <ProgramDescriptor program={program} linked={false} showLocation />
          </Panel>
        </Collapse>
      </Attr>
      <Attr
        name="Bookings to cancel"
        label={`${bookingCancelCount} / ${cancelableBookings.length}`}
      >
        <Checkbox.Group
          onChange={e => change({ bookings: e })}
          style={{display: 'flex', flexDirection: 'column'}}
          defaultValue={defaultBookingsChecked}
          value={cancellations.bookings}
        >
          {bookingOptions}
        </Checkbox.Group>
      </Attr>
    </div>
  )
}

export const EditBeneficiaryModal = ({ beneficiary, complete }) => {
  const dispatch = useDispatch()
  const theme = useTheme(ThemeContext)
  const [beneficiaryStatus, setBeneficiaryStatus] = useState(beneficiary.status)
  const completer = typeof complete === 'function' ? complete : () => {}
  const status = useStatus(acts.UPDATE_BENEFICIARY)

  const onOk = () => {
    const benefitSig = typeof beneficiary.benefit === 'string' ? beneficiary.benefit : beneficiary.benefit.id
    dispatch(updateBeneficiary({ benefitSig, beneficiaryId: beneficiary.id, status: beneficiaryStatus }))
      .then(completer)
  }

  const handleChange = newStatus => {
    setBeneficiaryStatus(newStatus)
  }

  const opts = Object.keys(beneficiaryStatuses).map(key => (
    <Select.Option key={key} value={key}>{key}</Select.Option>
  ))

  return (
    <Modal
      title="Edit beneficiary"
      open
      okText={status.pending ? 'Saving...' : 'Save'}
      okButtonProps={{ loading: status.pending, disabled: status.pending }}
      onOk={onOk}
      onCancel={() => completer()}
    >
      <Attr name="Status">
        <div>
          <Select
           value={beneficiaryStatus}
           onChange={handleChange}
           style={{ width: theme.width[4] }}
         >
           {opts}
         </Select>
        </div>
      </Attr>
    </Modal>
  )
}

export const CancelBookingModal = ({ user, booking, complete, showOnlyCurrentBooking }) => {
  const dispatch = useDispatch()
  const completer = typeof complete === 'function' ? complete : () => {}
  const [resReservationCancellations, setResReservationCancellations] = useState(null)
  const [programCancellations, setProgramCancellations] = useState(null)
  const status = {
    cancelResReservations: useStatus(acts.USER_ADMIN_CANCEL_RES_RESERVATION_BOOKINGS),
    cancelProgram: useStatus(acts.USER_ADMIN_CANCEL_PROGRAM_BOOKINGS),
  }
  const isResReservation = booking.program.type === programTypes.RES_RESERVATION.name
  const isLoading = status.cancelResReservations.pending || status.cancelProgram.pending

  useStatusMsg(status.cancelResReservations, { error: e => `Failed to cancel: ${e}` })

  const onOk = () => {
    if (isResReservation) {
      dispatch(userCancelResReservationBookings({ userId: user.id, ...resReservationCancellations }))
        .then(complete)
    } else {
      dispatch(userCancelProgramBookings({ userId: user.id, ...programCancellations }))
        .then(complete)
    }
  }

  let display = null
  if (isResReservation) {
    display = (
      <CancelResReservationForm
        booking={booking}
        cancellations={resReservationCancellations}
        onChange={e => setResReservationCancellations(e)}
      />
    )
  } else {
    display = (
      <CancelProgramForm
        booking={booking}
        cancellations={programCancellations}
        onChange={e => setProgramCancellations(e)}
        showOnlyCurrentBooking={showOnlyCurrentBooking}
      />
    )
  }

  // TODO maybe have a section to email users a custom message if they were canceled
  return (
    <Modal
      title={'Cancel booking'}
      open
      okText={isLoading ? 'Canceling...' : 'Cancel bookings'}
      okButtonProps={{ danger: true, loading: isLoading, disabled: isLoading }}
      onOk={onOk}
      onCancel={() => completer()}
    >
      {display}
    </Modal>
  )
}

const ProgramBookingsActionsMenu = ({ booking, onCancel }) => {
  const history = useHistory()
  const hasInvoice = Boolean(booking.invoice)
  const isCanceled = programUtils.isCanceled(booking)

  const handleActionsMenu = e => {
    if (e.key === 'cancel') {
      if (typeof onCancel === 'function') {
        onCancel(booking)
      }
    }
  }

  let items = [
    {
      label: (
        <Link to={paths.admin.billing.INVOICE(booking.invoice)}>View invoice</Link>
      ),
      disabled: !hasInvoice,
      key: 'invoice',
      title: !hasInvoice && 'No invoice associated with this booking',
    },
    {
      label: 'Cancel',
      key: 'cancel',
      danger: true,
    },
  ]

  if (isCanceled) {
    items = items.filter(i => i.key !== 'cancel')

  }

  return (
    <Menu onClick={handleActionsMenu} items={items} />
  )
}

export const BeneficiaryActionMenu = ({ beneficiary, onEdit }) => {
  const handleActionsMenu = e => {
    if (e.key === 'edit') {
      if (typeof onEdit === 'function') {
        onEdit(beneficiary)
      }
    }
  }

  return (
    <Menu onClick={handleActionsMenu}>
      <Menu.Item key="edit">Edit</Menu.Item>
    </Menu>
  )
}

const beneficiariesColumns = ({ onEdit }) => ([
  {
    title: 'Benefit',
    key: 'benefit',
    dataIndex: 'benefit',
    render: (val, record) => (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <a href={urls.orgBenefitHomepage(val.org.id, val.id)}>{val.name}</a>
        <small><Link to={paths.org.HOME(val.org.id)}>{val.org.name}</Link></small>
      </div>
    ),
  },
  {
    title: 'Beneficiary Id',
    key: 'id',
    dataIndex: 'id',
    render: (val, record) => (
      <small><pre>{val}</pre></small>
    ),
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    render: (val, record) => (
      <Tag>{val}</Tag>
    )
  },
  {
    title: 'Beneficiary group memberships',
    key: 'beneficiaryGroups',
    dataIndex: 'beneficiaryGroups',
    render: (val, record) => {
      if (!Array.isArray(val) || val.length === 0) {
        return null
      }
      return val.map(bg => (
        <div key={bg.id}>
          {bg.beneficiaryGroup.name} <small><Tag>Joined {formatDateTimeTz(bg.createdAt)}</Tag></small>
        </div>
      ))
    },
  },
  {
    title: 'Joined',
    key: 'createdAt',
    dataIndex: 'createdAt',
    defaultSortOrder: 'descend',
    sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    render: (val, record) => <div>{formatDateTimeTz(val)}</div>,
  },
  {
    title: 'Actions',
    key: 'id',
    render: (val, record) => {
      return (
        <div>
          <Dropdown
            overlay={<BeneficiaryActionMenu beneficiary={record} onEdit={onEdit} />}
            trigger={['click']}
          >
            <Button>
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        </div>
      )
    },
  },
])

const userCustomersColumns = [
  {
    title: 'Org',
    key: 'org',
    dataIndex: 'org',
    render: (val, record) => <Link to={paths.org.HOME(val.id)}>{val.name}</Link>,
  },
  {
    title: 'Customer ID',
    key: 'id',
    dataIndex: 'id',
    render: (val, record) => <div>{val}</div>,
  },
  {
    title: 'Joined',
    key: 'createdAt',
    dataIndex: 'createdAt',
    defaultSortOrder: 'descend',
    sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    render: (val, record) => <div>{formatDateTimeTz(val)}</div>,
  },
]

const UserType = ({ user }) => {
  const hasProPage = userUtils.isPro(user.type) && user.page
  return (
    <div>
      <div>{userUtils.userTypeLabel(user.type)}</div>
      {hasProPage && <Link to={paths.admin.PRO_PAGE(user.page.id)}>View pro page</Link>}
    </div>
  )
}

export const UserBookings = ({ bookings, onCancel }) => {
  return (
    <Table
      size="small"
      columns={bookingColumns({ onCancel })}
      dataSource={bookings}
    />
  )
}

const User = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const [user, setUser] = useState(null)
  const [bookings, setBookings] = useState(null)
  const [packages, setPackages] = useState(null)
  const [clubMemberships, setClubMemberships] = useState(null)
  const [orgTeamMemberships, setOrgTeamMemberships] = useState(null)
  const [venueTeamMemberships, setVenueTeamMemberships] = useState(null)
  const [permits, setPermits] = useState(null)
  const [beneficiaries, setBeneficiaries] = useState(null)
  const [userCustomers, setUserCustomers] = useState(null)
  const [userWaitlists, setUserWaitlists] = useState(null)
  const [editing, setEditing] = useState(false)
  const [addingVenueMemberships, setAddingVenueMemberships] = useState(false)
  const [addingOrgTeamMemberships, setAddingOrgTeamMemberships] = useState(false)
  const [cancelingBooking, setCancelingBooking] = useState(null)
  const [newPasswordResetLink, setNewPasswordResetLink] = useState(null)
  const [editingBeneficiary, setEditingBeneficiary] = useState(null)
  const status = {
    fetch: acts.FETCH_USER,
    bookings: acts.FETCH_USER_PROGRAM_BOOKINGS,
    packages: acts.FETCH_USER_PACKAGES,
    clubs: acts.FETCH_USER_CLUB_MEMBERSHIPS,
    permits: acts.FETCH_USER_USER_PERMITS,
    customers: acts.FETCH_USER_CUSTOMERS,
    beneficiaries: acts.FETCH_USER_BENEFICIARIES,
    waitlists: acts.FETCH_USER_WAITLISTS,
    orgTeamMemberships: acts.FETCH_USER_ORG_TEAM_MEMBERSHIPS,
    venueTeamMemberships: acts.FETCH_USER_VENUE_TEAM_MEMBERSHIPS,
    edit: acts.UPDATE_USER,
  }

  useStatusMsg(status.fetch, { error: 'Failed to fetch user' })
  useStatusMsg(status.bookings, { error: 'Failed to fetch program bookings' })
  useStatusMsg(status.packages, { error: 'Failed to fetch packages' })
  useStatusMsg(status.clubs, { error: 'Failed to fetch club memberships' })
  useStatusMsg(status.permits, { error: 'Failed to fetch user permits' })
  useStatusMsg(status.customers, { error: 'Failed to fetch user customers' })
  useStatusMsg(status.beneficiaries, { error: 'Failed to fetch user beneficiaries' })
  useStatusMsg(status.waitlists, { error: 'Failed to fetch user waitlists' })
  useStatusMsg(status.orgTeamMemberships, { error: 'Failed to fetch user org team memberships' })
  useStatusMsg(status.venueTeamMemberships, { error: 'Failed to fetch user venue team memberships' })
  useStatusMsg(status.edit, {
    pending: 'Updating...',
    error: 'Failed to update user',
    success: 'User updated.',
  })

  useEffect(() => {
    dispatch(fetchUser(id))
      .then(u => setUser(u))
    dispatch(fetchUserProgramBookings(id))
      .then(b => setBookings(b))
    dispatch(fetchUserPackages({ userId: id }))
      .then(p => setPackages(p))
    dispatch(fetchUserClubMemberships(id))
      .then(m => setClubMemberships(m))
    dispatch(fetchPermitsForUser(id))
      .then(p => setPermits(p))
    dispatch(fetchUserBeneficiaries({ userId: id }))
      .then(b => setBeneficiaries(b))
    dispatch(fetchUserCustomers({ userId: id }))
      .then(c => setUserCustomers(c))
    dispatch(fetchUserWaitlists({ userId: id }))
      .then(w => setUserWaitlists(w))
    dispatch(fetchUserOrgTeamMemberships({ userId: id }))
      .then(m => setOrgTeamMemberships(m))
    dispatch(fetchUserVenueTeamMemberships({ userId: id }))
      .then(m => setVenueTeamMemberships(m))
    return () => {
      dispatch(clearStatus(acts.FETCH_USER))
      dispatch(clearStatus(acts.FETCH_USER_PROGRAM_BOOKINGS))
      dispatch(clearStatus(acts.FETCH_USER_PACKAGES))
      dispatch(clearStatus(acts.FETCH_USER_CLUB_MEMBERSHIPS))
      dispatch(clearStatus(acts.FETCH_USER_USER_PERMITS))
      dispatch(clearStatus(acts.FETCH_USER_CUSTOMERS))
      dispatch(clearStatus(acts.FETCH_USER_BENEFICIARIES))
      dispatch(clearStatus(acts.FETCH_USER_WAITLISTS))
      dispatch(clearStatus(acts.FETCH_ORG_TEAM_MEMBERSHIPS))
      dispatch(clearStatus(acts.FETCH_VENUE_TEAM_MEMBERSHIPS))
      dispatch(clearStatus(acts.UPDATE_USER))
    }
  }, [id])

  const handleMenuClick = e => {
    if (e.key === 'edit-user') {
      setEditing(true)
    }
  }

  const actionMenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="edit-user">
        Edit
      </Menu.Item>
    </Menu>
  )

  const loaded = [
    user,
    bookings,
    packages,
    clubMemberships,
    orgTeamMemberships,
    venueTeamMemberships,
    permits,
    beneficiaries,
    userCustomers,
  ].reduce((acc, curr) => acc && Boolean(curr), true)

  if (!loaded) {
    return <LoadingOutlined />
  }

  let eqxDisplay = null
  const isEquinox = !!user.eqxMemberId
  if (isEquinox) {
    eqxDisplay = (
      <Attr name="Equinox member ID">
        <div>
          <div style={{marginBottom: theme.spacing[2]}}>{user.eqxMemberId}</div>
          <Collapse style={{maxWidth: '600px'}}>
            <Panel header="Info" key="info">
              <div style={{whiteSpace: 'pre-wrap', overflow: 'scroll', overflowWrap: 'break-word'}}>
                {JSON.stringify(user.eqxInfo, null, '\t')}
              </div>
            </Panel>
          </Collapse>
        </div>
      </Attr>
    )
  }

  const generatePasswordResetLink = () => {
    dispatch(generateUserPasswordResetLink({ userId: user.id }))
      .then(data => setNewPasswordResetLink(data.resetUrl))
  }

  return (
    <>
      <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to={paths.admin.USERS()}>Users</Link></Breadcrumb.Item>
        <Breadcrumb.Item>{userUtils.formatName(user)}</Breadcrumb.Item>
      </Breadcrumb>
      <div>
        <div style={{marginTop: theme.spacing[3], display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <h1>{userUtils.formatName(user)}</h1>
          <Dropdown overlay={actionMenu} trigger={['click']}>
            <Button>
              Actions <DownOutlined />
            </Button>
          </Dropdown>
        </div>
        <Attr name="ID">
          <ID>{user.id}</ID>
        </Attr>
        <Attr name="Profile image">
          <div>
            {user.profileImage ?
              <ImagePreview asset={user.profileImage} width="150px" height="150px" style={{objectFit: 'cover'}}/> :
              <i>No image</i>
            }
          </div>
        </Attr>
        <Attr name="Signup date">
          <div>{formatDateTimeTz(user.createdAt)}</div>
        </Attr>
        <Attr name="Type">
          <UserType user={user} />
        </Attr>
        <Attr name="Email">
           <div>
            {user.email}
          </div>
        </Attr>
        <Attr name="Gender">
          <div>
            {user.gender ? user.gender : <i>None</i>}
          </div>
        </Attr>
        <Attr name="Birthdate">
          <div>
            {user.birthdate ? formatDate(user.birthdate) : <i>None</i>}
          </div>
        </Attr>
        <Attr name="Location">
          <div>{user.location ? user.location : <i>None</i>}</div>
        </Attr>
        <Attr name="Occupation">
          <div>{user.occupation ? user.occupation : <i>None</i>}</div>
        </Attr>
        <Attr name="Player Level">
          <div>
            <PlayerLevel user={user} />
          </div>
        </Attr>
        <Attr name="Stripe customer ID">
           <div>
             <a href={urls.stripeCustomer(user.stripeCustomerId)}>{user.stripeCustomerId}</a>
          </div>
        </Attr>
        <Attr name="Sendbird user ID">
           <div>
             {user.sendbirdUserId ?
               <a href={urls.sendbirdUser(user.sendbirdUserId)}>{user.sendbirdUserId}</a> :
               <i>No sendbird account</i>
             }
          </div>
        </Attr>
        <Attr name="Password reset">
           <div>
             <div style={{marginBottom: theme.spacing[1]}}>
               {(!user.passwordReset && !newPasswordResetLink) &&
                 <Noner none="No password reset"></Noner>
               }
               {(user.passwordReset && !newPasswordResetLink) &&
                 <i>Reset link generated on {formatDateTimeTz(user.passwordReset.createdAt)}</i>
               }
             </div>
             {!newPasswordResetLink &&
               <Button onClick={generatePasswordResetLink}>Generate new reset link</Button>
             }
             {newPasswordResetLink &&
               <a href={newPasswordResetLink}>{newPasswordResetLink}</a>
             }
          </div>
        </Attr>
        {eqxDisplay}
        <Collapse>
          <Panel
            header={<CountHeader title="Payment methods" count={user.paymentMethods.length} />}
            key="payment-methods"
          >
            <Table
              size="small"
              columns={paymentMethodColumns}
              dataSource={user.paymentMethods}
              pagination={false}
            />
          </Panel>
          <Panel
            header={<CountHeader title="Bookings" count={bookings.length} />}
            key="bookings"
          >
            <UserBookings bookings={bookings} onCancel={booking => setCancelingBooking(booking)} />
          </Panel>
          <Panel
            header={<CountHeader title="Packages" count={packages.length} />}
            key="packages"
          >
            <Table
              size="small"
              columns={packageColumns({ currentUser: user })}
              dataSource={packages}
            />
          </Panel>
          <Panel
            header={<CountHeader title="Waitlists" count={userWaitlists?.length} />}
            key="waitlists"
          >
            {status.waitlists.pending && <LoadingOutlined />}
            {!status.waitlists.pending &&
              <Table
                size="small"
                columns={waitlistColumns()}
                dataSource={userWaitlists}
                pagination={false}
              />
            }
          </Panel>
          <Panel
            header={<CountHeader title="Club memberships" count={clubMemberships.length} />}
            key="club-memberships"
          >
            <Table
              size="small"
              columns={membershipColumns}
              dataSource={clubMemberships}
              pagination={false}
            />
          </Panel>
          <Panel
            header={<CountHeader title="Org team memberships" count={orgTeamMemberships.length} />}
            key="org-team-memberships"
          >
            <div style={{marginBottom: theme.spacing[3], display: 'flex'}}>
              <Button style={{marginLeft: 'auto'}} onClick={() => setAddingOrgTeamMemberships(true)}>
                <PlusOutlined /> Add org team membership
              </Button>
            </div>
            <Table
              size="small"
              columns={orgTeamMembershipsColumns({
                onDelete: deleted => setOrgTeamMemberships(prev => prev.filter(p => p.id !== deleted.id)),
              })}
              dataSource={orgTeamMemberships}
              pagination={false}
            />
          </Panel>
          <Panel
            header={<CountHeader title="Venue team memberships" count={venueTeamMemberships.length} />}
            key="venue-team-memberships"
          >
            <div style={{marginBottom: theme.spacing[3], display: 'flex'}}>
              <Button style={{marginLeft: 'auto'}} onClick={() => setAddingVenueMemberships(true)}>
                <PlusOutlined /> Add venue team membership
              </Button>
            </div>
            <Table
              size="small"
              columns={venueTeamMembershipsColumns({
                onDelete: deleted => setVenueTeamMemberships(prev => prev.filter(p => p.id !== deleted.id)),
              })}
              dataSource={venueTeamMemberships}
              pagination={false}
            />
          </Panel>
          <Panel
            header={<CountHeader title="Permits" count={permits.length} />}
            key="permits"
          >
            <Table
              size="small"
              columns={permitColumns}
              dataSource={permits}
              pagination={false}
            />
          </Panel>
          <Panel
            header={<CountHeader title="Beneficiary of" count={beneficiaries.length} />}
            key="beneficiary-of"
          >
            <Table
              size="small"
              columns={beneficiariesColumns({ onEdit: beneficiary => setEditingBeneficiary(beneficiary) })}
              dataSource={beneficiaries}
              pagination={false}
            />
          </Panel>
          <Panel
            header={<CountHeader title="Customer of" count={userCustomers.length} />}
            key="customer-of"
          >
            <Table
              size="small"
              columns={userCustomersColumns}
              dataSource={userCustomers}
              pagination={false}
            />
          </Panel>
        </Collapse>
      </div>
      { editing &&
        <EditUserModal
          user={user}
          complete={user => {
            if (user) {
              setUser(user)
            }
            setEditing(false)
          }}
        />
      }
      { addingVenueMemberships &&
        <AddVenueTeamMembershipModal
          user={user}
          complete={updatedMemberships => {
            if (updatedMemberships) {
              setVenueTeamMemberships(updatedMemberships)
            }
            setAddingVenueMemberships(false)
          }}
        />
      }
      { addingOrgTeamMemberships &&
        <AddOrgTeamMembershipModal
          user={user}
          complete={updatedMemberships => {
            if (updatedMemberships) {
              setOrgTeamMemberships(updatedMemberships)
            }
            setAddingOrgTeamMemberships(false)
          }}
        />
      }
      { cancelingBooking &&
        <CancelBookingModal
          user={user}
          booking={cancelingBooking}
          complete={updatedBookings => {
            if (updatedBookings) {
              setBookings(updatedBookings)
            }
            setCancelingBooking(null)
          }}
        />
      }
      { editingBeneficiary &&
        <EditBeneficiaryModal
          beneficiary={editingBeneficiary}
          complete={updatedBeneficiary => {
            if (updatedBeneficiary) {
              setBeneficiaries(prev => {
                const next = [...prev]
                const idx = next.findIndex(b => b.id === updatedBeneficiary.id)
                if (idx >= 0) {
                  next[idx] = updatedBeneficiary
                }
                return next
              })
            }
            setEditingBeneficiary(null)
          }}
        />
      }
    </>
  )
}

export default User
