import React, { useEffect, useState } from 'react'
import { message } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { LoadingOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { useStatus, useStatusMsg } from '../../reducers'
import { fetchClubVideo, updateClubVideo } from '../../actions/org'
import { clearStatus } from '../../actions/status'
import { FocusHeader } from '../../components/header'
import { Cta } from '../../components/common'
import { Focus as FocusContainer } from '../../components/containers'
import { paths, actions as acts } from '../../constants'
import { VideoEditor } from '../../components/videos'

const EditClubVideo = () => {
  const { osid, id, videoId } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const [video, setVideo] = useState(null)
  const [products, setProducts] = useState([])
  const status = {
    fetch: useStatus(acts.FETCH_CLUB_VIDEO),
    edit: useStatus(acts.EDIT_CLUB_VIDEO),
  }

  useEffect(() => {
    dispatch(fetchClubVideo({ osid, clubId: id, videoId })).then(v => {
      if (Array.isArray(v.products) && v.products.length > 0) {
        v.products.sort((a, b) => a.order - b.order)
        setProducts(v.products.map(p => (p.product ? p.product : p)))
      }
      setVideo(v)
    })
  }, [videoId])

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.EDIT_CLUB_VIDEO))
      dispatch(clearStatus(acts.FETCH_CLUB_VIDEO))
    }
  }, [])

  useStatusMsg(status.edit, {
    pending: 'Saving...',
    error: 'Failed to save video',
    success: 'Video saved',
  })

  const missingFields = () => {
    const missing = ['title', 'description', 'videoUrl'].filter(k => {
      if (!video[k]) {
        return true
      }
      return false
    })
    if (missing.length > 0) {
      const msg = `${missing.join(', ')} ${missing.length > 1 ? 'are' : 'is'} required`
      message.error(`${msg.charAt(0).toUpperCase()}${msg.substr(1)}`)
      return true
    }
    return false
  }

  const saveClubVideo = () => {
    if (missingFields()) {
      return
    }
    const newVideo = video
    newVideo.products = products
    dispatch(updateClubVideo({ osid, clubId: id, videoId, video: newVideo })).then(() =>
      history.push(paths.org.CLUB_VIDEOS(osid, id)),
    )
  }

  return (
    <>
      <FocusHeader
        title="Edit a club video"
        actions={<Cta onClick={saveClubVideo}>Save video</Cta>}
      />
      <FocusContainer style={{ paddingTop: '20px', paddingBottom: '20px' }}>
        {(status.fetch.pending || !video) && (
          <center>
            <LoadingOutlined />
          </center>
        )}
        {video && (
          <VideoEditor
            video={video}
            onChange={v => setVideo(v)}
            products={products}
            setProducts={p => setProducts(p)}
          />
        )}
      </FocusContainer>
    </>
  )
}

export default EditClubVideo
