import * as billingConsts from '../constants/billing'

export const cardBrandLabel = cardBrandName => {
  if (cardBrandName in billingConsts.cardBrands) {
    return billingConsts.cardBrands[cardBrandName].label
  }
  return 'Unknown card brand'
}

export default {
  cardBrandLabel,
}
