import { MenuOutlined } from '@ant-design/icons'
import * as React from 'react'
import { Key } from 'react'
import styled from 'styled-components'

export type ReactChild<T = never> =
  | React.ReactNode
  | null
  | string
  | number
  | false
  | T
export type ReactChildren<T = never> = ReactChild<T> | ReactChildren<T>[]

export declare namespace Reorderable {
  export type Props<T> = {
    items: T[]
    onChange: (newItems: T[]) => any
    keyExtractor: (item: T, i: number) => Key
    renderItem: (item: T, i: number) => ReactChildren
  }
}

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`

const Draggable = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  cursor: grab;

  :active {
    cursor: grabbing !important;
  }
`

function swap<T>(arr: T[], index1: number, index2: number) {
  if (index1 < 0 || index1 >= arr.length) {
    return arr
  }

  if (index2 < 0 || index2 >= arr.length) {
    return arr
  }

  arr = [...arr]

  const tmp = arr[index1]
  arr[index1] = arr[index2]
  arr[index2] = tmp

  return arr
}

export function Reorderable<T>({
  items,
  onChange,
  keyExtractor,
  renderItem,
}: Reorderable.Props<T>) {
  const [movingIndex, setMovingIndex] = React.useState(-1)
  const ref = React.useRef<HTMLDivElement>(null)

  const onChangeRef = React.useRef(onChange)
  onChangeRef.current = onChange

  React.useEffect(() => {
    let index1 = -1
    function handleDrag(event: DragEvent) {
      event.preventDefault()
      if (event.dataTransfer) {
        event.dataTransfer.dropEffect = 'none'
      }
      const target = event.target as HTMLElement | null
      index1 = parseInt(target?.dataset.index ?? "-1")
      setMovingIndex(index1)
    }

    function handleDrop(event: Event) {
      // walk up the dom until we find something droppable
      let crnt = event.target as HTMLElement | null
      while (crnt && !crnt.getAttribute('draggable')) {
        crnt = crnt.parentElement
      }
      const index2 = parseInt(crnt?.dataset.index ?? "-1")
      onChangeRef.current(swap(items, index1, index2))
      setMovingIndex(-1)
    }

    function cancel(e: Event) {
      e.preventDefault()
    }

    document.addEventListener('dragenter', cancel)
    document.addEventListener('dragover', cancel)
    document.addEventListener('drag', handleDrag, false)
    document.addEventListener('drop', handleDrop, false)
    return () => {
      document.removeEventListener('dragenter', cancel)
      document.removeEventListener('dragover', cancel)
      document.removeEventListener('drag', handleDrag, false)
      document.removeEventListener('drop', handleDrop, false)
    }
  }, [items])

  return (
    <FlexCol ref={ref}>
      {items.map((item, i) => (
        <React.Fragment key={keyExtractor(item, i)}>
          <Draggable data-index={i} className="draggable" draggable="true" style={i === movingIndex ? { opacity: 0.2 } : undefined}>
            <MenuOutlined style={{marginRight: 8}} />
            {renderItem(item, i)}
          </Draggable>
        </React.Fragment>
      ))}
    </FlexCol>
  )
}
