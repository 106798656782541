import { Tag } from 'antd'
import dayjs from 'dayjs'
import { CalendarOutlined, ExclamationCircleOutlined, SyncOutlined } from '@ant-design/icons'

export const DateTag = ({ date, onClose, showCalendarIcon, status, ...props }) => {
  const iconProps = {}
  if (status) {
    iconProps.color = status
    if (status === 'processing') {
      iconProps.icon = <SyncOutlined spin />
    }
    if (status === 'error') {
      iconProps.icon = <ExclamationCircleOutlined />
    }
    if (status === 'success') {
      iconProps.icon = <CalendarOutlined />
    }
  } else if (showCalendarIcon) {
    iconProps.icon = <CalendarOutlined />
  }

  return (
    <Tag
      closable
      onClose={e => {
        e.preventDefault()
        if (typeof onClose === 'function') {
          onClose()
        }
      }}
      {...iconProps}
      {...props}
    >
      {dayjs(date).format('D MMM YYYY')}
    </Tag>
  )
}
