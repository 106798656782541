import { useEffect, useState } from 'react'
import { Select, InputNumber, Tag, Collapse, Modal, Menu, Breadcrumb, Dropdown, Table, message } from 'antd'
import { PlusOutlined, EllipsisOutlined, DownOutlined, LoadingOutlined } from '@ant-design/icons'
import { Noner, ID, Button, Cta as CtaButton } from '../../../components/common'
import * as adminActs from '../../../actions/admin'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useStatus, useStatusMsg } from '../../../reducers'
import { clearStatus } from '../../../actions/status'
import { paths, actions as acts } from '../../../constants'
import { ThemeContext, useTheme } from 'styled-components'
import { CountHeader, Head, PaddedContainer, Title } from '../../../components/common'
import { AdminVenueLink } from '../../../components/venue'
import { formatDateTimeTz } from '../../../util/time'
import Attr from '../../../components/Attr'
import { BenefitVenueEditor, BeneficiaryGroupEditor } from './editors.jsx'

export const BeneficiaryGroupActionsMenu = ({ beneficiaryGroup, onRemove, onEdit }) => {
  const dispatch = useDispatch()
  const removeStatus = useStatus(acts.REMOVE_BENEFICIARY_GROUP)

  useStatusMsg(removeStatus, {
    pending: 'Removing available lesson...',
    error: e => typeof e.message === 'string' ? e.message: 'Failed to remove beneficiary group',
    success: 'Beneficiary group removed',
  })

  useEffect(() => () => dispatch(clearStatus(acts.REMOVE_BENEFICIARY_GROUP)), [])

  const handleActionMenu = e => {
    if (e.key === 'remove') {
      Modal.confirm({
        title: 'Remove beneficiary group?',
        okText: 'Remove',
        okButtonProps: {
          danger: true,
          loading: removeStatus.pending || false,
          disabled: removeStatus.pending || false,
        },
        content: (
          <div>
            <p>Are you sure you want to remove this beneficiary group?</p>
          </div>
        ),
        onOk: () => {
          return dispatch(
            adminActs.removeBeneficiaryGroup({
              benefitSig: beneficiaryGroup.benefit,
              beneficiaryGroupId: beneficiaryGroup.id,
            })
          )
            .then(() => {
              if (typeof onRemove === 'function') {
                onRemove(beneficiaryGroup)
              }
            })
        },
      })
    } else if (e.key === 'edit') {
      if (typeof onEdit === 'function') {
        onEdit(beneficiaryGroup)
      }
    }

  }
  return (
    <Menu onClick={handleActionMenu}>
      <Menu.Item key="edit">Edit</Menu.Item>
      <Menu.Item key="remove" danger>Remove</Menu.Item>
    </Menu>
  )
}

const beneficiaryGroupCols = ({ benefit, onRemove, onEdit }) => ([
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (val, record) => {
      return (
        <div>
          <Link to={paths.admin.BENEFICIARY_GROUP(benefit.sid, record.id)}>{val}</Link>
          <small><ID>{record.id}</ID></small>
        </div>
      )
    }
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (val, record) => {
      return <Tag>{val}</Tag>
    },
  },
  {
    title: 'Verification method',
    dataIndex: 'verificationMethod',
    key: 'verficationMethod',
    render: (val, record) => {
      return val ? <Tag>{val}</Tag> : <Noner />
    },
  },
  {
    title: 'Users',
    dataIndex: 'users',
    key: 'users',
    render: (val, record) => {
      return (
        <div>
          {Array.isArray(val) ? val.length : 0}
        </div>
      )
    },
  },
  {
    title: 'Created',
    key: 'createdAt',
    dataIndex: 'createdAt',
    defaultSortOrder: 'descend',
    sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    render: (val, record) => (
      <div>{formatDateTimeTz(val)}</div>
    )
  },
  {
    title: '',
    key: 'actions',
    dataIndex: 'actions',
    render: (val, record) => {
      return (
        <div style={{textAlign: 'center'}}>
          <Dropdown
            overlay={<BeneficiaryGroupActionsMenu beneficiaryGroup={record} onRemove={onRemove} onEdit={onEdit} />}
            trigger={['click']}
          >
            <Button>
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        </div>
      )
    }
  },
])

const benGroupRequiredFields = ['name', 'status']
const benGroupVerificationFields = ['verificationMethod', 'verificationName', 'verificationDescription', 'verificationFallbackMessage']
const benGroupMissingFields = (benGroup) => {
  let missing = benGroupRequiredFields.filter(k => !benGroup[k] ? true : false)
  if (benGroup.hasOwnProperty('verifcationMethod')) {
      missing = missing.concat(benGroupVerificationFields.filter(k => !benGroup[k] ? true : false))
  }
  return missing
}

const AddBeneficiaryGroupModal = ({ benefit, complete }) => {
  const completer = typeof complete === 'function' ? complete : () => {}
  const [beneficiaryGroup, setBeneficiaryGroup] = useState(null)
  const dispatch = useDispatch()
  const status = useStatus(acts.CREATE_BENEFICIARY_GROUP)

  useStatusMsg(status, {
    pending: 'Creating...',
    error: e => `Failed to create beneficiary group: ${e}`,
    success: 'Beneficiary group created',
  })

  useEffect(() => {
    return () => dispatch(clearStatus(acts.CREATE_BENEFICIARY_GROUP))
  }, [])

  const onOk = () => {
    const mfs = benGroupMissingFields(beneficiaryGroup)
    if (!beneficiaryGroup || mfs.length > 0) {
      const labels = mfs.join(', ')
      const msg = `${labels} ${mfs.length > 1 ? 'are' : 'is'} required`
      message.error(msg)
      return
    }
    dispatch(adminActs.createBeneficiaryGroup({ benefitSig: benefit.sid, ...beneficiaryGroup }))
      .then(newBeneficiaryGroup => completer(newBeneficiaryGroup))
  }

  return (
    <Modal
      title="Create beneficiary group"
      visible
      okText="Create"
      okButtonProps={{disabled: Boolean(status.pending), loading: Boolean(status.pending)}}
      onCancel={() => completer()}
      onOk={onOk}
    >
      <BeneficiaryGroupEditor
        beneficiaryGroup={beneficiaryGroup}
        onChange={next => setBeneficiaryGroup(next)} 
      />
    </Modal>
  )
}

export const EditBeneficiaryGroupModal = ({ beneficiaryGroup, complete }) => {
  const completer = typeof complete === 'function' ? complete : () => {}
  const [editingGroup, setEditingGroup] = useState(beneficiaryGroup)
  const dispatch = useDispatch()
  const status = useStatus(acts.UPDATE_BENEFICIARY_GROUP)

  useStatusMsg(status, {
    pending: 'Updating...',
    error: e => `Failed to update beneficiary group. ${e}: ${e.formatData()}`,
    success: 'Beneficiary group saved',
  })

  useEffect(() => {
    return () => dispatch(clearStatus(acts.UPDATE_BENEFICIARY_GROUP))
  }, [])

  const onOk = () => {
    const mfs = benGroupMissingFields(editingGroup)
    if (!editingGroup || mfs.length > 0) {
      const labels = mfs.join(', ')
      const msg = `${labels} ${mfs.length > 1 ? 'are' : 'is'} required`
      message.error(msg)
      return
    }
    const args = {
      benefitSig: beneficiaryGroup.benefit,
      beneficiaryGroupId: beneficiaryGroup.id,
      ...editingGroup,
    }
    if (Array.isArray(editingGroup.caps) && editingGroup.caps.length > 0) {
      args['caps'] = editingGroup.caps.map(c => {
        // change any beneficiary-group-cap to a cap
        if (c.hasOwnProperty('cap')) {
          return c.cap
        }
        return c
      })
    }
    dispatch(adminActs.updateBeneficiaryGroup(args))
      .then(updatedGroup => completer(updatedGroup))
  }

  return (
    <Modal
      title="Edit beneficiary group"
      visible
      okText="Save"
      okButtonProps={{disabled: Boolean(status.pending), loading: Boolean(status.pending)}}
      onCancel={() => completer()}
      onOk={onOk}
    >
      <BeneficiaryGroupEditor
        beneficiaryGroup={editingGroup}
        onChange={next => setEditingGroup(next)}
      />
    </Modal>
  )
}

const BeneficiaryGroups = ({ benefit, onChange }) => {
  const theme = useTheme(ThemeContext)
  const changer = typeof onChange === 'function' ? onChange : () => {}
  const [adding, setAdding] = useState(false)
  const [editing, setEditing] = useState(null)

  const onRemove = removedGroup => {
    if (removedGroup) {
      changer(benefit.beneficiaryGroups.filter(bg => bg.id !== removedGroup.id))
    }
  }

  const onAdd = bg => changer([...benefit.beneficiaryGroups, bg])

  const onUpdate = updatedBg => {
    const next = [...benefit.beneficiaryGroups]
    const idx = next.findIndex(bg => bg.id === updatedBg.id)
    if (idx !== -1) {
      next[idx] = updatedBg
    }
    changer(next)
  }

  return (
    <div>
      <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: theme.spacing[2]}}>
        <Button onClick={() => setAdding(true)}>
          <PlusOutlined /> Create beneficiary group
        </Button>
      </div>
      <Table
        size="small"
        columns={beneficiaryGroupCols({ benefit, onRemove, onEdit: bg => setEditing(bg) })}
        dataSource={benefit.beneficiaryGroups}
        pagination={false}
      />
      {adding &&
        <AddBeneficiaryGroupModal
          benefit={benefit}
          complete={beneficiaryGroup => {
            if (beneficiaryGroup) {
              onAdd(beneficiaryGroup)
            }
            setAdding(false)
          }}
        />
      }
      {editing &&
        <EditBeneficiaryGroupModal
          beneficiaryGroup={editing}
          complete={updatedGroup => {
            if (updatedGroup) {
              onUpdate(updatedGroup)
            }
            setEditing(null)
          }}
        />
      }
    </div>
  )
}

const benVenueRequiredFields = ['venue', 'status']
const benVenueMissingFields = benVenue => {
  if (!benVenue) {
    return benVenueRequiredFields
  }
  const missing = benVenueRequiredFields.filter(k => !benVenue[k] ? true : false)
  return missing
}

const AddBenefitVenueModal = ({ benefit, complete }) => {
  const completer = typeof complete === 'function' ? complete : () => {}
  const [benefitVenue, setBenefitVenue] = useState(null)
  const dispatch = useDispatch()
  const status = useStatus(acts.ADD_BENEFIT_VENUE)

  useStatusMsg(status, {
    pending: 'Adding...',
    error: e => `Failed to add benefit venue: ${e}`,
    success: 'Benefit venue added',
  })

  useEffect(() => {
    return () => dispatch(clearStatus(acts.ADD_BENEFIT_VENUE))
  }, [])

  // TODO
  // show users and bookings each user has made in that venue
  // allow editing FAQs
  // remove visibilityDays (use window if necessary)
  const onOk = () => {
    const mfs = benVenueMissingFields(benefitVenue)
    if (!benefitVenue || mfs.length > 0) {
      const labels = mfs.join(', ')
      const msg = `${labels} ${mfs.length > 1 ? 'are' : 'is'} required`
      message.error(msg)
      return
    }
    dispatch(adminActs.addBenefitVenue({ benefitSig: benefit.sid, ...benefitVenue }))
      .then(newBenefitVenue => completer(newBenefitVenue))
  }

  return (
    <Modal
      title="Add a venue"
      visible
      okText="Add"
      okButtonProps={{disabled: Boolean(status.pending), loading: Boolean(status.pending)}}
      onCancel={() => completer()}
      onOk={onOk}
    >
      <BenefitVenueEditor
        benefitVenue={benefitVenue}
        onChange={next => setBenefitVenue(next)}
      />
    </Modal>
  )
}

const EditBenefitVenueModal = ({ benefitVenue, complete }) => {
  const completer = typeof complete === 'function' ? complete : () => {}
  const [bv, setBv] = useState(null)
  const dispatch = useDispatch()
  const status = useStatus(acts.UPDATE_BENEFIT_VENUE)

  useStatusMsg(status, {
    pending: 'Saving...',
    error: e => `Failed to update venue: ${e}`,
    success: 'Venue has been updated',
  })

  useEffect(() => {
    return () => dispatch(clearStatus(acts.UPDATE_BENEFIT_VENUE))
  }, [])

  const onOk = () => {
    const mfs = benVenueMissingFields(bv)
    if (!bv || mfs.length > 0) {
      const labels = mfs.join(', ')
      const msg = `${labels} ${mfs.length > 1 ? 'are' : 'is'} required`
      message.error(msg)
      return
    }
    dispatch(adminActs.updateBenefitVenue({ benefitSig: benefitVenue.benefit, benefitVenueId: bv.id, ...bv }))
      .then(updatedBenefitVenue => completer(updatedBenefitVenue))
  }

  return (
    <Modal
      title="Edit a venue"
      visible
      okText="Save"
      okButtonProps={{disabled: Boolean(status.pending), loading: Boolean(status.pending)}}
      onCancel={() => completer()}
      onOk={onOk}
    >
      <BenefitVenueEditor
        benefitVenue={benefitVenue}
        onChange={next => setBv(next)}
        disabledFields={['venue']}
      />
    </Modal>
  )
}


export const BenefitVenueActionsMenu = ({ benefitVenue, onRemove, onEdit }) => {
  const dispatch = useDispatch()
  const removeStatus = useStatus(acts.DELETE_BENEFIT_VENUE)

  useStatusMsg(removeStatus, {
    pending: 'Removing venue...',
    error: e => typeof e.message === 'string' ? e.message: 'Failed to remove venue',
    success: 'Venue removed',
  })

  useEffect(() => () => dispatch(clearStatus(acts.DELETE_BENEFIT_VENUE)), [])

  const handleActionMenu = e => {
    if (e.key === 'remove') {
      Modal.confirm({
        title: 'Remove venue from benefit?',
        okText: 'Remove',
        okButtonProps: {
          danger: true,
          loading: removeStatus.pending || false,
          disabled: removeStatus.pending || false,
        },
        onOk: () => {
          return dispatch(
            adminActs.deleteBenefitVenue({
              benefitSig: benefitVenue.benefit,
              benefitVenueId: benefitVenue.id,
            })
          )
            .then(() => {
              if (typeof onRemove === 'function') {
                onRemove(benefitVenue)
              }
            })
        },
      })
    } else if (e.key === 'edit') {
      if (typeof onEdit === 'function') {
        onEdit(benefitVenue)
      }
    }

  }
  return (
    <Menu onClick={handleActionMenu}>
      <Menu.Item key="edit">Edit</Menu.Item>
      <Menu.Item key="remove" danger>Remove</Menu.Item>
    </Menu>
  )
}

const benefitVenueCols = ({ onRemove, onEdit }) => ([
  {
    title: 'Venue',
    dataIndex: 'venue',
    key: 'venue',
    defaultSortOrder: 'ascend',
    sorter: (a, b) => a.venue.name.localeCompare(b.venue.name),
    render: (val, record) => {
      return (
        <div>
          <AdminVenueLink venue={val} />
          <small><ID>{record.id}</ID></small>
        </div>
      )
    }
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    sorter: (a, b) => a.status.localeCompare(b.status),
    render: (val, record) => {
      return (
        <Tag>{val}</Tag>
      )
    }
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    render: (val, record) => {
      return (
        <Noner>{val}</Noner>
      )
    }
  },
  {
    title: 'Checkin instructions',
    dataIndex: 'checkinInstructions',
    key: 'checkinInstructions',
    render: (val, record) => {
      return (
        <Noner>{val}</Noner>
      )
    }
  },
  {
    title: 'FAQ section title',
    dataIndex: 'faqSectionTitle',
    key: 'faqSectionTitle',
    render: (val, record) => {
      return (
        <Noner>{val}</Noner>
      )
    }
  },
  {
    title: '',
    key: 'actions',
    dataIndex: 'actions',
    render: (val, record) => {
      return (
        <div style={{textAlign: 'center'}}>
          <Dropdown
            overlay={<BenefitVenueActionsMenu benefitVenue={record} onRemove={onRemove} onEdit={onEdit} />}
            trigger={['click']}
          >
            <Button>
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        </div>
      )
    }
  },
])

const BenefitVenues = ({ benefit, onChange }) => {
  const theme = useTheme(ThemeContext)
  const changer = typeof onChange === 'function' ? onChange : () => {}
  const [adding, setAdding] = useState(false)
  const [editing, setEditing] = useState(null)

  const onAdd = bv => changer([...benefit.venues, bv])

  const onUpdate = updatedBv => {
    const next = [...benefit.venues]
    const idx = next.findIndex(bv => bv.id === updatedBv.id)
    if (idx !== -1) {
      next[idx] = updatedBv
    }
    changer(next)
  }

  const onRemove = removed => {
    changer(benefit.venues.filter(v => v.id !== removed.id))
  }

  const onEdit = edited => {
    const next = [...benefit.venues]
    const idx = next.findIndex(v => v.id === edited.id)
    if (idx > -1) {
      next[idx] = edited
      changer(next)
    }
  }

  return (
    <div>
      <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: theme.spacing[2]}}>
        <Button onClick={() => setAdding(true)}>
          <PlusOutlined /> Add venue
        </Button>
      </div>
      <Table
        size="small"
        columns={benefitVenueCols({ onRemove, onEdit: bv => setEditing(bv) })}
        dataSource={benefit.venues}
        pagination={false}
      />
      {adding &&
        <AddBenefitVenueModal
          benefit={benefit}
          complete={benefitVenue => {
            if (benefitVenue) {
              onAdd(benefitVenue)
            }
            setAdding(false)
          }}
        />
      }
      {editing &&
        <EditBenefitVenueModal
          benefitVenue={editing}
          complete={benefitVenue => {
            if (benefitVenue) {
              onEdit(benefitVenue)
            }
            setEditing(null)
          }}
        />
      }
    </div>
  )
}

// expects an array of beneficiary groups with caps.cap expanded
// returns a new array of beneficiary groups where caps is an array of caps, not beneficiary-group-cap
const formatBeneficiaryGroupCaps = beneficiaryGroups => {
  if (!Array.isArray(beneficiaryGroups) || beneficiaryGroups.length === 0) {
    return beneficiaryGroups
  }
  return beneficiaryGroups.map(bg => {
    let caps = bg.caps
    if (Array.isArray(bg.caps) && bg.caps.length > 0) {
      caps = bg.caps.map(bgc => {
        if (bgc.hasOwnProperty('cap')) {
          return bgc.cap
        }
        return bgc
      })
    }
    return { ...bg, caps }
  })
}

const Benefit = () => {
  const { sig } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const [benefit, setBenefit] = useState(null)
  const [editingOrg, setEditingOrg] = useState(false)
  const [editingVenue, setEditingVenue] = useState(null)
  const [showSettingsInfo, setShowSettingsInfo] = useState(false)
  const [addingMembers, setAddingMembers] = useState(false)
  const [addingFeeRule, setAddingFeeRule] = useState(false)
  const status = useStatus(acts.FETCH_ORG)
  const theme = useTheme(ThemeContext)

  useEffect(() => {
    dispatch(adminActs.fetchBenefit({ sig }))
      .then(ben => setBenefit({
        ...ben,
        beneficiaryGroups: formatBeneficiaryGroupCaps(ben.beneficiaryGroups),
      }))
  }, [sig])

  if (!benefit) {
    return <LoadingOutlined />
  }

  const onChangeBeneficiaryGroups = updatedGroups => {
    setBenefit(prev => ({ ...prev, beneficiaryGroups: formatBeneficiaryGroupCaps(updatedGroups) }))
  }

  const onChangeVenues = updatedVenues => {
    setBenefit(prev => ({ ...prev, venues: updatedVenues }))
  }

  return (
    <PaddedContainer>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={paths.admin.BENEFITS()}>Benefits</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{sig}</Breadcrumb.Item>
      </Breadcrumb>
      <Head
        style={{ display: 'flex', justifyContent: 'space-between', marginTop: theme.spacing[3], alignItems: 'center' }}
      >
        <Title>{benefit.name}</Title>
        {/*
        <Dropdown
          trigger={['click']}
        >
          <CtaButton>
            Actions <DownOutlined />
          </CtaButton>
        </Dropdown>
        */}
      </Head>
      <Attr name="ID">
        <div>
          <small><ID>{benefit.id}</ID></small>
        </div>
      </Attr>
      <Attr name="Beneficiary groups">
        <BeneficiaryGroups benefit={benefit} onChange={onChangeBeneficiaryGroups} />
      </Attr>
      <Attr name="Venues">
        <BenefitVenues benefit={benefit} onChange={onChangeVenues} />
      </Attr>
    </PaddedContainer>
  )
}

export default Benefit
