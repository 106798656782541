import qs from 'query-string'
import queryString from '../util/query-string.ts'
import fetchData, { fetchCsv } from '../util/fetch'
import { paths, urls, actions as acts } from '../constants'
import { roles as clubRoles } from '../constants/club'
import act from './act'

export const setOrg = sid => async dispatch => {
  dispatch({ type: acts.SET_ORG_PENDING, payload: { sid } })
  try {
    const org = await fetchData({ url: urls.org.ORG(sid) })
    dispatch({ type: acts.SET_ORG_SUCCESS, payload: org })
    return org
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.SET_ORG_FAILURE, payload: { err } })
  }
}

export const fetchOrgs = () => async dispatch => {
  dispatch({ type: acts.FETCH_ORGS_PENDING, payload: { } })
  try {
    const orgs = await fetchData({ url: urls.org.ORGS() })
    dispatch({ type: acts.FETCH_ORGS_SUCCESS, payload: orgs })
    return orgs
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.FETCH_ORGS_FAILURE, payload: { err } })
  }
}

export const fetchOrg = act(acts.FETCH_ORG, ({ osid }) =>
  fetchData({
    url: urls.org.ORG(osid),
  }),
)

export const updateOrgSettings = act(acts.UPDATE_ORG_SETTINGS, ({ osid, settings }) =>
  fetchData({
    type: 'PATCH',
    url: urls.org.ORG_SETTINGS(osid),
    body: { settings },
  }),
)

export const createManualBooking = ({ osid, userId, email, programId, bookingDate }) => async dispatch => {
  dispatch({ type: acts.CREATE_MANUAL_BOOKING_PENDING, payload: { osid, userId, email, programId, bookingDate } })
  try {
    const { invoice, bookings } = await fetchData({
      type: 'POST',
      url: urls.org.PROGRAM_BOOKINGS(osid, programId),
      body: { userId, email, bookingDate }
    })
    dispatch({ type: acts.CREATE_MANUAL_BOOKING_SUCCESS, payload: { osid, programId, bookings } })
    return bookings
  } catch (err) {
    dispatch({ type: acts.CREATE_MANUAL_BOOKING_FAILURE, payload: err })
    console.log(err)
    throw err
  }
}

export const fetchProgramPosts = (osid, programId, cursor=null, limit=20) => async dispatch => {
  dispatch({ type: acts.FETCH_PROGRAM_POSTS_PENDING, payload: { osid, programId } })
  try {
    let url = urls.org.PROGRAM_POSTS(osid, programId)
    if (cursor) {
      url = qs.stringifyUrl({ url, query: { cursor } })
    }
    if (limit) {
      url = qs.stringifyUrl({ url, query: { limit } })
    }
    const posts = await fetchData({ url })
    dispatch({ type: acts.FETCH_PROGRAM_POSTS_SUCCESS, payload: posts })
    return posts
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.FETCH_PROGRAM_POSTS_FAILURE, payload: { err } })
    throw err
  }
}

export const fetchProgramPinnedPosts = (osid, programId) => async dispatch => {
  dispatch({ type: acts.FETCH_PROGRAM_PINNED_POSTS_PENDING, payload: { osid, programId } })
  try {
    const posts = await fetchData({
      url: urls.org.PROGRAM_PINNED_POSTS(osid, programId),
    })
    dispatch({ type: acts.FETCH_PROGRAM_PINNED_POSTS_SUCCESS, payload: posts })
    return posts
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.FETCH_PROGRAM_PINNED_POSTS_FAILURE, payload: { err } })
    throw err
  }
}

export const createProgramPost = (osid, programId, notify, { content, isAdminPost, pinned, images }) => async dispatch => {
  dispatch({
    type: acts.CREATE_PROGRAM_POST_PENDING,
    payload: { osid, programId, notify, content, isAdminPost, pinned },
  })
  try {
    let body = { content, isAdminPost, pinned }
    const hasImages = Array.isArray(images) && images.length > 0
    if (hasImages) {
      const fd = new FormData()
      images.forEach(i => fd.append('images', i))
      fd.append('attrs', JSON.stringify(body))
      body = fd
    }

    let url = urls.org.PROGRAM_POSTS(osid, programId)
    if (notify) {
      url = qs.stringifyUrl({ url, query: { notify: 1 } })
    }

    const post = await fetchData({
      type: 'POST',
      url,
      body,
      isUpload: hasImages,
    })
    dispatch({ type: acts.CREATE_PROGRAM_POST_SUCCESS, payload: post })
    return post
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.CREATE_PROGRAM_POST_FAILURE, payload: { err } })
    throw err
  }
}

export const fetchProgramPost = (osid, programId, postId) => async dispatch => {
  dispatch({ type: acts.FETCH_PROGRAM_POST_PENDING, payload: { osid, programId, postId } })
  try {
    const post = await fetchData({ url: urls.org.PROGRAM_POST(osid, programId, postId) })
    dispatch({ type: acts.FETCH_PROGRAM_POST_SUCCESS, payload: post })
    return post
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.FETCH_PROGRAM_POST_FAILURE, payload: { err } })
    throw err
  }
}

export const editProgramPost = (osid, programId, postId, { content, isAdminPost, pinned, images }) => async dispatch => {
  dispatch({
    type: acts.EDIT_PROGRAM_POST_PENDING,
    payload: { osid, programId, postId, content, isAdminPost, pinned },
  })
  try {
    let body = { content, isAdminPost, pinned }
    const hasImages = Array.isArray(images) && images.length > 0

    if (hasImages) {
      const fd = new FormData()
      images.forEach(i => fd.append('images', i))
      fd.append('attrs', JSON.stringify(body))
      body = fd
    }

    const post = await fetchData({
      type: 'PATCH',
      url: urls.org.PROGRAM_POST(osid, programId, postId),
      body,
      isUpload: hasImages
    })
    
    dispatch({ type: acts.EDIT_PROGRAM_POST_SUCCESS, payload: post })
    return post
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.EDIT_PROGRAM_POST_FAILURE, payload: { err } })
    throw err
  }
}

export const deleteProgramPost = (osid, programId, postId) => async dispatch => {
  dispatch({ type: acts.DELETE_PROGRAM_POST_PENDING, payload: { osid, programId, postId } })
  try {
    const res = await fetchData({
      type: 'DELETE',
      url: urls.org.PROGRAM_POST(osid, programId, postId),
    })
    dispatch({ type: acts.DELETE_PROGRAM_POST_SUCCESS, payload: res })
    return res
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.DELETE_PROGRAM_POST_FAILURE, payload: { err } })
    throw err
  }
}

export const pinProgramPost = (osid, programId, postId) => async dispatch => {
  dispatch({ type: acts.PIN_PROGRAM_POST_PENDING, payload: { osid, programId, postId } })
  try {
    const res = await fetchData({
      type: 'POST',
      url: urls.org.PIN_PROGRAM_POST(osid, programId, postId),
    })
    dispatch({ type: acts.PIN_PROGRAM_POST_SUCCESS, payload: res })
    return res
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.PIN_PROGRAM_POST_FAILURE, payload: { err } })
    throw err
  }
}

export const unpinProgramPost = (osid, programId, postId) => async dispatch => {
  dispatch({ type: acts.UNPIN_PROGRAM_POST_PENDING, payload: { osid, programId, postId } })
  try {
    const res = await fetchData({
      type: 'POST',
      url: urls.org.UNPIN_PROGRAM_POST(osid, programId, postId),
    })
    dispatch({ type: acts.UNPIN_PROGRAM_POST_SUCCESS, payload: res })
    return res
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.UNPIN_PROGRAM_POST_FAILURE, payload: { err } })
    throw err
  }
}

export const fetchProgramPostComments = (osid, programId, postId) => async dispatch => {
  dispatch({ type: acts.FETCH_PROGRAM_POST_COMMENTS_PENDING, payload: { osid, programId, postId } })
  try {
    const comments = await fetchData({
      url: urls.org.PROGRAM_POST_COMMENTS(osid, programId, postId),
    })
    dispatch({ type: acts.FETCH_PROGRAM_POST_COMMENTS_SUCCESS, payload: comments })
    return comments
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.FETCH_PROGRAM_POST_COMMENTS_FAILURE, payload: { err } })
    throw err
  }
}

export const createProgramPostComment = (osid, programId, postId, { comment, isAdminComment }) => async dispatch => {
  dispatch({
    type: acts.CREATE_PROGRAM_POST_COMMENT_PENDING,
    payload: { osid, programId, postId, comment, isAdminComment },
  })
  try {
    const cmt = await fetchData({
      type: 'POST',
      url: urls.org.PROGRAM_POST_COMMENTS(osid, programId, postId),
      body: {
        comment,
        isAdminComment,
      },
    })
    dispatch({ type: acts.CREATE_PROGRAM_POST_COMMENT_SUCCESS, payload: cmt })
    return cmt
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.CREATE_PROGRAM_POST_COMMENT_FAILURE, payload: { err } })
    throw err
  }
}

export const createProgramPostCommentReply = (osid, programId, postId, commentId, { comment, isAdminComment }) => async dispatch => {
  dispatch({
    type: acts.CREATE_PROGRAM_POST_COMMENT_REPLY_PENDING,
    payload: { osid, programId, postId, comment, isAdminComment },
  })
  try {
    const newComment = await fetchData({
      type: 'POST',
      url: urls.org.PROGRAM_POST_COMMENT_REPLIES(osid, programId, postId, commentId),
      body: {
        comment,
        isAdminComment,
      },
    })
    dispatch({ type: acts.CREATE_PROGRAM_POST_COMMENT_REPLY_SUCCESS, payload: newComment })
    return newComment
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.CREATE_PROGRAM_POST_COMMENT_REPLY_FAILURE, payload: { err } })
    throw err
  }
}

export const deleteProgramPostComment = (osid, programId, postId, commentId) => async dispatch => {
  dispatch({ type: acts.DELETE_PROGRAM_POST_COMMENT_PENDING, payload: { osid, programId, postId } })
  try {
    const res = await fetchData({
      type: 'DELETE',
      url: urls.org.PROGRAM_POST_COMMENT(osid, programId, postId, commentId),
    })
    dispatch({ type: acts.DELETE_PROGRAM_POST_COMMENT_SUCCESS, payload: res })
    return res
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.DELETE_PROGRAM_POST_COMMENT_FAILURE, payload: { err } })
    throw err
  }
}

export const updateProgramPostComment = (osid, programId, postId, commentId, { comment, isAdminComment }) => async dispatch => {
  dispatch({
    type: acts.UPDATE_PROGRAM_POST_COMMENT_PENDING,
    payload: { osid, programId, commentId, comment, isAdminComment },
  })
  try {
    const updatedComment = await fetchData({
      type: 'PATCH',
      url: urls.org.PROGRAM_POST_COMMENT(osid, programId, postId, commentId),
      body: {
        comment,
        isAdminComment,
      },
    })
    dispatch({ type: acts.UPDATE_PROGRAM_POST_COMMENT_SUCCESS, payload: updatedComment })
    return updatedComment
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.UPDATE_PROGRAM_POST_COMMENT_FAILURE, payload: { err } })
    throw err
  }
}

export const createClub = (osid, attrs) => async dispatch => {
  dispatch({ type: acts.CREATE_CLUB_PENDING, payload: { osid, attrs } })
  const hasImages = attrs.icon || attrs.cover
  try {
    let body = { attrs }
    if (hasImages) {
      const fd = new FormData()
      if (attrs.icon) {
        fd.append('icon', attrs.icon)
      }
      if (attrs.cover) {
        fd.append('cover', attrs.cover)
      }
      fd.append('attrs', JSON.stringify(attrs))
      body = fd
    }
    const club = await fetchData({
      type: 'POST',
      url: urls.org.CLUBS(osid),
      body,
      isUpload: hasImages,
    })
    dispatch({ type: acts.CREATE_CLUB_SUCCESS, payload: { osid, club } })
    return club
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.CREATE_CLUB_FAILURE, payload: { err } })
    throw err
  }
}

export const editClub = (osid, clubId, attrs) => async dispatch => {
  dispatch({ type: acts.EDIT_CLUB_PENDING, payload: { osid, attrs } })
  const hasImages = attrs.icon || attrs.cover
  try {
    let body = { attrs }
    if (hasImages) {
      const fd = new FormData()
      if (attrs.icon) {
        fd.append('icon', attrs.icon)
      }
      if (attrs.cover) {
        fd.append('cover', attrs.cover)
      }
      fd.append('attrs', JSON.stringify(attrs))
      body = fd
    }
    const club = await fetchData({
      type: 'PATCH',
      url: urls.org.CLUB(osid, clubId),
      body,
      isUpload: hasImages,
    })
    dispatch({ type: acts.EDIT_CLUB_SUCCESS, payload: { osid, club } })
    return club
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.EDIT_CLUB_FAILURE, payload: { err } })
    throw err
  }
}

export const updateClubSettings = act(
  acts.UPDATE_CLUB_SETTINGS,
  ({ osid, clubId, settings }) => fetchData({
    type: 'PATCH',
    url: urls.org.CLUB_SETTINGS(osid, clubId),
    body: { settings }
  })
)

export const fetchClubs = osid => async dispatch => {
  dispatch({ type: acts.FETCH_CLUBS_PENDING, payload: osid })
  try {
    const clubs = await fetchData({ url: urls.org.CLUBS(osid) })
    dispatch({ type: acts.FETCH_CLUBS_SUCCESS, payload: clubs })
    return clubs
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.FETCH_CLUBS_FAILURE, payload: { err } })
  }
}

export const fetchClub = (osid, id) => async dispatch => {
  dispatch({ type: acts.FETCH_CLUB_PENDING, payload: { osid, id } })
  try {
    const club = await fetchData({ url: urls.org.CLUB(osid, id) })
    dispatch({ type: acts.FETCH_CLUB_SUCCESS, payload: club })
    return club
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.FETCH_CLUB_FAILURE, payload: { err } })
  }
}

export const fetchClubMembers = act(
  acts.FETCH_CLUB_MEMBERS,
  ({ osid, clubId }) => fetchData({ url: urls.org.CLUB_MEMBERS(osid, clubId) })
)

export const addClubMember = act(
  acts.ADD_CLUB_MEMBER,
    ({ osid, clubId, userId, email, role, sendWelcomeEmail = false }) => {
    const clubRole = role === clubRoles.Member ? null : role
    return fetchData({
      type: 'POST',
      url: urls.org.CLUB_MEMBERS(osid, clubId),
      body: {
        userId,
        email,
        role: clubRole,
        sendWelcomeEmail,
      },
    })
    return fetchData({ url: urls.org.CLUB_MEMBERS(osid, clubId) })
  }
)

export const removeClubMember = (osid, clubId, userId) => async dispatch => {
  dispatch({ type: acts.REMOVE_CLUB_MEMBER_PENDING, payload: { osid, clubId, userId } })
  try {
    const members = await fetchData({
      type: 'DELETE',
      url: urls.org.CLUB_MEMBER(osid, clubId, userId),
    })
    dispatch({ type: acts.REMOVE_CLUB_MEMBER_SUCCESS, payload: members })
    return members
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.REMOVE_CLUB_MEMBER_FAILURE, payload: { err } })
    throw err
  }
}

export const editClubMember = (osid, clubId, userId, clubRole) => async dispatch => {
  dispatch({ type: acts.EDIT_CLUB_MEMBER_PENDING, payload: { osid, clubId, userId, role: clubRole } })
  const role = clubRole === clubRoles.Member ? null : clubRole
  try {
    const members = await fetchData({
      type: 'PATCH',
      url: urls.org.CLUB_MEMBER(osid, clubId, userId),
      body: { role }
    })
    dispatch({ type: acts.EDIT_CLUB_MEMBER_SUCCESS, payload: members })
    return members
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.EDIT_CLUB_MEMBER_FAILURE, payload: { err } })
    throw err
  }
}

export const fetchClubPosts = (osid, clubId, cursor=null, limit=20) => async dispatch => {
  dispatch({ type: acts.FETCH_CLUB_POSTS_PENDING, payload: { osid, clubId } })
  try {
    let url = urls.org.CLUB_POSTS(osid, clubId)
    if (cursor) {
      url = qs.stringifyUrl({ url, query: { cursor } })
    }
    if (limit) {
      url = qs.stringifyUrl({ url, query: { limit } })
    }
    const posts = await fetchData({ url })
    dispatch({ type: acts.FETCH_CLUB_POSTS_SUCCESS, payload: posts })
    return posts
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.FETCH_CLUB_POSTS_FAILURE, payload: { err } })
    throw err
  }
}

export const fetchClubPinnedPosts = (osid, clubId) => async dispatch => {
  dispatch({ type: acts.FETCH_CLUB_PINNED_POSTS_PENDING, payload: { osid, clubId } })
  try {
    const posts = await fetchData({
      url: urls.org.CLUB_PINNED_POSTS(osid, clubId),
    })
    dispatch({ type: acts.FETCH_CLUB_PINNED_POSTS_SUCCESS, payload: posts })
    return posts
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.FETCH_CLUB_PINNED_POSTS_FAILURE, payload: { err } })
    throw err
  }
}

export const createClubPost = (osid, clubId, notify, { content, isAdminPost, pinned, images }) => async dispatch => {
  dispatch({
    type: acts.CREATE_CLUB_POST_PENDING,
    payload: { osid, clubId, notify, content, isAdminPost, pinned },
  })
  try {
    let body = { content, isAdminPost, pinned }
    const hasImages = Array.isArray(images) && images.length > 0
    if (hasImages) {
      const fd = new FormData()
      images.forEach(i => fd.append('images', i))
      fd.append('attrs', JSON.stringify(body))
      body = fd
    }

    let url = urls.org.CLUB_POSTS(osid, clubId)
    if (notify) {
      url = qs.stringifyUrl({ url, query: { notify: 1 } })
    }
    
    const post = await fetchData({
      type: 'POST',
      url,
      body,
      isUpload: hasImages
    })
    dispatch({ type: acts.CREATE_CLUB_POST_SUCCESS, payload: post })
    return post
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.CREATE_CLUB_POST_FAILURE, payload: { err } })
    throw err
  }
}

export const fetchClubPost = (osid, clubId, postId) => async dispatch => {
  dispatch({ type: acts.FETCH_CLUB_POST_PENDING, payload: { osid, clubId, postId } })
  try {
    const post = await fetchData({ url: urls.org.CLUB_POST(osid, clubId, postId) })
    dispatch({ type: acts.FETCH_CLUB_POST_SUCCESS, payload: post })
    return post
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.FETCH_CLUB_POST_FAILURE, payload: { err } })
    throw err
  }
}

export const editClubPost = (osid, clubId, postId, { content, isAdminPost, pinned, images }) => async dispatch => {
  dispatch({
    type: acts.EDIT_CLUB_POST_PENDING,
    payload: { osid, clubId, postId, content, isAdminPost, pinned },
  })

  let body = { content, isAdminPost, pinned }
  const hasImages = Array.isArray(images) && images.length > 0

  if (hasImages) {
    const fd = new FormData()
    images.forEach(i => fd.append('images', i))
    fd.append('attrs', JSON.stringify(body))
    body = fd
  }
  try {
    const post = await fetchData({
      type: 'PATCH',
      url: urls.org.CLUB_POST(osid, clubId, postId),
      body,
      isUpload: hasImages
    })
    dispatch({ type: acts.EDIT_CLUB_POST_SUCCESS, payload: post })
    return post
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.EDIT_CLUB_POST_FAILURE, payload: { err } })
    throw err
  }
}

export const deleteClubPost = (osid, clubId, postId) => async dispatch => {
  dispatch({ type: acts.DELETE_CLUB_POST_PENDING, payload: { osid, clubId, postId } })
  try {
    const res = await fetchData({
      type: 'DELETE',
      url: urls.org.CLUB_POST(osid, clubId, postId),
    })
    dispatch({ type: acts.DELETE_CLUB_POST_SUCCESS, payload: res })
    return res
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.DELETE_CLUB_POST_FAILURE, payload: { err } })
    throw err
  }
}

export const pinClubPost = (osid, clubId, postId) => async dispatch => {
  dispatch({ type: acts.PIN_CLUB_POST_PENDING, payload: { osid, clubId, postId } })
  try {
    const res = await fetchData({
      type: 'POST',
      url: urls.org.PIN_CLUB_POST(osid, clubId, postId),
    })
    dispatch({ type: acts.PIN_CLUB_POST_SUCCESS, payload: res })
    return res
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.PIN_CLUB_POST_FAILURE, payload: { err } })
    throw err
  }
}

export const unpinClubPost = (osid, clubId, postId) => async dispatch => {
  dispatch({ type: acts.UNPIN_CLUB_POST_PENDING, payload: { osid, clubId, postId } })
  try {
    const res = await fetchData({
      type: 'POST',
      url: urls.org.UNPIN_CLUB_POST(osid, clubId, postId),
    })
    dispatch({ type: acts.UNPIN_CLUB_POST_SUCCESS, payload: res })
    return res
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.UNPIN_CLUB_POST_FAILURE, payload: { err } })
    throw err
  }
}

export const fetchClubPostComments = (osid, clubId, postId) => async dispatch => {
  dispatch({ type: acts.FETCH_CLUB_POST_COMMENTS_PENDING, payload: { osid, clubId, postId } })
  try {
    const res = await fetchData({
      url: urls.org.CLUB_POST_COMMENTS(osid, clubId, postId),
    })
    dispatch({ type: acts.FETCH_CLUB_POST_COMMENTS_SUCCESS, payload: res })
    return res
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.FETCH_CLUB_POST_COMMENTS_FAILURE, payload: { err } })
    throw err
  }
}

export const createClubPostComment = (osid, clubId, postId, { comment, isAdminComment }) => async dispatch => {
  dispatch({
    type: acts.CREATE_CLUB_POST_COMMENT_PENDING,
    payload: { osid, clubId, postId, comment, isAdminComment },
  })
  try {
    const newComment = await fetchData({
      type: 'POST',
      url: urls.org.CLUB_POST_COMMENTS(osid, clubId, postId),
      body: {
        comment,
        isAdminComment,
      },
    })
    dispatch({ type: acts.CREATE_CLUB_POST_COMMENT_SUCCESS, payload: newComment })
    return newComment
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.CREATE_CLUB_POST_COMMENT_FAILURE, payload: { err } })
    throw err
  }
}

export const createClubPostCommentReply = (osid, clubId, postId, commentId, { comment, isAdminComment }) => async dispatch => {
  dispatch({
    type: acts.CREATE_CLUB_POST_COMMENT_REPLY_PENDING,
    payload: { osid, clubId, postId, comment, isAdminComment },
  })
  try {
    const newComment = await fetchData({
      type: 'POST',
      url: urls.org.CLUB_POST_COMMENT_REPLIES(osid, clubId, postId, commentId),
      body: {
        comment,
        isAdminComment,
      },
    })
    dispatch({ type: acts.CREATE_CLUB_POST_COMMENT_REPLY_SUCCESS, payload: newComment })
    return newComment
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.CREATE_CLUB_POST_COMMENT_REPLY_FAILURE, payload: { err } })
    throw err
  }
}

export const deleteClubPostComment = (osid, clubId, postId, commentId) => async dispatch => {
  dispatch({ type: acts.DELETE_CLUB_POST_COMMENT_PENDING, payload: { osid, clubId, postId } })
  try {
    const res = await fetchData({
      type: 'DELETE',
      url: urls.org.CLUB_POST_COMMENT(osid, clubId, postId, commentId),
    })
    dispatch({ type: acts.DELETE_CLUB_POST_COMMENT_SUCCESS, payload: res })
    return res
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.DELETE_CLUB_POST_COMMENT_FAILURE, payload: { err } })
    throw err
  }
}

export const updateClubPostComment = (osid, clubId, postId, commentId, { comment, isAdminComment }) => async dispatch => {
  dispatch({
    type: acts.UPDATE_CLUB_POST_COMMENT_PENDING,
    payload: { osid, clubId, commentId, comment, isAdminComment },
  })
  try {
    const updatedComment = await fetchData({
      type: 'PATCH',
      url: urls.org.CLUB_POST_COMMENT(osid, clubId, postId, commentId),
      body: {
        comment,
        isAdminComment,
      },
    })
    dispatch({ type: acts.UPDATE_CLUB_POST_COMMENT_SUCCESS, payload: updatedComment })
    return updatedComment
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.UPDATE_CLUB_POST_COMMENT_FAILURE, payload: { err } })
    throw err
  }
}

export const fetchClubVideos = act(acts.FETCH_CLUB_VIDEOS, ({ osid, clubId }) =>
  fetchData({ url: urls.org.CLUB_VIDEOS(osid, clubId) }),
)

export const fetchClubVideo = act(acts.FETCH_CLUB_VIDEO, ({ osid, clubId, videoId }) =>
  fetchData({ url: urls.org.CLUB_VIDEO(osid, clubId, videoId) }),
)

export const updateClubVideo = act(acts.UPDATE_CLUB_VIDEO, ({ osid, clubId, videoId, video }) =>
  fetchData({
    type: 'PATCH',
    url: urls.org.CLUB_VIDEO(osid, clubId, videoId),
    body: { video },
  }),
)

export const updateClubVideosOrder = act(acts.UPDATE_CLUB_VIDEOS_ORDER, ({ osid, clubId, videos }) =>
  fetchData({
    type: 'PATCH',
    url: urls.org.CLUB_VIDEOS(osid, clubId),
    body: { videos },
  }),
)

export const deleteClubVideo = act(acts.DELETE_CLUB_VIDEO, ({ osid, clubId, videoId }) =>
  fetchData({
    type: 'DELETE',
    url: urls.org.CLUB_VIDEO(osid, clubId, videoId),
  }),
)

export const createClubVideo = act(acts.CREATE_CLUB_VIDEO, ({ osid, clubId, video }) =>
  fetchData({
    type: 'POST',
    url: urls.org.CLUB_VIDEOS(osid, clubId),
    body: { video },
  }),
)

export const fetchRoles = osid => async dispatch => {
  dispatch({ type: acts.FETCH_ROLES_PENDING, payload: { osid } })
  try {
    const roles = await fetchData({ url: urls.org.ROLES(osid) })
    dispatch({ type: acts.FETCH_ROLES_SUCCESS, payload: roles })
    return roles
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.FETCH_ROLES_FAILURE, payload: { err } })
    throw err
  }
}

export const fetchRole = (osid, roleId) => async dispatch => {
  dispatch({ type: acts.FETCH_ROLE_PENDING, payload: { osid, roleId } })
  try {
    const role = await fetchData({ url: urls.org.ROLE(osid, roleId) })
    dispatch({ type: acts.FETCH_ROLE_SUCCESS, payload: role })
    return role
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.FETCH_ROLE_FAILURE, payload: { err } })
    throw err
  }
}

export const assignRoleToUser = (osid, roleId, userId) => async dispatch => {
  dispatch({ type: acts.ASSIGN_ROLE_TO_USER_PENDING, payload: { osid, roleId, userId } })
  try {
    const res = await fetchData({
      type: 'POST',
      url: urls.org.ROLE_ASSIGN(osid, roleId),
      body: { userId }
    })
    dispatch({ type: acts.ASSIGN_ROLE_TO_USER_SUCCESS, payload: res })
    return res
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.ASSIGN_ROLE_TO_USER_FAILURE, payload: { err } })
    throw err
  }
}

export const unassignRoleFromUser = (osid, roleId, userId) => async dispatch => {
  dispatch({ type: acts.UNASSIGN_ROLE_FROM_USER_PENDING, payload: { osid, roleId, userId } })
  try {
    const res = await fetchData({
      type: 'POST',
      url: urls.org.ROLE_UNASSIGN(osid, roleId),
      body: { userId }
    })
    dispatch({ type: acts.UNASSIGN_ROLE_FROM_USER_SUCCESS, payload: res })
    return res
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.UNASSIGN_ROLE_FROM_USER_FAILURE, payload: { err } })
    throw err
  }
}

export const deleteClub = (osid, clubId) => async dispatch => {
  dispatch({ type: acts.DELETE_CLUB_PENDING, payload: { osid, clubId } })
  try {
    const res = await fetchData({
      type: 'DELETE',
      url: urls.org.CLUB(osid, clubId),
    })
    dispatch({ type: acts.DELETE_CLUB_SUCCESS, payload: res })
    return res
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.DELETE_CLUB_FAILURE, payload: { err } })
    throw err
  }
}

export const fetchClubFilters = osid => async dispatch => {
  dispatch({ type: acts.FETCH_CLUB_FILTERS_PENDING, payload: { osid } })
  try {
    const filters = await fetchData({ url: urls.org.SEARCH_CLUB_FILTERS(osid) })
    dispatch({ type: acts.FETCH_CLUB_FILTERS_SUCCESS, payload: filters })
    return filters
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.FETCH_CLUB_FILTERS_FAILURE, payload: { err } })
    throw err
  }
}

export const searchClubs = (osid, query) => async dispatch => {
  dispatch({ type: acts.SEARCH_CLUBS_PENDING, payload: { osid } })
  try {
    let url = urls.org.SEARCH_CLUBS(osid)
    if (query) {
      url = `${url}?${queryString.stringify(query)}`
    }
    const clubs = await fetchData({ url })
    dispatch({ type: acts.SEARCH_CLUBS_SUCCESS, payload: clubs })
    return clubs
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.SEARCH_CLUBS_FAILURE, payload: { err } })
    throw err
  }
}

export const downloadClubMembersCsv = (osid, clubId) => async dispatch => {
  dispatch({ type: acts.DOWNLOAD_CLUB_MEMBERS_CSV_SUCCESS, payload: { osid } })
  try {
    const csv = await fetchCsv({ url: urls.org.CLUB_MEMBERS_CSV(osid, clubId) })
    dispatch({ type: acts.DOWNLOAD_CLUB_MEMBERS_CSV_SUCCESS, payload: csv })
    return csv
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.DOWNLOAD_CLUB_MEMBERS_CSV_FAILURE, payload: { err } })
    throw err
  }
}

export const fetchClubProducts = act(
  acts.FETCH_CLUB_PRODUCTS,
  ({ osid, clubId }) => fetchData({ url: urls.org.CLUB_PRODUCTS(osid, clubId) })
)

export const setClubProducts = act(
  acts.SET_CLUB_PRODUCTS,
  ({ osid, clubId, productIds }) =>
    fetchData({
      type: 'PUT',
      url: urls.org.CLUB_PRODUCTS(osid, clubId),
      body: productIds,
    })
)

export const fetchTags = act(
  acts.FETCH_ORG_TAGS,
  ({ osid }) => fetchData({ url: urls.org.TAGS(osid) })
)

export const fetchTag = act(
  acts.FETCH_ORG_TAG,
  ({ osid, sig }) => fetchData({ url: urls.org.TAG(osid, sig) })
)

export const createTag = act(
  acts.CREATE_ORG_TAG,
  ({ osid, name, label, color }) =>
    fetchData({
      type: 'POST',
      url: urls.org.TAGS(osid),
      body: { name, label, color },
    })
)

export const updateTag = act(
  acts.UPDATE_ORG_TAG,
  ({ osid, sig, name, label, color }) =>
    fetchData({
      type: 'PATCH',
      url: urls.org.TAG(osid, sig),
      body: { name, label, color },
    })
)

export const deleteTag = act(
  acts.DELETE_ORG_TAG,
  ({ osid, sig }) => fetchData({ type: 'DELETE', url: urls.org.TAG(osid, sig) })
)

export const setProgramTags = act(
  acts.SET_PROGRAM_ORG_TAGS,
  ({ osid, programId, tagIds }) => fetchData({
    type: 'PATCH',
    url: urls.org.PROGRAM_TAGS(osid, programId),
    body: { tagIds },
  })
)

export const fetchSponsors = act(
  acts.FETCH_SPONSORS,
  ({ osid }) => fetchData({ url: urls.org.SPONSORS(osid) })
)

export const fetchSponsor = act(
  acts.FETCH_SPONSOR,
  ({ osid, id }) => fetchData({ url: urls.org.SPONSOR(osid, id) })
)

export const createSponsor = act(
  acts.CREATE_SPONSOR,
  ({ osid, name, notes, logo, icon, link }) => {
    let body = { name, notes, logo, icon, link }
    const hasImages = !!logo || !!icon
    if (hasImages) {
      const fd = new FormData()
      fd.append('logo', logo)
      fd.append('icon', icon)
      fd.append('link', link)
      fd.append('attrs', JSON.stringify(body))
      body = fd
    }
    return fetchData({
      type: 'POST',
      url: urls.org.SPONSORS(osid),
      isUpload: hasImages,
      body,
    })
  }
)

export const updateSponsor = act(
  acts.UPDATE_SPONSOR,
  ({ osid, sponsorId, name, notes, logo, icon, link }) => {
    let body = { name, notes, logo, icon, link }
    const hasImages = (!!logo && typeof logo === 'object') || (!!icon && typeof icon === 'object')
    if (hasImages) {
      const fd = new FormData()
      if (logo) {
        fd.append('logo', logo)
      }
      if (icon) {
        fd.append('icon', icon)
      }
      fd.append('attrs', JSON.stringify(body))
      body = fd
    }
    return fetchData({
      type: 'PATCH',
      url: urls.org.SPONSOR(osid, sponsorId),
      isUpload: hasImages,
      body,
    })
  }
)

export const deleteSponsor = act(
  acts.DELETE_SPONSOR,
  ({ osid, id }) => fetchData({ type: 'DELETE', url: urls.org.SPONSOR(osid, id) })
)

export const fetchAllProducts = act(
  acts.FETCH_ALL_PRODUCTS,
  ({ osid }) => fetchData({ url: urls.org.PRODUCTS(osid) })
)

export const fetchProduct = act(
  acts.FETCH_PRODUCT,
  ({ osid, productId }) => fetchData({ url: urls.org.PRODUCT(osid, productId) })
)

export const deleteProduct = act(
  acts.DELETE_PRODUCT,
  ({ osid, productId }) => fetchData({ type: 'DELETE', url: urls.org.PRODUCT(osid, productId) })
)

export const createProduct = act(
  acts.CREATE_PRODUCT,
  ({
    osid,
    type,
    name,
    description,
    priceCents,
    stockType,
    shippingRequired,
    images,
    offsiteUrl,
    offsiteCta,
    walmartItemId,
  }) => {
    const attrs = { type, name, description, priceCents, stockType, shippingRequired, offsiteUrl, offsiteCta, walmartItemId }
    let body = { attrs }
    const hasImages = Array.isArray(images) && images.length > 0
    if (hasImages) {
      const fd = new FormData()
      images.forEach(i => fd.append('images', i))
      fd.append('attrs', JSON.stringify(attrs))
      body = fd
    }
    return fetchData({
      type: 'POST',
      url: urls.org.PRODUCTS(osid),
      body,
      isUpload: hasImages,
    })
  }
)

export const editProduct = act(
  acts.EDIT_PRODUCT,
  ({
    osid,
    productId,
    type,
    name,
    description,
    priceCents,
    stockType,
    shippingRequired,
    images,
    offsiteUrl,
    offsiteCta,
    walmartItemId,
  }) => {
    const existingImages = images.filter(img => !img.isNew)
    const newImages = images.filter(img => img.isNew)
    const hasNewImages = newImages.length > 0
    const attrs = {
      type,
      name,
      description,
      priceCents,
      stockType,
      shippingRequired,
      images: existingImages,
      offsiteUrl,
      offsiteCta,
      walmartItemId,
    }
    let body = { attrs }
    if (hasNewImages) {
      const fd = new FormData()
      newImages.forEach(i => fd.append('images', i))
      fd.append('attrs', JSON.stringify(attrs))
      body = fd
    }
    return fetchData({
      type: 'PATCH',
      url: urls.org.PRODUCT(osid, productId),
      body,
      isUpload: hasNewImages,
    })
  }
)

export const fetchProductVariantStock = act(
  acts.FETCH_PRODUCT_VARIANT_STOCK,
  ({ osid, productId, variantId }) => fetchData({
    url: urls.org.PRODUCT_VARIANT_STOCK(osid, productId, variantId),
  })
)

export const addVariantStock = act(
  acts.ADD_VARIANT_STOCK,
  ({ osid, productId, variantId, quantity, note }) => fetchData({
    type: 'POST',
    url: urls.org.ADD_VARIANT_STOCK(osid, productId, variantId),
    body: { quantity, note },
  })
)

export const removeVariantStock = act(
  acts.REMOVE_VARIANT_STOCK,
  ({ osid, productId, variantId, quantity, note }) => fetchData({
    type: 'POST',
    url: urls.org.REMOVE_VARIANT_STOCK(osid, productId, variantId),
    body: { quantity, note },
  })
)

export const fetchAllVariantOptions = act(
  acts.FETCH_ALL_VARIANT_OPTIONS,
  ({ osid }) => fetchData({
    url: urls.org.VARIANT_OPTIONS(osid),
  })
)

// returns a list of variant options currently applied to a product
export const fetchProductVariantOptions = act(
  acts.FETCH_PRODUCT_VARIANT_OPTIONS,
  ({ osid, productId }) => fetchData({
    url: urls.org.PRODUCT_VARIANT_OPTIONS(osid, productId),
  })
)

export const createVariantOption = act(
  acts.CREATE_VARIANT_OPTION,
  ({ osid, name, values }) => fetchData({
    type: 'POST',
    url: urls.org.VARIANT_OPTIONS(osid),
    body: { name, values },
  })
)

export const fetchProductVariants = act(
  acts.FETCH_PRODUCT_VARIANTS,
  ({ osid, productId }) => fetchData({
    url: urls.org.PRODUCT_VARIANTS(osid, productId),
  })
)

export const changeProductVariantOptions = act(
  acts.CHANGE_PRODUCT_VARIANT_OPTIONS,
  ({ osid, productId, variantOptionIds }) => fetchData({
    type: 'POST',
    url: urls.org.PRODUCT_VARIANT_OPTIONS(osid, productId),
    body: { variantOptionIds },
  })
)

export const changeProductClubs = act(
  acts.CHANGE_PRODUCT_CLUBS,
  ({ osid, productId, clubIds }) => fetchData({
    type: 'POST',
    url: urls.org.PRODUCT_CLUBS(osid, productId),
    body: { clubIds },
  })
)

export const fetchAllOrders = act(
  acts.FETCH_ALL_ORDERS,
  ({ osid }) => fetchData({ url: urls.org.ORDERS(osid) })
)

export const fetchOrder = act(
  acts.FETCH_ORDER,
  ({ osid, orderId }) => fetchData({ url: urls.org.ORDER(osid, orderId) })
)

export const updateOrder = act(
  acts.UPDATE_ORDER,
  ({ osid, orderId, note, fulfilledAt }) => fetchData({
    type: 'PATCH',
    url: urls.org.ORDER(osid, orderId),
    body: { note, fulfilledAt },
  })
)

export const updateOrderItem = act(
  acts.UPDATE_ORDER_ITEM,
  ({ osid, orderId, itemId, note, fulfilledAt }) => fetchData({
    type: 'PATCH',
    url: urls.org.ORDER_ITEM(osid, orderId, itemId),
    body: { note, fulfilledAt },
  })
)

export const createTerminalConnectionToken = act(
  acts.TERMINAL_CREATE_CONNECTION_TOKEN,
  ({ osid }) => fetchData({
    type: 'POST',
    url: urls.org.TERMINAL_CONNECTION_TOKEN(osid),
  })
)

export const startTerminalOrder = act(
  acts.TERMINAL_START_ORDER,
  ({ osid, cart }) => fetchData({
    type: 'POST',
    url: urls.org.TERMINAL_START_ORDER(osid),
    body: { cart },
  })
)

export const estimateInvoice = act(
  acts.ESTIMATE_INVOICE,
  ({ osid, cart }) => fetchData({
    type: 'POST',
    url: urls.org.ESTIMATE_INVOICE(osid),
    body: { cart },
  })
)

export const cancelInvoice = act(
  acts.CANCEL_INVOICE,
  ({ osid, invoiceId }) => fetchData({
    type: 'POST',
    url: urls.org.INVOICE(osid, invoiceId),
  })
)

export const capturePayment = act(
  acts.CAPTURE_PAYMENT,
  ({ osid, paymentIntentId }) => fetchData({
    type: 'POST',
    url: urls.org.CAPTURE_PAYMENT(osid, paymentIntentId),
  })
)

export const emailReceipt = act(
  acts.EMAIL_RECEIPT,
  ({ osid, paymentIntentId, email }) => fetchData({
    type: 'POST',
    url: urls.org.EMAIL_RECEIPT(osid, paymentIntentId),
    body: { email },
  })
)

export const fetchUsers = act(
  acts.FETCH_USERS,
  ({ osid, sort = {}, searchInput = '', limit = null, offset = 0, equinox = false }) =>
    fetchData({
      url: qs.stringifyUrl({
        url: urls.org.USERS(osid),
        query: {
          sortColumn: sort.column,
          sortOrder: sort.order,
          searchInput,
          limit,
          offset,
          equinox,
        }
      })
    })
)

export const searchAllPrograms = act(
  acts.FETCH_PROGRAMS_SEARCH,
  ({ osid, sort = {}, searchInput = '', limit = null, offset = 0 }) =>
    fetchData({
      url: qs.stringifyUrl({
        url: urls.org.SEARCH_PROGRAMS(osid),
        query: {
          sortColumn: sort.column,
          sortOrder: sort.order,
          searchInput,
          limit,
          offset,
        },
      }),
    }),
)

export const searchUpcomingPrograms = act(
  acts.SEARCH_UPCOMING_PROGRAMS,
  ({ osid, searchInput = '', limit = null, offset = 0 }) =>
    fetchData({
      url: qs.stringifyUrl({
        url: urls.org.SEARCH_UPCOMING_PROGRAMS(osid),
        query: {
          searchInput,
          limit,
          offset,
        },
      }),
    }),
)

export const searchPastPrograms = act(
  acts.SEARCH_PAST_PROGRAMS,
  ({ osid, searchInput = '', limit = null, offset = 0 }) =>
    fetchData({
      url: qs.stringifyUrl({
        url: urls.org.SEARCH_PAST_PROGRAMS(osid),
        query: {
          searchInput,
          limit,
          offset,
        },
      }),
    }),
)

export const fetchConsts = act(
  acts.FETCH_CONSTS,
  ({ osid }) => fetchData({ url: urls.org.CONSTS(osid) })
)

export const fetchConstsByName = act(
  acts.FETCH_CONSTS_BY_NAME,
  ({ osid, name }) => fetchData({ url: urls.org.CONSTS_BY_NAME(osid, name) })
)

/* Benefits */
export const fetchBenefits = act(
  acts.FETCH_BENEFITS,
  ({ osid }) => fetchData({ url: urls.org.BENEFITS(osid) })
)

export const fetchBeneficiaryGroups = act(
  acts.FETCH_BENEFICIARY_GROUPS,
  ({ osid, benefitId }) =>
    fetchData({
      url: urls.org.BENEFICIARY_GROUPS(osid, benefitId)
    })
)

/* Program benefits */
export const addBenefitProgram = act(
  acts.ADD_BENEFIT_PROGRAM,
  ({ osid, programId, benefitId, checkinInstructions, exclusiveGroups }) =>
    fetchData({
      type: 'POST',
      url: urls.org.PROGRAM_BENEFITS(osid, programId),
      body: { benefitId, checkinInstructions, exclusiveGroups },
    })
)

export const updateBenefitProgram = act(
  acts.UPDATE_BENEFIT_PROGRAM,
  ({ osid, programId, benefitProgramId, checkinInstructions, exclusiveGroups }) =>
    fetchData({
      type: 'PUT',
      url: urls.org.PROGRAM_BENEFIT(osid, programId, benefitProgramId),
      body: { checkinInstructions, exclusiveGroups },
    })
)

export const removeBenefitProgram = act(
  acts.REMOVE_BENEFIT_PROGRAM,
  ({ osid, programId, benefitProgramId }) =>
    fetchData({
      type: 'DELETE',
      url: urls.org.PROGRAM_BENEFIT(osid, programId, benefitProgramId),
    })
)


/* Program reservations */
export const createProgramResReservations = act(
  acts.CREATE_PROGRAM_RES_RESERVATIONS,
  ({ osid, programId, reservations }) =>
    fetchData({
      url: urls.org.PROGRAM_RES_RESERVATIONS(osid, programId),
      type: 'POST',
      body: reservations,
    })
)

export const deleteProgramResReservation = act(
  acts.DELETE_PROGRAM_RES_RESERVATION,
  ({ osid, programId, resReservationId }) =>
    fetchData({
      url: urls.org.PROGRAM_RES_RESERVATION(osid, programId, resReservationId),
      type: 'DELETE',
    })
)


/* BTL Network */

export const fetchNetworkVenues = act(
  acts.FETCH_NETWORK_VENUES,
  ({ osid }) => fetchData({ url: urls.org.NET_VENUES(osid) })
)

export const fetchNetworkVenueAvailableResources = act(
  acts.FETCH_NETWORK_VENUE_AVAILABLE_RESOURCES,
  ({ osid, venueId, dateStart, dateEnd, wallStart, wallEnd }) => {
    let url = urls.org.NET_VENUE_AVAILABLE_RESOURCES(osid, venueId)
    url = qs.stringifyUrl({ url, query: { dateStart, dateEnd, wallStart, wallEnd } })
    return fetchData({ url })
  }
)


