import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Head, Title, PaddedContainer } from '../../components/common'
import { useDispatch } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons'
import { Tag } from 'antd'
import { useParams, Link } from 'react-router-dom'
import { paths, features, actions as acts } from '../../constants'
import * as orderConsts from '../../constants/order'
import { useStatus } from '../../reducers'
import { fetchAllOrders } from '../../actions/org'
import { useOrgNotFoundError } from '../../hooks/use-org-not-found-error'

const Table = styled.div`
  display: table;
  width: 100%;
`

const Row = styled(Link)`
  display: table-row;
  text-decoration: none;
`

const Cell = styled.div`
  cursor: pointer;
  display: table-cell;
  color: ${props => props.theme.font.color.primary};
  padding: ${props => props.theme.spacing[2]} ${props => props.theme.spacing[3]};
  border-bottom-color: ${props => props.theme.color.gray[1]};
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-collapse: collapse;
`

const FirstCell = styled(Cell)`
  padding-left: 4px;
`

const LastCell = styled(Cell)`
  padding-right: 4px;
`

const OrderRow = ({ order }) => {
  const { osid } = useParams()
  return (
    <Row to={paths.org.ORDER(osid, order.id)}>
      <FirstCell><code>{order.id}</code></FirstCell>
      <Cell><Tag>{orderConsts.statusTypes[order.status].label}</Tag></Cell>
      <Cell>{order.user.name}</Cell>
      <LastCell>{order.createdAt}</LastCell>
    </Row>
  )
}

const OrdersTable = ({ orders }) => {
  const elems = orders.map(o => (
    <OrderRow key={o.id} order={o} />
  ))
  return (
    <Table>
      {elems}
    </Table>
  )
}

const Orders = () => {
  const { osid } = useParams()
  const dispatch = useDispatch()
  const [orders, setOrders] = useState(null)
  const status = useStatus(acts.FETCH_ALL_ORDERS)

  useOrgNotFoundError(status, 'load orders', osid)

  useEffect(() => {
    dispatch(fetchAllOrders({ osid }))
      .then(ords => {
        // sort most recent first
        setOrders(ords.sort((a, b) => b.createdAt.localeCompare(a.createdAt)))
      })
  }, [osid])

  let display = <LoadingOutlined />
  if (orders) {
    display = (
      <OrdersTable orders={orders} />
    )
    if (display.length === 0) {
      display = (
        <i>No orders</i>
      )
    }
  }

  return (
    <PaddedContainer>
      <Head>
        <Title>{features.order.name.plural}</Title>
     </Head>
     {display}
    </PaddedContainer>
  )
}

export default Orders
