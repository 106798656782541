import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Centered as Container } from '../../components/containers'
import { Footer, Group } from '../../components/form'
import { login } from '../../actions/user'
import { paths } from '../../constants'
import { Form, Input, Button } from 'antd'

const Login = () => {
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [disabled, setDisabled] = useState(true)
  const dispatch = useDispatch()
  const location = useLocation()

  useEffect(() => {
    if (email.length > 0 && password.length > 0) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [email, password])
  const handleSignIn = e => {
    e.preventDefault()
    dispatch(login(email, password))
      .then(() => {
        if (location.pathname.includes('/admin')) {
          history.push(paths.admin.HOME())
        } else {
          history.push('/')
        }
      })
  }

  const handleChange = e => {
    if (e.target.name === 'email') {
      setEmail(e.target.value)
    }
    if (e.target.name === 'password') {
      setPassword(e.target.value)
    }
  }

  return (
    <Container>
      <h1>Break the Love</h1>
      <Form onSubmit={handleSignIn}>
        <Group>
          <label htmlFor="email">Email</label>
          <Input
            id="email"
            placeholder="email"
            type="text"
            name="email"
            value={email}
            onChange={handleChange}
          />
        </Group>
        <Group>
          <label htmlFor="password">Password</label>
          <Input
            id="password"
            placeholder="******"
            type="password"
            name="password"
            value={password}
            onChange={handleChange}
          />
        </Group>
        <Footer>
          <Button
            disabled={disabled}
            onClick={handleSignIn}
            htmlType="submit"
          >
            Sign in
          </Button>
        </Footer>
      </Form>
    </Container>
  )
}

export default Login
