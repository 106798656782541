import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { actions as acts, paths } from '../../../constants'
import urlUtils from '../../../util/url'
import { quickSearchClubs } from '../../../actions/admin'
import { clearStatus } from '../../../actions/status'
import { useStatus, useStatusMsg } from '../../../reducers'
import Search from './search'
import { serializeOrder } from './util'

const columns = [
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'name',
  },
  {
    title: 'sid',
    key: 'sid',
    dataIndex: 'sid',
  },
  {
    title: 'Org',
    key: 'org',
    dataIndex: 'org',
    render: (val, record) => {
      return val.name
    }
  },
]

const forwardPaths = {
  pp: {
    name: 'pp',
    default: true,
    desc: 'Club partner portal page',
    pather: club => paths.org.CLUB(club.org.sid, club.sid),
  },
  btl: {
    name: 'btl',
    external: true,
    desc: 'Break the Love club page',
    pather: club => urlUtils.mainSiteClub(club.sid),
  },
}

const ClubSearch = () => {
  const dispatch = useDispatch()
  const status = useStatus(acts.QUICK_SEARCH_CLUBS)
  useStatusMsg(status, { error: 'Failed to search clubs' })
  useEffect(() => {
    return () => dispatch(clearStatus(acts.QUICK_SEARCH_CLUBS))
  }, [])

  const search = async ({ input, currentPage, sort, resultsPerPage }) => {
    const res = await dispatch(quickSearchClubs({
      order: serializeOrder(sort),
      searchInput: input,
      limit: resultsPerPage,
      offset: (currentPage - 1) * resultsPerPage,
    }))
    return res
  }

  return (
    <Search
      title="Clubs"
      columns={columns}
      forwardPaths={forwardPaths}
      searcher={search}
      searching={status.pending}
    />
  )
}

export default ClubSearch
