import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { PlusOutlined } from '@ant-design/icons'
import Container from './Container'
import { Posts, usePosts } from '../../components/posts'
import { CtaLink as BTLButton } from '../../components/common'
import { paths } from '../../constants'
import * as orgActs from '../../actions/org'

const ProgramPosts = () => {
  const { osid } = useParams()
  const dispatch = useDispatch()
  const program = useSelector(s => s.currentProgram)

  const programPosts = usePosts(osid, {
    loadPosts: (cursor, limit) => orgActs.fetchProgramPosts(osid, program.id, cursor, limit),
    loadPinned: () => orgActs.fetchProgramPinnedPosts(osid, program.id),
    pinPost: postId => orgActs.pinProgramPost(osid, program.id, postId),
    unpinPost: postId => orgActs.unpinProgramPost(osid, program.id, postId),
    deletePost: postId => orgActs.deleteProgramPost(osid, program.id, postId),
    editPostPath: postId => paths.org.EDIT_PROGRAM_POST(osid, program.id, postId),
    viewPostPath: postId => paths.org.PROGRAM_POST(osid, program.id, postId),
  })

  useEffect(() => {
    if (program.id) {
      programPosts.loadPosts()
      programPosts.loadPinned()
    }
  }, [program.id])

  return (
    <Container>
      <h1>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <span>Posts</span>
          <BTLButton to={paths.org.NEW_PROGRAM_POST(osid, program.id)}><PlusOutlined /> New post</BTLButton>
        </div>
      </h1>
      <Posts {...programPosts} />
    </Container>
  )
}

export default ProgramPosts
