import React from 'react'
import styled from 'styled-components'
import { Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

const Container = styled.div`
  margin-bottom: ${props => props.theme.spacing[3]}
`

const AttrTitle = styled.span`
  font-weight: ${props => props.theme.font.weight[7]};
  color: rgba(0, 0, 0, 0.65);
`

const AttrHeadWrap = styled.span`
  display: inline-block;
  margin-right: ${props => props.theme.spacing[2]};
  margin-bottom: ${props => props.theme.spacing[1]};
`

const AttrLabel = styled.span`
  color: ${props => props.theme.font.color.secondary};
`

const AttrDescription = styled.div`
  margin-bottom: ${props => props.theme.spacing[1]};
  color: ${props => props.theme.font.color.secondary};
`

const Attr = ({ name, label, description, children, tooltip, ...props }) => {
  let header = <AttrTitle>{name}</AttrTitle>
  if (label) {
    header = (
      <>
        {header} <AttrLabel>{label}</AttrLabel>
      </>
    )
  }
  if (tooltip) {
    header = (
      <Tooltip title={tooltip}>
        {header} <InfoCircleOutlined />
      </Tooltip>
    )
  }

  return (
    <Container {...props}>
      {name ? <AttrHeadWrap>{header}</AttrHeadWrap> : null}
      { description &&
          <AttrDescription>{description}</AttrDescription>
      }
      <span>{children}</span>
    </Container>
  )
}

export default Attr
