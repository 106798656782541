import React from 'react'
import { Select } from 'antd'
import { getTimeZones } from '@vvo/tzdb'
import moment from 'moment-timezone'

const { Option } = Select
const timezones = getTimeZones()

const tzsByName = timezones.reduce((acc, curr) => {
  acc[curr.name] = curr
  return acc
}, {})

const fmtTz = ({ rawOffsetInMinutes, alternativeName, abbreviation }) => {
  const offset = moment
    .utc()
    .startOf('day')
    .add(rawOffsetInMinutes, 'minutes')
    .format('hh:mm')
  const sign = rawOffsetInMinutes < 0 ? '-' : '+'
  return `${sign}${offset} ${alternativeName} ${abbreviation}`
}

const opts = timezones.map(tz => (
  <Option key={tz.name} value={tz.name}>
    {fmtTz(tz)}
  </Option>
))

const hasString = (search, input) => search.toLowerCase().indexOf(input.toLowerCase()) >= 0

const TimezoneSelect = ({ value, onChange, ...props }) => {
  const handleChange = tzName => {
    if (typeof onChange === 'function') {
      onChange(tzName)
    }
  }

  return (
    <Select
      value={value}
      onChange={handleChange}
      showSearch
      filterOption={(input, option) => {
        const inp = input.toLowerCase()
        const value = option.value
        const tz = tzsByName[value]
        const { alternativeName, abbreviation, mainCities } = tz

        return (
          hasString(alternativeName, inp) ||
          hasString(abbreviation, inp) ||
          mainCities.some(c => hasString(c, inp))
        )
      }}
      {...props}
    >
      {opts}
    </Select>
  )
}

export default TimezoneSelect
