import { actions as acts } from '../constants'

const initState = {
  token: null,
}

const admin = (state = initState, { type, payload }) => {
  switch(type) {
    case acts.LOGIN_ADMIN_SUCCESS:
      return {
        ...payload,
      }
    case acts.LOGOUT_ADMIN_SUCCESS:
      return {
        ...initState,
      }
    default:
      return state
  }
  return state
}

export default admin
