import React from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { Form, Input } from 'antd'

const Label = styled.span`
  color: rgba(0, 0, 0, 0.65);
  font-weight: ${props => props.theme.font.weight[7]};
`

const initState = {
  name: null,
  label: null,
  color: null,
}

const TagEditor = ({ onChange, tag }) => {
  const { osid } = useParams()

  if (!tag) {
    if (typeof onChange === 'function') {
      onChange(initState)
    }
    return null
  }

  const { name, label, color } = tag

  const change = update => {
    if (typeof onChange === 'function') {
      onChange({ ...tag, ...update })
    }
  }

  return (
    <Form layout="vertical">
      <Form.Item name="name" label={<Label>Name</Label>} initialValue={name}>
        <Input placeholder="" onChange={e => change({ name: e.target.value })} value={name} />
      </Form.Item>
      <Form.Item name="label" label={<Label>Label</Label>} initialValue={label}>
        <Input name="label" value={label} onChange={e => change({ label: e.target.value })} />
      </Form.Item>
      <Form.Item name="color" label={<Label>Color</Label>} initialValue={color}>
        <Input name="color" type="color" value={color} onChange={e => change({ color: e.target.value })} />
      </Form.Item>
    </Form>
  )
}

export default TagEditor
