import { useStatusMsg } from '../reducers'

/**
 * Custom hook to display status messages based on the status object.
 * If any status message is a function, it calls the function with
 * the payload of the status and uses the return value as the content for the message.
 *
 * @param {Object} status - The status object containing the status of an operation.
 * @param {boolean} status.pending - Indicates if the operation is pending.
 * @param {boolean} status.error - Indicates if there was an error in the operation.
 * @param {boolean} status.success - Indicates if the operation was successful.
 * @param {string} actionName - The name of the action that was performed.
 * @param {string} osid - The organization id.
 */
export const useOrgNotFoundError = (status, actionName, osid) => {
  useStatusMsg(status, {
    error: (data) => {
      if (data.err?.name === 'Org not found' || data.name === 'Org not found') {
        return osid ? `There was no ${osid} org found` : 'There was no org found';
      }
      return `Failed to ${actionName}`;
    },
  });
};