import { paths } from '../constants'
import { Link } from 'react-router-dom'

export const AdminInvoiceLink = ({ children, invoice, ...rest }) => {
  let sig = null
  let childs = children

  if (invoice) {
    sig = typeof invoice === 'string' ? invoice : invoice.id
  }

  if (!sig) {
    return childs
  }

  // render something if children is not passed
  if (!childs) {
    childs = typeof invoice === 'string' ? invoice : 'View invoice'
  }

  return (
    <Link to={paths.admin.billing.INVOICE(sig)} {...rest} >
      {childs}
    </Link>
  )
}
