import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { Badge } from 'antd'

const BadgeTitle = ({ title, count, backgroundColor = 'white', color = 'inherit' }) => {
  const theme = useContext(ThemeContext)
  return (
    <div style={{display: 'flex', alignItems: 'center'}}>
      <span style={{marginRight: theme.spacing[1]}}>{title}</span> <Badge style={{ backgroundColor, color }} count={count} />
    </div>
  )
}

export default BadgeTitle
