import { Select, InputNumber, Tag, Collapse, Modal, Menu, Breadcrumb, Dropdown, Table } from 'antd'
import { LinkOutlined, PlusOutlined, EllipsisOutlined, LoadingOutlined, DownOutlined } from '@ant-design/icons'
import { ID, Button, Cta as CtaButton } from '../../../components/common'
import { CountHeader, Head, PaddedContainer, Title } from '../../../components/common'
import { Link, useHistory, useParams } from 'react-router-dom'
import { paths, actions as acts } from '../../../constants'
import * as resConsts from '../../../constants/resource'
import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { useStatus, useStatusMsg } from '../../../reducers'
import { clearStatus } from '../../../actions/status'
import {
  fetchOrg,
  addOrgTeamMembership,
  removeOrgTeamMembership,
  addOrgFeeRule,
  removeOrgFeeRule,
  metricPerResourceBookingsByMonth,
  metricPerResourceBookingsByMonthQuery,
  metricPerParticipantBookingsByMonth,
  metricPerParticipantBookingsByMonthQuery,
} from '../../../actions/admin'
import { ThemeContext, useTheme } from 'styled-components'
import { ActionsMenu, EditOrg } from '.'
import { EditVenue, VenueActionsMenu, VenueAddress, VenueImages, VenueLinks, VenueDetails } from './venue'
import Attr from '../../../components/Attr'
import { formatDateTimeTz } from '../../../util/time'
import urlUtils from '../../../util/url'
import formatUtils from '../../../util/format'
import UserSelect from '../../../components/UserSelect'
import { Creator } from '../../../components/creator'
import { PayoutCountMethodSelect, FeeRuleTypeSelect, FeeRuleTypeDisplay } from '../../../components/billing'
import { PricingTypeSelect, PricingTypeDisplay } from '../../../components/resource'
import { AdminBenefitSelect } from '../../../components/benefit'
import { PackageSets } from './package'

const { Panel } = Collapse
const { Option } = Select

const OrgTeamMembershipActionsMenu = ({ membership, onDelete }) => {
  const dispatch = useDispatch()
  const removeStatus = useStatus(acts.REMOVE_ORG_TEAM_MEMBERSHIP)

  useStatusMsg(removeStatus, {
    pending: 'Removing user from org team...',
    error: 'Failed to remove user from org team',
    success: 'User removed from org team',
  })

  useEffect(() => () => dispatch(clearStatus(acts.REMOVE_ORG_TEAM_MEMBERSHIP)), [])

  const handleActionsMenu = e => {
    if (e.key === 'remove') {
      Modal.confirm({
        title: 'Remove user from org team?',
        okText: 'Remove',
        okButtonProps: {
          danger: true,
          loading: removeStatus.pending || false,
          disabled: removeStatus.pending || false,
        },
        content: (
          <div>
            <p>Are you sure you want to remove this user from the org team?</p>
          </div>
        ),
        onOk: () => {
          return dispatch(removeOrgTeamMembership({ orgSig: membership.org, membershipId: membership.id })).then(() => {
            if (typeof onDelete === 'function') {
              onDelete(membership)
            }
          })
        },
      })
    }
  }

  return (
    <Menu onClick={handleActionsMenu}>
      <Menu.Item key="remove" danger>Remove</Menu.Item>
    </Menu>
  )
}

const teamMemberColumns = ({ onRemove }) => ([
  {
    title: 'Member',
    dataIndex: 'user',
    key: ['user', 'id'],
    render: (val, record) => (
      <div>
        <Link to={paths.admin.USER(val.id)}>{val.name}</Link>
        <div><small>{val.email}</small></div>
      </div>
    ),
  },
  {
    title: 'Joined',
    key: 'createdAt',
    dataIndex: 'createdAt',
    defaultSortOrder: 'descend',
    sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    render: (val, record) => (
      <div>{formatDateTimeTz(val)}</div>
    )
  },
  {
    title: '',
    key: 'actions',
    dataIndex: 'actions',
    render: (val, record) => {
      return (
        <div style={{textAlign: 'center'}}>
          <Dropdown
            overlay={<OrgTeamMembershipActionsMenu membership={record} onDelete={onRemove} />}
            trigger={['click']}
          >
            <Button>
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        </div>
      )
    }
  },
])

const FeeRuleActionsMenu = ({ feeRule, onRemove }) => {
  const dispatch = useDispatch()
  const removeStatus = useStatus(acts.REMOVE_ORG_FEE_RULE)

  useStatusMsg(removeStatus, {
    pending: 'Removing fee rule...',
    error: e => `Failed to remove fee rule: ${e}`,
    success: 'Fee rule removed',
  })

  useEffect(() => () => dispatch(clearStatus(acts.REMOVE_ORG_FEE_RULE)), [])

  const handleActionsMenu = e => {
    if (e.key === 'remove') {
      Modal.confirm({
        title: 'Remove fee rule?',
        okText: 'Remove',
        okButtonProps: {
          danger: true,
          loading: removeStatus.pending || false,
          disabled: removeStatus.pending || false,
        },
        content: (
          <div>
            <p>Are you sure you want to remove this fee rule?</p>
          </div>
        ),
        onOk: () => {
          return dispatch(removeOrgFeeRule({ orgSig: feeRule.org, ruleId: feeRule.id })).then(() => {
            if (typeof onRemove === 'function') {
              onRemove(feeRule)
            }
          })
        },
      })
    }
  }

  return (
    <Menu onClick={handleActionsMenu}>
      <Menu.Item key="remove" danger>Remove</Menu.Item>
    </Menu>
  )
}

const feeRuleColumns = ({ onRemove }) => ([
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    render: (val, record) => {
      return (
        <FeeRuleTypeDisplay feeRuleType={val} />
      )
    },
  },
  {
    title: 'Amount',
    dataIndex: 'percentAmount',
    key: 'percentAmount',
    render: (val, record) => {
      return formatUtils.percent(val)
    },
  },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (val, record) => {
      return (
        <Creator createdAt={val} creator={record.creator} />
      )
    },
  },
  {
    title: '',
    key: 'actions',
    dataIndex: 'actions',
    render: (val, record) => {
      return (
        <div style={{textAlign: 'center'}}>
          <Dropdown
            overlay={<FeeRuleActionsMenu feeRule={record} onRemove={onRemove} />}
            trigger={['click']}
          >
            <Button>
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        </div>
      )
    }
  },
])

const venuesColumns = ({ orgSig, onEditVenue }) => ([
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (val, record) => {
      return (
        <div>
          <Link to={paths.admin.ORG_VENUE(orgSig, record.sid)}>{val}</Link>
          <small>
            <pre style={{marginBottom: 0}}>{record.sid}</pre>
            <Tag>{record.bookability}</Tag>
          </small>
        </div>
      )
    },
  },
  {
    title: 'Links',
    dataIndex: 'links',
    key: 'links',
    render: (val, record) => {
      return (
        <VenueLinks venue={record} />
      )
    }
  },
  {
    title: 'Details',
    dataIndex: 'details',
    key: 'details',
    render: (val, record) => {
      return (
        <VenueDetails venue={record} />
      )
    },
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
    render: (val, record) => {
      const address = { ...val, addressLine1: val.line1, addressLine2: val.line2 }
      return (
        <VenueAddress venue={record} />
      )
    }
  },
  {
    title: 'Images',
    dataIndex: 'images',
    key: 'images',
    render: (val, record) => {
      return <VenueImages venue={record} />
    }
  },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
    width: '10',
    render: (val, record) => (
      <Dropdown
        overlay={<VenueActionsMenu venue={record} onEdit={onEditVenue} />}
        trigger={['click']}
      >
          <EllipsisOutlined
            className="ant-dropdown-link"
            style={{ fontSize: '1.75em', fontWeight: 'bold' }}
          />
      </Dropdown>
    ),
  },
])

const AddTeamMembershipModal = ({ org, complete }) => {
  const dispatch = useDispatch()
  const theme = useTheme(ThemeContext)
  const completer = typeof complete === 'function' ? complete : () => {}
  const [user, setUser] = useState(null)
  const status = useStatus(acts.ADD_ORG_TEAM_MEMBERSHIP)

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.ADD_ORG_TEAM_MEMBERSHIP))
    }
  }, [])

  useStatusMsg(status, { error: 'Failed to add user to org team' })

  const onOk = () => {
    dispatch(addOrgTeamMembership({ userId: user.id, orgSig: org.id }))
      .then(res => completer(res))
      .catch(() => {})
  }

  return (
    <Modal
      title={'Add org team member'}
      visible
      okText={'Add'}
      okButtonProps={{ disabled: !Boolean(org) }}
      onOk={onOk}
      onCancel={() => completer()}
      confirmLoading={status.pending || false}
    >
      <UserSelect
        id="user"
        onChange={u => setUser(u)}
        value={user}
        style={{ width: theme.width[5] }}
        placeholder="Select a user"
      />
    </Modal>
  )
}

const AddFeeRuleModal = ({ org, complete }) => {
  const dispatch = useDispatch()
  const theme = useTheme(ThemeContext)
  const completer = typeof complete === 'function' ? complete : () => {}
  const [type, setType] = useState(null)
  const [percentAmount, setPercentAmount] = useState(null)
  const status = useStatus(acts.ADD_ORG_FEE_RULE)

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.ADD_ORG_FEE_RULE))
    }
  }, [])

  useStatusMsg(status, { error: e => `Failed to add fee rule: ${e}` })

  const onOk = () => {
    dispatch(addOrgFeeRule({ orgSig: org.id, type, percentAmount }))
      .then(res => completer(res))
      .catch(() => {})
  }

  return (
    <Modal
      title={'Add fee rule'}
      visible
      okText={'Add'}
      okButtonProps={{ disabled: !Boolean(org) }}
      onOk={onOk}
      onCancel={() => completer()}
      confirmLoading={status.pending || false}
    >
      <Attr name="Type">
        <div>
          <FeeRuleTypeSelect
            id="fee-rule-type"
            onChange={frt => setType(frt)}
            value={type}
            style={{ width: theme.width[5] }}
          />
        </div>
      </Attr>
      <Attr name="Amount">
        <div>
          <InputNumber
            id="percent-amount"
            onChange={pa => setPercentAmount(pa / 100)}
            value={percentAmount * 100}
            style={{ width: theme.width[5] }}
            addonAfter="%"
          />
        </div>
      </Attr>
    </Modal>
  )
}

// returns the table column spec for an array of objects
const getCols = data => {
  const cols = {}
  if (!Array.isArray(data)) {
    return []
  }
  data.map(d => {
    const keys = Object.keys(d)
    keys.map(k => {
      if (!cols.hasOwnProperty(k)) {
        cols[k] = { title: k, dataIndex: k, key: k }
      }
    })
  })
  return Object.values(cols)
}

export const BookingMetrics = ({ org, venue }) => {
  const dispatch = useDispatch()
  const theme = useTheme(ThemeContext)
  const [pricingType, setPricingType] = useState(null)
  const [disablePricingType, setDisablePricingType] = useState(false)
  const [benefit, setBenefit] = useState(null)
  const [countMethod, setCountMethod] = useState(null)
  const [bookingType, setBookingType] = useState('RES_RESERVATION')
  const [outputType, setOutputType] = useState('data')
  const [onlyCheckedin, setOnlyCheckedin] = useState(true)
  const [groupByProgramName, setGroupByProgramName] = useState(false)
  const [groupByResType, setGroupByResType] = useState(false)
  const [generatedSummary, setGeneratedSummary] = useState(null)
  const [orgVenueCopy, setOrgVenueCopy] = useState(null)
  const [cols, setCols] = useState(null)
  const [data, setData] = useState(null)

  const hasData = Boolean(data)
  const formComplete = pricingType && countMethod

  useEffect(() => {
    if (org) {
      setOrgVenueCopy('org')
    } else if (venue) {
      setOrgVenueCopy('venue')
    }
  }, [org, venue])

  // we don't currently support grouping by resource type when pricing type is resource
  useEffect(() => {
    if (pricingType === resConsts.pricingTypes.PER_RESOURCE.name && groupByResType) {
      setGroupByResType(false)
    }
  }, [pricingType, groupByResType])

  const onGenerate = () => {
    let action = null
    let orgVenueQuery = {}
    if (org) {
      orgVenueQuery = { orgId: org.id }
    } else if (venue) {
      orgVenueQuery = { venueId: venue.id }
    }
    const ben = Boolean(benefit) ? benefit.id : benefit
    const params = {
      ...orgVenueQuery,
      benefitId: ben,
      countMethod,
      checkedIn:
      onlyCheckedin,
      bookingType,
      groupByProgramName,
      groupByResType,
    }
    if (pricingType === resConsts.pricingTypes.PER_RESOURCE.name) {
      if (outputType === 'query') {
        action = metricPerResourceBookingsByMonthQuery(params)
      } else {
        action = metricPerResourceBookingsByMonth(params)
      }
    }
    if (pricingType === resConsts.pricingTypes.PER_PARTICIPANT.name) {
      if (outputType === 'query') {
        action = metricPerParticipantBookingsByMonthQuery(params)
      } else {
        action = metricPerParticipantBookingsByMonth(params)
      }
    }
    dispatch(action)
      .then(res => {
        if (!Array.isArray(res)) {
          setCols(null)
          setData([])
          setGeneratedSummary(null)
        }
        setCols(getCols(res))
        setData(res)
        setGeneratedSummary(`Generated ${resConsts.pricingTypes[pricingType].label}, ${benefit?.name || 'Regular bookings'}, ${countMethod} on ${new Date()}`)
      })
  }

  const onChangeBookingType = val => {
    if (val === 'PROGRAM') {
      setPricingType(resConsts.pricingTypes.PER_PARTICIPANT.name)
      setDisablePricingType(true)
    } else {
      setDisablePricingType(false)
      setGroupByProgramName(false) // grouping by name is only available on programs
    }
    setBookingType(val)
  }

  return (
    <div>
      <Select onChange={onChangeBookingType} value={bookingType}>
        <Option value="RES_RESERVATION">Resource reservations</Option>
        <Option value="PROGRAM">Programs</Option>
      </Select> <span> </span>
      <AdminBenefitSelect allowEmpty emptyLabel="Regular bookings" value={benefit} onChange={e => setBenefit(e)} /> <span> </span>
      { bookingType === 'PROGRAM' ? <PricingTypeDisplay pricingType={pricingType} /> : <PricingTypeSelect value={pricingType} disabled={disablePricingType} onChange={e => setPricingType(e)} /> } <span> </span>
      <PayoutCountMethodSelect value={countMethod} onChange={e => setCountMethod(e)}/> <span> </span>
      <Select onChange={val => setOnlyCheckedin(val)} value={onlyCheckedin}>
        <Option value={true}>Only checked-in bookings</Option>
        <Option value={false}>All bookings</Option>
      </Select> <span> </span>
      { bookingType === 'PROGRAM' &&
        <>
          <Select onChange={val => setGroupByProgramName(val)} value={groupByProgramName}>
            <Option value={false}>Total count</Option>
            <Option value={true}>Count by program name</Option>
          </Select> <span> </span>
        </>
      }
      { bookingType === 'RES_RESERVATION' &&
        <>
          <Select onChange={val => setGroupByResType(val)} value={groupByResType}>
            <Option value={false}>Total count</Option>
            <Option
              value={true}
              disabled={pricingType === resConsts.pricingTypes.PER_RESOURCE.name}
            >
              Count by resource type
            </Option>
          </Select> <span> </span>
        </>
      }
      <Select onChange={val => setOutputType(val)} value={outputType}>
        <Option value="data">Output data</Option>
        <Option value="query">Output query</Option>
      </Select> <span> in this {orgVenueCopy} </span>
      <Button disabled={!formComplete} onClick={() => onGenerate()}>Generate</Button>
      <div style={{marginTop: theme.spacing[2]}}>
        <div style={{marginBottom: theme.spacing[1]}}><small>{generatedSummary}</small></div>
        { (hasData && outputType === 'data' && Array.isArray(data)) && <Table size="small" columns={cols} dataSource={data} pagination={false} /> }
        { (hasData && outputType === 'query' && !Array.isArray(data)) && <pre>{data.query}</pre> }
      </div>
    </div>
  )
}

const Org = () => {
  const { sig } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const [org, setOrg] = useState(null)
  const [editingOrg, setEditingOrg] = useState(false)
  const [editingVenue, setEditingVenue] = useState(null)
  const [showSettingsInfo, setShowSettingsInfo] = useState(false)
  const [addingMembers, setAddingMembers] = useState(false)
  const [addingFeeRule, setAddingFeeRule] = useState(false)
  const status = useStatus(acts.FETCH_ORG)
  const theme = useTheme(ThemeContext)

  useEffect(() => {
    dispatch(fetchOrg({ sig })).then(org => {
      setOrg(org)
    })
  }, [sig])

  useStatusMsg(status, {
    error: 'Failed to fetch org',
  })

  if (!org) {
    return <LoadingOutlined />
  }

  const onEditVenue = venue => {
    setEditingVenue(venue)
  }

  const onRemoveFeeRule = feeRule => {
    if (feeRule) {
      setOrg(prev => ({
        ...prev,
        feeRules: prev.feeRules.filter(fr => fr.id !== feeRule.id),
      }))
    }
  }

  const onPackageSetsChange = updatedSets => {
    setOrg(prev => ({ ...prev, packageSets: updatedSets }))
  }

  return (
    <PaddedContainer>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={paths.admin.ORGS()}>Orgs</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{sig}</Breadcrumb.Item>
      </Breadcrumb>
      <Head
        style={{ display: 'flex', justifyContent: 'space-between', marginTop: theme.spacing[3], alignItems: 'center' }}
      >
        <Title>{org.name}</Title>
        <Dropdown
          overlay={
            <ActionsMenu
              onEdit={() => setEditingOrg(true)}
              org={org}
            />
          }
          trigger={['click']}
        >
          <CtaButton>
            Actions
            <DownOutlined />
          </CtaButton>
        </Dropdown>
      </Head>
      <Attr name="Id">
        <ID>{org.id}</ID>
      </Attr>
      <Attr name="Sid">
        <div>{org.sid}</div>
      </Attr>
      <Attr name="Stripe Account Id">
        <div>{org.stripeAccountId ?? <i>None</i>}</div>
      </Attr>
      <Attr name="Links">
        <div>
          <div style={{marginBottom: theme.spacing[2]}}>
            <Link to={paths.org.HOME(org.sid)}>Partner portal <LinkOutlined /></Link>
          </div>
          <div style={{marginBottom: theme.spacing[2]}}>
            <a href={urlUtils.bosOrgHomepage(org.sid)}>BreakOS <LinkOutlined /></a>
          </div>
        </div>
      </Attr>
      <Attr name="Settings">
        <div>
          <Link to={paths.org.ORG_SETTINGS(org.sid)}>
            View in partner portal <LinkOutlined />
          </Link>
        </div>
      </Attr>
      <Attr name="Team members">
        <Collapse>
          <Panel
            header={<CountHeader title="Team members" count={org.members.length} />}
            key="team-members"
          >
            <div style={{marginBottom: theme.spacing[3], display: 'flex'}}>
              <Button style={{marginLeft: 'auto'}} onClick={() => setAddingMembers(true)}>
                <PlusOutlined /> Add team member
              </Button>
            </div>
            <Table
              size="small"
              columns={teamMemberColumns({
                onRemove: deleted => setOrg(prev => ({ ...org, members: org.members.filter(m => m.id !== deleted.id) }))
              })}
              dataSource={org.members}
              pagination={false}
            />
          </Panel>
        </Collapse>
      </Attr>
      <Attr name="Fee rules">
        <Collapse>
          <Panel
            header={<CountHeader title="Fee rules" count={org.feeRules.length} />}
            key="fee-rules"
          >
            <div style={{marginBottom: theme.spacing[3], display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={() => setAddingFeeRule(true)}>
                <PlusOutlined /> Add fee rule
              </Button>
            </div>
            <Table
              size="small"
              columns={feeRuleColumns({ onRemove: onRemoveFeeRule })}
              dataSource={org.feeRules}
              pagination={false}
            />
          </Panel>
        </Collapse>
      </Attr>
      <Attr name="Booking metrics">
        <BookingMetrics org={org} />
      </Attr>
      <Attr name="Package sets">
        <PackageSets sets={org.packageSets} onChange={onPackageSetsChange} />
      </Attr>
      <Attr name="Venues">
        <Table size="small" columns={venuesColumns({ orgSig: sig, onEditVenue })} dataSource={org.venues} pagination={false} style={{ marginTop: theme.spacing[3] }}/>
      </Attr>
      {editingOrg && (
        <EditOrg
          org={org}
          complete={updatedOrg => {
            if (updatedOrg) {
              setOrg(updatedOrg)
            }
            setEditingOrg(false)
            history.push(paths.admin.ORG(updatedOrg.sid))
          }}
        />
      )}
      {editingVenue && (
        <EditVenue
          venue={editingVenue}
          complete={updatedVenue => {
            if (updatedVenue) {
              setOrg(prev => {
                const venues = prev.venues.map(v => v.id === updatedVenue.id ? updatedVenue : v)
                return { ...prev, venues }
              })
            }
            setEditingVenue(null)
          }}
        />
      )}
      { addingMembers &&
        <AddTeamMembershipModal
          org={org}
          complete={updatedMemberships => {
            if (updatedMemberships) {
              setOrg(prev => ({ ...prev, members: updatedMemberships }))
            }
            setAddingMembers(false)
          }}
        />
      }
      { addingFeeRule &&
        <AddFeeRuleModal
          org={org}
          complete={newFeeRule => {
            if (newFeeRule) {
              setOrg(prev => ({ ...prev, feeRules: [...prev.feeRules, newFeeRule] }))
            }
            setAddingFeeRule(false)
          }}
        />
      }
    </PaddedContainer>
  )
}

export default Org
