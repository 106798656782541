import React, { useEffect, useState } from 'react'
import { EllipsisOutlined, PlusOutlined, LoadingOutlined } from '@ant-design/icons'
import { Select, Breadcrumb, Checkbox, Tag, Menu, Dropdown, Button, Input, Table, Modal } from 'antd'
import { Link, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Attr from '../../../components/Attr'
import { Cta as BTLButton } from '../../../components/common'
import * as adminActs from '../../../actions/admin'
import { clearStatus } from '../../../actions/status'
import { useStatus, useStatusMsg } from '../../../reducers'
import { formatDateTimeTz } from '../../../util/time'
import { paths, actions as acts } from '../../../constants'
import { UserPermitsTable } from './UserPermits'

const { TextArea } = Input
const { Option } = Select

const Active = ({ account }) => {
  return account.active ? <Tag color="green">Active</Tag> : <Tag>Deactivated</Tag>
}

export const NycPermitAccountSelector = ({ value, onChange, ...props }) => {
  const dispatch = useDispatch()
  const [accounts, setAccounts] = useState(null)
  const [val, setVal] = useState()
  const status = useStatus(acts.FETCH_NYC_PERMIT_ACCOUNTS)

  useEffect(() => {
    dispatch(adminActs.fetchNycPermitAccounts())
      .then(accs => {
        setAccounts(accs.reduce((acc, curr) => {
          acc[curr.id] = curr
          return acc
        }, {}))
      })
    return () => {
      dispatch(clearStatus(acts.FETCH_NYC_PERMIT_ACCOUNTS))
    }
  }, [])

  useEffect(() => {
    // convert value to account id when account list or value changes
    if (accounts && Object.keys(accounts).length > 0 && value) {
      setVal(value.id)
    }
  }, [accounts, value])


  const handleChange = id => {
    if (typeof onChange === 'function') {
      onChange(accounts[id])
    }
  }

  if (!accounts) {
    return <LoadingOutlined />
  }

  const options = Object.keys(accounts).map(aid => {
    const a = accounts[aid]
    return (
      <Option key={a.id} value={a.id} disabled={!a.active}>
        <div>{a.email}</div>
        <div>{a.password}</div>
        <div><Active account={a} /></div>
      </Option>
    )
  })

  return (
    <Select
      value={val}
      onChange={handleChange}
      {...props}
    >
      {options}
    </Select>
  )
}

export const NycPermitAccountInfo = ({ account }) => {
  return (
    <div>
      <Attr>
        <Active account={account} />
      </Attr>
      <Attr name="Email">
        <div>{account.email}</div>
      </Attr>
      <Attr name="Password">
        <div>{account.password}</div>
      </Attr>
      <Attr name="Notes">
        <div>{account.notes}</div>
      </Attr>
      <Attr name={account.creator ? 'Created by' : 'Created'}>
        <div>
          {account.creator &&
            <Link to={paths.admin.USER(account.creator.id)}>{account.creator.name}</Link>
          }
          <div>{formatDateTimeTz(account.createdAt)}</div>
        </div>
      </Attr>
    </div>
  )
}

export const NycPermitAccount = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const [account, setAccount] = useState(null)
  const status = useStatus(acts.FETCH_NYC_PERMIT_ACCOUNT)

  useEffect(() => {
    dispatch(adminActs.fetchNycPermitAccount(id))
      .then(a => setAccount(a))
    return () => {
      dispatch(clearStatus(acts.FETCH_NYC_PERMIT_ACCOUNT))
    }
  }, [id])

  useStatusMsg(status, { error: 'Failed to load NYC permit account' })

  if (!account) {
    return <LoadingOutlined />
  }

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={paths.admin.PERMITS()}>Permits</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={paths.admin.NYC_PERMIT_ACCOUNTS()}>NYC permit accounts</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{id}</Breadcrumb.Item>
      </Breadcrumb>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <h1 style={{marginRight: '.5em'}}>Account</h1>
      </div>
      <NycPermitAccountInfo account={account} />
      <Attr
        name="User permits"
        tooltip="User permits that have been assigned to be purchased with this card"
      >
        <div>
          <UserPermitsTable permits={account.userPermits} size="small" />
        </div>
      </Attr>
    </div>
  )
}

const cols = ({ setEditing }) => ([
  {
    title: '',
    width: '10px',
    dataIndex: 'active',
    key: 'active',
    render: (val, record) => (
      <Active account={record} />
    ),
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    render: (val, record) => <Link to={paths.admin.NYC_PERMIT_ACCOUNT(record.id)}>{val}</Link>,
  },
  {
    title: 'Password',
    dataIndex: 'password',
    key: 'password',
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
    key: 'notes',
  },
  {
    title: '',
    width: '10px',
    render: (val, record) => {
      const handleMenuClick = e => {
        if (e.key === 'edit') {
          setEditing(record)
        }
      }
      const actionMenu = (
        <Menu onClick={handleMenuClick}>
          <Menu.Item key="edit">Edit</Menu.Item>
        </Menu>
      )
      return (
        <Dropdown overlay={actionMenu} trigger={['click']} placement="bottomRight">
          <Button size="small"><EllipsisOutlined /></Button>
        </Dropdown>
      )
    }
  },
])

const EditAccountModal = ({ account, complete }) => {
  const dispatch = useDispatch()
  const [notes, setNotes] = useState(account.notes)
  const [active, setActive] = useState(account.active)

  const onOk = () => {
    dispatch(adminActs.updateNycPermitAccount(account.id, { notes, active }))
      .then(updated => {
        if (typeof complete === 'function') {
          complete(updated)
        }
      })
  }
  const onCancel = () => {
    if (typeof complete === 'function') {
      complete()
    }
  }

  return (
    <Modal
      title="Edit NYC permit account"
      visible
      onOk={onOk}
      onCancel={onCancel}
    >
      <Attr name="Email">
        <div>
          {account.email}
        </div>
      </Attr>
      <Attr name="Password">
        <div>
          {account.password}
        </div>
      </Attr>
      <Attr name="Notes">
        <div>
          <TextArea
            value={notes}
            onChange={e => setNotes(e.target.value)}
          />
        </div>
      </Attr>
      <div>
        <Checkbox checked={active} onChange={e => setActive(e.target.checked)}>
          Active
        </Checkbox>
      </div>
    </Modal>
  )
}

const AddAccountModal = ({ complete }) => {
  const dispatch = useDispatch()
  const [email, setEmail] = useState(null)
  const [password, setPassword] = useState(null)
  const [notes, setNotes] = useState(null)
  const status = useStatus(acts.CREATE_NYC_PERMIT_ACCOUNT)

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.CREATE_NYC_PERMIT_ACCOUNT))
    }
  }, [])

  const onOk = () => {
    dispatch(adminActs.createNycPermitAccount({ email, password, notes }))
      .then(newAccount => {
        if (typeof complete === 'function') {
          complete(newAccount)
        }
      })
      .catch(e => console.log(e))
  }

  const onCancel = () => {
    if (typeof complete === 'function') {
      complete()
    }
  }

  return (
    <Modal
      title="Add NYC permit account"
      visible
      okText="Save"
      onOk={onOk}
      okButtonProps={{
        disabled: status.pending,
        loading: !!status.pending,
      }}
      onCancel={onCancel}
    >
      <Attr name="Email">
        <div>
          <Input
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="keenan@breakthelove.com"
          />
        </div>
      </Attr>
      <Attr name="Password">
        <div>
          <Input
            value={password}
            onChange={e => setPassword(e.target.value)}
            placeholder="******"
          />
        </div>
      </Attr>
      <Attr name="Notes">
        <div>
          <TextArea
            value={notes}
            onChange={e => setNotes(e.target.value)}
          />
        </div>
      </Attr>
    </Modal>
  )
}

const NycPermitAccounts = () => {
  const dispatch = useDispatch()
  const [accounts, setAccounts] = useState(null)
  const [adding, setAdding] = useState(false)
  const [editing, setEditing] = useState(null)
  const status = useStatus(acts.FETCH_NYC_PERMIT_ACCOUNTS)

  useEffect(() => {
    dispatch(adminActs.fetchNycPermitAccounts())
      .then(accs => setAccounts(accs))

    return () => {
      dispatch(clearStatus(acts.FETCH_NYC_PERMIT_ACCOUNTS))
    }
  }, [])

  const onAddComplete = newAccount => {
    if (newAccount) {
      setAccounts(prev => [...prev, newAccount])
    }
    setAdding(false)
  }

  const onEditComplete = editedAccount => {
    if (editedAccount) {
      setAccounts(prev => {
        const next = [...prev]
        const idx = next.findIndex(a => a.id === editedAccount.id)
        if (idx > -1) {
          next[idx] = editedAccount
        }
        return next
      })
    }
    setEditing(null)
  }

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={paths.admin.PERMITS()}>Permits</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          NYC permit accounts
        </Breadcrumb.Item>
      </Breadcrumb>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <h1 style={{marginRight: '.5em'}}>NYC Permit Accounts</h1>
        <BTLButton onClick={() => setAdding(true)}><PlusOutlined /> New account</BTLButton>
      </div>
      <Table rowKey="id" columns={cols({ setEditing })} dataSource={accounts} pagination={false} />
      { adding && <AddAccountModal complete={onAddComplete} /> }
      { editing && <EditAccountModal account={editing} complete={onEditComplete} />}
    </div>
  )
}

export default NycPermitAccounts
