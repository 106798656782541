import React, { useEffect, useReducer } from 'react'
import { InputGroup, SelectContainer, Input, Title } from './step'
import { actions as acts } from '../../../constants'
import { PROGRAM } from '../../../constants/marketingLabels'
import { programTypes, programSubtypes } from '../../../constants/program'
import { typeLabel, subtypeLabel } from '../../../util/program'
import { lower } from '../../../util/string'
import Attr from '../../../components/Attr'

const initState = ({ type, subtype }) => ({
  type,
  subtype,
})

const reducer = (state, action) =>  ({ ...state, ...action })

const ProgramType = ({ clinic, onChange }) => {
  const [state, dp] = useReducer(reducer, initState(clinic))

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(state)
    }
  }, [state])

  const genSubtypes = () => (
    Object.keys(programSubtypes[state.type]).map(key => {
      const pst = programSubtypes[state.type][key]
      return (
        <span key={pst.name}>
          <input
            type="radio"
            id={pst.name}
            name="subtype"
            value={pst.name}
            defaultChecked={state.subtype === pst.name}
            onChange={e => dp({ subtype: e.target.value })}
          />
          <label htmlFor={pst.name}>{pst.label}</label>
        </span>
      )
    })
  )

  const types = Object.keys(programTypes).map(key => {
    const pt = programTypes[key]
    return (
      <span key={pt.name}>
        <input
          type="radio"
          id={pt.name}
          name="type"
          value={pt.name}
          defaultChecked={state.type === pt.name}
          onChange={e => dp({ type: e.target.value })}
        />
        <label htmlFor={pt.name}>{pt.label}</label>
      </span>
    )
  })

  return (
    <>
      <InputGroup>
        <Title>What kind of {PROGRAM} would you like to create?</Title>
        <SelectContainer>
          {types}
        </SelectContainer>
      </InputGroup>
      <div>
        { state.type in programTypes &&
          <>
            <InputGroup>
              <Title>
                What kind of {lower(typeLabel({ type: state.type }))} is this?
              </Title>
              <SelectContainer>
                {genSubtypes()}
              </SelectContainer>
            </InputGroup>
          </>
        }
      </div>
    </>
  )
}

const Viewer = ({ program }) => {
  return (
    <>
      <Attr name="Type">
        <div>{typeLabel(program)}</div>
      </Attr>
      <Attr name="Subtype">
        <div>{subtypeLabel(program)}</div>
      </Attr>
    </>
  )
}
const step = ProgramType

const complete = ({ type, subtype }) =>
  type in programTypes && subtype in programSubtypes[type]

const applicable = () => true

export default { step, Viewer, complete, applicable }
