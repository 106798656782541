import React, { useState, useEffect, useReducer, useMemo } from 'react'
import { Dropdown, Menu, Tag, Input, Button, Modal, Checkbox } from 'antd'
import { CaretDownOutlined, PlusOutlined } from '@ant-design/icons'
import { useSelector, useDispatch } from 'react-redux'
import Attr from '../../../components/Attr'
import PageHeading from '../../../components/admin/PageHeading'
import PageTitle from '../../../components/admin/PageTitle'
import {
  fetchAttributes,
  updateAttribute,
  deleteAttribute,
  createAttribute,
} from '../../../actions/admin'
import { actions as acts } from '../../../constants'
import { useStatus, useStatusMsg } from '../../../reducers'
import { types as attrTypes } from '../../../constants/attribute.js'
import { Reorderable } from '../../../components/Reorderable'
import { DeclarativeForm, useDeclarativeForm } from '../../../components/DeclarativeForm'

let currNewId = 0
const newValue = () => ({
  id: `new-${currNewId++}`,
  name: null,
})

function EditableInput({
  value,
  onChange,
  defaultEditing = false
}) {
  const [editing, setEditing] = useState(defaultEditing)
  return (
    <>
      <Input
        value={value}
        style={{
          width: 'inherit',
          ...(editing ? {} : {
            borderColor: 'transparent',
            color: 'black',
            background: 'transparent',
            cursor: 'unset'
          })
        }}
        onChange={onChange}
        disabled={!editing}
      />
      {!editing && (
        <Button type="link" onClick={() => setEditing(!editing)} style={{ paddingRight: 0 }}>
          Edit
        </Button>
      )}
    </>
  )
}

const CreateModal = ({ existingAttribute, complete, isEdit = false, type }) => {
  const dispatch = useDispatch()
  const [name, setName] = useState(existingAttribute && existingAttribute.name || null)
  const [values, setValues] = useState(existingAttribute && existingAttribute.values || [])
  const status = {
    create: useStatus(acts.CREATE_ATTRIBUTE),
    update: useStatus(acts.UPDATE_ATTRIBUTE),
  }

  const formProps = useDeclarativeForm({
    elements: () => {
      switch (existingAttribute?.type ?? type.name) {
        case attrTypes.CLUB.name:
          return [
            { name: 'webFilterable', checkbox: {}, label: "Allow filtering on website", description: "" },
            { name: 'appFilterable', checkbox: {}, label: "Allow filtering on mobile app", description: "" }
          ]
        case attrTypes.PROGRAM.name:
          return [
            { name: 'required', checkbox: {}, label: "Required", description: "Required attributes require an event to have at least one value during event creation or editing" },
            { name: 'searchFilterable', checkbox: {}, label: "Allow filtering on search page", description: "Search filterable attributes are available as a filter on the events search page" },
            { name: 'proSearchFilterable', checkbox: {}, label: "Allow filtering on pro search page", description: "Pro search filterable attributes are available as a filter on all pro event search pages" }
          ]
        case attrTypes.USER_PROFILE.name:
          return [
            { name: 'required', checkbox: {}, label: "Required", description: "Required attributes require the user to select at least one value during onboarding or profile editing" },
          ]
        default:
          return []
      }
    },
    defaults: {
      ...(existingAttribute?.clubSettings ?? {}),
      ...(existingAttribute?.programSettings ?? {}),
      ...(existingAttribute?.userProfileSettings ?? {})
    }
  })

  const formComplete = [
    name && name.length > 0,
    values && values.length > 0 && values.reduce((acc, curr) => acc || curr.name, false),
  ].reduce((acc, curr) => curr && acc, true)

  const updateValue = (id, value) => {
    const newValues = [...values]
    const idx = newValues.findIndex(v => v.id === id)
    newValues.splice(idx, 1, { ...newValues[idx], ...value })
    setValues(newValues)
  }

  const onValueChange = (id, name) => {
    updateValue(id, { name })
  }

  const remove = id => setValues(values.filter(v => v.id !== id))

  const onOk = async () => {
    try {
      // The that value order matches what the user sees visually
      const orderedValues = values.map((v, i) => ({ ...v, order: i + 1 }))
      // strip ids from new values and do not send empty values
      const newValues = orderedValues.map(v => {
        if (v.id.indexOf('new-') !== -1) {
          if (!v.name) {
            return false
          }
          return { ...v, id: undefined }
        }
        return v
      }).filter(Boolean)
      const attributeDetails = { name, settings: formProps.data }
      if (isEdit) {
        await dispatch(updateAttribute(existingAttribute.id, attributeDetails, orderedValues))
      } else {
        await dispatch(createAttribute(type.name, attributeDetails, newValues))
      }
      complete()
    } catch (err) { }
  }

  const typeName = type?.label ?? attrTypes[existingAttribute?.type]?.label ?? ''

  return (
    <div>
      <Modal
        title={`${isEdit ? 'Edit' : 'Create new'} ${typeName.toLowerCase()} attribute`}
        visible
        destroyOnClose
        okButtonProps={{ disabled: !formComplete }}
        onOk={onOk}
        okText="Save"
        onCancel={() => complete()}
        confirmLoading={status.create.pending || status.update.pending || false}
      >
        <Attr name="Name">
          <Input
            style={{ width: 'inherit', display: 'block' }}
            placeholder="e.g. Level"
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </Attr>
        <DeclarativeForm {...formProps} />
        <Attr name="Values">
          <Reorderable
            items={values}
            keyExtractor={v => v.id}
            renderItem={v => (
              <div key={v.id} style={{ display: 'flex' }}>
                <EditableInput
                  value={v.name}
                  style={{ width: 'inherit' }}
                  onChange={e => onValueChange(v.id, e.target.value)}
                  defaultEditing={v.id.indexOf('new-') === 0}
                />
                <Button type="link" onClick={() => remove(v.id)}>Remove</Button>
              </div>
            )}
            onChange={newValues => setValues(newValues)}
          />

          <Button
            type="dashed"
            style={{ width: '100%', marginTop: '0.5em' }}
            onClick={() => setValues([...values, newValue()])}
          >
            <PlusOutlined /> Add value
          </Button>
        </Attr>
      </Modal>
    </div>
  )
}

const AttrList = ({ attributes, setEditing, setEditAttr, deleteAttr }) => {
  if (attributes.length === 0) {
    return (
      <div>
        <i>No attributes exist</i>
      </div>
    )
  }

  return attributes.map(attr => {
    const vals = attr.values.map(v => (
      <Tag key={v.id}>{v.name}</Tag>
    ))
    const menu = (
      <Menu>
        <Menu.Item onClick={() => { setEditing(true); setEditAttr(attr) }}>Edit</Menu.Item>
        <Menu.Item
          onClick={() => {
            Modal.confirm({
              title: 'Confirm',
              okText: 'Delete attribute',
              okButtonProps: {
                type: 'danger',
              },
              content: (
                <div>
                  <p>Are you sure you want to delete attribute "{attr.name}"?</p>
                </div>
              ),
              onOk: () => deleteAttr(attr.id)
            })
          }}>Delete</Menu.Item>
      </Menu>
    )
    return (
      <div key={attr.id} style={{ marginBottom: '1em' }}>
        <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
          <Dropdown overlay={menu} trigger={['click']}>
            <div style={{ display: 'inline-block', cursor: 'pointer' }}>
              {attr.name} <CaretDownOutlined style={{ fontSize: '14px' }} />
            </div>
          </Dropdown>
        </div>
        <div style={{ display: 'flex' }}>
          {vals}
        </div>
      </div>
    )
  })
}


const Attributes = () => {
  const dispatch = useDispatch()
  const attributes = useSelector(s => Array.isArray(s.attributes) ? s.attributes : [])
  const [addingType, setAddingType] = useState(false)
  // TODO: the value of editing should determin what fields the edit mdoal shows
  const [editing, setEditing] = useState('')
  const [editAttr, setEditAttr] = useState(null) // attr to edit
  const status = {
    delete: useStatus(acts.DELETE_ATTRIBUTE),
  }

  useStatusMsg(status.delete, {
    success: 'Attribute deleted',
    error: 'Failed to delete attribute'
  })

  useEffect(() => { dispatch(fetchAttributes()) }, [])

  const deleteAttr = id => dispatch(deleteAttribute(id))

  const attrs = useMemo(() => {
    const out = {
      [attrTypes.PROGRAM.name]: [],
      [attrTypes.CLUB.name]: [],
      [attrTypes.USER_PROFILE.name]: []
    }

    attributes.forEach(crnt => {
      out[crnt.type].push(crnt)
    })

    return out
  }, [attributes])

  return (
    <>
      <PageHeading>
        <PageTitle>Attributes</PageTitle>
        {(addingType || editing) &&
          <CreateModal
            type={addingType}
            complete={() => { setAddingType(false); setEditing(false); setEditAttr(null) }}
            existingAttribute={editAttr}
            isEdit={editing}
          />
        }
      </PageHeading>
      <div style={{ marginBottom: '3em' }}>
        {Object.entries(attrs).map(([key, value]) => (
          <React.Fragment key={key}>
            <Attr name={attrTypes[key].label}>
              <Button type="primary" onClick={() => setAddingType(attrTypes[key])}>Add</Button>
              <AttrList
                attributes={value}
                setEditing={setEditing}
                setEditAttr={setEditAttr}
                deleteAttr={deleteAttr}
              />
            </Attr>
          </React.Fragment>
        ))}
      </div>
    </>
  )
}

export default Attributes
