import React, { useState, useEffect } from 'react'
import { Head, Title, PaddedContainer } from '../../components/common'
import { features } from '../../constants'
import { MediumInfo } from '../../components/info'
import { Button, Cta } from '../../components/common'
import { EditFilled } from '@ant-design/icons'
import { Settings as GeneralSettings } from '../../components/settings'
import { fetchConstsByName, fetchOrg, updateOrgSettings } from '../../actions/org'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useContext } from 'react'
import { ThemeContext } from 'styled-components'

const OrgSettings = () => {
  const dispatch = useDispatch()
  const { osid } = useParams()
  const theme = useContext(ThemeContext)
  const [editing, setEditing] = useState(false)
  const [allSettings, setAllSettings] = useState(null)
  const [settings, setSettings] = useState(null)

  useEffect(() => {
    dispatch(fetchOrg({ osid })).then(org => {
      setSettings(org.settings)
    })
    dispatch(fetchConstsByName({ osid, name: 'org' })).then(consts => {
      setAllSettings(consts.settings)
    })
  }, [])

  const onSaveSettings = () => {
    dispatch(updateOrgSettings({ osid, settings })).then(updatedOrg => {
      setSettings(updatedOrg.settings)
      setEditing(false)
    })
  }

  const onCancelEdit = () => {
    setEditing(false)
    dispatch(fetchOrg({ osid })).then(org => {
      setSettings(org.settings)
    })
  }

  let buttons = (
    <Button onClick={() => setEditing(true)}>
      <EditFilled /> Edit
    </Button>
  )
  if (editing) {
    buttons = (
      <div>
        <Button style={{ marginRight: theme.spacing[2] }} onClick={onCancelEdit}>
          Cancel
        </Button>
        <Cta onClick={onSaveSettings}>Save</Cta>
      </div>
    )
  }

  return (
    <PaddedContainer>
      <Head>
        <Title>{features.orgSettings.name.plural}</Title>
      </Head>
      <MediumInfo button={buttons}>
        <div style={{ maxWidth: theme.width[8] }}>
          <GeneralSettings
            allSettings={allSettings}
            settings={settings}
            editing={editing}
            onChange={updatedSettings => setSettings(updatedSettings)}
          />
        </div>
      </MediumInfo>
    </PaddedContainer>
  )
}

export default OrgSettings
