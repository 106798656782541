import { validate } from 'uuid'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { formatDateTimeTz } from '../util/time'
import { fetchUser } from '../actions/admin'
import UserLink from './admin/UserLink'

// creator is either a user id or a user object
export const Creator = ({ createdAt, creator }) => {
  const dispatch = useDispatch()
  const [user, setUser] = useState(null)

  useEffect(() => {
    if (typeof creator === 'string' && validate(creator)) {
      dispatch(fetchUser(creator))
        .then(u => setUser(u))
    } else {
      setUser(creator)
    }
  }, [creator])

  return (
    <div>
      <div>{formatDateTimeTz(createdAt)}</div>
      {user && <div>by <UserLink user={user} /></div>}
    </div>
  )
}
