import React, { useContext, useState, useEffect } from 'react'
import { Button, Affix } from 'antd'
import { ArrowLeftOutlined, CloseOutlined, SwapOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import styled, { ThemeContext } from 'styled-components'
import { matchPath, useLocation, Link, useHistory, useParams } from 'react-router-dom'
import { actions as acts, paths, features, patterns } from '../constants'
import { logout } from '../actions/user'
import { useStatus } from '../reducers'
import UnstyledLink from './UnstyledLink'
import Gutter from './Gutter'

const Pill = styled.div`
  border-radius: 40px;
  padding: 2px 10px;
  margin-right: 4px;
  font-weight: 500;

  &:hover {
    background-color: white;
  }

  &.active {
    background-color: ${props => props.theme.color.primary};
    color: white;
  }

  &.active:hover {
    background-color: ${props => props.theme.color.primary};
    color: white;
  }
`

const NavPill = ({ active, to, children }) => {
  return (
    <Pill className={active ? 'active' : null}>
      <UnstyledLink to={to}>
        {children}
      </UnstyledLink>
    </Pill>
  )
}

const AccountDetails = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const self = useSelector(s => s.self)
  const status = useStatus(acts.FETCH_SELF)
  if (!self || status.pending) {
    return (
      <div>Loading...</div>
    )
  }
  const onLogout = () => {
    dispatch(logout())
      .then(() => history.push('/'))
  }
  return (
    <div style={{ display: 'flex' }}>
      <div style={{marginRight: '10px'}}>{self.name}</div>
      <Button size="small" type="secondary" onClick={onLogout}>
        Logout
      </Button>
    </div>
  )
}

const OrgHeader = () => {
  // org, user, logout
  const org = useSelector(s => s.org)
  const status = useStatus(acts.SET_ORG)
  let orgName = 'Loading...'
  if (org && !status.pending) {
    orgName = org.name
  }
  return (
    <Gutter style={{backgroundColor: 'white'}}>
      <div style={{display: 'flex', padding: '10px 0px'}}>
        <div>{orgName} <Link to={paths.org.ORGS()}><SwapOutlined /></Link></div>
        <div style={{ flexGrow: 1 }}></div>
        <AccountDetails />
      </div>
    </Gutter>
  )
}

const NavHeader = () => {
  const loc = useLocation()
  const { osid } = useParams()
  const theme = useContext(ThemeContext)
  const [feature, setFeature] = useState(null)
  const match = matchPath(loc.pathname, { path: patterns.org.GENERAL_FEATURE() })

  useEffect(() => {
    if (match) {
      setFeature(match.params.feature)
    }
  }, [loc.pathname])

  return (
    <Gutter style={{ backgroundColor: theme.color.background }}>
      <nav style={{
        display: 'flex', 
        padding: '8px 0px', 
        boxShadow: `inset 0 -1px ${theme.color.gray[1]}`,
      }}>
      <NavPill
        to={paths.org.PROGRAMS(osid)}
        active={feature === features.program.url}
      >
        {features.program.name.plural}
      </NavPill>
      <NavPill
        active={feature === features.club.url}
        to={paths.org.CLUBS(osid)}
      >
        {features.club.name.plural}
      </NavPill>
      <NavPill
        active={feature === features.product.url}
        to={paths.org.PRODUCTS(osid)}
      >
        {features.product.name.plural}
      </NavPill>
      <NavPill
        active={feature === features.order.url}
        to={paths.org.ORDERS(osid)}
      >
        {features.order.name.plural}
      </NavPill>
      <NavPill
        active={feature === features.pos.url}
        to={paths.org.POS(osid)}
      >
        {features.pos.name.plural}
      </NavPill>
      <NavPill
        active={feature === features.location.url}
        to={paths.org.LOCATIONS(osid)}
      >
        {features.location.name.plural}
      </NavPill>
      <NavPill
        active={feature === features.role.url}
        to={paths.org.ROLES(osid)}
      >
        {features.role.name.plural}
      </NavPill>
      <NavPill
        active={feature === features.sponsor.url}
        to={paths.org.SPONSORS(osid)}
      >
        {features.sponsor.name.plural}
      </NavPill>
      <NavPill
        active={feature === features.tag.url}
        to={paths.org.ORG_TAGS(osid)}
      >
        {features.tag.name.plural}
      </NavPill>
      <NavPill
        active={feature === features.orgSettings.url}
        to={paths.org.ORG_SETTINGS(osid)}
      >
        {features.orgSettings.name.plural}
      </NavPill>
      </nav>
    </Gutter>
  )
}

const FocusHead = styled.div`
  background-color: white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-left: 10px;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;

  box-shadow: inset 0 -1px ${props => props.theme.color.gray[1]};
`

const VerticalSeparator = styled.div`
  width: 1px;
  height: 18px;
  border-radius: 4px;
  background-color: rgb(193, 201, 210);
  margin-left: 10px;
  margin-right: 20px;
`

export const FocusHeader = ({ title, actions, onClose, backIcon='close' }) => {
  let backButton;
  switch (backIcon) {
    case 'close': {
      backButton = (
        <CloseOutlined />
      )
      break
    }
    case 'back': {
      backButton = (
        <ArrowLeftOutlined />
      )
      break
    }
  }
  const history = useHistory()
  return (
    <Affix offsetTop={0}>
      <FocusHead>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Button size="small" type="text" onClick={() => history.goBack()}>
            {backButton}
          </Button>
          <VerticalSeparator /> {title}
        </div>
        <div>{actions}</div>
      </FocusHead>
    </Affix>
  )
}

const Header = () => {
  return (
    <Affix offsetTop={0}>
      <OrgHeader />
      <NavHeader />
    </Affix>
  )
}

export default Header
