import React, { useContext } from 'react'
import { countries } from 'countries-list'
import { ThemeContext } from 'styled-components'
import { Select } from 'antd'

const countryOpts = Object.keys(countries).map(code => (
  <Select.Option key={code} value={code}>
    {countries[code].name}
  </Select.Option>
))

const CountrySelector = ({ ...props }) => {
  const theme = useContext(ThemeContext)
  return (
    <Select
      readOnly
      showSearch
      placeholder="Select a country"
      style={{ width: theme.width[5] }}
      optionFilterProp="children"
      filterOption={
        (input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      {...props}
    >
      {countryOpts}
    </Select>
  )
}

export default CountrySelector
