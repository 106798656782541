import styled from 'styled-components'

const Dropzone = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 2px dashed #eeeeee;
  border-radius: 2px;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  width: 100%;
  cursor: pointer;
  margin-top: 1rem;
  &:focus {
    border-color: #03cf9e;
  }
`

export default Dropzone
