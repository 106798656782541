import React, { useEffect, useState } from 'react'
import { message, Button } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { LoadingOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import ClubEditor, { initState } from './ClubEditor'
import { useStatus, useStatusMsg } from '../../reducers'
import { editClub, fetchClub } from '../../actions/org'
import { clearStatus } from '../../actions/status'
import { FocusHeader } from '../../components/header'
import { Cta } from '../../components/common'
import { Focus as FocusContainer } from '../../components/containers'
import { paths, actions as acts } from '../../constants'
import get from 'lodash.get'

const EditClub = () => {
  const { osid, id } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const [club, setClub] = useState(null)
  const status = {
    fetch: useStatus(acts.FETCH_CLUB),
    edit: useStatus(acts.EDIT_CLUB),
  }


  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.FETCH_CLUB))
      dispatch(clearStatus(acts.EDIT_CLUB))
    }
  }, [])

  useEffect(() => {
    dispatch(fetchClub(osid, id))
      .then(c => setClub(c))
  }, [osid, id])

  useStatusMsg(status.edit, {
    pending: 'Saving...',
    error: 'Failed to save club',
    success: res => {
      return (
        <>
          Club edited. <a href={paths.org.CLUB(osid, res.club.id)}>View</a>
        </>
      )
    },
  })

  const saveClub = () => {
    // check if required fields are present, if not, message
    const missing = ['name', 'description', 'cover', 'sid'].filter(k => {
      if (!club[k]) {
        return true
      }
      return false
    })
    if (missing.length > 0) {
      const errmsg = `${missing.join(', ')} ${missing.length > 1 ? 'are' : 'is'} required`
      message.error(`${errmsg.charAt(0).toUpperCase()}${errmsg.substr(1)}`)
      return 
    }
    // convert club.sponsors to an array of ids
    const sponsors = club.sponsors.map(spn => {
      // spn can be either a club-sponsor model or a sponsor model
      return spn.sponsor ? spn.sponsor.id : spn.id
    })

    // convert club.venues to an array of ids
    const venues = club.venues.map(venue => venue.id)

    // club benefits can be a string id, an object, or null
    const benefit = typeof club.benefit === 'string' ? club.benefit : get(club.benefit, 'id')
    // filter out keys that cannot be edited to reduce amount of data sent to server for certain clubs
    const updatedClub = { ...club, sponsors, benefit, venues }
    const allowedKeys = Object.keys(initState)
    Object.keys(updatedClub).map(key => {
      if (!allowedKeys.includes(key)) {
        delete updatedClub[key]
      }
    })
    dispatch(editClub(osid, id, updatedClub))
      .then(c => history.push(paths.org.CLUB(osid, c.sid)))
      .catch(() => {})
  }

  return (
    <>
      <FocusHeader
        title="Edit a club"
        actions={<Cta onClick={saveClub}>Save club</Cta>}
      />
      <FocusContainer style={{paddingTop: '20px', paddingBottom: '20px'}}>
        {(status.fetch.pending || !club) && <center><LoadingOutlined /></center>}
        {club && <ClubEditor club={club} onChange={c => setClub(c)} />}
      </FocusContainer>
    </>
  )
}

export default EditClub
