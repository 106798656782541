import { useEffect, useState, createContext } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { fetchAllConsts } from '../actions/admin'
import { useDispatch } from 'react-redux'

export const ConstsContext = createContext()

const ConstsProvider = ({ children }) => {
  const dispatch = useDispatch()
  const [consts, setConsts] = useState(null)

  useEffect(() => {
    dispatch(fetchAllConsts())
      .then(c => setConsts(c))
  }, [])

  if (!consts) {
    return (
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
        <LoadingOutlined />
      </div>
    )
  }

  return (
    <ConstsContext.Provider value={consts}>
      {children}
    </ConstsContext.Provider>
  )
}

export default ConstsProvider
