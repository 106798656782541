import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { message, Button } from 'antd'
import { useStatus, useStatusMsg } from '../../reducers'
import { createLocation, updateLocation } from '../../actions/location'
import { FocusHeader } from '../../components/header'
import { Focus as FocusContainer } from '../../components/containers'
import { Cta } from '../../components/common'
import { clearStatus } from '../../actions/status'
import { paths, actions as acts } from '../../constants'
import LocationEditor from './LocationEditor'
import { fetchLocation } from '../../actions/location'

const EditLocation = () => {
  const { osid, id } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const [location, setLocation] = useState()
  const status = useStatus(acts.UPDATE_LOCATION)

  useEffect(() => {
    dispatch(fetchLocation(osid, id)).then(loc => setLocation(loc))
    return () => {
      dispatch(clearStatus(acts.UPDATE_LOCATION))
    }
  }, [])

  useStatusMsg(status, {
    pending: 'Editing...',
    error: 'Failed to edit location',
    success: res => {
      return (
        <>
          Location edited. <a href={paths.org.LOCATION(osid, res.id)}>View</a>
        </>
      )
    },
  })

  const missingFields = () => {
    const missing = ['name', 'addressLine1', 'country', 'city', 'lat', 'lon'].filter(k => {
      if (!location[k]) {
        return true
      }
      return false
    })
    if (missing.length > 0) {
      const msg = `${missing.join(', ')} ${missing.length > 1 ? 'are' : 'is'} required`
      message.error(`${msg.charAt(0).toUpperCase()}${msg.substr(1)}`)
      return true
    }
    return false
  }

  const editLocation = () => {
    if (missingFields()) {
      return
    }

    dispatch(updateLocation(osid, id, location)).then(loc =>
      history.push(paths.org.LOCATION(osid, loc.id)),
    )
  }

  return (
    <>
      <FocusHeader
        title="Edit location"
        actions={
          <Cta disabled={status.pending ? true : false} onClick={editLocation}>
            {status.pending ? 'Saving location...' : 'Save location'}
          </Cta>
        }
      />
      <FocusContainer style={{ paddingTop: '20px', paddingBottom: '20px' }}>
        <LocationEditor location={location} onChange={location => setLocation(location)} />
      </FocusContainer>
    </>
  )
}

export default EditLocation
