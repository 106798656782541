import { descConst } from "./util";

export const stockTypes = {
  ...descConst('UNLIMITED', 'Unlimited', ''),
  ...descConst('LIMITED', 'Limited', ''),
}

export const stockChangeTypes = {
  ...descConst('INCREASE', 'Increase', ''),
  ...descConst('DECREASE', 'Decrease', ''),
  ...descConst('PURCHASE', 'Purchase', ''),
}

export const types = {
  ...descConst('ONSITE', 'Onsite', ''),
  ...descConst('OFFSITE', 'Offsite', ''),
  ...descConst('WALMART', 'Walmart', ''),
}

// product types which can contain variants
export const variantProductTypes = [types.ONSITE, types.OFFSITE]
