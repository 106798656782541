import React, { useContext, useEffect, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { ConstsContext } from '../../../contexts/consts'
import { useDispatch } from 'react-redux'
import { PlusOutlined, EllipsisOutlined, LoadingOutlined } from '@ant-design/icons'
import { Modal, Form, Input, InputNumber, Menu, Dropdown, Table, Tag } from 'antd'
import { useStatus, useStatusMsg } from '../../../reducers'
import { Link, useParams, useHistory } from 'react-router-dom'
import { clearStatus } from '../../../actions/status'
import {
  updateUserPackageItem,
  countUserPackageItemUses,
  fetchUserPackage,
} from '../../../actions/admin'
import { Button, Noner, ID, Title } from '../../../components/common'
import Attr from '../../../components/Attr'
import { AdminOrgLink } from '../../../components/org'
import { AdminVenueLink } from '../../../components/venue'
import { AdminUserLink } from '../../../components/user'
import { AdminProgramLink } from '../../../components/program'
import { UserPackageInvoiceLink, RedemptionTerm } from '../../../components/package'
import { ConstSelect } from '../../../components/consts'
import { CancelBookingModal, UserBookings } from '../Users/User'
import { actions as acts } from '../../../constants'

const userPackageItemUsesCols = () => [
  {
    title: 'Program',
    dataIndex: ['booking', 'program'],
    key: ['booking', 'id'],
    render: (val, record) => {
      return (
        <AdminProgramLink program={val} />
      )
    },
  },
  {
    title: 'Program',
    dataIndex: ['booking', 'program'],
    key: ['booking', 'id'],
    render: (val, record) => {
      return (
        <AdminProgramLink program={val} />
      )
    },
  },
]

// expects uses.booking.program, and beneficiaryGroup to be expanded
// only expects this to be called on items of type PROGRAM or BENEFICIARY_GROUP
const UserPackageItemUses = ({ item, onCancelBooking }) => {
  const { packageItem: piconsts } = useContext(ConstsContext)
  const [cancelingBooking, setCancelingBooking] = useState(null)

  if (item.type === piconsts.types.PROGRAM) {
    const bookings = item.uses.map(u => u.booking)
    return (
      <>
        <UserBookings bookings={bookings} onCancel={booking => setCancelingBooking(booking)}/>
        { cancelingBooking &&
          <CancelBookingModal
            user={cancelingBooking.purchaser}
            booking={cancelingBooking}
            complete={() => setCancelingBooking(null)}
            showOnlyCurrentBooking
          />
        }
      </>
    )
  }

  if (item.type === piconsts.types.BENEFICIARY_GROUP) {
    return (
      <>
        <div>{item.beneficiaryGroup?.name}</div>
        <Attr name="Beneficiary group user ID">
          <small><pre>{item.beneficiaryGroupUse?.beneficiaryGroupUser?.id}</pre></small>
        </Attr>
      </>
    )
  }

  return null
}

const ItemUsage = ({ item }) => {
  const dispatch = useDispatch()
  const [uses, setUses] = useState(null)
  const status = useStatus(acts.COUNT_USER_PACKAGE_ITEM_USES)
  useStatusMsg(status, { error: e => `Error: ${e}` })

  useEffect(() => {
    if (item.type === piconsts.types.PROGRAM) {
      dispatch(countUserPackageItemUses({ userPackageId: item.userPackage, userPackageItemId: item.id }))
        .then(res => setUses(res.count))
    }
  }, [])

  const { packageItem: piconsts } = useContext(ConstsContext)
  switch(item.type) {
    case piconsts.types.PROGRAM: {
      return status.success ? `${uses} use${uses === 1 ? '': 's'}` : 'Loading...'
    }
    case piconsts.types.BENEFICIARY_GROUP: {
      return <div>Fulfilled</div>
    }
    default: {
      return <Noner none="N/A" />
    }
  }
}

const UserPackageItemActionsMenu = ({ item, onEdit }) => {
  const dispatch = useDispatch()
  const handleActionsMenu = e => {
    if (e.key === 'edit' && typeof onEdit === 'function') {
      onEdit(item)
    }
  }

  return (
    <Menu onClick={handleActionsMenu}>
      <Menu.Item key="edit" onClick={e => e.domEvent.stopPropagation()}>Edit</Menu.Item>
    </Menu>
  )
}

const userPackageItemCols = ({ itemTypes, onEdit }) => [
  Table.EXPAND_COLUMN,
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    render: (val, record) => {
      if (!val) {
        return <Noner none="No type" />
      }
      return (
        <Tag>{val}</Tag>
      )
    },
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
  },
  {
    title: 'Usage',
    dataIndex: 'uses',
    key: 'uses',
    render: (val, record) => (
      <ItemUsage item={record} />
    ),
  },
  {
    title: 'Actions',
    key: 'id',
    width: 20,
    render: (val, record) => {
      return (
        <div style={{textAlign: 'center'}}>
          <Dropdown
            overlay={<UserPackageItemActionsMenu item={record} onEdit={onEdit} />}
            trigger={['click']}
          >
            <Button onClick={e => e.stopPropagation()}>
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        </div>
      )
    },
  },
]

// allow deletiong of an item with no uses (beneficiary or program use)
const EditUserPackageItemModal = ({ item, complete }) => {
  const completer = typeof complete === 'function' ? complete : () => {}
  const dispatch = useDispatch()
  const [description, setDescription] = useState(item.description)
  const [quantity, setQuantity] = useState(item.quantity)
  const status = useStatus(acts.UPDATE_USER_PACKAGE_ITEM)
  useStatusMsg(status, { error: e => `Error: ${e}` })

  useEffect(() => () => dispatch(clearStatus(acts.UPDATE_USER_PACKAGE_ITEM)), [])

  const onOk = () => {
    dispatch(updateUserPackageItem({
      userPackageId: item.userPackage,
      userPackageItemId: item.id,
      update: { description, quantity },
    }))
    .then(completer)
  }

  return (
    <Modal
      title="Update user package item"
      open={true}
      okText="Save"
      okButtonProps={{ disabled: Boolean(status.pending), loading: Boolean(status.pending) }}
      onCancel={() => completer()}
      onOk={onOk}
    >
      <Form layout="vertical">
        <Form.Item required name="description" label="Description" initialValue={description}>
          <Input
            placeholder="Description"
            onChange={e => setDescription(e.target.value)}
          />
        </Form.Item>
        <Form.Item name="quantity" label="Quantity" initialValue={quantity}>
          <InputNumber type="number" precision={0} onChange={e => setQuantity(e)} min={0} step={1} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

//const AddUserPackageItemModal = ({ complete }) => {
//  const completer = typeof complete === 'function' ? complete : () => {}
//  const dispatch = useDispatch()
//  const [type, setType] = useState(null)
//  const [description, setDescription] = useState(null)
//  const [quantity, setQuantity] = useState(null)
//  const status = useStatus(acts.UPDATE_USER_PACKAGE_ITEM)
//
//  const onOk = () => {
//  }
//
//  return (
//    <Modal
//      title="Add user package item"
//      open={true}
//      okText="Save"
//      okButtonProps={{ disabled: Boolean(status.pending), loading: Boolean(status.pending) }}
//      onCancel={() => completer()}
//      onOk={onOk}
//    >
//      <Form layout="vertical">
//        <Form.Item required name="type" label="Type" initialValue={type}>
//          <ConstSelect
//            name="packageItem"
//            constKey="types"
//            value={type}
//            onChange={t => setType(t)}
//          />
//        </Form.Item>
//        <Form.Item required name="description" label="Description" initialValue={description}>
//          <Input
//            placeholder="Description"
//            onChange={e => setDescription(e.target.value)}
//          />
//        </Form.Item>
//        <Form.Item name="quantity" label="Quantity" initialValue={quantity}>
//          <InputNumber type="number" precision={0} onChange={e => setQuantity(e)} min={0} step={1} />
//        </Form.Item>
//      </Form>
//    </Modal>
//  )
//}

// expects uses.booking.program, query, and beneficiaryGroup to be expanded
const UserPackageItems = ({ items, onCancelBooking, onAddItem, onItemUpdated }) => {
  const theme = useContext(ThemeContext)
  const [editing, setEditing] = useState(null)
  const [adding, setAdding] = useState(false)
  const { packageItem: piconsts } = useContext(ConstsContext)
  const expandableTypes = [piconsts.types.PROGRAM, piconsts.types.BENEFICIARY_GROUP]

  return (
    <>
      {/*
      <div style={{display: 'flex', justifyContent: 'end', marginBottom: theme.spacing[2] }}>
        <Button onClick={() => setAdding(true)}><PlusOutlined /> Add item</Button>
      </div>
      */}
      <Table
        size="small"
        columns={userPackageItemCols({ itemTypes: piconsts.types, onEdit: item => setEditing(item) })}
        dataSource={items}
        pagination={false}
        expandable={{
          expandedRowRender: record => {
            return (
              <UserPackageItemUses item={record} onBookingCancel={onCancelBooking} />
            )
          },
          rowExpandable: record =>  expandableTypes.includes(record.type),
          expandRowByClick: true,
        }}
      />
      {editing &&
         <EditUserPackageItemModal
           item={editing}
           complete={updatedItem => {
             if (typeof onItemUpdated === 'function') {
               onItemUpdated(updatedItem)
             }
             setEditing(null)
           }}
         />
      }
      {/* adding &&
         <AddUserPackageItemModal
           complete={newItem => {
             if (typeof onAddItem === 'function') {
               onAddItem(newItem)
             }
             setAdding(false)
           }}
         />
     */}
    </>
  )
}

export const UserPackage = () => {
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const { userPackageId } = useParams()
  const [userPackage, setUserPackage] = useState(null)
  const status = {
    fetch: useStatus(acts.FETCH_USER_PACKAGE),
  }

  useStatusMsg(status.fetch, { error: e => `Error: ${e}` })

  useEffect(() => {
    dispatch(fetchUserPackage({ userPackageId }))
      .then(res => setUserPackage(res))
  }, [])

  if (!userPackage) {
    return <LoadingOutlined />
  }

  const onCancelBooking = () => {
    // refresh package to get latest booking statuses
    dispatch(fetchUserPackage({ userPackageId }))
      .then(res => setUserPackage(res))
  }

  const onItemUpdated = () => {
    // refresh package
    dispatch(fetchUserPackage({ userPackageId }))
      .then(res => setUserPackage(res))
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          marginTop: theme.spacing[3],
          marginBottom: theme.spacing[3],
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Title>{userPackage.name}</Title>
      </div>
      <Attr name="ID">
        <ID>{userPackage.id}</ID>
      </Attr>
      <Attr name="Description">
        <div>{userPackage.description}</div>
      </Attr>
      <Attr name="User">
        <AdminUserLink user={userPackage.user} />
      </Attr>
      <Attr name="Status">
        <Tag>{userPackage.status}</Tag>
      </Attr>
      <Attr name="Invoice">
        <UserPackageInvoiceLink orderItems={userPackage.orderItems}>View invoice</UserPackageInvoiceLink>
      </Attr>
      <Attr name="Org">
        <AdminOrgLink org={userPackage.org}>
          <Noner cond={userPackage.org}>{userPackage.org?.name}</Noner>
        </AdminOrgLink>
      </Attr>
      <Attr name="Venue">
        <AdminVenueLink venue={userPackage.venue}>
          <Noner cond={userPackage.venue}>{userPackage.venue?.name}</Noner>
        </AdminVenueLink>
      </Attr>
      <Attr name="Redemption term">
        <RedemptionTerm {...userPackage} />
      </Attr>
      <Attr name="Items & Usage">
        <UserPackageItems
          items={userPackage.userPackageItems}
          onCancelBooking={onCancelBooking}
          onItemUpdated={onItemUpdated}
        />
      </Attr>
    </div>
  )
}

