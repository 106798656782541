import React from 'react'
import styled from 'styled-components'
import bLogo from '../assets/b-logo.png'
import { formatInitials } from '../util/user'

const ProfileInitials = styled.div`
  border-radius: 50%;
  background-color: #dee7e4;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ size }) => {
    switch (size) {
      case "sm":
        return `
          height: 30px;
          width: 30px;
          line-height: 30px;
          font-size: 0.8rem;
        `
      case "md":
        return `
          height: 38px;
          width: 38px;
          line-height: 38px;
          font-size: 1rem;
        `
      case "lg":
        return `
          height: 45px;
          width: 45px;
          line-height: 45px;
          font-size: 1.5rem;
        `
    }
  }}
`

export const BTLAvatar = ({ className, style, size = "md" }) => (
  <ProfileInitials
    className={className}
    size={size}
    style={{
      ...style,
      backgroundImage: `url(${bLogo})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      borderRadius: 0,
      backgroundColor: 'white',
    }}
  />
)

export const Avatar = ({ className, user, style, size = "md" }) => (
  <ProfileInitials className={className} style={style} size={size}>
    {formatInitials(user)}
  </ProfileInitials>
)

export default Avatar
