import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useHistory, Link } from 'react-router-dom'
import { Button } from 'antd'
import { Centered as Container } from '../../components/containers'
import Gutter from '../../components/Gutter'
import { useDispatch } from 'react-redux'
import { useStatus } from '../../reducers'
import { fetchOrgs } from '../../actions/org'
import { logout } from '../../actions/user'
import { paths, actions as acts } from '../../constants'

const OrgsContainer = styled.div`
  padding: 1em;
  margin: 1em 2em;
  background-color: white;
  min-width: 400px;
  text-align: left;
  border-radius: 6px;
  border: 1px hsl(0deg 0% 0% / 0.075);
  box-shadow:
    0 1px 1px hsl(0deg 0% 0% / 0.075),
    0 2px 2px hsl(0deg 0% 0% / 0.075),
    0 4px 4px hsl(0deg 0% 0% / 0.075),
    0 8px 8px hsl(0deg 0% 0% / 0.075),
    0 16px 16px hsl(0deg 0% 0% / 0.075)
  ;
`
const Orgs = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [orgs, setOrgs] = useState([])
  const status = useStatus(acts.FETCH_ORGS)
  
  useEffect(() => {
    dispatch(fetchOrgs())
      .then(o => setOrgs(o))
  }, [])

  if (status.pending || !status.success) {
    return 'Loading...'
  }

  const onLogout = () => {
    dispatch(logout())
      .then(() => history.push('/'))
  }

  const names = orgs.map(o => (
    <div key={o.id}>
      <Link to={paths.org.HOME(o.sid)}>{o.name}</Link>
    </div>
  ))

  // TODO allow a search of orgs (for the admin), or maybe just show all orgs in system for an admin?
  return (
    <Gutter>
      <div style={{display: 'flex', padding: '10px 0px'}}>
        <div style={{flexGrow: 1}}></div>
        <Button size="small" type="secondary" onClick={onLogout}>Logout</Button>
      </div>
      <Container>
        <h2>Organizations</h2>
        <OrgsContainer>
          {names.length > 0 ? names : <i>You are not part of any organizations</i>}
        </OrgsContainer>
      </Container>
    </Gutter>
  )
}

export default Orgs
