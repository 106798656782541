import React from 'react'
import styled from 'styled-components'

export const Centered = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  width: 100vw;
  height: 100vh;
  padding: 0 0 2% 0;
`

export const Splash = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`

const Img = styled.img`
  margin-left: auto;
  object-fit: cover;
`
const ImgContainer = styled.div`
  object-fit: cover;
`
export const SplashImage = ({ ...props }) => {
  return (
    <ImgContainer>
      <Img {...props} />
    </ImgContainer>
  )
}

export const Focus = styled.div`
  padding: 0 8px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
`
