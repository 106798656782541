import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { paths } from '../../../constants'
import Attr from '../../../components/Attr'
import { formatDateTimeTz } from '../../../util/time'
import { Table } from 'antd'

const columns = [
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
    sorter: (a, b) => a.name.localeCompare(b.name),
    render: (val, record) => (
      <Link to={paths.admin.USER(val.id)}>{val.name}</Link>
    )
  },
  {
    title: 'Invoice',
    dataIndex: 'invoice',
    key: 'invoice',
    render: (val, record) => (
      <div>{val.id}</div>
    )
  },
  {
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    render: (val, record) => (
      <div>{formatDateTimeTz(val)}</div>
    )
  },
]

const Uses = ({ promoCode }) => {
  return (
    <>
      <Attr name="Total uses">
        {promoCode.uses.length}
      </Attr>
      <Attr name="Uses">
        <Table
          size="middle"
          columns={columns}
          dataSource={promoCode.uses}
          pagination={false}
        />
      </Attr>
    </>
  )
}

export default Uses
