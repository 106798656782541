import React from 'react'
import InternalTagLabel from './admin/InternalTagLabel'
import Tag from './Tag'

const TagList = ({ tags }) => {
  if (tags && tags.length > 0) {
    const mapped = tags.map(t => (
      <Tag key={t.id}><InternalTagLabel {...t} /></Tag>
    ))
    return (
      <div>{mapped}</div>
    )
  }
  return <div style={{fontStyle: 'italic'}}>None</div>
}

export default TagList
