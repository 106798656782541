module.exports = {
  apiUrl: process.env.REACT_APP_API_URL || 'http://localhost:5000',
  siteUrl: process.env.REACT_APP_SITE_URL || 'http://localhost:3001',
  mainAppUrl: process.env.REACT_APP_MAIN_APP_URL || 'http://localhost:3000',
  stripeDashboardUrl:
    process.env.REACT_APP_STRIPE_DASHBOARD_URL ||
    'https://dashboard.stripe.com/test',
  stripePublishableKey:
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ||
    'pk_test_PpCK9DI3ygU9xRh4wygxOQJo00EDHwDiDT',
  s3AssetBucket: process.env.REACT_APP_S3_ASSETS_BUCKET || 'breakthelove-development-assets-1',
  s3AssetBucketRegion: process.env.REACT_APP_S3_ASSETS_BUCKET_REGION || 'us-east-2',
  tinyMceApiKey: process.env.REACT_APP_TINYMCE_API_KEY,
  mapboxAccessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || '',
  mapboxDefaultStyle: process.env.REACT_APP_MAPBOX_DEFAULT_STYLE || 'mapbox://styles/mapbox/streets-v9',
  sendbirdAppId: process.env.REACT_APP_SENDBIRD_APP_ID,
  bosUrl: process.env.REACT_APP_BOS_URL || 'http://localhost:3000',
}
