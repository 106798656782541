import {
  programTypes,
  programSubtypes,
  bookingStatusTypes,
  pricingTypes,
} from '../constants/program'
import { cancellationPolicies } from '../constants/index'
import { parseTime, tzByName } from './time'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import * as userUtils from './user'
dayjs.extend(utc)
dayjs.extend(timezone)

export const pricingTypeLabel = ({ pricingType }) => {
  if (pricingType) {
    if (pricingTypes[pricingType]) {
      return pricingTypes[pricingType].label
    }
  }
}

export const typeLabel = ({ type }) => type && programTypes[type].label

export const cancellationPolicyLabel = (type) => type && cancellationPolicies[type].label

export const subtypeLabel = ({ type, subtype }) => (type && subtype) && programSubtypes[type][subtype].label


const formatSummaryDate = (program, dateFormat) =>
  dayjs.utc(program.date).tz('UTC').format(dateFormat)


export const dateTimeSummary = program => {
  let desc;
  if (program && program.date) {
    desc = `${dateSummary(program)} ${timeSummary(program)}`
  }
  return desc
}

export const dateSummary = program => {
  let desc;
  if (program && program.date) {
    desc = `${formatSummaryDate(program, 'D MMM YYYY')}`
  }
  return desc
}

export const timeSummary = program => {
  let desc;
  const tz = tzByName(program.tz)
  const tzLabel = tz ? tz.abbreviation : ''
  if (program && program.startTime && program.endTime) {
    desc = `${formatTime(program, 'LT')} ${tzLabel}`
  }
  return desc
}

export const formatDate = (program, weekdayFormat = 'dddd', dateFormat = 'MMMM DD') => {
  let desc;
  if (program && program.date) {
    desc = formatSummaryDate(program, dateFormat)
  }
  return desc
}


export const formatTime = (program, timeFormat = 'LT') => {
  if (program && program.startTime && program.endTime) {
    return `${parseTime(program.startTime).format(timeFormat)} - ${parseTime(program.endTime).format(timeFormat)}`
  }
}

export const typeDescription = program => {
  if (program) {
    return `${subtypeLabel(program)} ${typeLabel(program)}`
  }
}

const bookedStatuses = [
  bookingStatusTypes.CONFIRMED.name,
  bookingStatusTypes.BOOKED.name,
]

// given a booking, returns whether the book is completed and booked or not
export const isBooked = booking => bookedStatuses.includes(booking.status)
export const isCanceled = booking => booking.status === bookingStatusTypes.CANCELED.name

// given an array of bookings returns the number that are booked
export const countBookings = bookings => Array.isArray(bookings) ? bookings.filter(isBooked).length : 0

// expects booking.participant to be expanded if it exists
export const participantNameFromBooking = booking => {
  if (booking.participant) {
    return userUtils.formatName(booking.participant)
  }
  return [booking.participantFirstName, booking.participantLastName].filter(Boolean).join(' ')
}

// expects booking.participant to be expanded if it exists
export const participantEmailFromBooking = booking => {
  if (booking.participant) {
    return booking.participant.email
  }
  return booking.participantEmail
}

export const locationName = program => {
  return program.location?.address?.name
}

export default {
  typeLabel,
  subtypeLabel,
  dateSummary,
  timeSummary,
  dateTimeSummary,
  formatDate,
  formatTime,
  typeDescription,
  isBooked,
  isCanceled,
  pricingTypeLabel,
  countBookings,
  participantNameFromBooking,
  participantEmailFromBooking,
  locationName,
}
