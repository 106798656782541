import React, { useState, useEffect, useReducer } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Input } from 'antd'
import { InputGroup, Title, Desc } from './step'
import { typeLabel } from '../../../util/program'
import { lower } from '../../../util/string'
import Attr from '../../../components/Attr'

const PlayerInput = styled(Input)` 
  .ant-input-wrapper.ant-input-group {
    width: ${props => props.theme.width[5]}
  }
`
const initState = ({ minParticipants, maxParticipants }) => ({
  minParticipants,
  maxParticipants,
})

const reducer = (state, action) => ({ ...state, ...action })

const Players = ({ onChange, clinic }) => {
  const dispatch = useDispatch()
  const [state, dp] = useReducer(reducer, initState(clinic))

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(state)
    }
  }, [state])

  return (
    <>
      <InputGroup>
        <Title>Number of participants</Title>
        <Desc>
          Give the minimum and maximum number of participants this {lower(typeLabel(clinic))} can accommodate
        </Desc>
        <Attr name="Min">
          <PlayerInput
            type="number"
            size="large"
            addonAfter="min participants"
            defaultValue={state.minParticipants}
            onChange={e => dp({ minParticipants: +e.target.value })}
          />
        </Attr>
        <Attr name="Max">
          <PlayerInput
            type="number"
            size="large"
            addonAfter="max participants"
            defaultValue={state.maxParticipants}
            onChange={e => dp({ maxParticipants: +e.target.value })}
          />
        </Attr>
      </InputGroup>
    </>
  )
}

const Viewer = ({ program }) => {
  return (
    <>
      <Attr name="Min"><div>{program.minParticipants}</div></Attr>
      <Attr name="Max"><div>{program.maxParticipants}</div></Attr>
    </>
  )
}

const step = Players

const complete = ({ minParticipants, maxParticipants }) => {
  return minParticipants !== null && maxParticipants && maxParticipants >= minParticipants;
}

const applicable = () => true

export default { step, Viewer, complete, applicable }
