import { useEffect, useState } from 'react'
import { Select } from 'antd'
import { Noner } from './common'
import { pricingTypes } from '../constants/resource'

const { Option } = Select

export const PricingTypeSelect = ({ value, onChange, ...props }) => {
  const [val, setVal] = useState(value)
  const changer = typeof onChange === 'function' ? onChange : () => {}

  const opts = Object.keys(pricingTypes).map(k => {
    const pt = pricingTypes[k]
    return (
      <Option key={pt.name} value={pt.name} data-name={pt.label}>
        <div>{pt.label}</div>
      </Option>
    )
  })

  const handleChange = name => {
    setVal(name)
    changer(name)
  }

  useEffect(() => {
    setVal(value)
  }, [value])

  return (
    <Select
      value={val}
      onChange={handleChange}
      placeholder="Select a pricing type"
      {...props}
    >
      {opts}
    </Select>
  )
}

export const PricingTypeDisplay = ({ pricingType, none="No pricing type" }) => {
  if (!pricingType || !pricingTypes[pricingType]) {
    return <Noner none={none}></Noner>
  }
  return (
    <span>{pricingTypes[pricingType].label}</span>
  )
}
