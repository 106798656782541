import { urls, actions as acts } from '../constants'
import fetchData from '../util/fetch'

export const fetchClubAttributes = osid => async dispatch => {
  dispatch({ type: acts.FETCH_CLUB_ATTRIBUTES_PENDING, payload: { osid } })
  try {
    const attrs = await fetchData({ url: urls.org.CLUB_ATTRIBUTES(osid) })
    dispatch({ type: acts.FETCH_CLUB_ATTRIBUTES_SUCCESS, payload: attrs })
    return attrs
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.FETCH_CLUB_ATTRIBUTES_FAILURE, payload: err })
  }
}
