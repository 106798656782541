import React, { useEffect, useState, useReducer } from 'react'
import { Link } from 'react-router-dom'
import { ExportOutlined, EllipsisOutlined, EditOutlined } from '@ant-design/icons'
import { Menu, Dropdown, Breadcrumb, Table } from 'antd'
import { useDispatch } from 'react-redux'
import Attr from '../../../components/Attr'
import { Button } from '../../../components/common'
import * as adminActs from '../../../actions/admin'
import { clearStatus } from '../../../actions/status'
import { useStatus, useStatusMsg } from '../../../reducers'
import { paths, actions as acts } from '../../../constants'
import { formatDateTimeTz } from '../../../util/time'
import { EditNotesModal } from './UserPermit'
import moment from 'moment-timezone'
import { downloadDataAsFile } from '../../../util/download'

const handleActionMenu = (permit, actions) => e => {
  if (e.key === 'edit-notes') {
    actions.setEditingNotes(permit)
  }
}

const actionMenu = (permit, actions) => (
  <Menu onClick={handleActionMenu(permit, actions)}>
    <Menu.Item key="edit-notes"><EditOutlined /> Edit notes</Menu.Item>
  </Menu>
)

const cols = ({ setEditingNotes }) => ([
  {
    title: 'Application info',
    dataIndex: 'appFirstName',
    key: 'appFirstName',
    render: (val, record) => (
      <div>
        <Link to={paths.admin.USER_PERMIT(record.id)}>
          {`${record.appFirstName} ${record.appLastName}`}
          <div>{record.permitInfo.name}</div>
        </Link>
      </div>
    ),
  },
  {
    title: 'Application status',
    dataIndex: 'applicationStatus',
    key: 'applicationStatus',
  },
  {
    title: 'Payment status',
    dataIndex: 'paymentStatus',
    key: 'paymentStatus',
  },
  {
    title: 'Scraper status',
    dataIndex: 'scraperStatus',
    key: 'scraperStatus',
  },
  {
    title: 'Fulfillment status',
    dataIndex: 'fulfillmentStatus',
    key: 'fulfillmentStatus',
  },
  {
    title: 'Notes',
    dataIndex: 'internalNotes',
    key: 'internalNotes',
  },
  {
    title: 'Purchased by',
    dataIndex: 'createdAt',
    key: 'createdAt',
    defaultSortOrder: 'descend',
    sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    render: (val, record) => (
      <div>
        <div>
          <Link to={paths.admin.USER(record.user.id)}>{record.user.name}</Link>
        </div>
        <small>{formatDateTimeTz(val)}</small>
      </div>
    ),
  },
  {
    title: '',
    dataIndex: 'id',
    key: 'id',
    render: (val, record) => {
      const actions = { setEditingNotes }
      return (
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <Dropdown overlay={actionMenu(record, actions)} trigger={['click']}>
            <Button><EllipsisOutlined style={{fontSize: '16px'}}/></Button>
          </Dropdown>
        </div>
      )
    },
  },
])

export const UserPermitsTable = ({ permits, actions, ...props }) => {
  return (
    <Table
      rowKey="id"
      columns={cols(actions)}
      dataSource={permits}
      pagination={false}
      {...props}
    />
  )
}

const initState = []
const reducer = (state, action) => {
  switch(action.type) {
    case 'SET': {
      return action.payload
    }
    case 'UPDATE': {
      const next = [...state]
      const idx = next.findIndex(p => p.id === action.payload.id)
      if (idx !== -1) {
        next[idx] = action.payload
      }
      return next
    }
  }
}

const UserPermits = () => {
  const dispatch = useDispatch()
  const [permits, dp] = useReducer(reducer, initState)
  const [editingNotes, setEditingNotes] = useState(null)
  const status = useStatus(acts.FETCH_ALL_USER_PERMITS)

  const actions = {
    setEditingNotes,
  }

  useEffect(() => {
    dispatch(adminActs.fetchAllUserPermits())
      .then(ps => dp({ type: 'SET', payload: ps }))

    return () => {
      dispatch(clearStatus(acts.FETCH_ALL_USER_PERMITS))
    }
  }, [])

  useStatusMsg(status, {
    error: 'Failed to fetch user permits',
  })

  const onClickExport = () => {
    dispatch(adminActs.downloadUserPermitsCsv())
      .then(data => {
        downloadDataAsFile({
          data,
          filename: `user-permits-${moment(new Date()).format('YYYY-M-DTHH:mm')}.csv`,
          filetype: 'text/csv;charset=utf-8;',
        })
      })
  }

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={paths.admin.PERMITS()}>Permits</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          User permits
        </Breadcrumb.Item>
      </Breadcrumb>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <h1 style={{marginRight: '.5em'}}>User Permits</h1>
        <Button onClick={onClickExport}>
          Export <ExportOutlined style={{fontSize: '14px'}} />
        </Button>
      </div>
      <UserPermitsTable permits={permits} actions={actions} />
      {editingNotes &&
        <EditNotesModal
          permit={editingNotes}
          complete={updatedPermit => {
            if (updatedPermit) {
              dp({ type: 'UPDATE', payload: updatedPermit })
            }
            setEditingNotes(null)
          }}
        />
      }
    </div>
  )
}

export default UserPermits
