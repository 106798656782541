import { keyedConst as kc } from './util'

export const types = {
  ...kc('BOOLEAN'),
  ...kc('SELECT'),
  ...kc('DATE'),
  ...kc('NUMBER'),
  ...kc('EMAIL'),
  ...kc('OBJECT'),
  ...kc('TEXT'),
  ...kc('RICHTEXT'),
}
