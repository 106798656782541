import React, { useEffect, useState } from 'react'
import { message, Button } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { LoadingOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { ProductEditor } from './editors'
import { useStatus, useStatusMsg } from '../../reducers'
import { editProduct, fetchProduct } from '../../actions/org'
import { clearStatus } from '../../actions/status'
import { FocusHeader } from '../../components/header'
import { Cta } from '../../components/common'
import { Focus as FocusContainer } from '../../components/containers'
import { paths, actions as acts } from '../../constants'
import * as productConsts from '../../constants/product'

const EditProduct = () => {
  const { osid, id } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const [product, setProduct] = useState(null)
  const status = {
    fetch: useStatus(acts.FETCH_PRODUCT),
    edit: useStatus(acts.EDIT_PRODUCT),
  }


  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.FETCH_PRODUCT))
      dispatch(clearStatus(acts.EDIT_PRODUCT))
    }
  }, [])

  useEffect(() => {
    dispatch(fetchProduct({ osid, productId: id }))
      .then(c => setProduct(c))
  }, [osid, id])

  useStatusMsg(status.edit, {
    pending: 'Saving...',
    error: 'Failed to save product',
    success: res => {
      return (
        <>
          Product edited. <a href={paths.org.PRODUCT(osid, res.id)}>View</a>
        </>
      )
    },
  })

  const saveProduct = () => {
    // check if required fields are present, if not, message
    const missing = ['type', 'name'].filter(k => {
      if (!product[k]) {
        return true
      }
      return false
    })
    if (missing.length > 0) {
      const errmsg = `${missing.join(', ')} ${missing.length > 1 ? 'are' : 'is'} required`
      message.error(`${errmsg.charAt(0).toUpperCase()}${errmsg.substr(1)}`)
      return 
    }
    if (
      product.type !== productConsts.types.WALMART.name && // walmart products don't require an image
      (!product.images || (Array.isArray(product.images) && product.images.length === 0))
    ) {
      message.error('At least one product image is required')
      return
    }

    if (product.type === productConsts.types.WALMART.name && !product.walmartItemId) {
      message.error('Walmart item ID is required on Walmart products')
      return true
    }

    if (product.type === productConsts.types.ONSITE.name && typeof product.priceCents !== 'number') {
      message.error('A price is required for onsite products')
      return true
    }

    dispatch(editProduct({ osid, productId: id, ...product }))
      .then(p => history.push(paths.org.PRODUCT(osid, p.id)))
      .catch(() => {})
  }

  return (
    <>
      <FocusHeader
        title="Edit a product"
        actions={<Cta onClick={saveProduct}>Save product</Cta>}
      />
      <FocusContainer style={{paddingTop: '20px', paddingBottom: '20px'}}>
        {(status.fetch.pending || !product) && <center><LoadingOutlined /></center>}
        {product && <ProductEditor product={product} onChange={c => setProduct(c)} disableType />}
      </FocusContainer>
    </>
  )
}

export default EditProduct
