import styled from 'styled-components'

const VideoEmbedContainer = styled.div`
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
`
const VideoEmbedIframe = styled.iframe`
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
`

const VideoEmbed = ({ url, ...props }) => {
  return (
    <VideoEmbedContainer {...props}>
      <VideoEmbedIframe
        width={853}
        height={480}
        src={url}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Video Player"
      />
    </VideoEmbedContainer>
  )
}

export default VideoEmbed
