import { descConst } from './util'

export const pricingTypes = {
  ...descConst('PER_PARTICIPANT', 'Per participant', ''),
  ...descConst('PER_RESOURCE', 'Per space', ''),
}

export default {
  pricingTypes,
}
