import { useState, useEffect } from 'react'
import { message } from 'antd'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { FocusHeader } from '../../../components/header'
import { Button, Cta } from '../../../components/common'
import { Focus as FocusContainer } from '../../../components/containers'
import { createUser } from '../../../actions/admin'
import { paths, actions as acts } from '../../../constants'
import { useStatus, useStatusMsg } from '../../../reducers'
import { clearStatus } from '../../../actions/status'
import UserEditor from './UserEditor'

const NewUser = () => {
  const { osid } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const [user, setUser] = useState(null)
  const status = {
    create: useStatus(acts.CREATE_USER)
  }

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.CREATE_USER))
    }
  }, [])

  useStatusMsg(status.create, {
    pending: 'Creating...',
    error: err => {
      if (typeof err.message === 'string') {
        return err.message
      }
      return 'Failed to create user'
    },
    success: res => {
      return (
        <>
          User created. <a href={paths.admin.USER(res.id)}>View</a>
        </>
      )
    },
  })

  const missingFields = () => {
    const missing = ['email', 'type'].filter(k => {
      if (!user[k]) {
        return true
      }
      return false
    })
    if (missing.length > 0) {
      const msg = `${missing.join(', ')} ${missing.length > 1 ? 'are' : 'is'} required`
      message.error(`${msg.charAt(0).toUpperCase()}${msg.substr(1)}`)
      return true
    }
    return false
  }
  const submitUser = createAnother => () => {
    if (missingFields()) {
      return
    }
    dispatch(createUser({ osid, user }))
      .then(newUser => {
        if (createAnother) {
          setUser(null)
        } else {
          history.push(paths.admin.USER(newUser.id))
        }
      })
      .catch(() => {})
  }

  return (
    <>
      <FocusHeader
        title="Create a user"
        actions={
          <>
              <Button
                style={{marginRight: '.5em'}}
                size="small"
                disabled={status.create.pending ? true : false}
                onClick={submitUser(true)}
              >
                Submit and create another
              </Button>
              <Cta
                disabled={status.create.pending ? true : false}
                onClick={submitUser(false)}
              >
                { status.create.pending ? 'Creating user...' : 'Create user' }
              </Cta>
          </>
        }
      />
      <FocusContainer style={{paddingTop: '20px', paddingBottom: '20px'}}>
        <UserEditor user={user} onChange={u => setUser(u)} />
      </FocusContainer>
    </>
  )
}

export default NewUser
