import { v4 as uuid } from 'uuid'
import { useState, useEffect, useContext } from 'react'
import { Button as AntButton, Select, Table, InputNumber, Radio, Form, Input } from 'antd'
import { useDispatch } from 'react-redux'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons'
import { ThemeContext } from 'styled-components'
import { ConstsContext } from '../../../contexts/consts'
import { Button, Noner, DollarInput, PercentInput, Label } from '../../../components/common'
import { ConstSelect } from '../../../components/consts.jsx'
import { BulkInput } from '../../../components/bulk.jsx'
import { actions as acts } from '../../../constants'
import Attr from '../../../components/Attr'
import { Settings } from '../../../components/settings'
import { AdminVenueSelect } from '../../../components/venue'
import { filterSettings } from '../../../util/settings'
import pluralize from 'pluralize'

const { TextArea } = Input
const { Option } = Select

const initBeneficiaryGroupState = {
  name: null,
  description: null,
  status: null,
  verificationMethod: null,
  verificationName: null,
  verificationDescription: null,
  verificationFallbackMessage: null,
  verificationSettings: null,
  verificationEmails: null,
  baseDiscountPercent: null,
  baseDiscountCents: null,
  caps: null,
}

const initBenefitVenueState = {
  venue: null,
  description: null,
  status: null,
  checkinInstructions: null,
  faqSectionTitle: null,
  settings: null,
}

const initCapState = {
  type: null,
  timePeriod: null,
  maxBookingCount: null,
}

const getVerificationType = ({ verificationMethod } = {}) => {
  return verificationMethod ? 'allow-verification' : 'disallow-verification'
}

export const CapEditor = ({ cap, onChange }) => {
  cap = cap ?? initCapState
  const theme = useContext(ThemeContext)
  const changer = typeof onChange === 'function' ? onChange : () => {}

  const { type, timePeriod, maxBookingCount } = cap

  const change = update => {
    changer({ ...cap, ...update })
  }

  return (
    <div style={{display: 'flex'}}>
      <div>
        <label>Booking type</label>
        <ConstSelect
          name="cap"
          constKey="types"
          style={{minWidth: theme.width[4]}}
          onChange={v => change({ type: v })}
          value={type}

        />
      </div>
      <div>
        <label>Time period</label>
        <ConstSelect
          name="cap"
          constKey="timePeriods"
          style={{minWidth: theme.width[4]}}
          onChange={v => change({ timePeriod: v })}
          value={timePeriod}
        />
      </div>
      <div>
        <label>Max bookings</label>
        <InputNumber
          type="number"
          precision={0}
          onChange={v => change({ maxBookingCount: v })}
          min={0}
          step={1}
          value={maxBookingCount}
          style={{width: theme.width[3]}}
        />
      </div>
    </div>
  )
}

const BeneficiaryGroupCapsEditor = ({ caps, onChange }) => {
  const theme = useContext(ThemeContext)
  const changer = typeof onChange === 'function' ? onChange : () => {}

  const addCap = () => {
    const next = !Array.isArray(caps) ? [] : caps
    changer([...next, { id: `new-${uuid()}`, ...initCapState }])
  }

  const removeCap = cap => {
    changer(caps.filter(c => c.id !== cap.id))
  }

  const updateCap = cap => {
    const next = [...caps]
    const idx = next.findIndex(c => c.id === cap.id)
    if (idx !== -1) {
      next[idx] = cap
    }
    changer(next)
  }

  let elems;
  if (Array.isArray(caps) && caps.length > 0) {
    elems = caps.map(c => {
      return (
        <div
          key={c.id}
          style={{
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'flex-end', 
            marginBottom: theme.spacing[3],
          }}
        >
          <CapEditor cap={c} onChange={updated => updateCap(updated)} />
          <Button onClick={() => removeCap(c)}>Remove</Button>
        </div>
      )
    })
  }

  return (
    <div style={{marginTop: theme.spacing[2]}}>
      {elems}
      <Button onClick={() => addCap()}><PlusOutlined /> Add cap</Button>
    </div>
  )
}

const emailCols = ({ onRemove }) => ([
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
    width: 100,
    sorter: (a, b) => a.value.localeCompare(b.value),
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    width: 10,
    sorter: (a, b) => {
      return a.type.localeCompare(b.type)
    },
  },
  {
    title: 'Allowed',
    dataIndex: 'allowed',
    key: 'allowed',
    width: 10,
    sorter: (a, b) => a.allowed ? 1 : b.allowed ? -1 : 0,
    render: (val, record) => {
      return val ? 'Allowed' : 'Not allowed'
    }
  },
  {
    title: 'Actions',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    width: 10,
    render: (val, record) => {
      return (
        <AntButton
          danger
          type="text"
          onClick={() => typeof onRemove === 'function' && onRemove(record)}
        >
          Remove
        </AntButton>
      )
    },
  },
])

const useVerificationEmailSummary = verificationEmails => {
  const { beneficiaryGroupVerificationEmail } = useContext(ConstsContext)
  const { types } = beneficiaryGroupVerificationEmail

  if (!Array.isArray(verificationEmails) || verificationEmails.length === 0) {
    return '0 emails'
  }
  const numDomains = verificationEmails.filter(ve => ve.type === types.DOMAIN).length
  const numEmails = verificationEmails.filter(ve => ve.type === types.EMAIL).length
  const emailSummary = numEmails ? `${numEmails} ${pluralize('email', numEmails)}` : null
  const domainSummary = numDomains ? `${numDomains} ${pluralize('domain', numDomains)}` : null
  return [emailSummary, domainSummary].filter(Boolean).join(', ')
}

const BeneficiaryGroupVerificationEmailsEditor = ({ verificationEmails, onChange }) => {
  const theme = useContext(ThemeContext)
  const { beneficiaryGroupVerificationEmail } = useContext(ConstsContext)
  const changer = typeof onChange === 'function' ? onChange : () => {}
  const [bulkInput, setBulkInput] = useState(null) // inputted string
  const [bulkParts, setBulkParts] = useState(null) // inputted string split into parts
  const [bulkType, setBulkType] = useState(beneficiaryGroupVerificationEmail.types.EMAIL)
  const [bulkAllowed, setBulkAllowed] = useState(true)
  const verificationEmailSummary = useVerificationEmailSummary(verificationEmails)

  const hasParts = Array.isArray(bulkParts) && bulkParts.length > 0
  const hasVerificationEmails = Array.isArray(verificationEmails) && verificationEmails.length > 0

  const resetInputs = () => {
    setBulkInput(null)
    setBulkParts(null)
    setBulkType(beneficiaryGroupVerificationEmail.types.EMAIL)
    setBulkAllowed(true)
  }

  const onAdd = () =>  {
    if (!hasParts) {
      return
    }
    const inputs = bulkParts.map(bi => {
      return {
        value: bi.toLowerCase(),
        allowed: bulkAllowed,
        type: bulkType,
      }
    })
    const next = Array.isArray(verificationEmails) ? verificationEmails : []
    changer([...next, ...inputs])
    resetInputs()
  }

  const onRemove = record =>  {
    const next = Array.isArray(verificationEmails) ? verificationEmails : []
    changer(next.filter(e => e.value !== record.value))
  }

  const onRemoveAll = () =>  {
    changer([])
  }

  const onInput = (parts, input) => {
    setBulkInput(input)
    setBulkParts(parts)
  }

  let addButtonText = 'Add'
  if (hasParts) {
    const len = bulkParts.length
    addButtonText = `Add ${len} ${pluralize(bulkType.toLowerCase(), len)}`
  }

  return (
    <div style={{marginTop: theme.spacing[2]}}>
      <div style={{marginBottom: theme.spacing[2]}}>
        <div style={{marginBottom: theme.spacing[2]}}>
          <BulkInput
            onChange={onInput}
            placeholder="Add emails/domains separated by space, comma, or new line"
            value={bulkInput}
          />
        </div>
          <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', marginBottom: theme.spacing[3]}}>
          <ConstSelect
            name="beneficiaryGroupVerificationEmail"
            constKey="types"
            value={bulkType}
            onChange={t => setBulkType(t)}
            style={{maxWidth: theme.width[4]}}
          />
          <Select
            value={bulkAllowed}
            onChange={v => setBulkAllowed(v)}
            style={{maxWidth: theme.width[4]}}
          >
            <Option value={true}>Allowed</Option>
            <Option value={false}>Not allowed</Option>
          </Select>
          <Button disabled={!hasParts} onClick={onAdd}><PlusOutlined /> {addButtonText}</Button>
        </div>
      </div>
      <div style={{marginBottom: theme.spacing[2], textAlign: 'right'}}>
        <span style={{marginRight: theme.spacing[2]}}>{verificationEmailSummary}</span>
        <AntButton
          danger
          type="text"
          size="small"
          onClick={onRemoveAll}
          disabled={!hasVerificationEmails}
        >
          Remove all
        </AntButton>
      </div>
      <Table
        size="small"
        columns={emailCols({ onRemove })}
        dataSource={verificationEmails}
        scroll={{x: 400}}
      />
    </div>
  )
}

export const BeneficiaryGroupEditor = ({ beneficiaryGroup, onChange }) => {
  beneficiaryGroup = beneficiaryGroup ?? initBeneficiaryGroupState
  const changer = typeof onChange === 'function' ? onChange : () => {}
  const theme = useContext(ThemeContext)
  const dispatch = useDispatch()
  const { benefit: benefitConsts } = useContext(ConstsContext)
  const [allVerificationSettings, setAllVerificationSettings] = useState(null)
  const [verificationType, setVerificationType] = useState(beneficiaryGroup ? getVerificationType(beneficiaryGroup) : null)

  useEffect(() => {
    if (beneficiaryGroup && beneficiaryGroup.verificationMethod && benefitConsts) {
      const methods = benefitConsts.beneficiaryGroupVerificationMethodTypes
      let keys = []
      switch(beneficiaryGroup.verificationMethod) {
        case methods.RECEIPT: {
          keys = benefitConsts.beneficiaryGroupVerificationMethods[methods.RECEIPT].settingsKeys
          break
        }
        case methods.ACCOUNT_EMAIL: { // this uses a separate db table instead of the beneficiary group settings object
          keys = []
          break
        }
        case methods.CODE: {
          keys = benefitConsts.beneficiaryGroupVerificationMethods[methods.CODE].settingsKeys
          break
        }
        case methods.ACCOUNT_CARD_BRAND: {
          keys = benefitConsts.beneficiaryGroupVerificationMethods[methods.ACCOUNT_CARD_BRAND].settingsKeys
          break
        }
      }
      const allSettings = keys.reduce((acc, curr) => {
        acc[curr] = benefitConsts.beneficiaryGroupVerificationSettings[curr]
        return acc
      }, {})
      setAllVerificationSettings(Object.keys(allSettings).length > 0 ? allSettings : null)
    } else {
      setAllVerificationSettings(null)
    }
  }, [beneficiaryGroup, benefitConsts])

  const {
    name,
    description,
    status,
    verificationMethod,
    verificationName,
    verificationDescription,
    verificationFallbackMessage,
    verificationSettings,
    verificationEmails,
    baseDiscountPercent,
    baseDiscountCents,
    caps,
  } = beneficiaryGroup

  const change = update => {
    changer({ ...beneficiaryGroup, ...update })
  }

  const clearVerificationAttrs = () => {
    changer({
      ...beneficiaryGroup,
      verificationMethod: null,
      verificationName: null,
      verificationDescription: null,
      verificationFallbackMessage: null,
      verificationSettings: null,
      verificationSettings: null,
    })
  }

  if (!benefitConsts) {
    return <LoadingOutlined />
  }

  const isEmailVerificationMethod = verificationMethod === benefitConsts.beneficiaryGroupVerificationMethodTypes.ACCOUNT_EMAIL

  return (
    <Form layout="vertical" requiredMark={"optional"}>
      <Form.Item required name="name" label={<Label>Name</Label>} initialValue={name}>
        <Input placeholder="" onChange={e => change({ name: e.target.value })} value={name} />
      </Form.Item>
      <Form.Item name="description" label={<Label>Description</Label>} initialValue={description}>
        <TextArea placeholder="" onChange={e => change({ description: e.target.value })} value={description} />
      </Form.Item>
      <Form.Item required name="status" label={<Label>Status</Label>} initialValue={status}>
        <ConstSelect
          name="benefit"
          constKey="beneficiaryGroupStatuses"
          onChange={st => change({ status: st })}
          value={status}
        />
      </Form.Item>
      <Attr name="Discount" description="The discount this beneficiary group receives on bookings in this benefit. Percent and amount discounts will be combined if both are specified">
        <Form.Item name="base-discount-percent" label={<Label>Percent discount</Label>} initialValue={baseDiscountPercent} >
          <PercentInput
            onChange={percent => change({ baseDiscountPercent: percent })}
            value={baseDiscountPercent}
            style={{width: theme.width[4]}}
            min={0}
          />
        </Form.Item>
        <Form.Item name="base-discount-cents" label={<Label>Amount discount</Label>} initialValue={baseDiscountCents} >
          <DollarInput
            onChange={cents => change({ baseDiscountCents: cents })}
            value={baseDiscountCents}
            style={{width: theme.width[4]}}
            min={0}
          />
        </Form.Item>
      </Attr>
      <Attr name="Verification" description="Configures how a user becomes part of a beneficiary group">
        <Radio.Group
          buttonStyle="solid"
          style={{marginBottom: theme.spacing[3]}}
          value={verificationType}
          onChange={e => {
            setVerificationType(e.target.value)
            if (e.target.value === 'disallow-verification') {
              clearVerificationAttrs()
            }
          }}
        >
          <Radio.Button value="allow-verification">Allow users to join</Radio.Button>
          <Radio.Button value="disallow-verification">Do not allow users to join</Radio.Button>
        </Radio.Group>
        {verificationType === 'allow-verification' &&
          <>
            <Form.Item
              required
              name="verification-method"
              label={<Label>Verification method</Label>}
              initialValue={verificationMethod}
              preserve={false}
            >
              <ConstSelect
                name="benefit"
                constKey="beneficiaryGroupVerificationMethods"
                onChange={vm => change({ verificationMethod: vm })}
                value={verificationMethod}
              />
            </Form.Item>
            <Form.Item
              required
              name="verification-name"
              label={<Label>Verification name</Label>}
              initialValue={verificationName}
              preserve={false}
            >
              <Input
                placeholder="Walmart receipt upload"
                onChange={e => change({ verificationName: e.target.value })}
                value={verificationName}
              />
            </Form.Item>
            <Form.Item
              required
              name="verification-description"
              label={<Label>Verification description</Label>}
              initialValue={verificationDescription}
              preserve={false}
            >
              <Input
                placeholder="Upload a photo of a Walmart receipt from a recent purchase"
                onChange={e => change({ verificationDescription: e.target.value })}
                value={verificationDescription}
              />
            </Form.Item>
            <Form.Item
              required
              name="verification-fallback-message"
              label={<Label>Verification fallback message</Label>}
              initialValue={verificationFallbackMessage}
              preserve={false}
            >
              <Input
                placeholder="That verification failed, please contact concierge@breakthelove.com"
                onChange={e => change({ verificationFallbackMessage: e.target.value })}
                value={verificationFallbackMessage}
              />
            </Form.Item>
            { !isEmailVerificationMethod &&
                <Form.Item
                  name="verification-settings"
                  label={<Label>Verification settings</Label>}
                  initialValue={verificationSettings}
                  preserve={false}
                >
                  <Settings
                    editing
                    allSettings={allVerificationSettings}
                    settings={beneficiaryGroup.verificationSettings}
                    onChange={s => change({ verificationSettings: s })}
                  />
                </Form.Item>
            }

            { isEmailVerificationMethod &&
                <Form.Item
                  name="beneficiary-group-verification-emails"
                  label={<Label>Verification emails</Label>}
                  initialValue={verificationEmails}
                  preserve={false}
                >
                  <div>
                    Add emails/domains that are allowed/not allowed to join this group
                    <BeneficiaryGroupVerificationEmailsEditor
                      verificationEmails={verificationEmails}
                      onChange={emails => change({ verificationEmails: emails })}
                    />
                  </div>
                </Form.Item>
            }

          </>
        }
      </Attr>
      <Attr
        name="Caps"
        description="Limit the number of bookings available for each user in this group. Bookings are counted per person. Time period is counted by date of program/reservation."
      >
        <BeneficiaryGroupCapsEditor caps={caps} onChange={updated => change({ caps: updated })} />
      </Attr>
    </Form>
  )
}

export const BenefitVenueEditor = ({ benefitVenue, onChange, disabledFields }) => {
  benefitVenue = benefitVenue ?? initBenefitVenueState
  const consts = useContext(ConstsContext)
  const allSettings = consts['benefit-venue'].settings
  const theme = useContext(ThemeContext)
  const changer = typeof onChange === 'function' ? onChange : () => {}

  const { venue, description, status, checkinInstructions, faqSectionTitle, settings } = benefitVenue

  const change = update => {
    changer({ ...benefitVenue, ...update })
  }

  return (
    <Form layout="vertical" requiredMark="optional">
      <Form.Item required name="venue" label={<Label>Venue</Label>} initialValue={venue}>
        <AdminVenueSelect
          value={venue}
          onChange={v => change({ venue: v.id })}
          disabled={Array.isArray(disabledFields) && disabledFields.includes('venue')}
        />
      </Form.Item>
      <Form.Item required name="status" label={<Label>Status</Label>} initialValue={status}>
        <ConstSelect
          name="benefit"
          constKey="venueStatuses"
          style={{minWidth: theme.width[4]}}
          onChange={v => change({ status: v })}
          value={status}
        />
      </Form.Item>
      <Form.Item name="description" label={<Label>Description</Label>} initialValue={description}>
        <TextArea placeholder="" onChange={e => change({ description: e.target.value })} value={description} />
      </Form.Item>
      <Form.Item name="checkinInstructions" label={<Label>Checkin instructions</Label>} initialValue={checkinInstructions}>
        <TextArea placeholder="" onChange={e => change({ checkinInstructions: e.target.value })} value={checkinInstructions} />
      </Form.Item>
      <Form.Item name="faqSectionTitle" label={<Label>FAQ section title</Label>} initialValue={faqSectionTitle}>
        <Input
          onChange={e => change({ faqSectionTitle: e.target.value })}
          value={faqSectionTitle}
        />
      </Form.Item>
      <Form.Item
        name="settings"
        label={<Label>Settings</Label>}
        initialValue={settings}
        preserve={false}
      >
        <Settings
          editing
          allSettings={allSettings}
          settings={benefitVenue.settings}
          onChange={s => change({ settings: s })}
        />
      </Form.Item>
    </Form>
  )
}

export default {
  BeneficiaryGroupEditor,
  BenefitVenueEditor,
  CapEditor,
}
