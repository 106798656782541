import { Form, Input } from 'antd';
import { z } from 'zod';

const initState = {
  name: null,
  sid: null,
}

const sidSchema = z.string()
  .transform(val => val.trim())
  .transform(val => val.split(/\s+/).join('-'));

const OrgEditor = ({ onChange, org }) => {
  const [form] = Form.useForm();
  const changer = typeof onChange === 'function' ? onChange : () => {}

  if (!org) {
    if (typeof onChange === 'function') {
      onChange(initState)
    }
    return null
  }

  const change = update => changer({ ...org, ...update })

  const validateSid = (_, value) => {
    const { success } = sidSchema.safeParse(value)
    if (!success) throw new Error('Invalid SID format');
    change({ sid: sidSchema.parse(value) });
  };

  return (
    <Form 
      form={form}
      layout="vertical" 
      requiredMark={'optional'}
      initialValues={org}
    >
      <Form.Item 
        required 
        name="name" 
        label="Name"
        rules={[{ required: true, message: 'Please input the org name' }]}
      >
        <Input 
          placeholder="Org's name"
          onChange={e => change({ name: e.target.value })}
        />
      </Form.Item>
      <Form.Item 
        required 
        name="sid" 
        label="Sid"
        rules={[
          { required: true, message: 'Please input the SID' },
          { validator: validateSid }
        ]}
      >
        <Input placeholder="e.g. btl" />
      </Form.Item>
      <Form.Item name="stripeAccount" label="Stripe Account Id" initialValue={org.stripeAccountId}>
        <Input 
          placeholder="e.g. acct_123456abcdefg"
          onChange={e => change({ stripeAccountId: e.target.value })}
        />
      </Form.Item>
    </Form>
  )
}

export default OrgEditor
