import React, { useEffect, useState } from 'react'
import { message } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { LoadingOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { useStatus, useStatusMsg } from '../../reducers'
import { fetchProgramPost, editProgramPost } from '../../actions/org'
import { clearStatus } from '../../actions/status'
import { FocusHeader } from '../../components/header'
import { Cta } from '../../components/common'
import { Focus as FocusContainer } from '../../components/containers'
import { paths, actions as acts } from '../../constants'
import { PostEditor } from '../../components/posts'

const EditProgramPost = () => {
  const { osid, id, postId } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const [post, setPost] = useState(null)
  const status = {
    fetch: useStatus(acts.FETCH_PROGRAM_POST),
    edit: useStatus(acts.EDIT_PROGRAM_POST),
  }

  useEffect(() => {
    dispatch(fetchProgramPost(osid, id, postId))
      .then(p => setPost(p))
  }, [postId])

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.EDIT_PROGRAM_POST))
      dispatch(clearStatus(acts.FETCH_PROGRAM_POST))
    }
  }, [])

  useStatusMsg(status.edit, {
    pending: 'Saving...',
    error: 'Failed to save post',
    success: 'Post saved',
  })

  const missingFields = () => {
    const missing = ['content'].filter(k => {
      if (!post[k]) {
        return true
      }
      return false
    })
    if (missing.length > 0) {
      const msg = `${missing.join(', ')} ${missing.length > 1 ? 'are' : 'is'} required`
      message.error(`${msg.charAt(0).toUpperCase()}${msg.substr(1)}`)
      return true
    }
    return false
  }

  const saveProgramPost = () => {
    if (missingFields()) {
      return
    }
    const editedPost = {
      content: post.content,
      isAdminPost: post.isAdminPost,
      pinned: post.pinned,
      images: post.images
    }
    dispatch(editProgramPost(osid, id, postId, editedPost))
      .then(post => {
        history.push(paths.org.PROGRAM_POSTS(osid, id))
      })
      .catch(() => {})
  }

  return (
    <>
      <FocusHeader
        title="Edit a program post"
        actions={
          <Cta onClick={saveProgramPost}>Save post</Cta>
        }
      />
      <FocusContainer style={{paddingTop: '20px', paddingBottom: '20px'}}>
        {(status.fetch.pending || !post) && <center><LoadingOutlined /></center>}
        {post && <PostEditor post={post} onChange={p => setPost(p)} hideNotify />}
      </FocusContainer>
    </>
  )
}

export default EditProgramPost
