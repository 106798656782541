import React, { useReducer, useEffect } from 'react'
import dayjs from 'dayjs'
import { DatePicker, TimePicker } from 'antd'
import { formatTime, parseTime, tzByName } from '../../../util/time'
import { typeLabel } from '../../../util/program'
import { lower } from '../../../util/string'
import { title } from '../../../util/string'
import { InputGroup, SelectContainer, Title, Desc } from './step'
import Attr from '../../../components/Attr'
import TimezoneSelect from '../../../components/TimezoneSelect'


function disabledDate(current) {
  // Can not select days before today
  return current && current < dayjs().startOf('day')
}

const initState = ({ date, startTime, endTime, tz }) => ({
  date,
  startTime,
  endTime,
  tz
})

const reducer = (state, action) => ({ ...state, ...action })

const Timing = ({ onChange, clinic }) => {
  const [state, dp] = useReducer(reducer, initState(clinic))

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(state)
    }
  }, [state])

  const ClassDate = (
    <InputGroup>
      <Title>{title(typeLabel(clinic))} date</Title>
      <DatePicker
        size="large"
        placeholder={`${title(typeLabel(clinic))} date`}
        disabledDate={disabledDate}
        defaultValue={clinic.date && dayjs(clinic.date)}
        onChange={(date, dateString) => dp({ date: dateString })}
      />
    </InputGroup>
  )

  return (
    <>
      {ClassDate}
      {state.date &&
        <>
          <InputGroup>
            <Title>{title(typeLabel(clinic))} time</Title>
            <Desc>
              Set a start and end time for the {lower(typeLabel(clinic))}
            </Desc>
            <SelectContainer>
              <TimePicker
                size="large"
                use12Hours
                format="h:mm a"
                minuteStep={15}
                placeholder="Select start"
                defaultValue={clinic.startTime && dayjs(`${clinic.date} ${clinic.startTime}`)}
                onChange={time => dp({ startTime: time && time.format('HH:mm:ss') })}
                value={state.startTime && dayjs(`${state.date} ${state.startTime}`)}
              />
              <TimePicker
                size="large"
                use12Hours
                format="h:mm a"
                minuteStep={15}
                placeholder="Select end"
                defaultValue={clinic.endTime && dayjs(`${clinic.endTime}`)}
                onChange={time => dp({ endTime: time && time.format('HH:mm:ss') })}
                value={state.endTime && dayjs(`${state.date} ${state.endTime}`)}
              />
            </SelectContainer>
          </InputGroup>
          <InputGroup>
            <Title>Timezone</Title>
            <TimezoneSelect value={state.tz} onChange={newTz => dp({ tz: newTz })} style={{ width : '270px' }} />
          </InputGroup>
        </>
      }
    </>
  )
}

const Viewer = ({ program }) => {
  const required = [program.date, program.startTime, program.endTime]
  if (required.map(Boolean).filter(r => r === false).length > 0) {
    return null
  }
  let tzDisplay = ''
  if (program.tz) {
    const tz = tzByName(program.tz)
    if (tz) {
      tzDisplay = `${tz.alternativeName} (${tz.abbreviation})`
    } else {
      tzDisplay = <i>Invalid timezone: {`${program.tz}`}</i>
    }
  } else {
    tzDisplay = <i>No timezone</i>
  }
  return (
    <>
      <Attr name="Date">
        <div>{program.date}</div>
      </Attr>
      <Attr name="Start time">
        <div>{program.startTime}</div>
      </Attr>
      <Attr name="End time">
        <div>{program.endTime}</div>
      </Attr>
      <Attr name="Timezone">
        <div>{tzDisplay}</div>
      </Attr>
    </>
  )
}

const step = Timing

const complete = ({ date, startTime, endTime, tz }) => {
  if (!date) {
    return false
  }
  if (!startTime || !endTime) {
    return false
  }

  if (!tz) {
    return false
  }

  return true
}

const applicable = () => true

export default { step, Viewer, complete, applicable }
