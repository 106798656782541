import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import styled, { ThemeContext } from 'styled-components'
import { Form, Input } from 'antd'
import StateSelector from '../../components/StateSelector'
import CountrySelector from '../../components/CountrySelector'
import MapPreview from '../../components/MapPreview'

const FormContainer = styled.div`
  width: ${props => props.theme.width[7]};
`

const Group = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.spacing[3]};
`
const Label = styled.label`
  margin-right: ${props => props.theme.spacing[3]};
  width: ${props => props.theme.width[5]};
  text-align: right;
`

export const RequiredLabel = styled(Label)`
  &:before {
    display: inline-block;
    margin-right: ${props => props.theme.spacing[1]};
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
`

const FullWidth = styled.div`
  width: 100%;
`

const initState = {
  name: null,
  addressLine1: null,
  addressLine2: null,
  city: null,
  state: null,
  country: null,
  postcode: null,
  locationPage: null,
  lat: null,
  lon: null,
}

const LocationEditor = ({ location, onChange }) => {
  const { osid } = useParams()
  const theme = useContext(ThemeContext)

  if (!location) {
    if (typeof onChange === 'function') {
      onChange(initState)
    }
    return null
  }

  const {
    name,
    addressLine1,
    addressLine2,
    city,
    state,
    country,
    postcode,
    locationPage,
    lat,
    lon,
  } = location

  const change = update => {
    if (typeof onChange === 'function') {
      onChange({ ...location, ...update })
    }
  }

  return (
    <div>
      <FormContainer>
        <Form>
          <Group>
            <RequiredLabel htmlFor="name">Name</RequiredLabel>
            <FullWidth>
              <Input
                id="name"
                value={name}
                onChange={e => change({ name: e.target.value })}
              />
            </FullWidth>
          </Group>
          <Group>
            <RequiredLabel htmlFor="address-country">Country</RequiredLabel>
            <FullWidth>
              <CountrySelector
                id="address-country"
                readOnly
                showSearch
                defaultActiveFirstOption
                onChange={v => change({ country: v })}
                value={country}
                style={{ width: theme.width[5] }}
                placeholder="Select a Country"
                defaultValue="US"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />
            </FullWidth>
          </Group>
          <Group>
            <RequiredLabel htmlFor="address-line-1">Address Line 1</RequiredLabel>
            <FullWidth>
              <Input
                id="address-line-1"
                placeholder="Street and number, P.O. box, c/o"
                value={addressLine1}
                onChange={e => change({ addressLine1: e.target.value })}
              />
            </FullWidth>
          </Group>
          <Group>
            <Label htmlFor="address-line-2">Address Line 2</Label>
            <FullWidth>
              <Input
                id="address-line-2"
                placeholder="Apartment, suite, unit, building floor, neighborhood etc."
                value={addressLine2}
                onChange={e => change({ addressLine2: e.target.value })}
              />
            </FullWidth>
          </Group>
          <Group>
            <RequiredLabel htmlFor="address-city">City</RequiredLabel>
            <FullWidth>
              <Input
                id="address-city"
                style={{ width: theme.width[5] }}
                value={city}
                onChange={e => change({ city: e.target.value })}
              />
            </FullWidth>
          </Group>
          {country === 'US' ? (
            <Group>
              <RequiredLabel htmlFor="address-state">State</RequiredLabel>
              <FullWidth>
                <StateSelector
                  id="address-state"
                  value={state}
                  onChange={v => change({ state: v })}
                />
              </FullWidth>
            </Group>
          ) : (
            <Group>
              <Label htmlFor="address-state">State/Province/Region</Label>
              <FullWidth>
                <Input
                  id="address-state"
                  style={{ width: theme.width[5] }}
                  value={state}
                  onChange={e => change({ state: e.target.value })}
                />
              </FullWidth>
            </Group>
          )}
          <Group>
            {country && country === 'US' && (
              <RequiredLabel htmlFor="address-postcode">Zip Code</RequiredLabel>
            )}
            {!(country && country === 'US') && (
              <Label htmlFor="address-postcode">ZIP/Postal Code</Label>
            )}
            <FullWidth>
              <Input
                id="address-postcode"
                style={{ width: theme.width[4] }}
                value={postcode}
                onChange={e => change({ postcode: e.target.value })}
              />
            </FullWidth>
          </Group>
          <Group>
            <RequiredLabel htmlFor="address-lat">Latitude</RequiredLabel>
            <FullWidth>
              <Input
                type="number"
                id="address-lat"
                value={lat}
                onChange={e => change({ lat: e.target.value })}
              />
            </FullWidth>
          </Group>
          <Group>
            <RequiredLabel htmlFor="address-lon">Longitude</RequiredLabel>
            <FullWidth>
              <Input
                type="number"
                id="address-lon"
                value={lon}
                onChange={e => change({ lon: e.target.value })}
              />
            </FullWidth>
          </Group>
          <Group>
            <Label htmlFor="address-location-page">Location Page</Label>
            <FullWidth>
              <Input
                id="address-location-page"
                value={locationPage}
                onChange={e => change({ locationPage: e.target.value })}
              />
            </FullWidth>
          </Group>
          {location.lon && location.lat && (
            <div style={{ position: 'relative', marginTop: 20 }}>
              <MapPreview location={location} />
            </div>
          )}
        </Form>
      </FormContainer>
    </div>
  )
}
export default LocationEditor
