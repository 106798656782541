import React, { useState } from 'react'
import { purchasableSkus } from '../constants/billing'
import { Tag, Select } from 'antd'

const { Option } = Select

const Selector = ({ value, onChange, skus, ...props }) => {
  const [val, setVal] = useState(value)

  const opts = purchasableSkus.map(sku => (
    <Option key={sku} value={sku}>
      <Tag>{sku}</Tag>
    </Option>
  ))

  const handleChange = sku => {
    setVal(sku)
    if (typeof onChange === 'function') {
      onChange(sku)
    }
  }

  return (
    <Select
      value={val}
      onChange={handleChange}
      showSearch
      filterOption={(input, option) => { 
        const inp = input.toLowerCase()
        const value = option.children
        if (value[0]) { // names can now be null sometimes since the db check was removed
          const matchesOption = value[0].toLowerCase().indexOf(inp) >= 0
          return matchesOption || option.value.toLowerCase().indexOf(inp) >= 0
        }
        return false
      }}
      {...props}
    >
      {opts}
    </Select>
  )
}

export const PurchasableSkuSelect = ({ ...props }) => {
  return (
    <Selector {...props} skus={purchasableSkus} />
  )
}

export default {
  PurchasableSkuSelect
}
