import { actions as acts, urls } from '../constants'
import fetchData from '../util/fetch'

export const fetchProgramAttributes = osid => async dispatch => {
  dispatch({ type: acts.FETCH_PROGRAM_ATTRIBUTES_PENDING, payload: {} })
  try {
    const attrs = await fetchData({ url: urls.org.PROGRAM_ATTRIBUTES(osid) })
    dispatch({ type: acts.FETCH_PROGRAM_ATTRIBUTES_SUCCESS, payload: attrs })
    return attrs
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.FETCH_PROGRAM_ATTRIBUTES_FAILURE, payload: err })
  }
}
