import { LoadingOutlined } from '@ant-design/icons'
import { Checkbox } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { fetchProgramAttributes } from '../../../actions/partner'
import Attr from '../../../components/Attr'
import { actions as acts } from '../../../constants'
import { useStatus } from '../../../reducers'
import store from '../../../store'
import { InputGroup, RequiredLabel, Title } from './step'
import SelectAttributes from '../../../components/SelectAttributes'
import AttributesViewer from '../../../components/AttributesViewer'

const Attributes = ({ onChange, clinic }) => {
  const { osid } = useParams()
  const dispatch = useDispatch()
  const [allAttributes, setAllAttributes] = useState([])
  const [attrs, setAttrs] = useState(clinic.attributes)
  const status = useStatus(acts.FETCH_PROGRAM_ATTRIBUTES)
  useEffect(() => {
    dispatch(fetchProgramAttributes(osid))
      .then(res => {
        setAllAttributes(res)
      })
  }, [])

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange({ attributes: attrs })
    }
  }, [attrs])

  if (status.pending) {
    return <LoadingOutlined />
  }

  return (
    <>
      <Title>Attributes</Title>
      <SelectAttributes
        allAttributes={allAttributes}
        onChange={newAttrs => setAttrs(newAttrs)}
        attributes={attrs}
      />
    </>
  )
}

const Viewer = ({ program }) => {
  return (
    <AttributesViewer attributes={program.attributes} />
  )
}

const step = Attributes

// complete if all required attributes have at least one value selected
const complete = async ({ attributes }, osid) => {
  // TODO: make this so it doesn't refetch program attributes
  // on every call (use some caching mechanism).
  const availableAttrs = await store.dispatch(fetchProgramAttributes(osid))

  const requiredAttrs = availableAttrs
    .filter(a => a.programSettings.required)
    .reduce((acc, curr) => {
      acc[curr.id] = false
      return acc
    }, {})

  if (Object.keys(requiredAttrs).length > 0) {
    if (!Array.isArray(attributes) || attributes.length === 0) {
      return false
    }

    attributes.forEach(attr => {
      if (attr.attribute.id in requiredAttrs) {
        requiredAttrs[attr.attribute.id] = true
      }
    })

    return Object.values(requiredAttrs)
      .reduce((acc, curr) => acc && curr, true)
  }

  return true
}

// only applicable if attributes exist on the server
const applicable = async program => {
  // TODO: make this so it doesn't refetch program attributes
  // on every call (use some caching mechanism).
  const attributes = await store.dispatch(fetchProgramAttributes(program ? program.org : undefined))
  return attributes.length > 0
}

export default { step, Viewer, complete, applicable }
