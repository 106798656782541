import { Checkbox } from 'antd'
import React from 'react'

type Element = {
  checkbox: React.ComponentProps<typeof Checkbox>,
  label: string,
  description: string,
  name: string
}

type FormJsonData = Record<string, string | boolean>

function collectFormData(elm: HTMLFormElement) {
  const jsonData: FormJsonData = {}

  elm.querySelectorAll('input,textarea').forEach(elm => {
    const input = elm as HTMLInputElement | HTMLFormElement
    const name = input.name

    if (name) {
      switch (input.type) {
        case 'checkbox':
          jsonData[name] = input.checked
          break
        default:
          jsonData[name] = input.value
      }
    }
  })

  return jsonData
}

export function useDeclarativeForm({
  elements,
  defaults = {},
}: {
  elements: (state: Record<string, string>) => Element[],
  defaults?: FormJsonData
}) {
  const formRef = React.useRef<HTMLFormElement>(null)
  const [data, setData] = React.useState({})
  const [signal,setSignal] = React.useState(0)

  const sendSignal = React.useCallback(() => setSignal(n => n + 1), [])

  React.useEffect(() => {
    const elm = formRef.current
    if (elm) {
      const handleChange = () => {
        setData(collectFormData(elm))
      }
      
      handleChange()
      const observer = new MutationObserver(handleChange);
      observer.observe(elm, { childList: true, subtree: true });

      elm.addEventListener('change', handleChange)
      return () => {
        elm.removeEventListener('change', handleChange)
      }
    }
  }, [signal])

  return {
    defaults,
    elements: elements(data),
    formRef,
    data,
    signal: sendSignal
  }
}

export function DeclarativeForm({
  defaults,
  signal,
  elements,
  formRef,
}: ReturnType<typeof useDeclarativeForm>) {

  React.useEffect(() => {
    // force form hook to register effect after 
    // this component renders and ref has been set
    signal()
  }, [signal])

  return (
    <form ref={formRef} onSubmit={e => e.preventDefault()}>
      {elements.map(elm => {
        if (elm.checkbox) {
          return (
            <React.Fragment key={elm.name}>
              <Checkbox 
                defaultChecked={Boolean(defaults[elm.name])}
                {...elm.checkbox} 
                name={elm.name} 
              >
                {elm.label ?? elm.checkbox.children}
              </Checkbox>
              <p style={{ color: '#9c9292fa', fontSize: '12px' }}>
                {elm.description}
              </p>
            </React.Fragment>
          )
        }

        return null
      })}
    </form>
  )
}
