import React from 'react'
import { Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

const InternalTagLabel = ({ name, description }) => (
  <Tooltip title={description} placement="right">
    {name}
    <QuestionCircleOutlined style={{marginLeft: '0.25rem'}} />
  </Tooltip>
)

export default InternalTagLabel
