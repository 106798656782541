export function promiseActions(name) {
  return {
    ...keyedConst(name), // this is primarily used for indexing errors and loading indicators
    ...keyedConst(`${name}_PENDING`), 
    ...keyedConst(`${name}_SUCCESS`), 
    ...keyedConst(`${name}_FAILURE`), 
  }
}

export function keyedConst(name) { 
  return {
    [name]: name
  }
}

export function labelConst(name, label) { 
  return {
    [name]: label
  }
}

export function descConst(name, label, description) {
  return {
    [name]: { name, label, description }
  }
}

// given a key map and an array of names, returns an array of labels
export const namesToLabels = (keys, names) => names.map(n => {
  if (n in keys) {
    return keys[n].label
  }
})
