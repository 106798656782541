import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import { paths } from '../../../constants'
import { PROGRAM } from '../../../constants/marketingLabels'
import { logout } from '../../../actions/user'
import styled from 'styled-components'
import capitalize from 'capitalize'
import pluralize from 'pluralize'

const Nav = styled.nav`
  fill: black;
  height: ${props => props.theme.height[3]};
  display: flex;
  background-color: ${props => props.theme.color.nearBlack};
  justify-content: space-between;
  border-bottom: 1px solid;
  border-color: white;
  border-color: white;
`

const NavLink = styled(Link)`
  display: inline-block;
  font-weight: ${props => props.theme.font.weight[6]};
  color: white;
  margin-right: ${props => props.theme.spacing[3]};
  text-decoration: none;
`
const LinkSection = styled.div`
  display: flex;
  align-items: center;
  padding: ${props => props.theme.spacing[3]};
`

const TitleSection = styled(Link)`
  display: flex;
  align-items: center;
  padding: ${props => props.theme.spacing[3]};
  text-decoration: none;

`

const Title = styled.span`
  line-height: ${props => props.theme.font.lh.solid};
  font-size: ${props => props.theme.font.size[3]};
  font-weight: ${props => props.theme.font.weight[6]};
  color: ${props => props.theme.color.white};
`

const Header = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const onLogout = () => {
    dispatch(logout())
      .then(() => history.push(paths.admin.HOME()))
  }

  return (
    <Nav>
      <TitleSection to={paths.admin.DASHBOARD()}>
        <Title>Admin</Title>
      </TitleSection>
      <LinkSection>
        <NavLink to={paths.admin.DASHBOARD()}>Dashboard</NavLink>
        <NavLink to={paths.admin.PERMITS()}>Permits</NavLink>
        <NavLink to={paths.admin.ATTRIBUTES()}>Attributes</NavLink>
        <NavLink to={paths.admin.USERS()}>Users</NavLink>
        <NavLink to={paths.admin.ORGS()}>Orgs</NavLink>
        <NavLink to={paths.admin.PROS()}>Pros</NavLink>
        <NavLink to={paths.admin.PROGRAMS()}>{ capitalize(pluralize(PROGRAM)) }</NavLink>
        <NavLink to={paths.admin.INTERNAL_TAGS()}>Internal Tags</NavLink>
        <NavLink to={paths.admin.CUSTOM_LANDING_PAGES()}>Custom Landing Pages</NavLink>
        <NavLink to={paths.admin.emails.INDEX()}>Emails</NavLink>
        <NavLink to={paths.admin.billing.PROMO_CODES()}>Promo Codes</NavLink>
        <NavLink to={paths.admin.CUSTOM_EMAIL_CAPTURES()}>CEC</NavLink>
        <NavLink to={paths.admin.BTL()}>BTL</NavLink>
        <NavLink to={paths.admin.BENEFITS()}>Benefits</NavLink>
        <NavLink to="#" onClick={onLogout}>Logout</NavLink>
      </LinkSection>
    </Nav>
  )
}

export default Header
