import React from 'react'
import Attr from '../../../components/Attr'
import * as urlUtils from '../../../util/url'
import ImagePreview from '../../../components/ImagePreview'
import ReactHtmlParser from 'react-html-parser'

const Overview = ({ pro }) => {
  let url = null
  if (pro.page.url) {
    url = urlUtils.mainSiteProPage(pro.page.url)
  }
  const images = pro.page.images.map(i => (
    <ImagePreview key={i.id} asset={i} width="auto" height="150px" style={{marginRight: '1em'}} />
  ))
  return (
    <>
      <Attr name="URL">
        <div>
          { url && <a href={url}>{url}</a> }
          { !url && <i>No URL</i> }
        </div>
      </Attr>
      <Attr name="Description">
        {ReactHtmlParser(pro.page.description)}
      </Attr>
      <Attr name="Images">
        <div>
          { images.length === 0 && <i>No images</i> }
          { images.length > 0 && <>{images}</> }
        </div>
      </Attr>
    </>
  )
}

export default Overview
