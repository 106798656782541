import { useState, useEffect } from 'react'
import { ThemeContext, useTheme } from 'styled-components'
import { Tooltip, Button as AntButton, Modal, Dropdown, Tag, Table, Breadcrumb } from 'antd'
import { DownOutlined, ExclamationCircleOutlined, SyncOutlined, EllipsisOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { useParams, Link } from 'react-router-dom'
import { Button, ID, Title, PaddedContainer, Head } from '../../../components/common'
import Attr from '../../../components/Attr'
import { AdminUserLink } from '../../../components/user'
import UserSelect from '../../../components/UserSelect'
import { actions as acts, paths } from '../../../constants'
import { useStatus } from '../../../reducers'
import * as adminActs from '../../../actions/admin'
import { useDispatch } from 'react-redux'
import { formatDateTimeTz } from '../../../util/time'
import { EditBeneficiaryModal, BeneficiaryActionMenu } from '../Users/User'

const AddUsersToBeneficiaryGroupModal = ({ beneficiaryGroup, complete }) => {
  const dispatch = useDispatch()
  const completer = typeof complete === 'function' ? complete : () => {}
  const [addingUsers, setAddingUsers] = useState(false)
  const [usersToAdd, setUsersToAdd] = useState([])
  const [usersStatusMap, setUsersStatusMap] = useState({})
  const status = useStatus(acts.MANUALLY_ADD_USER_TO_BENEFICIARY_GROUP)

  const onOk = () => {
    setUsersStatusMap({})
    setAddingUsers(true)
    for (let i = 0; i < usersToAdd.length; i++) {
      const uid = usersToAdd[i].id
      setUsersStatusMap(prev => ({
        ...prev,
        [uid]: { ...prev[uid], status: 'processing' },
      }))
      dispatch(
        adminActs.manuallyAddUserToBeneficiaryGroup({
          benefitSig: beneficiaryGroup.benefit,
          beneficiaryGroupId: beneficiaryGroup.id,
          userId: uid,
        })
      )
      .then(() => {
        setUsersStatusMap(prev => ({
          ...prev,
          [uid]: { ...prev[uid], status: 'success' },
        }))
      })
      .catch(e => {
        console.log(e)
        setUsersStatusMap(prev => ({
          ...prev,
          [uid]: { ...prev[uid], status: 'error', message: e.message },
        }))
      })
    }
    setAddingUsers(false)
  }

  const onAddUser = user => {
    setUsersToAdd(prev => Array.from(new Set([...prev, user])))
  }

  const onRemoveUser = user => {
    setUsersToAdd(prev => prev.filter(u => u.id !== user.id))
    setUsersStatusMap(prev => 
      Object.keys(prev).reduce((acc, curr) => {
        if (curr !== user.id) {
          acc[curr] = prev[curr]
        }
        return acc
      }, {})
    )
  }

  return (
    <Modal
      title={`Add users to ${beneficiaryGroup.name} group`}
      open
      okText={addingUsers ? 'Adding...' : 'Add'}
      okButtonProps={{ loading: addingUsers, disabled: addingUsers }}
      onOk={onOk}
      cancelText="Close"
      onCancel={() => completer()}
    >
      <Attr name="Select user">
        <div>
          <UserSelect
           placeholder="Search users by name"
           onChange={onAddUser}
           style={{ width: '100%' }}
          />
        </div>
        <div style={{marginTop: '1em'}}>
          {usersToAdd.map(u => {
            console.log(usersStatusMap[u.id])
            let color = 'default'
            if (usersStatusMap.hasOwnProperty(u.id)) {
              color = usersStatusMap[u.id].status
            }
            let icon = null
            if (color === 'error') {
              icon = <ExclamationCircleOutlined />
            } else if (color === 'processing') {
              icon = <SyncOutlined spin />
            }
            let tooltip = null
            if (color === 'error') {
              tooltip = usersStatusMap[u.id].message
            }
            return (
              <div
                key={u.id}
                style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '.5em'}}
              >
                <div>
                  <Tooltip title={tooltip}>
                    <Tag
                      key={u.id}
                      closable={false}
                      color={color}
                      icon={icon}
                    >
                     {u.name} <small>{u.email}</small>
                    </Tag>
                  </Tooltip>
                </div>
                <AntButton danger type="text" onClick={() => onRemoveUser(u)}>Remove</AntButton>
              </div>
            )
          })}
        </div>
      </Attr>
    </Modal>
  )
}

const beneficiaryGroupUserCols = ({ onEdit }) => [
  {
    title: 'Name',
    dataIndex: 'beneficiary',
    key: 'beneficiary',
    render: (val, record) => {
      return (
        <AdminUserLink user={val.customer.user} />
      )
    },
  },
  {
    title: 'Beneficiary status',
    dataIndex: ['beneficiary', 'status'],
    key: ['beneficiary', 'status'],
    render: (val, record) => {
      return (
        <Tag>{val}</Tag>
      )
    },
  },
  {
    title: 'Verification method',
    dataIndex: 'verificationInfo',
    key: 'verificationInfo',
    render: (val, record) => {
      let method = val.method
      // TODO 'MANUAL' is a string on the server. We should move that string into a constant so that we can reference that constant here
      if (val.input.method === 'MANUAL') {
        method = 'MANUAL'
      }

      return (
        <Tag>{method}</Tag>
      )
    },
  },
  {
    title: 'Joined',
    key: 'createdAt',
    dataIndex: 'createdAt',
    // TODO sorting needs to coordinate with the backend. enable this once the paginated endpoint supports accepting custom sorts
    //defaultSortOrder: 'descend',
    //sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    render: (val, record) => ( 
      <div>{formatDateTimeTz(val)}</div>
    )    
  },
  {
    title: 'Actions',
    key: 'id',
    render: (val, record) => {
      return (
        <div>
          <Dropdown
            overlay={<BeneficiaryActionMenu beneficiary={record.beneficiary} onEdit={onEdit} />}
            trigger={['click']}
          >
            <Button>
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        </div>
      )
    },
  }
]

const BeneficiaryGroupUsers = ({ beneficiaryGroup, onChange }) => {
  const theme = useTheme(ThemeContext)
  const dispatch = useDispatch()
  const [users, setUsers] = useState(null)
  const [pagination, setPagination] = useState(null)
  const [pageSize, setPageSize] = useState(20)
  const [offset, setOffset] = useState(0)
  const [adding, setAdding] = useState(false)
  const [editing, setEditing] = useState(null)
  const statuses = {
    fetch: useStatus(acts.FETCH_BENEFICIARY_GROUP_USERS)
  }

  const getData = () => {
    dispatch(
      adminActs.fetchBeneficiaryGroupUsers({
        benefitSig: beneficiaryGroup.benefit.id,
        beneficiaryGroupId: beneficiaryGroup.id,
        limit: pageSize,
        offset,
      })
    )
    .then(res => {
      setUsers(res.data)
      setPagination(res.pagination)
    })
  }

  useEffect(() => { getData() }, [beneficiaryGroup.id, offset, pageSize])

  if (!pagination || !users) {
    return <LoadingOutlined />
  }

  const handleChange = (page, filters, sorter) => {
    setOffset(page.current * page.pageSize - page.pageSize)
    setPageSize(page.pageSize)
  }

  return (
    <div>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: theme.spacing[2]}}>
        <div>{pagination.total} user{pagination.total === 1 ? '' : 's'}</div>
        <Button onClick={() => setAdding(true)}>
          <PlusOutlined /> Add user
        </Button>
      </div>
      <Table
        size="small"
        columns={beneficiaryGroupUserCols({ onEdit: beneficiary => setEditing(beneficiary) })}
        dataSource={users}
        onChange={handleChange}
        pagination={{ total: pagination.total, pageSize, position: ['bottomRight', 'topRight'] }}
      />
      {adding &&
        <AddUsersToBeneficiaryGroupModal
          beneficiaryGroup={beneficiaryGroup}
          complete={() => {
            getData()
            setAdding(false)
          }}
        />
      }
      {editing &&
        <EditBeneficiaryModal
          beneficiary={editing}
          complete={updatedBeneficiary => {
            if (updatedBeneficiary) {
              getData()
            }
            setEditing(null)
          }}
        />
      }
    </div>
  )
}
const BeneficiaryGroups = ({ benefit, onChange }) => {
  const theme = useTheme(ThemeContext)
  const changer = typeof onChange === 'function' ? onChange : () => {}
  const [adding, setAdding] = useState(false)
  const [editing, setEditing] = useState(null)

  const onRemove = removedGroup => {
    if (removedGroup) {
      changer(benefit.beneficiaryGroups.filter(bg => bg.id !== removedGroup.id))
    }
  }

  const onAdd = bg => changer([...benefit.beneficiaryGroups, bg])

  const onUpdate = updatedBg => {
    const next = [...benefit.beneficiaryGroups]
    const idx = next.findIndex(bg => bg.id === updatedBg.id)
    if (idx !== -1) {
      next[idx] = updatedBg
    }
    changer(next)
  }

}

const BeneficiaryGroup = () => {
  const { sig, benGroupId } = useParams()
  const dispatch = useDispatch()
  const theme = useTheme(ThemeContext)
  const [beneficiaryGroup, setBeneficiaryGroup] = useState(null)
  const [editing, setEditing] = useState(false)

  const fetchGroup = () => {
    dispatch(adminActs.fetchBeneficiaryGroup({ benefitSig: sig, beneficiaryGroupId: benGroupId }))
      .then(benGroup => setBeneficiaryGroup(benGroup))
  }

  useEffect(() => {
    fetchGroup()
  }, [sig, benGroupId])

  if (!beneficiaryGroup) {
    return <LoadingOutlined />
  }

  return (
    <PaddedContainer>
      <Breadcrumb>
        <Breadcrumb.Item><Link to={paths.admin.BENEFITS()}>Benefits</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to={paths.admin.BENEFIT(sig)}>{sig}</Link></Breadcrumb.Item>
        <Breadcrumb.Item>{beneficiaryGroup.name}</Breadcrumb.Item>

      </Breadcrumb>
      <Head
        style={{ display: 'flex', justifyContent: 'space-between', marginTop: theme.spacing[3], alignItems: 'center' }}
      >
        <Title>{beneficiaryGroup.name}</Title>
      </Head>
      <Attr name="ID">
        <div>
          <small><ID>{beneficiaryGroup.id}</ID></small>
        </div>
      </Attr>
      <Attr name="Status">
        <div>
          <Tag>{beneficiaryGroup.status}</Tag>
        </div>
      </Attr>
      <Attr name="Users">
        <BeneficiaryGroupUsers beneficiaryGroup={beneficiaryGroup} />
      </Attr>
    </PaddedContainer>
  )
}

export default BeneficiaryGroup
