import fetchData from '../util/fetch'
import { actions as acts, urls } from '../constants'
import act from "./act";

export const fetchLocation = (osid, id) => async dispatch => {
  dispatch({ type: acts.FETCH_LOCATION_PENDING, payload: { osid, id } })
  try {
    const location = await fetchData({ url: urls.org.LOCATION(osid, id) })
    dispatch({ type: acts.FETCH_LOCATION_SUCCESS, payload: location })
    return location
  } catch(err) {
    console.log(err)
    dispatch({ type: acts.FETCH_LOCATION_FAILURE, payload: err })
  }
}

export const fetchLocations = osid => async dispatch => {
  dispatch({ type: acts.FETCH_LOCATIONS_PENDING, paylod: { osid } })
  try {
    const locations = await fetchData({ url: urls.org.LOCATIONS(osid) })
    dispatch({ type: acts.FETCH_LOCATIONS_SUCCESS, payload: locations })
    return locations
  } catch(err) {
    console.log(err)
    dispatch({ type: acts.FETCH_LOCATIONS_FAILURE, payload: err })
  }
}

export const updateLocation = (osid, id, attrs) => async dispatch => {
  dispatch({ type: acts.UPDATE_LOCATION_PENDING })
  try {
    const location = await fetchData({
      type: 'PATCH',
      url: urls.org.LOCATION(osid, id),
      body: {
        name: attrs.name,
        addressLine1: attrs.addressLine1,
        addressLine2: attrs.addressLine2,
        city: attrs.city,
        state: attrs.state,
        postcode: attrs.postcode,
        country: attrs.country,
        lat: attrs.lat,
        lon: attrs.lon,
        locationPage: attrs.locationPage,
      },
    })
    dispatch({ type: acts.UPDATE_LOCATION_SUCCESS, payload: location })
    return location
  } catch(err) {
    console.log(err)
    dispatch({ type: acts.UPDATE_LOCATION_FAILURE, payload: err })
  }
}

export const deleteLocation = (osid, id) => async dispatch => {
  dispatch({ type: acts.DELETE_LOCATION_PENDING, payload: { id }})
  try {
    await fetchData({ type: 'DELETE', url: urls.org.LOCATION(osid, id) })
    dispatch({ type: acts.DELETE_LOCATION_SUCCESS, payload: { id } })
    return Promise.resolve()
  } catch(err) {
    console.log(err)
    dispatch({ type: acts.DELETE_LOCATION_FAILURE, payload: err })
    throw err
  }
}

export const createLocation = act(
  acts.CREATE_LOCATION,
  ({ osid, attrs }) => fetchData({
    type: 'POST',
    url: urls.org.LOCATIONS(osid),
    body: attrs,
  })
)

