import React, { useReducer, useState, useEffect, useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { RollbackOutlined, EllipsisOutlined, LoadingOutlined } from '@ant-design/icons'
import { Select, Input, Dropdown, Menu, Button, Tag, Modal, Breadcrumb } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { actions as acts, paths } from '../../../constants'
import { centsToDollars, percent } from '../../../util/format'
import * as numUtils from '../../../util/number'
import urls, { orgBenefitHomepage } from '../../../util/url'
import { fullyRefunded } from '../../../util/payment'
import { formatDateTimeTz } from '../../../util/time'
import { purchasableSkus, invoiceStatusTypes, skus } from '../../../constants/billing'
import * as adminActs from '../../../actions/admin'
import { clearStatus } from '../../../actions/status'
import { useDispatch } from 'react-redux'
import { Link, useParams, useHistory } from 'react-router-dom'
import { useStatus, useStatusMsg } from '../../../reducers'
import PageHeading from '../../../components/admin/PageHeading'
import PageTitle from '../../../components/admin/PageTitle'
import PlayerLevel from '../../../components/PlayerLevel'
import PaymentMethod from '../../../components/PaymentMethod'
import Attr from '../../../components/Attr'
import UserLink from '../../../components/admin/UserLink'
import NoteEditor from '../../../components/NoteEditor'
import JSONModal from '../../../components/json-modal'

const lineItemGtc = '15% 1fr 10% 10% 10% 10%'

const LineItemHeader = styled.div`
  display: grid;
  grid-template-columns: ${lineItemGtc};
  font-weight: bold;
  margin-bottom: 1em;
`

const LineItemRow = styled.div`
  display: grid;
  grid-template-columns: ${lineItemGtc};
  margin-bottom: 1.5em;
  grid-row-gap: .25em;
  grid-template-areas:
    "sku desc qty unit tot actions"
    ". note . . . ."
    ". discount . . . .";

  & .sku {
    grid-area: sku;
  }

  & .desc {
    grid-area: desc;
  }

  & .qty {
    grid-area: qty;
  }

  & .unit {
    grid-area: unit;
    text-align: right;
  }

  & .tot {
    grid-area: tot;
    text-align: right;
  }

  & .note {
    grid-area: note;
  }

  & .actions {
    grid-area: actions;
    display: flex;
    justify-content: center;
  }
  & .discount {
    grid-area: discount;
  }
`

const SumRow = styled.div`
  display: grid;
  grid-template-columns: ${lineItemGtc};
  margin-bottom: 0.5em;
  grid-template-areas: ". . . key tot .";
  & .key {
    grid-area: key;
    font-weight: bold;
    text-align: right;
  }
  & .tot {
    grid-area: tot;
    text-align: right;
  }
` 

const RefundRow = styled.div`
  display: grid;
  grid-template-columns: .07fr .5fr .75fr 10% 15% 10%;
  grid-template-areas:
    "space date stripe amount reason more";

  & .space {
    grid-area: space;
  }
  & .date {
    grid-area: date;
  }
  & .stripe {
    grid-area: stripe;
  }
  & .amount {
    grid-area: amount;
  }
  & .reason {
    grid-area: reason;
  }
  & .more {
    grid-area: more;
    display: flex;
    justify-content: center;
  }

  margin-bottom: 1em;
`

const PaymentRow = styled.div`
  display: grid;
  grid-template-columns: .5fr .75fr 10% 15% 10% 10%;
  grid-template-areas:
    "date stripe amount status card more"
    "note . . . . .";

  & .date {
    grid-area: date;
  }
  & .stripe {
    grid-area: stripe;
  }
  & .amount {
    grid-area: amount;
  }
  & .status {
    grid-area: status;
  }
  & .card {
    grid-area: card;
    text-align: right;
  }
  & .more {
    grid-area: more;
    display: flex;
    justify-content: center;
  }
  & .note {
    grid-area: note;
  }
`

const Spaced = styled.span`
  margin-right: ${props => props.theme.spacing[2]};
`

// Discount 10OFF -$10, -10%
const InlinePromo = ({ lineItem }) => {
  const { promoCode, discountCents, discountPercent } = lineItem
  const discounts = []
  if (discountCents) {
    discounts.push(`-${centsToDollars(discountCents)}`)
  }
  if (discountPercent) {
    discounts.push(`-${percent(discountPercent)}`)
  }
  return (
    <div>
      <Spaced>
        <strong>Discount</strong>
      </Spaced>
      <Spaced>
        <Link to={paths.admin.billing.PROMO_CODE(promoCode.id)}>
          {promoCode.code}
        </Link>
      </Spaced>
      <Spaced>
        {discounts.join(', ')}
      </Spaced>
    </div>
  )
}

const InlineBenefit = ({ lineItem }) => {
  const { info } = lineItem
  const { benefit, beneficiaryGroup } = info.benefitInfo
  const discounts = []

  if (beneficiaryGroup) {
    const { baseDiscountCents, baseDiscountPercent } = beneficiaryGroup
    if (baseDiscountCents) {
      discounts.push(`-${centsToDollars(baseDiscountCents)}`)
    }
    if (baseDiscountPercent) {
      discounts.push(`-${percent(baseDiscountPercent)}`)
    }
  } else { // use default discount from benefit if beneficiary group not set on this line item
    const { settings } = benefit
    if (settings.defaultDiscountCents) {
      discounts.push(`-${centsToDollars(settings.defaultDiscountCents)}`)
    }
    if (settings.defaultDiscountPercent) {
      discounts.push(`-${percent(settings.defaultDiscountPercent)}`)
    }
  }

  return (
    <div>
      <Spaced>
        <strong>Benefit</strong>
      </Spaced>
      <Spaced>
        <a href={orgBenefitHomepage(benefit.org.id, benefit.id)}>{benefit.name}</a> {beneficiaryGroup && <span>as {beneficiaryGroup.name}</span>}
      </Spaced>
      <Spaced>{discounts.join(', ')}</Spaced>
    </div>
  )
}

const LineItem = ({ lineItem, update }) => {
  const li = lineItem;
  const dispatch = useDispatch()
  const [info, setInfo] = useState(null)
  const [editingNote, setEditingNote] = useState(false)
  const canUpdate = typeof update === 'function'

  const lineItemActionMenu = handleMenuClick => (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="info">More info</Menu.Item>
      { canUpdate && <Menu.Item key="note">Edit note</Menu.Item> }
    </Menu>
  )

  const handleLineItemMenu = e => {
    if (e.key === "info") {
      setInfo(li.info)
      return
    }
    if (e.key === "note") {
      setEditingNote(true)
      return
    }
  }

  let desc = li.description
  if (li.promoCode && li.sku === skus.PROMO) {
    desc = (
      <Link to={paths.admin.billing.PROMO_CODE(li.promoCode.id)}>
        {li.description}
      </Link>
    )
  } else if (li.sku === skus.PROGRAM && li.info.program_id) {
    desc = (
      <Link to={paths.admin.PROGRAM(li.info.program_id)}>
        {li.description}
      </Link>
    )
  }

  let showInlinePromo = li.promoCode && purchasableSkus.includes(li.sku)

  return (
    <>
      <LineItemRow>
        <span className="sku">
          <Tag>{li.sku}</Tag>
        </span>
        <span className="desc">{desc}</span>
        <span className="qty">{li.quantity}</span>
        <span className="unit">{centsToDollars(li.unitPriceCents)}</span>
        <span className="tot">{centsToDollars(li.totalPriceCents)}</span>
        {li.note && (
          <span className="note">
            <strong style={{ marginRight: '.5em' }}>Note</strong> {li.note}
          </span>
        )}
        {showInlinePromo && (
          <div className="discount">
            <InlinePromo lineItem={li} />
          </div>
        )}
        {li.benefit && (
          <div className="discount">
            <InlineBenefit lineItem={li} />
          </div>
        )}
        <div className="actions">
          <Dropdown overlay={lineItemActionMenu(handleLineItemMenu)} trigger={['click']}>
            <Button size="small" style={{ display: 'flex', alignItems: 'center' }}>
              <EllipsisOutlined style={{ fontSize: '1.2em' }} />
            </Button>
          </Dropdown>
        </div>
      </LineItemRow>
      {info && <JSONModal title={li.id} json={info} complete={() => setInfo(null)} />}
      {editingNote && (
        <NoteEditor
          title="Edit note"
          note={li.note}
          complete={newNote => {
            if (newNote !== li.note) {
              dispatch(adminActs.updateLineItemNote(li.id, newNote)).then(newNote => {
                update({ note: newNote })
                setEditingNote(false)
              })
              return
            }
            setEditingNote(false)
          }}
        />
      )}
    </>
  )
}

const Refund = ({ refund }) => {
  const [showInfo, setShowInfo] = useState(false)

  const actionMenu = handleMenuClick => (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="info">More info</Menu.Item>
    </Menu>
  )

  const handleMenu = e => {
    if (e.key === 'info') {
      setShowInfo(true)
    }
  }

  return (
    <RefundRow>
      <div className="space"></div>
      <div className="date">
        <div>{formatDateTimeTz(refund.createdAt)}</div>
        <div>Refunded by {refund.initiator ? <UserLink user={refund.initiator} /> : 'admin'}</div>
      </div>
      <div className="stripe">{refund.stripeRefundId}</div>
      <div className="amount">{centsToDollars(refund.amountCents)}</div>
      <div className="reason">{refund.reason}</div>
      <div className="more">
        <Dropdown overlay={actionMenu(handleMenu)} trigger={['click']}>
          <Button size="small" style={{display: 'flex', alignItems: 'center'}}>
            <EllipsisOutlined style={{fontSize: '1.2em'}} />
          </Button>
        </Dropdown>
      </div>
      { showInfo &&
          <JSONModal
            title={`Refund ${refund.id}`}
            json={refund.info}
            complete={() => setShowInfo(false)}
          />
      }
    </RefundRow>
  )
}

const Payment = ({ payment, invoice, update }) => {
  const dispatch = useDispatch()
  const p = payment
  const { paymentMethod } = p
  const [viewingInfo, setViewingInfo] = useState(false)
  const [editingNote, setEditingNote] = useState(false)
  const [refunding, setRefunding] = useState(false)
  const hasRefunds = Array.isArray(payment.refunds) && payment.refunds.length > 0
  const isFullyRefunded = fullyRefunded(payment)

  const handlePaymentsMenu = e => {
    if (e.key === "info") {
      setViewingInfo(true)
      return
    }
    if (e.key === "note") {
      setEditingNote(true)
      return
    }
    if (e.key === "refund") {
      setRefunding(true)
      return
    }
  }

  const paymentsActionMenu = handleMenuClick => (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="info">More info</Menu.Item>
      <Menu.Item key="note">Edit note</Menu.Item>
      { !isFullyRefunded && <Menu.Item key="refund">Refund</Menu.Item> }
    </Menu>
  )

  let refunds = null
  if (hasRefunds) {
    refunds = payment.refunds.map(r => (
      <Refund key={r.id} refund={r} />
    ))
  }
  return (
    <>
      <PaymentRow>
        <div className="date">{formatDateTimeTz(p.createdAt)}</div>
        <div className="stripe">
          <a href={urls.stripePayment(p.stripePaymentIntentId)}>
            {p.stripePaymentIntentId}
          </a>
        </div>
        <div className="amount">{centsToDollars(p.amountPaidCents)}</div>
        <div className="status">
          <Tag>{p.status}</Tag> {hasRefunds ? <Tag><RollbackOutlined /> {isFullyRefunded ? 'Refunded' : 'Partially refunded'}</Tag> : null}
        </div>
        <div className="card">
          <PaymentMethod method={paymentMethod} />
        </div>
        <div className="more">
          <Dropdown overlay={paymentsActionMenu(handlePaymentsMenu)} trigger={['click']}>
            <Button
              size="small"
              style={{display: 'flex', alignItems: 'center'}}
            >
              <EllipsisOutlined style={{fontSize: '1.2em'}} />
            </Button>
          </Dropdown>
        </div>
        { p.note && <div className="note"><strong>Note</strong> {p.note}</div> }
      </PaymentRow>
      {hasRefunds &&
        <>
          <div><b>Refunds</b></div>
          <div>
            {refunds}
          </div>
        </>
      }
      { viewingInfo &&
          <JSONModal
            title={p.id}
            json={p.info}
            complete={() => setViewingInfo(false)}
          />
      }
      { editingNote &&
          <NoteEditor
            title="Edit note"
            note={p.note}
            complete={newNote => {
              if (newNote !== p.note) {
                dispatch(adminActs.updatePaymentNote(p.id, newNote))
                  .then(newNote => {
                    update({ note: newNote })
                    setEditingNote(false)
                  })
                return
              }
              setEditingNote(false)
            }}
          />
      }
      { refunding &&
          <RefundModal
            payment={p}
            lineItems={invoice.lineItems}
            complete={refund => {
              // :( I don't think we should be updating refunds like this
              // but it works
              if (refund) {
                if (Array.isArray(p.refunds)) {
                  p.refunds.push(refund)
                } else {
                  p.refunds = [refund]
                }
              }
              setRefunding(false)
            }}
          />
      }
    </>
  )
}

const RefundModal = ({ payment, lineItems, complete }) => {
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const [formComplete, setFormComplete] = useState(false)
  const [refunding, setRefunding] = useState(false)
  const [amountCents, setAmountCents] = useState(0)
  const [reason, setReason] = useState(null)
  const [amountRefunded, setAmountRefunded] = useState(0)
  const [lineItemsMap, setLineItemsMap] = useState()
  const [selectedLineItems, setSelectedLineItems] = useState([])
  const status = useStatus(acts.REFUND_PAYMENT)

  useEffect(() => () => dispatch(clearStatus(acts.REFUND_PAYMENT)), [])

  useEffect(() => {
    if (Array.isArray(payment.refunds)) {
      const refunded = payment.refunds.reduce((acc, curr) => {
        return acc + curr.amountCents
      }, 0)
      setAmountRefunded(refunded)
      // set suggested full refund amount
      setAmountCents(payment.amountPaidCents - refunded)
    }
  }, [payment.refunds])

  useEffect(() => {
    const isFormComplete = [
      selectedLineItems.length > 0,
      reason !== null,
    ].reduce((acc, curr) => acc && curr, true)
    setFormComplete(isFormComplete)
  }, [amountCents, reason])

  useEffect(() => {
    setLineItemsMap(lineItems.reduce((acc, curr) => {
      acc[curr.id] = curr
      return acc
    }, {}))
  }, [lineItems])

  useStatusMsg(status, {
    success: 'Refund successful',
    error: 'Failed to refund payment',
    pending: 'Refunding payment...',
  })

  const onOk = () => {
    setRefunding(true)
    dispatch(adminActs.refundPayment(payment.id, selectedLineItems, reason))
      .then(refund => {
        setRefunding(false)
        if (typeof complete === 'function') {
          complete(refund)
          return
        }
      })
      .catch(err => {
        setRefunding(false)
      })
  }

  return (
    <Modal
      visible
      title="Refund payment"
      okText="Refund"
      okButtonProps={{disabled: !formComplete || refunding, loading: refunding}}
      onOk={onOk}
      onCancel={() => typeof complete === 'function' && complete()}
    >
      <Attr name="Original amount">
        {centsToDollars(payment.amountPaidCents)}
      </Attr>
      <Attr name="Refundable amount">
        {centsToDollars(payment.amountPaidCents-amountRefunded)}
      </Attr>
      <Attr name="Amount selected for refund">
        {centsToDollars(selectedLineItems.reduce((acc, curr) => acc + lineItemsMap[curr].totalPriceCents, 0))}
      </Attr>
      <Attr name="Line items">
        <div>
          <Select 
            mode="multiple"
            allowClear
            style={{ width: theme.width[5] }}
            placeholder="Select line items to refund"
            onChange={value => setSelectedLineItems(value)}
            options={lineItems.filter(li => !li.refunded).map(li => ({ value: li.id, label: li.description }))}
          />
        </div>
      </Attr>
      <Attr name="Reason">
        <div>
          <Select
            style={{width: theme.width[5]}}
            placeholder="Select reason"
            onChange={value => setReason(value)}
          >
            <Select.Option value="requested_by_customer">
              Requested by customer
            </Select.Option>
            <Select.Option value="duplicate">Duplicate</Select.Option>
            <Select.Option value="fraudulent">Fraudulent</Select.Option>
          </Select>
        </div>
      </Attr>
    </Modal>
  )
}

const initState = {
  id: null,
  subtotalCents: null,
  feesCents: null,
  salesTaxCents: null,
  amountBilledCents: null,
  status: null,
  note: null,
  user: null,
  lineItems: [],
  payments: [],
  createdAt: null,
  updatedAt: null,
}

const reducer = (state, action) => {
  if (action.type === 'SET') {
    return action.payload
  }
  const next = { ...state }
  if (action.type === 'UP') {
    // update specific entry in array
    if (Array.isArray(next[action.key]) && action.id) {
      next[action.key] = next[action.key].map(e => {
        if (e.id === action.id) {
          return {
            ...e,
            ...action.payload,
          }
        }
        return e
      })
    // update entry key if object
    } else if (typeof next[action.key] === 'object') {
      next[action.key] = {
        ...next[action.key],
        ...action.payload,
      }
    } else {
      next[action.key] = action.payload
    }
  }
  return next
}

const InvoiceUserView = ({ invoice }) => {
  let display = <i>No user</i>
  if (invoice.user) {
    display = (
      <>
        <Link to={paths.admin.USER(invoice.user.id)}>{invoice.user.name}</Link>
        <div>{invoice.user.email}</div>
        <div>
          <Tag>{invoice.user.type}</Tag>
          <PlayerLevel user={invoice.user} />
        </div>
      </>
    )
  }
  return (
    <>
      <h2>User</h2>
      <div style={{marginBottom: '2em'}}>
        {display}
      </div>
    </>
  )
}

export const EstimatedInvoice = ({ invoice }) => {
  const theme = useContext(ThemeContext)

  if (!invoice) {
    return <i>No invoice</i>
  }

  const lineItems = invoice.lineItems.map(li => (
    <LineItem key={li.id} lineItem={li} />
  ))

  return (
    <div style={{maxWidth: '1024px', marginBottom: theme.spacing[4]}}>
      <InvoiceUserView invoice={invoice} />
      { invoice.note && 
          <div style={{marginBottom: '2em'}}>
            <h2>Note</h2>
            <div>{invoice.note}</div>
          </div>
      }
      <h2>Line items</h2>
      <LineItemHeader>
        <div>SKU</div>
        <div>Details</div>
        <div>Quantity</div>
        <div style={{textAlign: 'right'}}>Unit Price</div>
        <div style={{textAlign: 'right'}}>Total Price</div>
        <div style={{textAlign: 'center'}}>{/* Actions */}</div>
      </LineItemHeader>
      {lineItems}
      <div>
        <SumRow>
          <div className="key">Subtotal</div>
          <div className="tot">{centsToDollars(invoice.subtotalCents)}</div>
        </SumRow>
        <SumRow>
          <div className="key">Fees</div>
          <div className="tot">{centsToDollars(invoice.feesCents)}</div>
        </SumRow>
        <SumRow>
          <div className="key">Tax</div>
          <div className="tot">{centsToDollars(invoice.salesTaxCents)}</div>
        </SumRow>
        <SumRow>
          <div className="key">Total</div>
          <div className="tot">{centsToDollars(invoice.amountBilledCents)}</div>
        </SumRow>
      </div>
    </div>
  )
}

export const Invoice = ({ id }) => {
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const [invoice, dp] = useReducer(reducer, initState)
  const [editingNote, setEditingNote] = useState(false)
  const status = {
    fetch: useStatus(acts.FETCH_INVOICE),
  }

  useStatusMsg(status.fetch, {
    error: 'Failed to fetch invoice',
  })

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.FETCH_INVOICE))
    }
  }, [])

  useEffect(() => {
    dispatch(adminActs.fetchInvoice(id))
      .then(inv => dp({ type: 'SET', payload: inv }))
  }, [id])

  if (!status.fetch.success || !invoice.id) {
    return <LoadingOutlined />
  }

  const lineItems = invoice.lineItems.map(li => (
    <LineItem
      key={li.id}
      lineItem={li}
      update={attrs => dp({ type: 'UP', key: 'lineItems', id: li.id, payload: attrs })}
    />
  ))

  const payments = invoice.payments.map(p => (
    <Payment
      key={p.id}
      payment={p}
      update={attrs => dp({ type: 'UP', key: 'payments', id: p.id, payload: attrs })}
      invoice={invoice}
    />
  ))

  const handleMenuClick = e => {
    if (e.key === 'edit-note') {
      setEditingNote(true)
      return
    }
  }

  const actionMenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="edit-note">Edit note</Menu.Item>
    </Menu>
  )

  return (
    <>
      <PageHeading
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <PageTitle style={{display: 'flex', alignItems: 'center'}}>
          <span style={{marginRight: theme.spacing[2]}}>Invoice</span>
          <Tag>{invoice.status ? invoiceStatusTypes[invoice.status] : 'Status unknown'}</Tag>
        </PageTitle>
        <Dropdown overlay={actionMenu} trigger={['click']}>
          <Button>
            Actions <DownOutlined />
          </Button>
        </Dropdown>
      </PageHeading>
      <div style={{maxWidth: '1024px', marginBottom: theme.spacing[4]}}>
        <InvoiceUserView invoice={invoice} />
        { invoice.note && 
            <div style={{marginBottom: '2em'}}>
              <h2>Note</h2>
              <div>{invoice.note}</div>
            </div>
        }
        <h2>Line items</h2>
        <LineItemHeader>
          <div>SKU</div>
          <div>Details</div>
          <div>Quantity</div>
          <div style={{textAlign: 'right'}}>Unit Price</div>
          <div style={{textAlign: 'right'}}>Total Price</div>
          <div style={{textAlign: 'center'}}>{/* Actions */}</div>
        </LineItemHeader>
        {lineItems}
        <div>
          <SumRow>
            <div className="key">Subtotal</div>
            <div className="tot">{centsToDollars(invoice.subtotalCents)}</div>
          </SumRow>
          <SumRow>
            <div className="key">Fees</div>
            <div className="tot">{centsToDollars(invoice.feesCents)}</div>
          </SumRow>
          <SumRow>
            <div className="key">Tax</div>
            <div className="tot">{centsToDollars(invoice.salesTaxCents)}</div>
          </SumRow>
          <SumRow>
            <div className="key">Total</div>
            <div className="tot">{centsToDollars(invoice.amountBilledCents)}</div>
          </SumRow>
        </div>
      </div>
      <div style={{maxWidth: '1024px'}}>
        <h2>Payments</h2>
        {payments}
      </div>
      { editingNote && 
          <NoteEditor
            title="Edit note"
            note={invoice.note}
            complete={newNote => {
              if (newNote !== invoice.note) {
                dispatch(adminActs.updateInvoiceNote(invoice.id, newNote))
                  .then(newNote => {
                    dp({ type: 'UP', key: 'note', payload: newNote })
                    setEditingNote(false)
                  })
                return
              }
              setEditingNote(false)
            }}
          />
      }
    </>
  )
}

const InvoicePage = () => {
  const { id } = useParams()
  const theme = useContext(ThemeContext)
  return (
    <div>
      <Breadcrumb separator=">" style={{marginBottom: theme.spacing[2]}}>
        <Breadcrumb.Item>
          <Link to={paths.admin.billing.PROMO_CODES()}>Invoices</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{id}</Breadcrumb.Item>
      </Breadcrumb>
      <Invoice id={id} />
    </div>
  )
}

export default InvoicePage
