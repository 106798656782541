import { useMemo, useContext, useEffect, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { useStatus, useStatusMsg } from '../reducers'
import { Select } from 'antd'
import { actions as acts, paths } from '../constants'
import { fetchNetworkVenues } from '../actions/org'
import { fetchVenues } from '../actions/admin'
import Address from '../components/Address'
import { Link } from 'react-router-dom'

const { Option } = Select

// venue can be a venue object or a venue id/sid
export const AdminVenueLink = ({ children, venue, ...rest }) => {
  let sig = null
  let childs = children

  if (venue) {
    sig = typeof venue === 'string' ? venue : venue.sid
  }

  if (!sig) {
    return childs
  }

  // render something if children is not passed
  if (!childs) {
    childs = typeof venue === 'string' ? venue : venue.name
  }

  return (
    <Link to={paths.admin.VENUE(sig)} {...rest} >
      {childs}
    </Link>
  )
}

// venueFetcher is a dispatchable function that should return venues with addresses expanded
const Selector = ({ venueFetcher, venueFetcherAction, value, onChange, ...props }) => {
  const theme = useContext(ThemeContext)
  const dispatch = useDispatch()
  const [venues, setVenues] = useState({}) // map of venue id to venue object
  const [val, setVal] = useState(null)
  const status = useStatus(venueFetcherAction)

  useEffect(() => {
    dispatch(venueFetcher())
      .then(vns => {
        setVenues(
          vns
            .sort((a, b) => a.name.localeCompare(b.name))
            .reduce((acc, curr) => {
              acc[curr.id] = curr
              return acc
            }, {}),
        )
    })
  }, [venueFetcher])

  useEffect(() => {
    // convert value to venue id when venue list or value changes
    if (venues && Object.keys(venues).length > 0 && value) {
      // supports value being either a venue object or a venue id
      setVal(typeof value === 'string' ? value : value.id)
    }
  }, [venues, value])

  useStatusMsg(status, { error: 'Failed to fetch venues' })

  if (!status.success) {
    return 'Loading...'
  }

  const opts = Object.keys(venues).map(id => {
    const v = venues[id]
    return (
      <Option key={v.id} value={v.id} data-name={v.name.trim().toLowerCase()} data-org-name={v.org?.name?.trim()?.toLowerCase()}>
        <div>{v.name}</div>
        <div style={{color: theme.font.color.secondary}}>
          <small style={{display: 'block'}}><Address.SummaryLine address={v.address} /></small>
          <small>{v.org.name}</small>
        </div>
      </Option>
    )
  })

  const handleChange = id => {
    setVal(id)
    if (onChange) {
      onChange(venues[id])
    }
  }

  return (
    <Select
      value={val}
      onChange={handleChange}
      showSearch
      filterOption={(input, option) => { 
        const inp = input ? input.toLowerCase() : ''
        const matchesOption = option['data-name'].indexOf(inp) >= 0 || option['data-org-name'].indexOf(inp) >= 0
        return matchesOption || option.value.toLowerCase().indexOf(inp) >= 0
      }}
      {...props}
    >
      {opts}
    </Select>
  )
}

export const VenueSelect = ({ value, onChange, ...props }) => {
  const { osid } = useParams()

  let venueFetcher = () => {
    return fetchNetworkVenues({ osid })
  }

  useEffect(() => {
    venueFetcher = () => {
      return fetchNetworkVenues({ osid })
    }
  }, [osid])

  return (
    <Selector
      venueFetcher={venueFetcher}
      venueFetcherAction={acts.FETCH_NETWORK_VENUES}
      value={value}
      onChange={onChange}
      {...props}
    />
  )
}

export const AdminVenueSelect = ({ value, onChange, ...props }) => {
  const venueFetcher = useMemo(() => () => fetchVenues(), [])
  return (
    <Selector
      venueFetcher={venueFetcher}
      venueFetcherAction={acts.FETCH_ALL_VENUES}
      value={value}
      onChange={onChange}
      {...props}
    />
  )
}
