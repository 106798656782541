import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { patterns } from '../../constants'
import LocationList from './LocationList'
import Location from './Location'

const Locations = () => (
  <Switch>
    <Route exact path={patterns.org.LOCATIONS()} component={LocationList} />
    <Route exact path={patterns.org.LOCATION()} component={Location} />
  </Switch>
)

export default Locations
