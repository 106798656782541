import React, { useContext, useEffect, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { DatePicker, InputNumber, Form, Modal } from 'antd'
import ClubSelect from '../../../components/ClubSelect'
import { useDispatch } from 'react-redux'
import { actions as acts } from '../../../constants'
import { useStatus, useStatusMsg } from '../../../reducers'
import { clearStatus } from '../../../actions/status'
import Attr from '../../../components/Attr'
import { createExploreClubsPosition, updateExploreClubsPosition } from '../../../actions/admin'


const ExploreClubsPositionEditor = ({ complete, existingPosition, isEdit = false }) => {
  const dispatch = useDispatch()
  const [club, setClub] = useState(existingPosition && existingPosition.club)
  const [position, setPosition] = useState(existingPosition && existingPosition.position)

  const status = {
    create: useStatus(acts.CREATE_CLUB_POSITION),
    update: useStatus(acts.UPDATE_CLUB_POSITION),
  }

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.CREATE_CLUB_POSITION))
      dispatch(clearStatus(acts.UPDATE_CLUB_POSITION))
    }
  }, [])

  useStatusMsg(status.create, { error: 'Failed to create club position' })
  useStatusMsg(status.update, { error: 'Failed to update club position' })

  const onOk = async () => {
    if (isEdit) {
      dispatch(updateExploreClubsPosition({ clubId: club.id, position }))
        .then(res => complete())
        .catch(() => {})
    } else {
      dispatch(createExploreClubsPosition({ clubId: club.id, position }))
        .then(res => complete())
        .catch(() => {})
    }
  }

  const formComplete = [position, club].reduce((acc, curr) => curr && acc, true)

  return (
    <Modal
      title={`${isEdit ? `Update ${existingPosition.club.name}` : 'Create club position'}`}
      visible
      okText={isEdit ? 'Save' : 'Create'}
      okButtonProps={{ disabled: !formComplete }}
      onOk={onOk}
      onCancel={() => complete()}
      confirmLoading={status.create.pending || status.update.pending || false}
    >
      <Form>
        <Attr name="Club">
          <div>
          <ClubSelect
            placeholder="Select a club"
            style={{width: '100%'}}
            value={existingPosition ? existingPosition.club : null}
            onChange={club => setClub(club)}
          />
          </div>
        </Attr>
        <Attr name="Position">
          <div>
            <InputNumber placeholder="1" value={position} min={1} onChange={e => setPosition(e)} />
          </div>
        </Attr>
      </Form>
    </Modal>
  )
}

export default ExploreClubsPositionEditor
