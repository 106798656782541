import React, { useReducer, useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import styled, { ThemeContext } from 'styled-components'
import { Button, Radio, Select, Form, Input as input } from 'antd'
import { Input, InputGroup, SelectContainer, Title, Desc } from './step'
import { typeLabel } from '../../../util/program'
import { fetchLocations } from '../../../actions/location'
import StateSelector from '../../../components/StateSelector'
import CountrySelector from '../../../components/CountrySelector'
import MapPreview from '../../../components/MapPreview'
import Attr from '../../../components/Attr'
import Address, { useAddress, addressComplete } from '../../../components/Address'

const { TextArea } = input

const FormContainer = styled.div`
  width: ${props => props.theme.width[7]};
`

const Group = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.spacing[3]};
`
const Label = styled.label`
  margin-right: ${props => props.theme.spacing[3]};
  width: ${props => props.theme.width[5]};
  text-align: right;
`

export const RequiredLabel = styled(Label)`
  &:before {
    display: inline-block;
    margin-right: ${props => props.theme.spacing[1]};
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun,sans-serif;
    line-height: 1;
    content: '*';
  }
`

const FullWidth = styled.div`
  width: 100%;
`

const LocationContainer = styled.div`
  max-width: 100em;
  overflow-wrap: break-word;  
`

const initLocationState = {
  address: {
    name: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: undefined,
    postcode: '',
    country: 'US',
    locationPage: '',
    lat: '',
    lon: ''
  },
  publicDescription: '',
  bookedDescription: '',
  showFullAddress: false,
  locationId: null
}

const LocationPreview = ({ location }) => {
  const { address } = location
  return (
    <div>
      <Attr name="Name">
        <div>{address.name}</div>
      </Attr>
      <Attr name="Address">
        <Address address={address} />
      </Attr>
      <Attr name="Public description" tooltip="Always visible on program description pages">
        <LocationContainer>{location.publicDescription ? location.publicDescription : <i>None</i>}</LocationContainer>
      </Attr>
      <Attr name="Booked description" tooltip="Only visible in confirmation email after user has booked">
        <LocationContainer>{location.bookedDescription ? location.bookedDescription : <i>None</i>}</LocationContainer>
      </Attr>
      <Attr name="Show full address" tooltip="If not set, will only display city, state, country, and map lat/lon on public description page">
        <LocationContainer>{location.showFullAddress ? "Yes" : "No"}</LocationContainer>
      </Attr>
      <Attr name="Location page">
        <LocationContainer>{address.locationPage ? <a href={address.locationPage}>Page</a> : <i>None</i>}</LocationContainer>
      </Attr>
      <Attr name="Coordinates">
        <LocationContainer>
          {address.lat}, {address.lon}
        </LocationContainer>
        <div style={{ position: 'relative', marginTop: 20 }}>
          <MapPreview.Program location={location} />
        </div>
      </Attr>
    </div>
  )
}

const LocationEditor = ({ location, onChange }) => {
  const theme = useContext(ThemeContext)
  const state = useAddress(location.address)
  const [publicDescription, setPublicDescription] = useState(location.publicDescription)
  const [bookedDescription, setBookedDescription] = useState(location.bookedDescription)
  const [showFullAddress, setShowFullAddress] = useState(location.showFullAddress)

  const isCountryUs = state.address.country && state.address.country === 'US'

  useEffect(() => {
    onChange({
      ...location,
      publicDescription,
      bookedDescription,
      showFullAddress,
      address: state.address,
    })
  }, [state.address, publicDescription, bookedDescription, showFullAddress])

  let stateInput = (
    <Group>
      <Label htmlFor="address-state">State/Province/Region</Label>
      <FullWidth>
        <Input
          id="address-state"
          style={{ width: theme.width[5] }}
          value={state.address.state}
          onChange={e => state.setState(e.target.value)}
        />
      </FullWidth>
    </Group>
  )
  if (isCountryUs) {
    stateInput = (
      <Group>
        <RequiredLabel htmlFor="address-state">State</RequiredLabel>
        <FullWidth>
          <StateSelector
            id="address-state"
            value={state.address.state}
            onChange={v => state.setState(v)}
          />
        </FullWidth>
      </Group>
    )
  }

  return (
    <div>
      <Title>New Address</Title>
      <FormContainer>
        <Form>
          <Group>
            <RequiredLabel htmlFor="name">Name</RequiredLabel>
            <FullWidth>
              <Input
                id="name"
                value={state.address.name}
                onChange={e => state.setName(e.target.value)}
              />
            </FullWidth>
          </Group>
          <Group>
            <Label htmlFor="public-description">Public description</Label>
            <FullWidth>
              <TextArea
                id="public-description"
                value={publicDescription}
                onChange={e => setPublicDescription(e.target.value)}
                rows={4}
                placeholder="Always visible on program description pages"
              />
            </FullWidth>
          </Group>
          <Group>
            <Label htmlFor="booked-description">Booked description</Label>
            <FullWidth>
              <TextArea
                id="booked-description"
                value={bookedDescription}
                onChange={e => setBookedDescription(e.target.value)}
                rows={4}
                placeholder="Only visible in confirmation email after user has booked"
              />
            </FullWidth>
          </Group>
          <Group>
            <Label htmlFor="show-full-address">Show Full Address</Label>
            <FullWidth>
              <Radio.Group value={showFullAddress} onChange={e => setShowFullAddress(e.target.value)}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </FullWidth>
          </Group>
          <Group>
            <Label></Label>
            <FullWidth>
              If show full address is not set, only city, state, country, and map lat/lon
              will be visible on public description pages.
            </FullWidth>
          </Group>
          <Group>
            <RequiredLabel htmlFor="address-country">Country</RequiredLabel>
            <FullWidth>
              <CountrySelector
                id="address-country"
                readOnly
                showSearch
                defaultActiveFirstOption
                onChange={v => state.setCountry(v)}
                value={state.address.country}
                style={{ width: theme.width[5] }}
                placeholder="Select a Country"
                defaultValue="US"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />
            </FullWidth>
          </Group>
          <Group>
            <RequiredLabel htmlFor="address-line-1">Address Line 1</RequiredLabel>
            <FullWidth>
              <Input
                id="address-line-1"
                placeholder="Street and number, P.O. box, c/o"
                value={state.address.addressLine1}
                onChange={e => state.setLine1(e.target.value)}
              />
            </FullWidth>
          </Group>
          <Group>
            <Label htmlFor="address-line-2">Address Line 2</Label>
            <FullWidth>
              <Input
                id="address-line-2"
                placeholder="Apartment, suite, unit, building floor, neighborhood etc."
                value={state.address.addressLine2}
                onChange={e => state.setLine2(e.target.value)}
              />
            </FullWidth>
          </Group>
          <Group>
            <RequiredLabel htmlFor="address-city">City</RequiredLabel>
            <FullWidth>
              <Input
                id="address-city"
                style={{ width: theme.width[5] }}
                value={state.address.city}
                onChange={e => state.setCity(e.target.value)}
              />
            </FullWidth>
          </Group>
          {stateInput}
          <Group>
            { isCountryUs &&
                <RequiredLabel htmlFor="address-postcode">Zip Code</RequiredLabel>
            }
            { !isCountryUs &&
                <Label htmlFor="address-postcode">ZIP/Postal Code</Label>
            }
            <FullWidth>
              <Input
                id="address-postcode"
                style={{ width: theme.width[4] }}
                value={state.address.postcode}
                onChange={e => state.setPostcode(e.target.value)}
              />
            </FullWidth>
          </Group>
          <Group>
            <RequiredLabel htmlFor="address-lat">Latitude</RequiredLabel>
            <FullWidth>
              <Input
                type="number"
                id="address-lat"
                value={state.address.lat}
                onChange={e => state.setLat(e.target.value)}
              />
            </FullWidth>
          </Group>
          <Group>
            <RequiredLabel htmlFor="address-lon">Longitude</RequiredLabel>
            <FullWidth>
              <Input
                type="number"
                id="address-lon"
                value={state.address.lon}
                onChange={e => state.setLon(e.target.value)}
              />
            </FullWidth>
          </Group>
          <Group>
            <Label htmlFor="address-location-page">Location Page</Label>
            <FullWidth>
              <Input
                id="address-location-page"
                value={state.address.locationPage}
                onChange={e => state.setLocationPage(e.target.value)}
              />
            </FullWidth>
          </Group>
          { (location.address.lon && location.address.lat) &&
              <div style={{position: 'relative', marginTop: 20}}>
                <MapPreview.Program location={location} />
              </div>
          }
        </Form>
      </FormContainer>
    </div>
  )
}

const ExistingLocationsComponent = ({ locationState, setLocationState, locations }) => {
  const locationTable = (
    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
      <tbody>
        {locations.map((location) => {
          // get existing descriptions from most recently created programLocation
          let publicDescription = ''
          let bookedDescription = ''
          if (location.programLocations.length > 0) {
            const progLocs = location.programLocations
              .sort((a,b) => b.createdAt.localeCompare(a.createdAt))
            publicDescription = progLocs[0].publicDescription
            bookedDescription = progLocs[0].bookedDescription
          }
          return (
            <tr
              key={location.id}
              onClick={() => {
                setLocationState({
                  ...locationState,
                  address: { ...location },
                  publicDescription,
                  bookedDescription,
                  locationId: location.id
                })
              }}
              style={{ cursor: 'pointer' }}
            >
              <td style={{border: '1px solid #333333', padding: '10px 8px'}}>
                <div>{location.name}</div>
                <Address address={location} singleLine />
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
  return (
    <>
      <Title>Addresses</Title>
      { locations.length >= 1 ? locationTable : <Desc>No addresses exist</Desc> }
    </>
  )
}

const AddressLocationSelector = ({
  locationState,
  setLocationState,
  locations
}) => {
  const [addressType, setAddressType] = useState(
    locationState.locationId !== null ? 'existing' : ''
  )

  const resetAddress = (e) => {
    e.preventDefault()
    setLocationState(initLocationState)
  }
  const locationId = locationState.locationId

  let component = null
  if (addressType === 'existing') {
    if (locationState.address.id) {
      component = (
        <>
          <InputGroup>
            <Button size="small" onClick={() => setAddressType('manual')}>
              Edit location info
            </Button>
          </InputGroup>
          <LocationPreview location={locationState} />
        </>
      )
    } else {
      component = (
        <ExistingLocationsComponent
          locationState={locationState}
          setLocationState={setLocationState}
          locations={locations}
        />
      )
    }
  } else if (addressType === 'manual') {
    component = (
      <LocationEditor
        location={locationState}
        onChange={v => setLocationState(v)}
      />
    )
  }
  return (
    <>
      <InputGroup>
        <SelectContainer>
          <Radio.Group
            value={addressType}
            onChange={e => {
              e.target.value === 'manual' && setLocationState(initLocationState)
              setAddressType(e.target.value)
            }}
          >
            <Radio value='existing'>
              Choose from existing
            </Radio>
            <Radio value='manual'>
              Create new
            </Radio>
          </Radio.Group>
          <a onClick={e => resetAddress(e)}>Reset</a>
        </SelectContainer>
      </InputGroup>
      <InputGroup>
        {component}
      </InputGroup>
    </>
  )
}

const initState = ({ location }) => ({
  location: location !== null ? { ...location, locationId: location.address.id } : initLocationState,
})

const reducer = (state, action) => ({ ...state, ...action })

const LocationStep = ({ onChange, clinic }) => {
  const { osid } = useParams()
  const dispatch = useDispatch()
  const locations = useSelector(s => s.locations)
  const [state, dp] = useReducer(reducer, initState(clinic))

  useEffect(() => { dispatch(fetchLocations(osid)) }, [])
  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange({ location: state.location })
    }
  }, [state])

  const tl = typeLabel(clinic)
  return (
    <>
      <InputGroup>
        <Title>Location</Title>
        <Desc>
          Where will this {tl && tl.toLowerCase()} be held?
        </Desc>
      </InputGroup>
      <AddressLocationSelector
        locationState={state.location}
        setLocationState={v => dp({ location: v })}
        locations={locations}
      />
    </>
  )
}

const Viewer = ({ program }) => {
  let preview = (
    <i>No location</i>
  )
  if (program.location) {
    preview = (
       <LocationPreview location={program.location} />
    )
  }
  return (
    <>
      {preview}
    </>
  )
}

const step = LocationStep

const complete = ({ location }) => {
  if (location) {
    return addressComplete(location.address)
  }
  return false
}

const applicable = () => true

export default { step, Viewer, complete, applicable }
