import React from 'react'
import { Redirect } from 'react-router-dom'
import { paths } from '../../../constants'

const Home = () => {
  return (
    <Redirect to={paths.admin.DASHBOARD()} />
  )
}

export default Home
