import React from 'react'
import CreateForm from './components/CreateForm'
import { Container } from './styles'

const CreateClass = () => {
  return (
    <Container>
      <CreateForm />
    </Container>
  )
}

export default CreateClass
