import { descConst, labelConst, keyedConst } from './util'

export const feeRuleTypes = {
  ...descConst('TAX', 'Tax', 'Government taxes'),
  ...descConst('BTL_SERVICE_FEE', 'Service Fee', 'Break the Love\'s fee'),
}

export const userTypes = {
  ...descConst('ALL', 'All Users', 'All Users'),
  ...descConst('PARTNER_MEMBER', 'Member User', 'Users that have a membership with this partner'),
  ...descConst('PARTNER_NON_MEMBER', 'Non-member User', 'Users that do not have a membership with this partner'),
}

export const cardBrands = {
  ...descConst('amex', 'American Express', ''),
  ...descConst('cartes_bancaires', 'Cartes Bancaires', ''),
  ...descConst('diners_club', 'Diners Club', ''),
  ...descConst('discover', 'Discover', ''),
  ...descConst('jcb', 'JCB', ''),
  ...descConst('mastercard', 'Mastercard', ''),
  ...descConst('visa', 'Visa', ''),
  ...descConst('unionpay', 'UnionPay', ''),
}

export const invoiceStatusTypes = {
  ...labelConst('PENDING', 'Pending'),
  ...labelConst('CONFIRMED', 'Confirmed'),
  ...labelConst('PAID', 'Paid'),
  ...labelConst('CHARGE_FAILED', 'Charge failed'),
  ...labelConst('CANCELED', 'Canceled'),
}

export const skus = {
  ...keyedConst('PRODUCT'),
  ...keyedConst('PROGRAM'),
  ...keyedConst('FEE'),
  ...keyedConst('TAX'),
  ...keyedConst('PROMO'),
  ...keyedConst('PERMIT_NYC_ADULT'),
  ...keyedConst('PERMIT_NYC_YOUTH'),
  ...keyedConst('PERMIT_NYC_SENIOR'),
  ...keyedConst('PERMIT_NYC_ADULT_IDNYC'),
  ...keyedConst('RES_RESERVATION'),
}

export const unpurchasableSkus = [skus.FEE, skus.TAX, skus.PROMO]

export const purchasableSkus = Object.keys(skus).filter(s => !unpurchasableSkus.includes(s))
