// sorts nulls to last or first depending 
export const compareStrs = ({ nullsFirst=true }) => (a, b) => {
  if (a === null || a === undefined) {
    return nullsFirst ? -1 : 1
  }
  if (b === null || b === undefined) {
    return nullsFirst ? 1 : -1
  }
  return a.localeCompare(b)
}
