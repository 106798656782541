import { useEffect, useState } from 'react'
import { Select } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { useStatus, useStatusMsg } from '../reducers'
import { clearStatus } from '../actions/status'
import { fetchConstsByName } from '../actions/admin'
import { actions as acts } from '../constants'

const { Option } = Select

// generates a selector for consts from the backend
// name is the file name, key is the from the file's default export
export const ConstSelect = ({ name, constKey, value, onChange, ...props }) => {
  const dispatch = useDispatch()
  const [val, setVal] = useState(value)
  const changer = typeof onChange === 'function' ? onChange : () => {}
  const [options, setOptions] = useState(null)
  const status = useStatus(acts.FETCH_CONSTS_BY_NAME)

  useEffect(() => {
    dispatch(fetchConstsByName({ name }))
      .then(res => {
        const data = res[constKey]
        // considers keyedConsts, descConsts, valuedConsts, and labelConsts
        setOptions(Object.keys(data).map(k => {
          const v = data[k]
          if (typeof v === 'string') {
            return {
              name: v,
              label: v,
            }
          }
          if (typeof v === 'obj') {
            const n = v.hasOwnProperty('name') ? v.name : k
            return {
              name: n,
              label: v.hasOwnProperty('label') ? v.label : n,
              description: v.hasOwnProperty('description') ? v.description : undefined,
            }
          }
          return {
            name: k,
            label: k,
          }
        }))
      })
      return () => dispatch(clearStatus(acts.FETCH_CONSTS_BY_NAME))
  }, [name, constKey])

  useStatusMsg(status, { error: `Failed to fetch const: name=${name} constKey=${constKey}` })

  if (!status.success || !options) {
    return <LoadingOutlined />
  }

  const opts = options.map(o => {
    return (
      <Option key={o.name} value={o.name} data-name={o.label}>
        <div>{o.label}</div>
        {o.description && <div><small>{o.description}</small></div>}
      </Option>
    )
  })

  const handleChange = name => {
    setVal(name)
    changer(name)
  }

  return (
    <Select
      value={val}
      onChange={handleChange}
      {...props}
    >
      {opts}
    </Select>
  )
}
