import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { LoadingOutlined, DownOutlined } from '@ant-design/icons'
import { Link, Switch, Route, Redirect, useHistory, useParams } from 'react-router-dom'
import { Modal, Menu, Dropdown, Button, Breadcrumb, Tabs } from 'antd'
import { fetchProPage, fetchProShop, deleteProPage, deleteProShop } from '../../../actions/admin'
import { clearStatus } from '../../../actions/status'
import { fetchProPrograms } from '../../../actions/api'
import { paths, patterns, actions as acts } from '../../../constants'
import { useStatus, useStatusMsg } from '../../../reducers'
import { formatName } from '../../../util/user'
import { NotFoundError } from '../../../util/error'
import Overview from './Overview'
import Programs from './Programs'
import Shop from './Shop'
import AddProPageModal from './AddProPageModal'
import AddProShopModal from './AddProShopModal'

const { TabPane } = Tabs

const Pro = () => {
  const { id } = useParams()
  const history = useHistory()
  const [pro, setPro] = useState(null)
  const [programs, setPrograms] = useState([])
  const [shop, setShop] = useState(null)
  const [activeTab, setActiveTab] = useState('overview')
  const [editingPage, setEditingPage] = useState(false)
  const [creatingShop, setCreatingShop] = useState(false)
  const [editingShop, setEditingShop] = useState(false)
  const dispatch = useDispatch()
  const status = {
    fetch: useStatus(acts.FETCH_PRO_PAGE),
    fetchPrograms: useStatus(acts.FETCH_PRO_PROGRAMS),
    deleteProPage: useStatus(acts.DELETE_PRO_PAGE),
    deleteShop: useStatus(acts.DELETE_PRO_SHOP),
  }

  useStatusMsg(status.fetch, {
    error: 'Failed to load pro',
  })

  useStatusMsg(status.fetchPrograms, {
    error: 'Failed to load pro\'s programs',
  })

  useStatusMsg(status.deleteProPage, {
    error: 'Failed to delete pro page',
  })

  useStatusMsg(status.deleteShop, {
    error: 'Failed to delete pro shop',
  })

  useEffect(() => {
    dispatch(fetchProPage(id))
      .then(proPage => setPro(proPage))
  }, [id])

  useEffect(() => {
    if (pro) {
      dispatch(fetchProPrograms(pro.page.url))
        .then(programs => {
          setPrograms(programs)
        })
    }
    return () => dispatch(clearStatus(acts.FETCH_PRO_PROGRAMS))
  }, [pro])

  useEffect(() => {
    if (pro) {
      dispatch(fetchProShop(pro.page.id))
        .then(ps => setShop(ps))
        .catch(err => {
          if (err instanceof NotFoundError) {
            setShop(null)
          }
        })
    }
  }, [pro])

  if (!pro) {
    return <LoadingOutlined />
  }

  const upsertShopItem = item => {
    const idx = shop.items.findIndex(i => i.id === item.id)
    if (idx !== -1) {
      setShop(prev => {
        const items = [...prev.items]
        items[idx] = item
        return {
          ...prev,
          items,
        }
      })
    } else {
      setShop(prev => {
        return {
          ...prev,
          items: [...prev.items, item],
        }
      })
    }
  }

  const deleteShopItem = item => {
    setShop(prev => {
      return {
        ...prev,
        items: prev.items.filter(i => i.id !== item.id)
      }
    })
  }

  const onDeleteProPage = () =>
    dispatch(deleteProPage(pro.page.id))
      .then(() => history.push(paths.admin.PROS()))

  const onDeleteProShop = () =>
    dispatch(deleteProShop(pro.page.id))
      .then(() => {
        setShop(null)
      })

  const onTabChange = tab => { setActiveTab(tab) }

  const onAddShop = shop => {
    if (shop) {
      setShop(shop)
      setActiveTab('pro-shop')
    }
    setCreatingShop(false)
    setEditingShop(false)
  }

  const handleMenuClick = e => {
    if (e.key === 'delete-page') {
      Modal.confirm({
        title: 'Delete pro page',
        content: 'Deleting this page will only remove the public pro page and pro\'s pro shop. All events hosted or created by the pro will remain.',
        okText: 'Delete',
        okButtonProps: {
          type: 'primary',
          danger: true,
        },
        onOk: onDeleteProPage,
      })
    } else if (e.key === 'edit-page') {
      setEditingPage(true)
    } else if (e.key === 'create-shop') {
      setCreatingShop(true)
    } else if (e.key === 'edit-shop') {
      setEditingShop(true)
    } else if (e.key === 'delete-shop') {
      Modal.confirm({
        title: 'Delete pro shop',
        content: 'All shop items will be removed. Deleting this shop will not remove the pro page',
        okText: 'Delete',
        okButtonProps: {
          type: 'primary',
          danger: true,
        },
        onOk: onDeleteProShop,
      })
    }
  }

  const actionMenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="edit-page">
        Edit page
      </Menu.Item>
      { shop &&
          <Menu.Item key="edit-shop">
            Edit shop
          </Menu.Item>
      }
      { !shop &&
          <Menu.Item key="create-shop">
            Create shop
          </Menu.Item>
      }
      { shop &&
          <Menu.Item key="delete-shop">
            Delete shop
          </Menu.Item>
      }
      <Menu.Item key="delete-page">
        Delete page
      </Menu.Item>
    </Menu>
  )

  return (
    <>
      <Breadcrumb separator=">">
        <Breadcrumb.Item><Link to={paths.admin.PROS()}>Pros</Link></Breadcrumb.Item>
        <Breadcrumb.Item>{formatName(pro.page.user)}</Breadcrumb.Item>
      </Breadcrumb>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <h1>{formatName(pro.page.user)}</h1>
        <Dropdown overlay={actionMenu} trigger={['click']}>
          <Button>
            Actions <DownOutlined />
          </Button>
        </Dropdown>
      </div>
      <Tabs defaultActiveKey={activeTab} onChange={onTabChange} activeKey={activeTab}>
        <TabPane tab="Overview" key="overview">
          <Overview pro={pro} />
        </TabPane>
        <TabPane tab="Programs" key="programs">
          <Programs pro={pro} programs={programs}/>
        </TabPane>
        <TabPane tab="Pro Shop" key="pro-shop">
          <Shop
            pro={pro}
            shop={shop}
            upsertItem={upsertShopItem}
            deleteItem={deleteShopItem}
            createShop={() => setCreatingShop(true)}
          />
        </TabPane>
      </Tabs>
      { editingPage &&
          <AddProPageModal
            isEdit
            complete={updatedPage => {
              if (updatedPage) {
                setPro(prev => ({ ...prev, page: updatedPage }))
              }
              setEditingPage(false)
            }}
            existingProPage={pro.page}
          />
      }
      { (creatingShop || editingShop) &&
          <AddProShopModal
            proPage={pro.page}
            existingShop={shop}
            isEdit={editingShop}
            complete={onAddShop}
          />
      }
    </>
  )
}

export default Pro
