import React, { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Form, Input, Checkbox, Button } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import Editor from '../../components/Editor'
import SingleImageEditor from '../../components/SingleImageEditor'
import { mainSiteClub } from '../../util/url'
import { fetchClubAttributes } from '../../actions/attribute'
import { useStatus } from '../../reducers'
import { actions as acts } from '../../constants'
import SelectAttributes from '../../components/SelectAttributes'
import { SponsorSelect, Sponsor } from '../../components/sponsor'
import { BenefitSelect } from '../../components/benefit'
import { CheckboxHelp, Label } from '../../components/common'
import { Reorderable } from '../../components/Reorderable'
import { AdminVenueSelect } from '../../components/venue'
import { ThemeContext } from 'styled-components'

export const initState = {
  sid: null,
  name: null,
  description: null,
  icon: null,
  cover: null,
  isPublic: null,
  attributes: [],
  tagline: null,
  sponsors: [],
  benefit: null,
  venues: [],
}

const ClubEditor = ({ onChange, club }) => {
  const { osid } = useParams()
  const dispatch = useDispatch()
  const [allClubAttributes, setAllClubAttributes] = useState(null)
  const [sponsors, setSponsors] = useState([])
  const status = useStatus(acts.FETCH_CLUB_ATTRIBUTES)
  const theme = useContext(ThemeContext)

  useEffect(() => {
    if (!allClubAttributes) {
      dispatch(fetchClubAttributes(osid))
        .then(attrs => setAllClubAttributes(attrs))
    }
  }, [])

  useEffect(() => {
    // this takes care of the case where a club.sponsors contains either a club-sponsor
    // or just a sponsor
    if (Array.isArray(club.sponsors)) {
      setSponsors(club.sponsors.map(spn => spn.sponsor ? spn.sponsor : spn))
    } else {
      setSponsors([])
    }
  }, [club.sponsors])
  
  useEffect(() => {
    if (Array.isArray(club.venues)) {
      change({ venues: club.venues.map(cv => cv.venue ? cv.venue : cv) })
    }
  }, [])

  if (!club) {
    if (typeof onChange === 'function') {
      onChange(initState)
    }
    return null
  }

  const { name, sid, description, icon, cover, isPublic, attributes, tagline, benefit } = club

  const change = update => {
    if (typeof onChange === 'function') {
      onChange({ ...club, ...update })
    }
  }

  const onChangeSponsor = sponsor => {
    const sponsorExists = sponsors.find(s => s.id === sponsor.id)
    if (!sponsorExists) {
      change({ sponsors: [...sponsors, sponsor] })
    }
  }

  const onChangeVenue = venue => {
    const venueExists = (club.venues || []).find(v => v.id === venue.id)
    if (!venueExists) {
      change({ venues: [...(club.venues || []), venue] })
    }
  }

  const onChangeBenefit = benefit => change({ benefit })
  const onRemoveBenefit = () => change({ benefit: null })

  return (
    <Form layout="vertical">
      <Form.Item name="name" label={<Label>Name</Label>} initialValue={name}>
        <Input placeholder="" onChange={e => change({ name: e.target.value })} value={name} />
      </Form.Item>
      <Form.Item name="sid" label={<Label>Slug</Label>} initialValue={sid}>
        <Input
          addonBefore={mainSiteClub(' ')}
          placeholder=""
          onChange={e => change({ sid: e.target.value })}
          value={sid}
        />
      </Form.Item>
      <Form.Item name="description" label={<Label>Description</Label>} initialValue={description}>
        <Editor value={description} onChange={content => change({ description: content })} />
      </Form.Item>

      <Form.Item name="tagline" label={<Label>Tagline</Label>} initialValue={tagline}>
        <Input placeholder="" onChange={e => change({ tagline: e.target.value })} value={tagline} />
      </Form.Item>

      <Form.Item name="isPublic" initialValue={isPublic}>
        <div style={{ display: 'grid' }}>
          <Checkbox checked={isPublic} onChange={e => change({ isPublic: e.target.checked })}>
            <Label>Public</Label>
          </Checkbox>
          <CheckboxHelp>A public club is searchable on the main site</CheckboxHelp>
        </div>
      </Form.Item>
      <Form.Item name="icon" label={<Label>Icon</Label>}>
        <SingleImageEditor
          actionText="Select icon"
          onChange={img => change({ icon: img })}
          img={icon}
        />
      </Form.Item>
      <Form.Item name="cover" label={<Label>Cover image</Label>}>
        <SingleImageEditor
          actionText="Select cover"
          onChange={img => change({ cover: img })}
          img={cover}
        />
      </Form.Item>
      <Form.Item name="attributes" label={<Label>Club attributes</Label>}>
        {status.pending ? (
          <LoadingOutlined />
        ) : (
          <SelectAttributes
            allAttributes={allClubAttributes}
            attributes={attributes}
            onChange={attrs => change({ attributes: attrs })}
          />
        )}
      </Form.Item>
      <Form.Item name="sponsors" label={<Label>Sponsors</Label>}>
        <SponsorSelect
          value={null}
          onChange={onChangeSponsor}
          placeholder="Select a sponsor"
          style={{ marginBottom: theme.spacing[1] }}
        />
        <Reorderable
          items={sponsors}
          keyExtractor={s => s.id}
          renderItem={s => (
            <Sponsor key={s.id}>
              <span>{s.name}</span>
              <Button
                type="link"
                onClick={() => change({ sponsors: sponsors.filter(spn => spn.id !== s.id) })}
              >
                Remove
              </Button>
            </Sponsor>
          )}
          onChange={spn => change({ sponsors: [...spn] })}
        />
      </Form.Item>
      <Form.Item name="venues" label={<Label>Venues</Label>}>
        <AdminVenueSelect
          value={null}
          onChange={onChangeVenue}
          placeholder="Select a venue"
          style={{ marginBottom: theme.spacing[1] }}
        />
        {(club.venues || []).map(venue => (
          <div key={venue.id} style={{ marginLeft: theme.spacing[2]}}>
            <span>{venue.name}</span>
            <Button
              type="link"
              danger
              onClick={() => change({ venues: club.venues.filter(v => v.id !== venue.id) })}
            >
              Remove venue
            </Button>
          </div>
        ))}
      </Form.Item>
      <Form.Item name="benefit" label={<Label>Benefit</Label>}>
        <BenefitSelect
          value={null}
          onChange={onChangeBenefit}
          placeholder="Select a benefit"
          style={{ marginBottom: theme.spacing[1] }}
        />
        {benefit && (
          <div>
            <span>
              {benefit.name} <small>{benefit.sid}</small>
            </span>
            <Button type="link" danger onClick={onRemoveBenefit}>
              Remove benefit
            </Button>
          </div>
        )}
      </Form.Item>
    </Form>
  )
}

export default ClubEditor
