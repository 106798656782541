import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { message, Button } from 'antd'
import { useStatus, useStatusMsg } from '../../reducers'
import { createLocation } from '../../actions/location'
import { FocusHeader } from '../../components/header'
import { Focus as FocusContainer } from '../../components/containers'
import { Cta } from '../../components/common'
import { clearStatus } from '../../actions/status'
import { paths, actions as acts } from '../../constants'
import LocationEditor from './LocationEditor'

const NewLocations = () => {
  const { osid } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const [location, setLocation] = useState(null)
  const status = useStatus(acts.CREATE_LOCATION)

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.CREATE_LOCATION))
    }
  }, [])

  useStatusMsg(status, {
    pending: 'Creating...',
    error: 'Failed to create new location',
    success: res => {
      return (
        <>
          Location created. <a href={paths.org.LOCATION(osid, res.id)}>View</a>
        </>
      )
    },
  })

  const missingFields = () => {
    const missing = ['name', 'addressLine1', 'country', 'city', 'lat', 'lon'].filter(k => {
      if (!location[k]) {
        return true
      }
      return false
    })
    if (missing.length > 0) {
      const msg = `${missing.join(', ')} ${missing.length > 1 ? 'are' : 'is'} required`
      message.error(`${msg.charAt(0).toUpperCase()}${msg.substr(1)}`)
      return true
    }
    return false
  }

  const submitLocation = createAnother => () => {
    if (missingFields()) {
      return
    }

    dispatch(createLocation({ osid, attrs: location }))
      .then(loc => {
        if (createAnother) {
          setLocation(null)
        } else {
          history.push(paths.org.LOCATION(osid, loc.id))
        }
      })
  }

  return (
    <>
      <FocusHeader
        title="Create a location"
        actions={
          <>
            <Button
              style={{marginRight: '.5em'}}
              onClick={submitLocation(true)}
              size="small"
              disabled={status.pending ? true : false}
            >
              Submit and create another
            </Button>
            <Cta
              disabled={status.pending ? true : false}
              onClick={submitLocation(false)}
            >
              {status.pending ? 'Submitting location...' : 'Submit location'}
            </Cta>
          </>
        }
      />
      <FocusContainer style={{paddingTop: '20px', paddingBottom: '20px'}}>
        <LocationEditor location={location} onChange={location => setLocation(location)} />
      </FocusContainer>
    </>
  )
}

export default NewLocations