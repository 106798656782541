import React, { useEffect, useState } from 'react'
import { DownOutlined, LoadingOutlined } from '@ant-design/icons'
import { Table, Badge, Tabs, Dropdown, Breadcrumb } from 'antd'
import { useDispatch } from 'react-redux'
import { Link, useParams, useHistory } from 'react-router-dom'
import { actions as acts, paths } from '../../../constants'
import * as urlUtils from '../../../util/url'
import { formatDateTimeTz }from '../../../util/time'
import * as adminActs from '../../../actions/admin'
import { useStatus, useStatusMsg } from '../../../reducers'
import { Cta, Head, Title, PaddedContainer } from '../../../components/common'
import Attr from '../../../components/Attr'
import ImagePreview from '../../../components/ImagePreview'
import { UpdateCec, ActionsMenu } from './'

const { TabPane } = Tabs

const Noner = ({ children }) => {
  return children ? <div>{children}</div> : <div><i>None</i></div>
}

const Overview = ({ cec }) => {
  return (
    <div>
      <Attr name="URL">
        <div>
          <a href={urlUtils.mainSiteCec(cec.slug)}>{urlUtils.mainSiteCec(cec.slug)}</a>
        </div>
      </Attr>
      <Attr name="Customerio segment">
        <div>{cec.customerioSegmentId}</div>
      </Attr>
      <Attr name="Image">
        <div>
          {cec.image && <ImagePreview asset={cec.image} width="auto" height="150px" />}
          {!cec.image && <i>No image</i>}
        </div>
      </Attr>
      <Attr name="Pre-submission">
        <div>
          <Attr name="Title">
            <Noner>{cec.preTitle}</Noner>
          </Attr>
          <Attr name="Copy">
            <Noner>{cec.preCopy}</Noner>
          </Attr>
          <Attr name="CTA text">
            <Noner>{cec.preCtaText}</Noner>
          </Attr>
        </div>
      </Attr>
      <Attr name="Post-submission">
        <div>
          <Attr name="Title">
            <Noner>{cec.postTitle}</Noner>
          </Attr>
          <Attr name="Copy">
            <Noner>{cec.postCopy}</Noner>
          </Attr>
          <Attr name="CTA text">
            <Noner>{cec.postCtaText}</Noner>
          </Attr>
          <Attr name="CTA URL">
            <Noner>{cec.postCtaUrl}</Noner>
          </Attr>
        </div>
      </Attr>
    </div>
  )
}

const columns = [
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Date captured',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (val, record) => (
      <span>{formatDateTimeTz(val)}</span>
    )
  },
]
const CapturedEmails = ({ cec }) => {
  const emails = cec.capturedEmails.map
  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={cec.capturedEmails}
      pagination={false}
    />
  )
}

const Cec = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const [cec, setCec] = useState(null)
  const [editing, setEditing] = useState(false)
  const status = useStatus(acts.FETCH_CUSTOM_EMAIL_CAPTURE)

  useEffect(() => {
    dispatch(adminActs.fetchCec({ id }))
      .then(c => setCec(c))
  }, [])

  useStatusMsg(status, {
    error: 'Failed to load custom email capture',
  })

  if (!cec) {
    return <LoadingOutlined />
  }

  return (
    <PaddedContainer>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={paths.admin.CUSTOM_EMAIL_CAPTURES()}>cec</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{id}</Breadcrumb.Item>
      </Breadcrumb>
      <Head>
        <Title>{cec.name}</Title>
        <div style={{flexGrow: 1}}></div>
        <div style={{diplay: 'flex'}}>
          <Dropdown
            overlay={
              <ActionsMenu
                onEdit={updatedCec => setEditing(true)}
                onDelete={() => history.push(paths.admin.CUSTOM_EMAIL_CAPTURES())}
                cec={cec}
              />
            }
            trigger={['click']}
            placement="bottomRight"
          >
            <Cta>Actions <DownOutlined /></Cta>
          </Dropdown>
        </div>
      </Head>
      <Tabs defaultActiveKey="overview">
        <TabPane key="overview" tab="Overview">
          <Overview cec={cec} />
        </TabPane>
        <TabPane
          key="emails"
        tab={
          <span>
            Captured emails <Badge
              style={{ backgroundColor: 'white', color: 'inherit' }}
              count={cec.capturedEmails ? cec.capturedEmails.length : 0}
            />
            </span>
        }
        >
          <CapturedEmails cec={cec} />
        </TabPane>
      </Tabs>
      { editing && 
          <UpdateCec
            cec={cec}
            complete={updatedCec => {
              if (updatedCec) {
                setCec(updatedCec)
              }
              setEditing(false)
            }}
          />
      }
    </PaddedContainer>
  )
}

export default Cec
