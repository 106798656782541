import { useReducer, useContext, useState, useEffect } from 'react'
import { ThemeContext } from 'styled-components'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons'
import { message, Input, Modal, Alert, Select } from 'antd'
import { useStatus, useStatusMsg } from '../../reducers'
import { actions as acts } from '../../constants'
import {
  fetchAllVariantOptions,
  fetchProductVariantOptions,
  createVariantOption,
  changeProductVariantOptions,
} from '../../actions/org'
import Attr from '../../components/Attr'
import { Reorderable } from '../../components/Reorderable'
import { Cta, Button, Noner } from '../../components/common'
import { v4 as uuid } from 'uuid'

const { Option } = Select

const VariantOptionPreview = ({ variantOption }) => {
  const valueNames = variantOption.values.map(v => v.name)
  return (
    <span>
      {variantOption.name} <small>{valueNames.join(', ')}</small>
    </span>
  )
}

const initState = {
  name: null,
  values: [],
}

const newValue = () => ({
  id: `new-${uuid()}`,
  name: '',
})

const VariantOptionEditor = ({ variantOption, onChange }) => {
  const theme = useContext(ThemeContext)
  const changer = typeof onChange === 'function' ? onChange : () => {}

  if (!variantOption) {
    changer(initState)
    return null
  }

  const { name, values } = variantOption

  const change = update => {
    if (update.type === 'ADD_VALUE') {
      changer({ 
        ...variantOption,
        values: [...variantOption.values, newValue()],
      })
      return
    } else if (update.type === 'UPDATE_VALUE') {
      const { value } = update
      const next = { ...variantOption }
      // search for value, then replace
      const idx = next.values.findIndex(v => v.id === value.id)
      next.values[idx] = {
        ...next.values[idx],
        ...value,
      }
      changer(next)
      return
    } else if (update.type === 'REORDER') {
      changer({
        ...variantOption,
        values: update.values,
      })
      return
    } else if (update.type === 'REMOVE_VALUE') {
      changer({
        ...variantOption,
        values: variantOption.values.filter(v => v.id !== update.id),
      })
      return
    }
    changer({ ...variantOption, ...update })
  }

  let vals;
  if (Array.isArray(values) && values.length > 0) {
    vals = (
      <Reorderable
        items={values}
        onChange={values => change({ type: 'REORDER', values })}
        keyExtractor={v => v.id}
        renderItem={v =>
          <>
            <Input
              value={v.name}
              onChange={e => change({ type: 'UPDATE_VALUE', value: { ...v, name: e.target.value } })}
            />
            <a onClick={() => change({ type: 'REMOVE_VALUE', id: v.id })}>Remove</a>
          </>
        }
      />
    )
  }
  return (
    <div>
      <span>Create variant option</span>
      <Attr name="Variant option name">
        <Input
          name="name"
          value={name}
          onChange={e => change({ name: e.target.value })}
        />
      </Attr>
      <Attr name="Values">
        <div>
          <div style={{marginBottom: theme.spacing[2]}}>
            {vals}
          </div>
          <Button onClick={() => change({ type: 'ADD_VALUE' })}>
            <PlusOutlined /> Add value
          </Button>
        </div>
      </Attr>
    </div>
  )
}

// returns a cleaned and validated variant option for saving to server,
// else returns a falsy value and prints out an error message
const validateNewVariantOption = vo => {
  if (!vo) {
    message.error('A variant option is required')
    return null
  }
  if (!vo.name) {
    message.error('Variant option name is required')
    return null
  }
  if (!Array.isArray(vo.values) || vo.values.length === 0) {
    message.error('At least one variant option value is required')
    return null
  }
  if (!Array.isArray(vo.values) || vo.values.length === 0) {
    message.error('At least one variant option value is required')
    return null
  }
  const filtered = vo.values.filter(vov => vov.name)
  if (filtered.length === 0) {
    message.error('At least one variant option value is required')
    return null
  }
  const validated = {
    ...vo,
    values: filtered.map(vov => ({
      ...vov,
      // variant option values without ids will be assumed to be new by the server
      name: vov.name.indexOf('new-') === 0 ? undefined : vov.name,
    })),
  }
  return validated
}

export const VariantOptionSelector = ({ value, onChange, ...props }) => {
  const { osid } = useParams()
  const dispatch = useDispatch()
  const [variantOptions, setVariantOptions] = useState(null)
  const [variantOptionMap, setVariantOptionMap] = useState(null)
  const [creating, setCreating] = useState(false)
  const [newVariantOption, setNewVariantOption] = useState(null)
  const status = useStatus(acts.FETCH_ALL_VARIANT_OPTIONS)

  useEffect(() => {
    dispatch(fetchAllVariantOptions({ osid }))
      .then(vos => {
        setVariantOptions(vos)
        setVariantOptionMap(
          vos.reduce((acc, curr) => {
            acc[curr.id] = curr
            return acc
          }, {})
        )
      })
  }, [])
    
  if (!variantOptions || status.pending) {
    return <LoadingOutlined />
  }

  const onSelect = oid => {
    if (typeof onChange === 'function') {
      onChange(variantOptionMap[oid])
    }
  }
  
  const onSaveNewVariantOption = () => {
    const validatedOption = validateNewVariantOption(newVariantOption)
    console.log(validatedOption)
    if (!validatedOption) {
      return
    }
    dispatch(createVariantOption({ osid, ...validatedOption }))
      .then(savedVo => {
        setVariantOptions(prev => [...prev, savedVo])
        setCreating(false)
        setNewVariantOption(null)
      })
  }

  if (creating) {
    return (
      <div>
        <VariantOptionEditor
          variantOption={newVariantOption}
          onChange={nvo => setNewVariantOption(nvo)}
        />
        <div style={{ display: 'flex', justifyContent: 'end'}}>
          <div style={{marginRight: '.75em'}}>
            <Button onClick={() => { setNewVariantOption(null); setCreating(false) }}>Cancel</Button>
          </div>
          <Cta onClick={onSaveNewVariantOption}>Save</Cta>
        </div>
      </div>
    )
  }

  const opts = variantOptions.map(vo => (
    <Option key={vo.id} value={vo.id}>
      <VariantOptionPreview variantOption={vo} />
    </Option>
  ))
  return (
    <Select
      value={value}
      onChange={onSelect}
      dropdownRender={menu => (
        <div>
          {menu}
          <div style={{padding: '.2em .5em', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Button onClick={() => setCreating(true)}>
              <PlusOutlined  /> Create variant option
            </Button>
          </div>
        </div>
      )}
      {...props}
    >
      {opts}
    </Select>
  )
}

const VariantOptionsList = ({ variantOptions, onRemove }) => {
  if (!Array.isArray(variantOptions) || variantOptions.length === 0) {
    return <Noner none="No variant options">{null}</Noner>
  }
  const removable = typeof onRemove === 'function'
  const elems = variantOptions.map(vo => (
    <div key={vo.id} style={{display: 'flex', justifyContent: 'space-between'}}>
      <VariantOptionPreview variantOption={vo} />
      { removable && <a onClick={() => onRemove(vo)}>Remove</a> }
    </div>
  ))
  return (
    <div>
      {elems}
    </div>
  )
}

// returns true if both vo1 and vo2 contain the exact same variant options, regardless of order
const isSameVariantOptions = (vo1, vo2) => {
  const vo1Ids = vo1.map(vo => vo.id)
  const vo2Ids = vo2.map(vo => vo.id)
  return vo1Ids.length === vo2Ids.length && vo1Ids.every(vo1Id => vo2Ids.includes(vo1Id))
}

const MAX_VARIANT_OPTIONS = 3

export const EditVariantsModal = ({ product, complete }) => {
  const { osid } = useParams()
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const [oldSelectedOptions, setOldSelectedOptions] = useState(null)
  const [selectedOptions, setSelectedOptions] = useState(null)
  const status = useStatus(acts.FETCH_PRODUCT_VARIANT_OPTIONS)
  const completer = typeof complete === 'function' ? complete : () => {}

  useEffect(() => {
    dispatch(fetchProductVariantOptions({ osid, productId: product.id }))
      .then(pvo => {
        setOldSelectedOptions(pvo)
        setSelectedOptions(pvo)
      })
  }, [])

  if (status.pending) {
    return <LoadingOutlined />
  }

  const onOk = () => {
    // if the variant options haven't changed, then treat save as a cancel
    if (isSameVariantOptions(oldSelectedOptions, selectedOptions)) {
      completer()
    }
    if (selectedOptions.length > MAX_VARIANT_OPTIONS) {
      message.error(`Cannot have more than ${MAX_VARIANT_OPTIONS} variants. Please remove some and try again`)
      return
    }
    const variantOptionIds = selectedOptions.map(so => so.id)
    dispatch(changeProductVariantOptions({ osid, productId: product.id, variantOptionIds }))
      .then(updatedProduct => completer(updatedProduct))
  }

  const onCancel = () => {
    completer()
  }

  const onAddVariantOption = vo => {
    setSelectedOptions(prev => {
      const next = Array.isArray(prev) ? [...prev, vo] : [vo]
      const existing = {}
      // only allow unique additions of variant options
      return next.filter(vo => {
        if (existing.hasOwnProperty(vo.id)) {
          return false
        }
        existing[vo.id] = true
        return true
      })
    })
  }

  const onRemoveVariantOption = vo => {
    setSelectedOptions(prev => prev.filter(p => p.id !== vo.id))
  }

  return (
    <Modal
      title="Edit variants"
      visible
      okText="Save"
      onOk={onOk}
      onCancel={onCancel}
    >
      <div style={{marginBottom: theme.spacing[3]}}>
        <Alert
          showIcon
          type="warning"
          message="Changing product variants will delete all current product variants and create new ones"
        />
      </div>
      <Attr name="Variant options">
        <div>
          <VariantOptionSelector
            value={null}
            onChange={onAddVariantOption}
            style={{minWidth: '250px'}}
          />
          <div style={{marginTop: theme.spacing[3]}}>
            <VariantOptionsList
              variantOptions={selectedOptions}
              onRemove={onRemoveVariantOption}
            />
          </div>
        </div>
      </Attr>
    </Modal>
  )
}
