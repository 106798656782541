import React, { useReducer, useEffect } from 'react'
import pluralize from 'pluralize'
import { Radio } from 'antd'
import { InputGroup, SelectContainer, Title, Desc } from './step'
import { VenueSelect } from '../../../components/venue'
import { typeLabel } from '../../../util/program'
import { lower } from '../../../util/string'
import Attr from '../../../components/Attr'
import Address from '../../../components/Address'

// Determines the value of info.hasVenue
// Protects against cases where info.hasVenue does not exist
// (i.e. when program is no longer a draft)
const hasVenueValue = program => {
  if (program.draft && program.info) {
    return program.info.hasVenue
  } else {
    if (program.venue) {
      return true
    }
    return false
  }
  return null
}

const normalizeVenue = venue => typeof venue === 'string' ? venue : venue.id

const initState = program => ({
  venue: program.venue ? normalizeVenue(program.venue) : null,
  info: {
    hasVenue: hasVenueValue(program),
  }
})

const reducer = (state, action) => ({ ...state, ...action })

const Venue = ({ clinic, onChange }) => {
  const program = clinic
  const [state, dp] = useReducer(reducer, initState(program))

  useEffect(() => {
    if (typeof onChange === 'function') {
      const data = { ...state, venue: state.venue }
      if (program.draft) {
        onChange(data)
      } else {
        onChange({ ...data, info: undefined })
      }
    }
  }, [state])

  const onSelectChange = e => {
    const update = { info: { hasVenue: e.target.value } }
    if (!e.target.value) {
      update.venue = null
    }
    dp(update)
  }

  let venueDisplay = null
  if (state.info.hasVenue) {
    venueDisplay = (
      <VenueSelect
        placeholder="Select a venue"
        style={{width: '100%'}}
        value={state.venue}
        onChange={v => dp({ venue: v })}
      />
    )
  }

  return (
    <>
      <InputGroup>
        <Title>Venue</Title>
        <Desc>
          <p>
            Select a venue to host this {lower(typeLabel(program))}. {pluralize(typeLabel(program))} hosted by a venue will appear in the venue's checkin screen and on the venue's public homepage
          </p>
        </Desc>
        <SelectContainer>
          <Radio.Group onChange={onSelectChange} value={state.info.hasVenue}>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </SelectContainer>
      </InputGroup>
      {venueDisplay}
    </>
  )
}

const Viewer = ({ program }) => {
  if (!program.venue) {
    return <i>No venue</i>
  }
  if (typeof program.venue === 'string') {
    return (
      <div>
        {program.venue}
      </div>
    )
  }
  if (program.venue.address) {
    return (
      <div>
        <div>{program.venue.name}</div>
        <small><Address.SummaryLine address={program.venue.address} /></small>
      </div>
    )
  }
  return (
    <div>
      {program.venue.name}
    </div>
  )
}

const step = Venue

// ensures that this step is seen at least once, even if it's an optional field
const complete = ({ draft, info, venue }) => {
  if (draft) {
    const venueExists = Boolean(venue)
    if (!info || (typeof info.hasVenue !== 'boolean' || info.hasVenue && !venueExists)) {
      return false
    }
  }
  return true
}

const applicable = () => true

export default { step, Viewer, complete, applicable }
