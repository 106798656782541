import { createBrowserHistory } from 'history'
import { applyMiddleware, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import createRootReducer from './reducers'
import { self } from './actions/user'

export const history = createBrowserHistory()

const middlewares = [routerMiddleware(history), thunk]
if (process.env.NODE_ENV === 'development') {
  const { logger } = require('redux-logger');
  middlewares.push(logger);
}

const composeEnhancers = composeWithDevTools({})
const middleware = composeEnhancers(applyMiddleware(...middlewares))

const store = createStore(createRootReducer(history), { }, middleware)
store.dispatch(self())

export default store
