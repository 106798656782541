import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { LoadingOutlined, PlusCircleFilled } from '@ant-design/icons'
import { Link, useParams } from 'react-router-dom'
import { CtaLink, Head, Title, PaddedContainer } from '../../components/common'
import { paths, features, actions as acts } from '../../constants'
import { useStatus } from '../../reducers'
import { clearStatus } from '../../actions/status'
import { fetchTags } from '../../actions/org'
import { useOrgNotFoundError } from '../../hooks/use-org-not-found-error'

const Tags = () => {
  const { osid } = useParams()
  const [tags, setTags] = useState(null)
  const dispatch = useDispatch()
  const status = useStatus(acts.FETCH_ORG_TAGS)

  useEffect(() => {
    dispatch(fetchTags({ osid }))
      .then(tgs => setTags(tgs))
    return () => {
      dispatch(clearStatus(acts.FETCH_ORG_TAGS))
    }
  }, [osid])

  useOrgNotFoundError(status, 'fetch tags', osid)

  let display = (
    <LoadingOutlined />
  )

  if (Array.isArray(tags)) {
    if (tags.length === 0) {
      display = <i>No tags</i>
    } else {
      display = tags.map(t => (
        <div key={t.id}>
          <Link to={paths.org.ORG_TAG(osid, t.name)}>{t.name}</Link>
        </div>
      ))
    }
  }

  return (
    <PaddedContainer>
      <Head>
        <Title>{features.tag.name.plural}</Title>
        <CtaLink to={paths.org.NEW_ORG_TAG(osid)}>
          <PlusCircleFilled /> Create {features.tag.name.singular.toLowerCase()}
        </CtaLink>
      </Head>
      <div style={{marginTop: '1em'}}>
        {display}
      </div>
    </PaddedContainer>
  )
}

export default Tags
