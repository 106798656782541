import React from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { history } from '../store'
import styled from 'styled-components'
import { isLoggedIn } from '../util/auth'
import Home from '../containers/Home'
import Login from '../containers/Login'
import Orgs from '../containers/orgs'
import AdminApp from './AdminApp'
import OrgRoutes from './org-routes'

const Background = styled.div`
  background-color: ${props => props.theme.color.background};
`

const AuthRoute = ({ component: Component, ...props }) => (
  <Route {...props} render={props => {
    if (!isLoggedIn()) {
      return (
        <Redirect to='/login' />
      )
    }
    return (
      <Component {...props} />
    )
  }} />
)

const App = () => {
  // TODO
  // need to know if a route requires an org
  // if it does, need to know if the correct org is in the store
  // if not, need to get it
  // once correct org in store, then we can render the route
  // else, it's a loading page till then
  return (
    <ConnectedRouter history={history}>
      <Background className="App">
        <Switch>
          <Route path="/admin" component={AdminApp} />
          <Route exact path="/login" render={props => {
            if (isLoggedIn()) {
              return (
                <Redirect to="/" />
              )
            }
            return (
              <Login {...props} />
            )
          }} />
          <Route exact path="/" component={() => <Redirect to="/orgs" />} />
          <AuthRoute exact path="/orgs" component={Orgs} />
          <OrgRoutes />
        </Switch>
      </Background>
    </ConnectedRouter>
  )
}

export default App
