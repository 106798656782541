import React from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'
import { paths, patterns } from '../../../constants'
import Overview from './Overview'
import RallyReport from './RallyReport'
import CompetitionReport from './CompetitionReport'
import Preview from './Preview'

const Index = () => {
  return (
    <Switch>
      <Route exact path={paths.admin.emails.INDEX()} component={Overview} />
      <Route exact path={paths.admin.emails.RALLY_REPORT()} component={RallyReport} />
      <Route exact path={paths.admin.emails.COMPETITION_REPORT()} component={CompetitionReport} />
      <Route exact path={patterns.admin.emails.EMAIL_PREVIEW()} component={Preview} />
    </Switch>
  )
}

export default Index
