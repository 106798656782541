import React, { useState, useEffect, useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { message, Select, Button } from 'antd'
import { EditOutlined, LoadingOutlined } from '@ant-design/icons'
import Tag from '../../../components/Tag'
import PageHeading from '../../../components/admin/PageHeading'
import PageTitle from '../../../components/admin/PageTitle'
import InternalTagLabel from '../../../components/admin/InternalTagLabel'
import { paths, actions as acts } from '../../../constants'
import { fetchProgram, fetchInternalTags } from '../../../actions/admin'
import { useStatus } from '../../../reducers'
import Attr from '../../../components/Attr'
import TagList from '../../../components/TagList'
import { updateProgram } from '../../../actions/admin'

const { Option } = Select

const InternalTags = ({ program, setProgram }) => {
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const internalTags = useSelector(s => s.internalTags)
  const status = useState(acts.UPDATE_ADMIN_PROGRAM)
  const [editing, setEditing] = useState(false)
  const [newTags, setNewTags] = useState(program.internalTags)
  const [tagOptions, setTagOptions] = useState([])

  useEffect(() => { dispatch(fetchInternalTags()) }, [])

  useEffect(() => {
    setTagOptions(internalTags.map(it => (
      <Option key={it.id} value={it.id}>
        <InternalTagLabel {...it} />
      </Option>
    )))
  }, [internalTags])

  const updateTags = async () => {
    try {
      await dispatch(updateProgram(program.id, { internalTags: newTags }))
        .then(p => setProgram(p))
      message.success('Internal tags updated')
      setEditing(false)
    } catch (err) {
      message.error('Failed to update internal tags')
    }
  }

  if (editing) {
    const defaultTags = program.internalTags ? program.internalTags.map(it => ({
      label: <InternalTagLabel {...it.internalTag} />,
      value: it.internalTag.id,
    })) : []
    return (
      <div>
        <span>
          <Select
            mode="multiple"
            labelInValue
            style={{maxWidth: theme.width[5], width: '100%'}}
            placeholder="Add internal tags"
            disabled={status.pending}
            defaultValue={defaultTags}
            onChange={v => setNewTags(v.map(i => i.value))}
          >
           {tagOptions}
          </Select>
        </span>
        <span style={{marginLeft: theme.spacing[2]}}>
          <Button type="primary" size="small" onClick={() => updateTags()}>Save</Button>
          <Button type="link" size="small" onClick={() => { setEditing(false); setNewTags([]) }}>Cancel</Button>
        </span>
      </div>
    )
  }
  return (
    <>
      <Button size="small" onClick={() => setEditing(true)}>
        <EditOutlined />
        <span>Edit</span>
      </Button>
      <TagList tags={program.internalTags.map(it => it.internalTag)} />
    </>
  )
}

const Program = () => {
  const { id } = useParams()
  const theme = useContext(ThemeContext)
  const dispatch = useDispatch()
  const [program, setProgram] = useState(null)
  const status = useStatus(acts.FETCH_PROGRAM)

  useEffect(() => {
    dispatch(fetchProgram(id))
      .then(p => setProgram(p))
  }, [])

  if (!status.success || !program) {
    return (
      <LoadingOutlined />
    )
  }
  return (
    <div>
      <PageHeading>
        <PageTitle>{program.name}</PageTitle>
        { program.draft && <Tag>Draft</Tag> }
      </PageHeading>
      <Attr>
        <Link to={paths.org.PROGRAM(program.org.sid, program.id)}>View in partner portal</Link>
      </Attr>
      <Attr name="Internal Tags">
        <InternalTags program={program} setProgram={setProgram} />
      </Attr>
    </div>
  )
}

export default Program
