import React, { useState, useRef, useMemo } from 'react'
import { Input, Spin, Select } from 'antd'
import debounce from 'lodash/debounce'

export const DebounceSelect = ({
  searcher,
  onResult,
  renderOptions,
  debounceTimeout = 800,
  ...props
}) => {
  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState([])
  const fetchRef = useRef(0)

  const debounceFetcher = useMemo(() => {
    const loadOptions = value => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)
      searcher(value).then(newOptions => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return
        }
        onResult(newOptions)
        setOptions(renderOptions(newOptions))
        setFetching(false)
      })
    }

    return debounce(loadOptions, debounceTimeout)
  }, [searcher, debounceTimeout])

  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
    >
      {options}
    </Select>
  )
}

export const DebounceSearch = ({ searcher, onResult, debounceTimeout = 800, ...props }) => {
  const [fetching, setFetching] = useState(false)
  const fetchRef = useRef(0)

  const debounceFetcher = useMemo(() => {
    const loadOptions = value => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setFetching(true)
      searcher(value).then(newOptions => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return
        }
        onResult(newOptions)
        setFetching(false)
      })
    }

    return debounce(loadOptions, debounceTimeout)
  }, [searcher, debounceTimeout])

  return (
    <Input
      onChange={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
    />
  )
}
