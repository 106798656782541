import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React from 'react'
import ReactDOM from 'react-dom'
// @ts-ignore
import HttpsRedirect from 'react-https-redirect'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import config from './config'
import './index.css'
import App from './nav/App'
import store from './store'
import theme from './theme'
import 'antd/dist/reset.css'

import * as Sentry from '@sentry/react'

Sentry.init({
  dsn: 'https://650f80cd3066a60cd8bd7b413e3ab3bd@o512628.ingest.us.sentry.io/4507454973149184',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.feedbackIntegration({
      colorSchema: "system",
      enableScreenshot: false,
      showBranding: false
    })
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const stripePromise = loadStripe(config.stripePublishableKey)

ReactDOM.render(
  <HttpsRedirect>
    <Elements stripe={stripePromise}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Provider>
    </Elements>
  </HttpsRedirect>,
  document.getElementById('root'),
)
