import { v4 as uuid } from 'uuid'
import { useEffect, useContext, useState } from 'react'
import { ClubContext } from './Club'
import { useDispatch } from 'react-redux'
import { ThemeContext } from 'styled-components'
import { Modal, Tag, Button as AntButton } from 'antd'
import { PlusOutlined, EditFilled } from '@ant-design/icons'
import { Link, useParams } from 'react-router-dom'
import { MediumInfo } from '../../components/info'
import { Button, Cta } from '../../components/common'
import { Reorderable } from '../../components/Reorderable'
import { actions as acts, paths, features } from '../../constants'
import * as orgActs from '../../actions/org'
import { useStatus, useStatusMsg } from '../../reducers'
import { clearStatus } from '../../actions/status'
import { types as productTypes } from '../../constants/product'
import { ProductSelect } from '../../components/product'

const ClubProduct = ({ clubProduct, ...props }) => {
  const { osid } = useParams()
  const p = clubProduct.product
  const type = productTypes[p.type]?.label
  return (
    <span {...props}>
      <Tag>{type}</Tag>
      <Link to={paths.org.PRODUCT(osid, p.id)}>{p.name}</Link>
    </span>
  )
}

const EmptyProducts = () => {
  return (
    <div>
      <i>No {features.product.name.plural}</i>
    </div>
  )
}

const ClubShop = () => {
  const { osid } = useParams()
  const dispatch = useDispatch()
  const { club, setClub } = useContext(ClubContext)
  const theme = useContext(ThemeContext)
  const [editing, setEditing] = useState(false)
  const [products, setProducts] = useState([])
  const status = {
    fetch: useStatus(acts.FETCH_CLUB_PRODUCTS),
    save: useStatus(acts.SET_CLUB_PRODUCTS),
  }

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.SET_CLUB_PRODUCTS))
    }
  }, [])

  useEffect(() => {
    dispatch(orgActs.fetchClubProducts({ osid, clubId: club.id }))
      .then(prds => setProducts(prds))
    return () => {
      dispatch(clearStatus(acts.FETCH_CLUB_PRODUCTS))
    }
  }, [])

  useStatusMsg(status.fetch, {
    error: 'Failed to fetch club products',
  })

  useStatusMsg(status.save, {
    pending: 'Saving club products...',
    success: 'Club products saved',
    error: 'Failed to save club products',
  })

  const onChange = prds => setProducts(prds)
  const onRemove = i => setProducts(prev => prev.filter((_, j) => i !== j))

  const hasProducts = Array.isArray(products) && products.length > 0

  const onClickEdit = () => {
    setEditing(true)
  }

  const onCancelEdit = () => {
    setEditing(false)
    setProducts(club.products)
  }

  const onClickAddProduct = () => {
    setProducts(prev => [null, ...prev])
  }

  const setProduct = (product, i) => {
    setProducts(prev => prev.map((p, j) => {
      if (j === i) {
        // it's not necessary to populate all these wrapper fields, but we do need to wrap,
        // so might as well fill in these fields properly
        const now = new Date().toISOString()
        return {
          id: uuid(),
          createdAt: now,
          updatedAt: now,
          club: club.id,
          order: 0,
          product,
        }
      }
      return p
    }))
  }

  const onSaveProducts = () => {
    const productIds = products.filter(Boolean).map(p => p.product.id)
    dispatch(orgActs.setClubProducts({ osid, clubId: club.id, productIds }))
      .then(updatedProducts => {
        setProducts(updatedProducts)
        setEditing(false)
        setClub(prev => ({ ...prev, products: updatedProducts }))
      })
      .catch(e => console.log(e))
  }

  let display;
  let buttons;
  if (status.fetch.pending) {
    display = 'Loading...'
  } else {
    if (editing) {
      display = (
        <>
          <div style={{marginBottom: theme.spacing[2]}}>
            <Button onClick={onClickAddProduct}><PlusOutlined /> Add product</Button>
          </div>
          {
            hasProducts ? (
            <Reorderable
              items={products}
              keyExtractor={(p, i) => `${i}-${p ? p.id : 'new'}`}
              renderItem={(p, i) => {
                let display;
                if (!p) { // falsy p indicates this item is in the process of adding a product
                  display = (
                    <ProductSelect
                      value={null}
                      excludeIds={products.map(p => p && p.product.id)}
                      onChange={p => setProduct(p, i)}
                      style={{minWidth: '250px'}}
                    />
                  )
                } else {
                  display = (
                    <ClubProduct clubProduct={p} style={{ marginRight: theme.spacing[2] }} />
                  )
                }
                return (
                  <div>
                    {display}
                    <AntButton type="text" danger onClick={() => onRemove(i)}>Remove</AntButton>
                  </div>
                )
              }}
              onChange={onChange}
            />
            ) : <EmptyProducts />
          }
        </>
       )
      buttons = (
        <div>
          <Button
            style={{marginRight: theme.spacing[2]}}
            disabled={status.save.pending}
            onClick={onCancelEdit}
          >
            Cancel
          </Button>
          <Cta
            onClick={onSaveProducts}
            disabled={status.save.pending}
          >
            { status.save.pending ? 'Saving...' : 'Save' }
          </Cta>
        </div>
      )
    } else {
      const elems = hasProducts ? products.map(p => (
        <div key={p.id}>
          <ClubProduct clubProduct={p} />
        </div>
      )) : <EmptyProducts />

      display = elems
      buttons = (
        <Button onClick={onClickEdit}><EditFilled /> Edit</Button>
      )
    }
  }

  let info;
  if (!status.fetch.pending) {
    info = `${hasProducts ? products.length : 0} product${hasProducts && products.length === 1 ? '': 's'}`
  }

  return (
    <MediumInfo
      title="Products"
      button={buttons}
      info={info}
      style={{marginTop: 0}}
    >
      <div>{display}</div>
    </MediumInfo>
  )
}

export default ClubShop
