import { useEffect } from 'react'
import { combineReducers } from 'redux'
import { useSelector } from 'react-redux'
import { connectRouter } from 'connected-react-router'
import { message } from 'antd'
import draftProgram from './draftProgram'
import admin from './admin'
import classes from './classes'
import currentProgram from './current-program'
import internalTags from './internal-tags'
import customLandingPages from './custom-landing-pages'
import attributes from './attributes'
import errors, * as fromErrors from './errors'
import loading, * as fromLoading from './loading'
import success, * as fromSuccess from './success'
import locations from './locations'
import users from './users'
import user from './user'
import self from './self'
import org from './org'

const createRootReducer = history => combineReducers({
  router: connectRouter(history),
  org,
  self,
  user,
  admin,
  draftProgram,
  classes,
  currentProgram,
  errors,
  loading,
  success,
  internalTags,
  customLandingPages,
  locations,
  attributes,
  users,
})
export default createRootReducer

// selectors
export const getError = (state, actionName) => fromErrors.getError(state.errors, actionName)
export const getLoading = (state, actionName) => fromLoading.getLoading(state.loading, actionName)
export const getSuccess = (state, actionName) => fromSuccess.getSuccess(state.success, actionName)

export const useError = actionName => useSelector(state => getError(state, actionName))
export const useLoading = actionName => useSelector(state => getLoading(state, actionName))
export const useSuccess = actionName => useSelector(state => getSuccess(state, actionName))
export const useStatus = actionName => ({
  pending: useLoading(actionName),
  error: useError(actionName),
  success: useSuccess(actionName),
})

/**
 * Custom hook to display status messages based on the status object.
 * If any status message is a function, it calls the function with
 * the payload of the status and uses the return value as the content for the message.
 *
 * @param {Object} status - The status object containing the status of an operation.
 * @param {boolean} status.pending - Indicates if the operation is pending.
 * @param {boolean} status.error - Indicates if there was an error in the operation.
 * @param {boolean} status.success - Indicates if the operation was successful.
 * @param {Object} msgs - The messages object containing the messages to display.
 * @param {string|function} msgs.pending - The message to display when the operation is pending. Can be a string or a function that returns a string.
 * @param {string|function} msgs.error - The message to display when there is an error. Can be a string or a function that returns a string.
 * @param {string|function} msgs.success - The message to display when the operation is successful. Can be a string or a function that returns a string.
 */
export const useStatusMsg = (status, msgs) => {
  useEffect(() => {
    // TODO because we don't use a unique key this will overwrite when multiple
    // useStatusMsg() calls are used at the same time
    const key = 'key-status-msg'
    // TODO we have to destroy the pending message if there's no success message that follows it
    if (status.pending && msgs.pending) {
      if (typeof msgs.pending === 'function') {
        message.loading({ content: msgs.pending(status.pending), duration: 0, key })
      } else {
        message.loading({ content: msgs.pending, duration: 0, key })
      }
    }
    if (status.error && msgs.error) {
      if (typeof msgs.error === 'function') {
        message.error({ content: msgs.error(status.error), key })
      } else {
        message.error({ content: msgs.error, key })
      }
    }
    if (status.success && msgs.success) {
      if (typeof msgs.success === 'function') {
        message.success({ content: msgs.success(status.success), key })
      } else {
        message.success({ content: msgs.success, key })
      }
    }
  }, [status.pending, status.error, status.success])
}
