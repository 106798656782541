import { actions as acts } from '../constants'

const internalTags = (state=[], { type, payload }) => {
  switch(type) {
    case acts.FETCH_INTERNAL_TAGS_SUCCESS:
      return payload
    case acts.CREATE_INTERNAL_TAG_SUCCESS:
      return [...state, payload]
    case acts.DELETE_INTERNAL_TAG_SUCCESS:
      return state.filter(it => it.id !== payload.id)
    default:
      return state
  }
}

export default internalTags
