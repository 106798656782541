import React, { useState } from 'react'
import { roles } from '../constants/club'
import { Select } from 'antd'

const { Option } = Select

const ClubRoleSelect = ({ value, onChange, ...props }) => {
  const [val, setVal] = useState(value === null ? roles.Member : value)

  const opts = Object.keys(roles).map(k => (
    <Option key={k} value={k}>{k}</Option>
  ))

  const handleChange = role => {
    setVal(role)
    if (typeof onChange === 'function') {
      onChange(role)
    }
  }

  return (
    <Select
      value={val}
      onChange={handleChange}
      showSearch
      filterOption={(input, option) => {
        const inp = input.toLowerCase()
        const value = option.children
        // value[0] references the club role's name
        const matchesOption = value[0].toLowerCase().indexOf(inp) >= 0
        return matchesOption || option.value.toLowerCase().indexOf(inp) >= 0
      }}
      {...props}
    >
      {opts}
    </Select>
  )
}

export default ClubRoleSelect
