import React, { useEffect, useState, useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Table, Row, Col } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { fetchUsers } from '../../../actions/admin'
import { actions as acts, paths } from '../../../constants'
import { types as userTypes } from '../../../constants/user'
import { clearStatus } from '../../../actions/status'
import { useStatus } from '../../../reducers'
import { Link } from 'react-router-dom'
import * as urlUtils from '../../../util/url'
import AddProPageModal from './AddProPageModal'

const Pros = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const theme = useContext(ThemeContext)
  const users = useSelector(s => s.users.data)
  const [creating, setCreating] = useState(false)
  const [creatingPro, setCreatingPro] = useState(null)
  const status = useStatus(acts.FETCH_USERS)
  const columns = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      render: (val, record) => {
        return (
          <>
            <Link to={paths.admin.USER(record.id)}>{val}</Link>
            <div style={{color: theme.color.gray[2]}}>{record.email}</div>
          </>
        )
      }
    },
    {
      title: 'Pro page URL',
      key: 'page',
      dataIndex: 'page',
      render: (val, record) => {
        if (val) {
          if (!val.url) {
            return <i>No URL</i>
          }
          return (
            <a href={urlUtils.mainSiteProPage(val.url)}>
              {urlUtils.mainSiteProPage(val.url)}
            </a>
          )
        }
        return <i>N/A</i>
      }
    },
    {
      title: 'Actions',
      key: 'type',
      dataIndex: 'type',
      render: (val, record) => {
        if (record.page) {
          return (
            <Link to={paths.admin.PRO_PAGE(record.page.id)}>
              <Button>View pro page</Button>
            </Link>
          )
        } else {
          return (
            <Button
              type="primary"
              onClick={() => { setCreating(true); setCreatingPro(record); }}
            >
              Add pro page
            </Button>
          )
        }
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchUsers('type[]=PRO'))
    return () => {
      dispatch(clearStatus(acts.FETCH_USERS))
    }
  }, [])

  const onCreateProPage = page => {
    if (page) {
      history.push(paths.admin.PRO_PAGE(page.id))
    }
    setCreating(false)
    setCreatingPro(null)
  }

  return (
    <div>
      <h1>Pros</h1>
      { status.pending && <LoadingOutlined />}
      { !status.pending &&
        <>
          <Row style={{ marginBottom: "20px" }}>
            <Col span={12}>
              <Table
                columns={columns}
                dataSource={users}
              />
            </Col>
          </Row>
        </>
      }
      { creating && <AddProPageModal complete={onCreateProPage} creatingPro={creatingPro} /> }
    </div>
  )
}

export default Pros
