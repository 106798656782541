import React, { useEffect, useContext } from 'react'
import { useSelector } from 'react-redux'
import styled, { ThemeContext } from 'styled-components'
import { Menu, Breadcrumb, Badge } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { Redirect, Link, useParams, useRouteMatch } from 'react-router-dom'
import { actions, paths } from '../../constants'
import { PROGRAM } from '../../constants/marketingLabels'
import { Status } from '../../components/program'
import * as programUtils from '../../util/program'
import capitalize from 'capitalize'
import pluralize from 'pluralize'

export const OuterContent = styled.div`
  padding-bottom: ${props => props.theme.spacing[5]};

  @media (min-width: ${props => props.theme.breakpoints.lg}px) {
    padding: 0 ${props => props.theme.gutterSpace};
  }
  @media (max-width: ${props => props.theme.breakpoints.lg}px) {
    padding: 0 ${props => props.theme.spacing[2]};
  }
`

export const MainContent = styled.div`
  grid-start-column: 2;

  @media (min-width: ${props => props.theme.breakpoints.lg}px) {
    padding: ${props => props.theme.spacing[4]} ${props => props.theme.spacing[4]};
  }
  @media (max-width: ${props => props.theme.breakpoints.lg}px) {
    padding: ${props => props.theme.spacing[3]};
  }
`

const Container = ({ children }) => {
  const { osid, id } = useParams()
  const theme = useContext(ThemeContext)
  const match = useRouteMatch()
  const page = match.path.substring(match.path.lastIndexOf('/') + 1)
  const program = useSelector(s => s.currentProgram)
  const numBookings = programUtils.countBookings(program.bookings)
  const numReservations = Array.isArray(program.resourceReservations) ? program.resourceReservations.length : 0
  const numBenefits = Array.isArray(program.associatedBenefits) ? program.associatedBenefits.length : 0

  return (
    <OuterContent>
      <Breadcrumb style={{ marginTop: theme.spacing[4], marginBottom: theme.spacing[3] }}>
        <Breadcrumb.Item><Link to={paths.org.PROGRAMS(osid)}>{ capitalize(pluralize(PROGRAM)) }</Link></Breadcrumb.Item>
        <Breadcrumb.Item>{program ? program.name : id} <Status status={program.status} /></Breadcrumb.Item>
      </Breadcrumb>

      <div style={{marginBottom: theme.spacing[3]}}>
        <div><small>{programUtils.dateSummary(program)}</small></div>
        <div><small>{programUtils.timeSummary(program)}</small></div>
        <div><small>{programUtils.locationName(program)}</small></div>
      </div>

      <div
        style={{
          background: '#FFF',
          display: 'grid',
          gridTemplateColumns: '20vw auto',
        }}
      >
      <div style={{width: '20vw'}}>
        <Menu defaultSelectedKeys={[page]}>
          <Menu.Item key="overview">
            <Link to={paths.org.OVERVIEW(osid, id)}>Overview </Link>
          </Menu.Item>
          <Menu.Item key="bookings">
            <Link to={paths.org.BOOKINGS(osid, id)}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <span style={{marginRight: theme.spacing[2]}}>Bookings</span>
                <Badge
                  showZero
                  color={numBookings > 0 ? null : 'gray'}
                  count={numBookings}
                />
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item key="posts">
            <Link to={paths.org.PROGRAM_POSTS(osid, id)}>Posts</Link>
          </Menu.Item>
          <Menu.Item key="reservations">
            <Link to={paths.org.RESERVATIONS(osid, id)}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <span style={{marginRight: theme.spacing[2]}}>Reservations</span>
                <Badge
                  showZero
                  color={numReservations > 0 ? null : 'gray'}
                  count={numReservations}
                />
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item key="benefits">
            <Link to={paths.org.PROGRAM_BENEFITS(osid, id)}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <span style={{marginRight: theme.spacing[2]}}>Benefits</span>
                <Badge
                  showZero
                  color={numBenefits > 0 ? null : 'gray'}
                  count={numBenefits}
                />
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item key="tags">
            <Link to={paths.org.PROGRAM_TAGS(osid, id)}>Tags</Link>
          </Menu.Item>
          <Menu.Item key="settings">
            <Link to={paths.org.PROGRAM_SETTINGS(osid, id)}>Settings</Link>
          </Menu.Item>
        </Menu>
      </div>
        <MainContent>
          {program ? children : <LoadingOutlined />}
        </MainContent>
      </div>
    </OuterContent>
  )
}

export default Container
