import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  InputGroup,
  SelectContainer,
  Title,
  Desc,
} from './step'
import { typeLabel } from '../../../util/program'
import { lower } from '../../../util/string'
import { s3Asset } from '../../../util/url'
import styled from 'styled-components'
import Attr from '../../../components/Attr'
import { v4 as uuid } from 'uuid'
import { MultiImageUpload } from '../../../components/image-upload'

const ImageUploads = ({ onChange, clinic }) => {
  const dispatch = useDispatch()
  const [images, setImages] = useState(
    clinic.images.length >= 1 ? clinic.images : [],
  )

  useEffect(() => {
    if (clinic.images.length) {
      setImages(clinic.images)
    }
  }, [clinic.images])

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange({ images })
    }
  }, [images])

  useEffect(() => {
    // Revoke data URIs to avoid memory leaks
    return () => images.forEach(file => URL.revokeObjectURL(file.preview))
  }, [images])

  return (
    <>
      <InputGroup>
        <Title>Photo Uploads</Title>
        <Desc>Add new photos for this {lower(typeLabel(clinic))}?</Desc>
        <SelectContainer style={{ flexDirection: 'column' }}>
          <MultiImageUpload images={images} onChange={newImages => setImages(newImages)} />
        </SelectContainer>
      </InputGroup>
    </>
  )
}

const Viewer = ({ program }) => {
  if (!program.images || program.images.length === 0) {
    return <i>No images</i>
  } else {
    const images = program.images
      .sort((a,b) => a.order - b.order)
      .map(img => (
        <img
          key={img.id}
          src={
            img.hasOwnProperty('asset') ? s3Asset(img.asset) : img.preview
          }
          width={100}
          height={100}
          alt="img"
          style={{
            margin: '10px 10px 5px 0',
            objectFit: 'cover',
          }}
        />
      ))
    return (
      <Attr>{images}</Attr>
    )
  }
}

const step = ImageUploads

const complete = () => true

const applicable = () => true

export default { step, Viewer, complete, applicable }
