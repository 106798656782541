import { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Breadcrumb, Button, Dropdown, Menu, Modal, Table, message } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { ThemeContext } from 'styled-components'
import { useStatus, useStatusMsg } from '../../../reducers'
import { actions as acts, paths } from '../../../constants'
import { fetchBenefits } from '../../../actions/admin'
import { Title, PaddedContainer, Head, Cta as CtaButton } from '../../../components/common'
import { Creator } from '../../../components/creator'

const Benefits = () => {
  const dispatch = useDispatch()
  const status = useStatus(acts.FETCH_ADMIN_BENEFITS)
  useStatusMsg(status, { error: 'Failed to fetch benefits' })
  const [benefits, setBenefits] = useState([])
  const [settingsInfo, setSettingsInfo] = useState(null)
  const [creating, setCreating] = useState(false)
  const [editOrg, setEditOrg] = useState(false)
  const theme = useContext(ThemeContext)

  useEffect(() => {
    dispatch(fetchBenefits()).then(bens => setBenefits(bens))
  }, [])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (val, record) => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Link to={paths.admin.BENEFIT(record.sid)}>{val}</Link>
          <pre><small style={{ color: 'gray' }}>{record.sid}</small></pre>
        </div>
      ),
    },
    {
      title: 'Org',
      dataIndex: 'org',
      key: 'org',
      render: (val, record) => {
        return (
          <Link to={paths.admin.ORG(record.org.sid)}>{record.org.name}</Link>
        )
      },
    },
    {
      title: 'Created',
      dataIndex: 'creator',
      key: 'creator',
      render: (val, record) => {
        return (
          <Creator {...record} />
        )
      },
    },
  ]
  return (
    <PaddedContainer>
      <Breadcrumb>
        <Breadcrumb.Item style={{visibility: 'hidden'}}>Benefits</Breadcrumb.Item>
      </Breadcrumb>
      <Head
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: theme.spacing[3],
        }}
      >
        <Title>Benefits</Title>
        {/*<CtaButton onClick={() => setCreating(true)}>New</CtaButton>*/}
      </Head>
      {status.pending && <LoadingOutlined />}
      <Table
        dataSource={benefits}
        columns={columns}
        pagination={false}
        size="small"
      />
    </PaddedContainer>
  )
}

export default Benefits
