import React, { useReducer, useEffect } from 'react'
import { Button } from 'antd'
import { InputGroup, Title, Desc } from './step'
import { SportSelect } from '../../../components/sport'
import { btlSports } from '../../../constants'
import theme from '../../../theme'
import { typeLabel } from '../../../util/program'
import { lower } from '../../../util/string'

// Determines the value of info.hasSports
// Protects against cases where info.hasSports does not exist
// (i.e. when program is no longer a draft)
const hasSportsValue = program =>
  program.draft && program.info ? !!program.info.hasSports : !!program.sports

const initState = program => ({
  sports: Array.isArray(program.sports) ? program.sports : [],
  info: {
    hasSports: hasSportsValue(program),
  },
})

const reducer = (state, action) => ({ ...state, ...action })

const Sports = ({ clinic, onChange }) => {
  const program = clinic
  const [state, dp] = useReducer(reducer, initState(program))

  useEffect(() => {
    if (typeof onChange === 'function') {
      const data = {
        ...state,
        sports: state.sports,
      }
      onChange(data)
    }
  }, [state])

  let sportsDisplay = null

  if (state.sports) {
    let sportsList = <i>No sports</i>
    if (Array.isArray(state.sports) && state.sports.length > 0) {
      sportsList = (
        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: theme.spacing[1] }}>
          {state.sports.map(s => {
            const sport = btlSports[s]
            return (
              <span key={sport.name} style={{ marginBottom: theme.spacing[1] }}>
                {sport.label}
                <Button
                  type="link"
                  style={{ padding: 0, marginLeft: theme.spacing[1] }}
                  onClick={() => onRemove(sport)}
                >
                  Remove
                </Button>
              </span>
            )
          })}
        </div>
      )
    }

    const onRemove = sport => {
      dp({
        sports: state.sports.filter(s => s !== sport.name),
        info: { hasSports: state.sports.length > 1 },
      })
    }
    const onSelectSport = sport => {
      if (state.sports.indexOf(sport) === -1) {
        dp({ sports: [...state.sports, sport], info: { hasSports: true } })
      }
    }

    sportsDisplay = (
      <div>
        <SportSelect
          value={null}
          style={{ width: '100%', marginBottom: '1em' }}
          placeholder="Select a sport"
          onChange={onSelectSport}
        />
        <div>{sportsList}</div>
      </div>
    )
  }

  return (
    <>
      <InputGroup>
        <Title>Sports</Title>
        <Desc>
          <p>Select sports for this {lower(typeLabel(program))}:</p>
        </Desc>
      </InputGroup>
      {sportsDisplay}
    </>
  )
}

const Viewer = ({ program }) => {
  if (!Array.isArray(program.sports) || program.sports.length === 0) {
    return <i>No sports</i>
  }
  return (
    <div>
      {program.sports.map(s => (
        <div key={s} style={{ marginBottom: theme.spacing[1] }}>
          {btlSports[s].label}
        </div>
      ))}
    </div>
  )
}

const step = Sports

const complete = ({ info, draft }) => {
  if (draft) {
    return info.hasOwnProperty('hasSports')
  }
  return true
}

const applicable = () => true

export default { step, Viewer, complete, applicable }
