import React, { useState, useEffect, useContext, useRef, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { ThemeContext } from 'styled-components'
import { types as userTypes } from '../constants/user'
import { fetchUsers } from "../actions/org";
import { fetchUsers as fetchUsersAdmin } from "../actions/admin";
import { Select, Input, Spin } from 'antd'
import debounce from 'lodash/debounce'
import { useParams } from 'react-router-dom';

const { Option } = Select

export const Selector = ({ value, userType, onChange, ...props }) => {
  const dispatch = useDispatch()
  const [val, setVal] = useState(null)
  const [users, setUsers] = useState({})
  const { osid } = useParams()

  const handleChange = ({ value }) => {
    setVal(value)
    if (typeof onChange === 'function') {
      const user = users[value]
      onChange(user)
    }
  }

  return (
    <DebounceSelect
      showSearch
      value={val}
      placeholder="Search user"
      searcher={searchInput =>
        osid
          ? dispatch(fetchUsers({ osid, sort: { column: 'name', order: 'asc' }, searchInput }))
          : dispatch(fetchUsersAdmin({ sort: { column: 'name', order: 'asc' }, searchInput }))
      }
      onChange={handleChange}
      users={users}
      setUsers={setUsers}
      style={{ width: '100%' }}
    />
  )
}

function DebounceSelect({ searcher, setUsers, debounceTimeout = 800, ...props }) {
  const theme = useContext(ThemeContext)
  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState([])
  const fetchRef = useRef(0)

  const debounceFetcher = useMemo(() => {
    const loadOptions = value => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)
      searcher(value).then(newOptions => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return
        }
        const userMap = newOptions.data.reduce((acc, curr) => {
          acc[curr.id] = curr
          return acc
        }, {})

        setUsers(userMap)
        setOptions(
          Object.values(userMap).map(u => (
            <Option key={u.id} value={u.id}>
              {u.name} <span style={{fontSize: '12px', color: theme.font.color.secondary}}>{u.email}</span>
            </Option>
          ))
        )
        setFetching(false)
      })
    }

    return debounce(loadOptions, debounceTimeout)
  }, [searcher, debounceTimeout])

  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
    >
      {options}
    </Select>
  )
}

const ProSelector = ({ ...props }) => {
  return (
    <Selector {...props} userType={userTypes.PRO.name} />
  )
}

const UserSelect = Selector
UserSelect.Pro = ProSelector

export default UserSelect
