import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { LoadingOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { Link, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { paths, actions as acts } from '../constants'
import { fetchSponsors, fetchSponsor } from '../actions/org'
import { useStatus, useStatusMsg } from '../reducers'
import { Select } from 'antd'

const { Option } = Select

export const Sponsor = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgb(204, 204, 204);
  padding: .25em .5em;
  background-color: white;
  margin-bottom: 0.5em;
  max-width: 25em;
`

export const SponsorList = ({ sponsors, onRemove, link=false }) => {
  const removable = typeof onRemove === 'function'
  let display = (
    <i>No sponsors</i>
  )
  if (Array.isArray(sponsors) && sponsors.length > 0) {
    display = sponsors.map(spn => (
      <Sponsor key={spn.id}>
        <SponsorItem sponsor={spn} link={link} />
        { removable && <Button type="link" onClick={() => onRemove(spn)}>Remove</Button> }
      </Sponsor>
    ))
  }
  return (
    <div>
      {display}
    </div>
  )
}

export const SponsorItem = ({ id, sponsor, link=false }) => {
  const { osid } = useParams()
  const dispatch = useDispatch()
  const [spn, setSpn] = useState(sponsor)

  useEffect(() => {
    if (!sponsor && id) {
      dispatch(fetchSponsor({ osid, id }))
        .then(s => setSpn(s))
    }
  }, [id, sponsor])

  if (!spn) {
    return <LoadingOutlined />
  }

  return (
    link ? <Link to={paths.org.SPONSOR(osid, spn.id)}>{spn.name}</Link> : <span>{spn.name}</span>
  )
}

export const SponsorSelect = ({ value, onChange, ...props }) => {
  const { osid } = useParams()
  const dispatch = useDispatch()
  const [sponsors, setSponsors] = useState({}) // map of sponsor id to sponsor object
  const [val, setVal] = useState(null)
  const status = useStatus(acts.FETCH_SPONSORS)

  useEffect(() => {
    dispatch(fetchSponsors({ osid }))
      .then(spnsrs => {
        setSponsors(
          spnsrs.reduce((acc, curr) => {
            acc[curr.id] = curr
            return acc
          }, {})
        )
      })
  }, [osid])

  useEffect(() => {
    // convert value to sponsor id when sponsor list or value changes
    if (sponsors && Object.keys(sponsors).length > 0 && value) {
      setVal(value.id)
    }
  }, [sponsors, value])

  useStatusMsg(status, { error: 'Failed to fetch sponsors' })

  if (!status.success) {
    return 'Loading...'
  }

  const opts = Object.keys(sponsors).map(id => {
    const s = sponsors[id]
    return (
      <Option key={s.id} value={s.id} data-name={s.name}>
        {s.name}
      </Option>
    )
  })

  const handleChange = sid => {
    setVal(sid)
    if (onChange) {
      onChange(sponsors[sid])
    }
  }

  return (
    <Select
      value={val}
      onChange={handleChange}
      showSearch
      filterOption={(input, option) => { 
        const inp = input ? input.toLowerCase() : ''
        const matchesOption = option['data-name'].trim().toLowerCase().indexOf(inp) >= 0
        return matchesOption || option.value.toLowerCase().indexOf(inp) >= 0
      }}
      {...props}
    >
      {opts}
    </Select>
  )
}
