import React from 'react'
import PageHeading from '../../../components/admin/PageHeading'
import PageTitle from '../../../components/admin/PageTitle'

const Dashboard = () => {
  return (
    <div>
      <PageHeading>
        <PageTitle>Dashboard</PageTitle>
      </PageHeading>
    </div>
  )
}

export default Dashboard
