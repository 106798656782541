import React, { useEffect, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { useStatus, useStatusMsg } from '../../../reducers'
import { actions as acts } from '../../../constants'
import { getRegisteredEmails } from '../../../actions/admin'
import { Link } from 'react-router-dom'
import { paths } from '../../../constants'
import PageHeading from '../../../components/admin/PageHeading'
import PageTitle from '../../../components/admin/PageTitle'

const Overview = () => {
  const dispatch = useDispatch()
  const [registeredEmails, setRegisteredEmails] = useState([])
  const status = useStatus(acts.GET_REGISTERED_EMAILS)

  useEffect(() => {
    dispatch(getRegisteredEmails())
      .then(emails => setRegisteredEmails(emails))
  }, [])

  return (
    <>
      <PageHeading>
        <PageTitle>Emails</PageTitle>
      </PageHeading>
      <div>
        {status.pending && <LoadingOutlined /> }
        {status.success && registeredEmails.map(e => {
          return (
            <div>
              <Link to={paths.admin.emails.PREVIEW(e)} key={e}>{e}</Link>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default Overview
