
// simple server query order serializer for a single order usecase. a generalized serializer would take into account multiple sorts
export const serializeOrder = ({ column, order }={}) => {
  if (!column && !order) {
    return null
  }
  let direction = ''
  // default is asc which is '+' or ''
  if (typeof order === 'string' && order.toLowerCase() === 'desc') {
    direction = '-'
  }
  return `${direction}${column}`
}

export default {
  serializeOrder,
}
