import { actions as acts } from '../constants'
import { initState } from './user'

const self = (state=initState, { type, payload }) => {
  switch(type) {
    case acts.FETCH_SELF_SUCCESS:
      return payload
  }
  return state
}

export default self
