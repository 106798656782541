import React from 'react'
import { playerLevels } from '../constants/user'
import Tag from './Tag'

const PlayerLevel = ({ user }) => {
  if (!user) {
    return null
  }
  let level = playerLevels[user.playerLevel]
  if (!level) {
    level = { label: 'Level Unknown' }
  }
  return (
    <Tag>{level.label}</Tag>
  )
}

export default PlayerLevel
