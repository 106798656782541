import { useStatus, useStatusMsg } from '../../../reducers'
import { actions as acts, paths } from '../../../constants'
import { LoadingOutlined, EllipsisOutlined } from '@ant-design/icons'
import { Breadcrumb, Button, Dropdown, Menu, Modal, Table, message } from 'antd'
import { Title, PaddedContainer, Head, Cta as CtaButton } from '../../../components/common'
import { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { fetchOrgs, createOrg, deleteOrg, updateOrg } from '../../../actions/admin'
import { Link, useHistory, useParams } from 'react-router-dom'
import JSONModal from '../../../components/json-modal'
import { clearStatus } from '../../../actions/status'
import OrgEditor from './OrgEditor'
import { ThemeContext } from 'styled-components'

const CreateOrg = ({ complete }) => {
  const [org, setOrg] = useState(null)
  const dispatch = useDispatch()
  const status = useStatus(acts.CREATE_ORG)

  useStatusMsg(status, {
    pending: 'Creating...',
    error: 'Failed to create org',
    success: 'Org created',
  })

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.CREATE_ORG))
    }
  }, [])

  const onOk = () => {
    //Required fields
    const mfs = ['name'].filter(k => !org[k])
    if (!org || mfs.length > 0) {
      const labels = mfs.join(', ')
      const msg = `${labels} ${mfs.length > 1 ? 'are' : 'is'} required`
      message.error(msg)
      return
    }

    dispatch(createOrg({ org })).then(createdOrg => {
      if (typeof complete === 'function') {
        complete(createdOrg)
      }
    })
  }

  return (
    <Modal
      title="Create org"
      open={true}
      okText="Create"
      okButtonProps={{ disabled: Boolean(status.pending), loading: Boolean(status.pending) }}
      onCancel={() => (typeof complete === 'function' ? complete() : null)}
      onOk={onOk}
    >
      <OrgEditor org={org} onChange={org => setOrg(org)} />
    </Modal>
  )
}

export const EditOrg = ({ org, complete }) => {
  const [editOrg, setEditOrg] = useState(org)
  const dispatch = useDispatch()
  const status = useStatus(acts.UPDATE_ORG)

  useStatusMsg(status, {
    pending: 'Saving...',
    error: 'Failed to update org',
    success: 'Org updated',
  })

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.UPDATE_ORG))
    }
  }, [])

  const onOk = () => {
    //Required fields
    const mfs = ['name'].filter(k => !editOrg[k])
    if (!editOrg || mfs.length > 0) {
      const labels = mfs.join(', ')
      const msg = `${labels} ${mfs.length > 1 ? 'are' : 'is'} required`
      message.error(msg)
      return
    }

    // Make sure stripe account id is null if user sets it to empty string
    editOrg.stripeAccountId = editOrg.stripeAccountId || null

    dispatch(updateOrg({ sig: editOrg.id, org: editOrg })).then(updatedOrg => {
      if (typeof complete === 'function') {
        complete(updatedOrg)
      }
    })
  }

  return (
    <Modal
      title="Edit org"
      open={true}
      okText="Save"
      okButtonProps={{ disabled: Boolean(status.pending), loading: Boolean(status.pending) }}
      onCancel={() => (typeof complete === 'function' ? complete() : null)}
      onOk={onOk}
    >
      <OrgEditor org={editOrg} onChange={org => setEditOrg(org)} />
    </Modal>
  )
}

export const ActionsMenu = ({ onEdit, org }) => {
  const { sig } = useParams()
  const history = useHistory()
  const handleActionMenu = e => {
    if (e.key === 'edit') {
      onEdit(org)
    } else if (e.key === 'venue-upload') {
      history.push(paths.admin.ORG_VENUE_UPLOAD(sig))
    }
  }

  return (
    <Menu onClick={handleActionMenu}>
      <Menu.Item key="edit">Edit</Menu.Item>
      <Menu.Item key="venue-upload">Upload venues</Menu.Item>
    </Menu>
  )
}

const Orgs = () => {
  const dispatch = useDispatch()
  const status = useStatus(acts.FETCH_ALL_ORGS)
  useStatusMsg(status, { error: 'Failed to fetch orgs' })
  const [orgs, setOrgs] = useState([])
  const [settingsInfo, setSettingsInfo] = useState(null)
  const [creating, setCreating] = useState(false)
  const [editOrg, setEditOrg] = useState(false)
  const theme = useContext(ThemeContext)

  useEffect(() => {
    dispatch(fetchOrgs()).then(orgs => setOrgs(orgs))
  }, [])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (val, record) => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Link to={paths.admin.ORG(record.sid)}>{val}</Link>
          <small style={{ color: 'gray' }}>{record.sid}</small>
        </div>
      ),
    },
    {
      title: 'Links',
      dataIndex: 'sid',
      key: 'sid',
      render: (val, record) => <Link to={paths.org.HOME(val)}>View in partner portal</Link>,
    },
    {
      title: 'Settings',
      dataIndex: 'settings',
      key: 'settings',
      render: (val, record) => (
        <div>
          {val && (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              {Object.keys(val)
                .slice(0, 3)
                .map(key => (
                  <span key={key}>{key}</span>
                ))}
              <Button
                type="link"
                size="small"
                onClick={() => setSettingsInfo(record)}
                style={{ padding: 0 }}
              >
                More info
              </Button>
            </div>
          )}
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      render: (val, record) => (
        <Dropdown
          overlay={
            <ActionsMenu
              onEdit={() => setEditOrg(record)}
              org={record}
            />
          }
          trigger={['click']}
        >
          <EllipsisOutlined />
        </Dropdown>
      ),
    },
  ]
  return (
    <PaddedContainer>
      <Breadcrumb>
        <Breadcrumb.Item style={{visibility: 'hidden'}}>Orgs</Breadcrumb.Item>
      </Breadcrumb>
      <Head
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: theme.spacing[3],
        }}
      >
        <Title>Orgs</Title>
        <CtaButton onClick={() => setCreating(true)}>New</CtaButton>
      </Head>
      {status.pending && <LoadingOutlined />}
      {settingsInfo && (
        <JSONModal
          title={settingsInfo.name}
          json={settingsInfo.settings}
          complete={() => setSettingsInfo(null)}
        />
      )}
      <Table
        dataSource={orgs}
        columns={columns}
        pagination={false}
      />
      {creating && (
        <CreateOrg
          complete={org => {
            if (org) {
              setOrgs(prev => [...prev, org])
            }
            setCreating(false)
          }}
        />
      )}
      {editOrg && (
        <EditOrg
          org={editOrg}
          complete={org => {
            if (org) {
              setOrgs(prev => {
                const next = [...prev]
                const idx = next.findIndex(o => o.id === org.id)
                if (idx >= 0) {
                  next[idx] = org
                }
                return next
              })
            }
            setEditOrg(false)
          }}
        />
      )}
    </PaddedContainer>
  )
}

export default Orgs
