export const fullyRefunded = payment => {
  if (Array.isArray(payment.refunds)) {
    const refunded = payment.refunds.reduce((acc, curr) => {
      return acc + curr.amountCents
    }, 0)
    return refunded === payment.amountPaidCents
  }
  return false
}

export default {
  fullyRefunded,
}
