import React, { useReducer, useEffect, useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Row, Col, Checkbox, Button, Radio, Input } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { InputGroup, SelectContainer, Title, Desc } from './step'
import { centsToDollars } from '../../../util/number'
import { typeLabel, pricingTypeLabel } from '../../../util/program'
import { lower } from '../../../util/string'
import { pricingTypes } from '../../../constants/program'
import Attr from '../../../components/Attr'

const AmountInput = styled(Input)`
  .ant-input-wrapper.ant-input-group {
    width: ${props => props.theme.width[4]}
  }
`
const PlayerPrice = ({ program, participantPriceCents, setParticipantPriceCents }) => {
  const onChange = e => {
    const val = e.target.value
    if (val) {
      setParticipantPriceCents(+val*100)
    } else {
      setParticipantPriceCents(val)
    }
  }
  return (
    <InputGroup>
      <Title>Price per participant</Title>
      <Desc>
        What does each participant pay for this {lower(typeLabel(program))}?
      </Desc>
      <AmountInput
        type="number"
        size="large"
        addonBefore="$"
        defaultValue={participantPriceCents && centsToDollars(participantPriceCents)}
        onChange={onChange}
        value={participantPriceCents && participantPriceCents/100}
      />
    </InputGroup>
  )
}

const initState = ({ pricingType, participantPriceCents }) => ({
  pricingType,
  participantPriceCents,
})

const reducer = (state, action) => ({ ...state, ...action })

const PricingType = ({ onChange, clinic }) => {
  const [state, dp] = useReducer(reducer, initState(clinic))

  const options = Object.keys(pricingTypes).map(k => (
    <Radio key={k} value={k}>
      {pricingTypes[k].label}
    </Radio>
  ))

  useEffect(() => {
    if (typeof onChange === 'function') {
      let fields = { pricingType: state.pricingType }
      if (state.pricingType === pricingTypes.PAID.name) {
        fields = {
          ...fields,
          participantPriceCents: state.participantPriceCents,
        }
      }
      onChange(fields)
    }
  }, [state])

  let subStep = (<></>)
  switch (state.pricingType) {
    case pricingTypes.PAID.name:
      subStep = (
        <PlayerPrice
          program={clinic}
          participantPriceCents={state.participantPriceCents}
          setParticipantPriceCents={v => dp({ participantPriceCents: v })}
        />
      )
      break
  }

  
  return (
    <>
      <InputGroup>
        <Title>Pricing Type</Title>
        <Desc>
          How would you like to price this {lower(typeLabel(clinic))}?
        </Desc>
        <SelectContainer>
          <Radio.Group value={state.pricingType} onChange={e => dp({ pricingType: e.target.value })}>
            {options}
          </Radio.Group>
        </SelectContainer>
      </InputGroup>
      {subStep}
    </>
  )
}

const Viewer = ({ program }) => {
  let view = null
  if (program.pricingType === pricingTypes.PAID.name) {
    view = (
      <>
        {centsToDollars(program.participantPriceCents)} per participant
      </>
    )
  }
  return (
    <>
      <Attr name="Type">
        <div>{pricingTypeLabel(program)}</div>
        {view}
      </Attr>
    </>
  )
}

const step = PricingType

const complete = ({ pricingType, participantPriceCents }) => {
  if (pricingType) {
    switch (pricingType) {
      case pricingTypes.PAID.name:
        return participantPriceCents && participantPriceCents >= 0
      case pricingTypes.FREE.name:
        return true
      default: // unknown type
        return false
    }
  }
  return false
}

const applicable = () => true

export default { step, Viewer, complete, applicable }
