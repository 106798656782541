import { types } from '../constants/user'

export const formatInitials = ({ firstName, lastName, name }) => {
  if (firstName && lastName) {
    return `${firstName[0]}${lastName[0]}`.toUpperCase()
  }
  if (name) {
    return name
      .split(' ')
      .map(part => part[0])
      .join('')
      .toUpperCase()
  }
  return ' '
}

export const formatName = ({ firstName, lastName, name }) => {
  if (firstName && lastName) {
    return `${firstName.trim()} ${lastName.trim()}`
  }
  return name
}

export const isAdmin = userType => userType === types.ADMIN.name
export const isPlayer = userType => userType === types.PLAYER.name
export const isPro = userType => userType === types.PRO.name

export const userTypeLabel = userType => {
  if (types[userType]) {
    return types[userType].label
  }
  return ''
}
