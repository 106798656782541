import { urls, actions as acts } from '../constants'
import fetchData from '../util/fetch'
import act from './act'

export const fetchProPrograms = id => async dispatch => {
  dispatch({ type: acts.FETCH_PRO_PROGRAMS_PENDING, payload: { id } })
  try {
    const programs = await fetchData({ url: urls.api.PRO_PROGRAMS(id) })
    dispatch({ type: acts.FETCH_PRO_PROGRAMS_SUCCESS, payload: programs })
    return programs
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.FETCH_PRO_PROGRAMS_FAILURE, payload: err })
  }
}

export const walmartProductLookup = act(
  acts.WALMART_PRODUCT_LOOKUP,
  ({ itemId }) => fetchData({ url: urls.api.WALMART_PRODUCT_LOOKUP(itemId) })
)

