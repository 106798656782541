import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Form, Modal, Select, Input, DatePicker } from 'antd'
import { playerLevels } from '../../../constants/user'
import { formatName } from '../../../util/user'
import { updateUser } from '../../../actions/admin'
import { actions as acts } from '../../../constants'
import { useStatus, useStatusMsg } from '../../../reducers'
import { clearStatus } from '../../../actions/status'
import Attr from '../../../components/Attr'
import SingleImageEditor from '../../../components/SingleImageEditor'
import moment from 'moment-timezone'
import { types as userTypes } from '../../../constants/user'
import { genders } from '../../../constants/user'


const { Option } = Select

const EditUserModal = ({ user, complete }) => {
  const dispatch = useDispatch()
  const [profileImage, setProfileImage] = useState(user.profileImage)
  const [updateableUser, setUpdateableUser] = useState(user)
  const status = {
    level: useStatus(acts.UPDATE_USER_LEVEL),
    profileImage: useStatus(acts.UPDATE_USER_PROFILE_IMAGE),
  }

  const completer = typeof complete === 'function' ? complete : () => {}

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.UPDATE_USER_LEVEL))
      dispatch(clearStatus(acts.UPDATE_USER_PROFILE_IMAGE))
    }
  }, [])

  useStatusMsg(status.level, {
    success: 'Level updated',
    error: 'Failed to update level',
  })

  useStatusMsg(status.profileImage, {
    success: 'Profile image updated',
    pending: 'Saving profile image...',
    error: 'Failed to update profile image',
  })

  const change = update => {
    setUpdateableUser(prev => ({...prev, ...update}))
  }

  const onOk = async () => {
    dispatch(updateUser({ id: user.id, attrs: updateableUser, image: profileImage ? profileImage : undefined }))
      .then(user => {
        completer(user)
      })
  }

  const options = Object.keys(playerLevels).map(pl => (
    <Option key={pl}>{playerLevels[pl].label}</Option>
  ))

  const isLoading = status.level.pending || status.profileImage.pending

  return (
    <Modal
      title={`Edit user ${formatName(user)}`}
      visible
      okText={isLoading ? 'Saving...' : 'Save'}
      okButtonProps={{ disabled: isLoading }}
      onOk={onOk}
      onCancel={() => completer()}
    >
      <Form>
        <Attr name="First name">
          <div>
            <Input value={updateableUser.firstName} onChange={e => {
              const newData = { firstName: e.target.value }
              change({ ...newData, name: formatName({ ...updateableUser, ...newData }) })
            }} />
          </div>
        </Attr>
        <Attr name="Last name">
          <div>
            <Input value={updateableUser.lastName} onChange={e => {
              const newData = { lastName: e.target.value }
              change({ ...newData, name: formatName({ ...updateableUser, ...newData }) })
            }} />
          </div>
        </Attr>
        <Attr name="Gender">
          <div>
            <Select
              optionFilterProp="children"
              defaultValue={updateableUser.gender}
              onChange={e => change({ gender: e })}
              style={{ width: '100%' }}
            >
              {Object.keys(genders).map(k => 
                <Option key={k} value={k}>{genders[k].label}</Option>  
              )}
            </Select>
          </div>
        </Attr>
        <Attr name="Birthdate">
          <div>
            <DatePicker
              defaultValue={moment(updateableUser.birthdate, 'YYYY MM DD')}
              format="MM-DD-YYYY"
              onChange={date => {
                change({ birthdate: date.format() })
              }}
            />
          </div>
        </Attr>
        <Attr name="Location">
          <div>
            <Input value={updateableUser.location} onChange={e => change({ location: e.target.value })} />
          </div>
        </Attr>
        <Attr name="Occupation">
          <div>
            <Input value={updateableUser.occupation} onChange={e => change({ occupation: e.target.value })} />
          </div>
        </Attr>
        <Attr name="User type">
          <div>
            <Select
              optionFilterProp="children"
              defaultValue={updateableUser.type}
              onChange={e => change({ type: e })}
              style={{ width: '100%' }}
            >
              {Object.keys(userTypes).map(key => (
                <Option key={key} value={key}>
                  {userTypes[key].label}
                </Option>
              ))}
            </Select>
          </div>
        </Attr>
        <Attr name="Player Level">
          <div>
            <Select
              optionFilterProp="children"
              defaultValue={updateableUser.playerLevel}
              onChange={e => change({ playerLevel: e })}
              style={{ width: '100%' }}
            >
              {options}
            </Select>
          </div>
        </Attr>
        <Attr name="Profile image">
          <div>
            <SingleImageEditor
              img={profileImage}
              onChange={e => {
                setProfileImage(e)
              }}
              actionText="Select profile image"
            />
          </div>
        </Attr>
      </Form>
    </Modal>
  )
}

export default EditUserModal
