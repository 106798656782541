import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { message, Button } from 'antd'
import { useStatus, useStatusMsg } from '../../reducers'
import { createSponsor } from '../../actions/org'
import { FocusHeader } from '../../components/header'
import { Focus as FocusContainer } from '../../components/containers'
import { Cta } from '../../components/common'
import { clearStatus } from '../../actions/status'
import { paths, actions as acts } from '../../constants'
import SponsorEditor from './SponsorEditor'

const NewSponsor = () => {
  const { osid } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const [sponsor, setSponsor] = useState(null)
  const status = useStatus(acts.CREATE_SPONSOR)

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.CREATE_SPONSOR))
    }
  }, [])

  useStatusMsg(status, {
    pending: 'Creating...',
    error: 'Failed to create sponsor',
    success: res => {
      return (
        <>
          Sponsor created. <a href={paths.org.SPONSOR(osid, res.id)}>View</a>
        </>
      )
    },
  })

  const missingFields = () => {
    const missing = ['name', 'logo'].filter(k => {
      if (!sponsor[k]) {
        return true
      }
      return false
    })
    if (missing.length > 0) {
      const msg = `${missing.join(', ')} ${missing.length > 1 ? 'are' : 'is'} required`
      message.error(`${msg.charAt(0).toUpperCase()}${msg.substr(1)}`)
      return true
    }
    return false
  }

  const submitSponsor = createAnother => () => {
    if (missingFields()) {
      return
    }
    dispatch(createSponsor({ osid, ...sponsor }))
      .then(spnsr =>  {
        if (createAnother) {
          setSponsor(null)
        } else {
          history.push(paths.org.SPONSOR(osid, spnsr.id))
        }
      })
      .catch(() => {})
  }

  return (
    <>
      <FocusHeader
        title="Create a sponsor"
        actions={
          <>
            <Button
              style={{marginRight: '.5em'}}
              onClick={submitSponsor(true)}
              size="small"
              disabled={status.pending ? true : false}
            >
              Submit and create another
            </Button>
            <Cta
              disabled={status.pending ? true : false}
              onClick={submitSponsor(false)}
            >
              {status.pending ? 'Submitting sponsor...' : 'Submit sponsor'}
            </Cta>
          </>
        }
      />
      <FocusContainer style={{paddingTop: '20px', paddingBottom: '20px'}}>
        <SponsorEditor sponsor={sponsor} onChange={s => setSponsor(s)} />
      </FocusContainer>
    </>
  )
}

export default NewSponsor
