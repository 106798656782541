import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons'
import updateWith from 'lodash.updatewith'
import constant from 'lodash.constant'
import styled from 'styled-components'
import { actions as acts } from '../../../constants'
import { useStatus, useStatusMsg } from '../../../reducers'
import { fetchUpcomingPrograms } from '../../../actions/admin'
import { Select, Input } from 'antd'
import { ProgramSelect } from '../../../components/program'

export const Container = styled.div`
  max-width: ${props => props.theme.width[7]}
`

export const reducer = (state, action) => {
  const next = { ...state }
  const { type } = action
  if (type) {
    if (type === 'upcomingEvents') {
      const { pos, val } = action
      next.data.upcomingEvents[pos] = val
    } else if (type === 'data') {
      const { key, val } = action
      updateWith(next.data, key, constant(val), Object)
    } else {
      updateWith(next, type, constant(action.val), Object)
    }
  }
  return next
}

export const emailState = ({ ...data }={}, { ...meta }={}) => ({
  to: null,
  from: null,
  bcc: null,
  subject: null,
  options: {
    showUnsubscribe: false,
  },
  ...meta,
  data: {
    ...data,
    upcomingEvents: []
  }
})

const { Option } = Select

export const TextArea = ({ recommendedLength=100, onChange, ...props }) => {
  const [text, setText] = useState('')
  const [count, setCount] = useState(0)
  const onInput = e => {
    setText(e.target.value)
    setCount(e.target.value.length)
    onChange(e)
  }
  return (
    <>
      <Input.TextArea
        {...props}
        onChange={onInput}
        value={text}
      />
      <div style={{float: 'right', color: count > recommendedLength ? 'yellow' : ''}}>
        {count}/{recommendedLength} max characters recommended
      </div>
    </>
  )
}

export const SetScoreSelect = ({ ...props }) => {
  const options = [...Array(11).keys()] // scores from 0-10
    .map(score => (
      <Option key={score} value={score}>{score}</Option>
    ))
  return (
    <Select {...props}>
      {options}
    </Select>
  )
}

export const UpcomingSelects = ({ dp, numUpcoming=3 }) => {
  const dispatch = useDispatch()
  const [upcomingPrograms, setUpcomingPrograms] = useState([])
  const [upcomingSelects, setUpcomingSelects] = useState([])
  const status = useStatus(acts.FETCH_UPCOMING_PROGRAMS)

  useEffect(() => {
    dispatch(fetchUpcomingPrograms())
      .then(prgms => setUpcomingPrograms(prgms))
  }, [])

  useEffect(() => {
    if (status.success) {
      const selects = [...Array(numUpcoming).keys()]
        .map(i => {
          if (upcomingPrograms[i]) {
            dp({ type: `upcomingEvents`, pos: i, val: upcomingPrograms[i] })
            return (
              <ProgramSelect
                style={{marginBottom: '1em'}}
                key={upcomingPrograms[i].id}
                programs={upcomingPrograms}
                defaultValue={upcomingPrograms[i].id}
                onChange={val => dp({ type: `upcomingEvents`, pos: i, val: JSON.parse(val) })}
              />
            )
          }
          return undefined
        }).filter(Boolean)
      setUpcomingSelects(selects)
    }
  }, [upcomingPrograms])

  if (status.pending || !status.success) {
    return <LoadingOutlined />
  }
  return (
    <div>{upcomingSelects}</div>
  )
}

