import React, { useContext } from 'react'
import { Tag } from 'antd'
import { ThemeContext } from 'styled-components'
import Attr from '../../../components/Attr'
import UserCard from '../../../components/admin/UserCard'
import { ProgramCard } from '../../../components/program'
import * as timeUtils from '../../../util/time'
import formatUtils from '../../../util/format'
import { neverExpires } from './utils'

const Usability = ({ promoCode }) => {
  const theme = useContext(ThemeContext)
  const { applicableUsers } = promoCode
  if (!applicableUsers || (Array.isArray(applicableUsers) && applicableUsers.length === 0)) {
    return 'All users'
  }
  if (Array.isArray(promoCode.applicableUsers)) {
    const users = promoCode.applicableUsers.map(au => (
      <UserCard key={au.id} user={au} />
    ))
    if (users.length > 0) {
      return (
        <>
          Users
          <div
            style={{
              display: 'grid',
              gridGap: '1em',
              gridTemplateColumns: `repeat(auto-fill, minmax(${theme.width[5]}, 1fr))`,
            }}
          >
            {users}
          </div>
        </>
      )
    }
  }
}

const Applicability = ({ promoCode }) => {
  if (promoCode.entireInvoiceApplicable) {
    return 'Entire invoice'
  }
  // get programs for applicability
  if (Array.isArray(promoCode.applicablePrograms) && promoCode.applicablePrograms.length > 0) {
    const programs = promoCode.applicablePrograms.map(ap => (
      <ProgramCard key={ap.id} program={ap} />
    ))
    if (programs.length > 0) {
      return (
        <>
          Programs
          <div style={{display: 'flex', flexWrap: 'wrap'}}>
            {programs}
          </div>
        </>
      )
    }
  }

  if (Array.isArray(promoCode.applicableSkus) && promoCode.applicableSkus.length > 0) {
    const skus = promoCode.applicableSkus.map(apSku => (
      <Tag key={apSku.id}>{apSku.sku}</Tag>
    ))
    if (skus.length > 0) {
      return (
        <>
          SKUs
          <div style={{display: 'flex', flexWrap: 'wrap'}}>
            {skus}
          </div>
        </>
      )
    }
  }

  return (
    <i>No applicability</i>
  )
}

const ValidPeriod = ({ promoCode }) => {
  if (neverExpires(promoCode)) {
    return 'Never expires'
  }
  return (
    `${timeUtils.formatDate(promoCode.validFrom)} - ${timeUtils.formatDate(promoCode.validTo)}`
  )
}

const Discount = ({ promoCode }) => {
  const elems = []
  if (promoCode.discountCents) {
    elems.push((
      <div key={`${promoCode.id}-${promoCode.discountCents}`}>
        {formatUtils.centsToDollars(promoCode.discountCents)}
      </div>
    ))
  }
  if (promoCode.discountPercent) {
    elems.push((
      <div key={`${promoCode.id}-${promoCode.discountPercent}`}>
        {formatUtils.percent(promoCode.discountPercent)}
      </div>
    ))
  }
  return elems
}

const Overview = ({ promoCode }) => {
  return (
    <>
      <Attr name="Code">
        <div>
          {promoCode.code}
        </div>
      </Attr>
      <Attr name="Note">
        <div>
          {promoCode.note ? promoCode.note : <i>None</i>}
        </div>
      </Attr>
      <Attr name="Discount">
        <Discount promoCode={promoCode} />
      </Attr>
      <Attr name="Valid period (inclusive)">
        <div>
          <ValidPeriod promoCode={promoCode} />
        </div>
      </Attr>
      <Attr
        name="First purchase only"
        tooltip="If yes, only users who have never made a purchase can use this promo code"
      >
        <div>
          {promoCode.firstPurchaseOnly ? 'Yes' : 'No'}
        </div>
      </Attr>
      <Attr
        name="Max total uses"
        tooltip="Total number of times this promo code can be used across the entire system"
      >
        <div>
          {(!promoCode.maxTotalUses || promoCode.maxTotalUses <= 0) ? 'Unlimited' : promoCode.maxTotalUses}
        </div>
      </Attr>
      <Attr
        name="Max user uses"
        tooltip="Total number of times a user can use this promo code"
      >
        <div>
          {(!promoCode.maxUserUses || promoCode.maxUserUses <= 0) ? 'Unlimited' : promoCode.maxUserUses}
        </div>
      </Attr>
      <Attr name="Usability" tooltip="Who can use this promo code">
        <div>
          <Usability promoCode={promoCode} />
        </div>
      </Attr>
      <Attr name="Applicability" tooltip="What this promo code can be applied to">
        <div>
          <Applicability promoCode={promoCode} />
        </div>
      </Attr>
    </>
  )
}

export default Overview
