import { labelConst, descConst } from './util'

export const programTypes = {
  ...descConst('CLASS', 'Class', ''),
  ...descConst('COMPETITION', 'Competition', ''),
  ...descConst('COACHING', 'Coaching Session', ''),
  ...descConst('SPINOFF', 'Spin Off', ''),
  ...descConst('EXPERIENCE', 'Experience', ''),
  ...descConst('PASS', 'Pass', ''),
  ...descConst('RES_RESERVATION', 'Resource reservation', ''),
}

export const clubMembershipRequirements = {
  ...descConst('ALL', 'All', 'Membership to all clubs is required'),
  ...descConst('AT_LEAST_ONE', 'At least one', 'Membership to at least one club is required'),
}

export const programSubtypes = {
  CLASS: {
    ...descConst('ALL', 'All Level', 'Players of all levels'),
    ...descConst('RECREATIONAL', 'Recreational', 'Recreational players'),
    ...descConst('BEGINNER', 'Beginner', 'Beginner players'),
    ...descConst('INTERMEDIATE', 'Intermediate', 'Intermediate players'),
    ...descConst('ADVANCED', 'Advanced', 'Advanced players'),
  },
  COMPETITION: {
    ...descConst('SINGLES', 'Singles', 'Singles competition'),
    ...descConst('DOUBLES', 'Doubles', 'Doubles competition'),
  },
  COACHING: {
    ...descConst('PRIVATE', 'Private', 'Private coaching session'),
    ...descConst('SEMI_PRIVATE', 'Semi-Private', 'Semi-private coaching session'),
  },
  SPINOFF: {
    ...descConst('PING_PONG', 'Ping Pong', 'Ping pong game'),
    ...descConst('PADEL', 'Padel', 'Padel game'),
    ...descConst('PICKLEBALL', 'Pickleball', 'Pickleball game'),
  },
  EXPERIENCE: {
    ...descConst('GETAWAY', 'Getaway', 'Getaway experience'),
  },
  PASS: {
    ...descConst('LEAGUE', 'League', 'League pass'),
  },
  RES_RESERVATION: {
    ...descConst('RESERVATION', 'Reservation', 'Reservation'),
  },
}

export const pricingTypes = {
  ...descConst('PAID', 'Paid', ''),
  ...descConst('FREE', 'Free', ''),
}

export const statuses = {
  ...descConst('OK', 'OK', ''),
  ...descConst('CANCELED', 'Cancelled', ''),
}

export const bookingStatusTypes = {
  ...descConst('PENDING', 'Pending', 'User intends to book, but payment not yet recieved or failed at some point'),
  ...descConst('CONFIRMED', 'Confirmed', 'User has successfully completed payment; waiting for payment to be confirmed with our payments provider, but effectively booked'),
  ...descConst('BOOKED', 'Booked', 'Booking is finalized'),
  ...descConst('CANCELED', 'Canceled', 'Booking is cancelled by admin or user'),
}

export default {
  types: programTypes,
  subtypes: programSubtypes,
  pricingTypes,
  bookingStatusTypes,
  statuses,
}
