import React from 'react'
import { Tooltip, Tag } from 'antd'
import programConsts from '../constants/program'

const BookStatusTag = ({ status }) => {
  let color;
  switch(status) {
    case programConsts.bookingStatusTypes.PENDING.name:
      color = '#ffd666'
      break
    case programConsts.bookingStatusTypes.CONFIRMED.name:
      color = '#69c0ff'
      break
    case programConsts.bookingStatusTypes.BOOKED.name:
      color = '#95de64'
      break
    case programConsts.bookingStatusTypes.CANCELED.name:
      color = '#ff7875'
      break
    default:
      color = 'inherit'
      break
  }
  return (
    <div>
      <Tooltip
        placement="top"
        title={programConsts.bookingStatusTypes[status].description}
      >
        <Tag color={color}>
          {programConsts.bookingStatusTypes[status].label}
        </Tag>
      </Tooltip>
    </div>
  )
}

export default BookStatusTag
