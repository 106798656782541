import { useContext, useEffect, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { useDispatch } from 'react-redux'
import { Button, Cta, Head, Title, Subtitle, PaddedContainer } from '../../components/common'
import { Typography, message, Select, InputNumber, Input, Modal, Divider as AntDivider, Menu, Dropdown, Breadcrumb } from 'antd'
import { CodeSandboxOutlined, EditFilled, EllipsisOutlined, DownOutlined, LoadingOutlined } from '@ant-design/icons'
import { useHistory, useParams, Link } from 'react-router-dom'
import { paths, actions as acts, features } from '../../constants'
import * as orgActs from '../../actions/org'
import { clearStatus } from '../../actions/status'
import { walmartProductLookup } from '../../actions/api'
import { useStatus, useStatusMsg } from '../../reducers'
import { MiniInfo, MediumInfo, Divider } from '../../components/info'
import Attr from '../../components/Attr'
import * as productConsts from '../../constants/product'
import formatUtils from '../../util/format'
import * as timeUtils from '../../util/time'
import { ProductVariantEditor } from './editors'
import { EditVariantsModal } from './variant-option'
import ClubSelect from '../../components/ClubSelect'
import { ClubList } from '../../components/club'
import ImagePreview from '../../components/ImagePreview'
import { Noner } from '../../components/common'
import { WalmartProductPreview } from './walmart'

const { Option } = Select
const { Paragraph } = Typography

const Row = styled.div`
  display: contents;
  grid-auto-rows: 1fr;

  &:hover {
    background-color: rgb(246, 248, 250);
  }
`
const Cell = styled.div`
  background-color: inherit;
  padding-top: .5em;
  padding-bottom: .5em;
  padding-left: 4px;
  padding-right: 4px;
  align-self: stretch;

  display: flex;
  align-items: center;
`

const Stock = ({ stockType, stock }) => {
  if (stockType === productConsts.stockTypes.UNLIMITED.name) {
    return <span>∞</span>
  }
  let stockNum = stock
  if (typeof stockNum !== 'number') {
    stockNum = 0
  }
  return (
    <span>{stockNum} unit{stockNum === 1 ? '' : 's'}</span>
  )
}

//const StockSummary = ({ product }) => {
//  const { osid } = useParams()
//  const dispatch = useDispatch()
//  const [summary, setSummary] = useState(null)
//  
//  useEffect(() => {
//    dispatch(orgActs.fetchProductStockSummary({ osid, productId: product.id }))
//      .then(sum => setSummary(sum))
//  }, [])
//
//  let display = <LoadingOutlined />
//  if (product.stockType === productConsts.stockTypes.UNLIMITED.name) {
//    return <span>∞</span>
//  } else if (summary) {
//    const total = summary.reduce((acc, curr) => {
//      return acc + curr.stock
//    }, 0)
//    display = (
//      <span>{total} unit{total === 1 ? '' : 's'}</span>
//    )
//  }
//
//  return display
//}

const ChangeStockModal = ({ variant, complete }) => {
  const { osid, id } = useParams()
  const dispatch = useDispatch()
  const [type, setType] = useState(productConsts.stockChangeTypes.INCREASE.name)
  const [quantity, setQuantity] = useState(null)
  const [note, setNote] = useState(null)
  const status = {
    add: useStatus(acts.ADD_VARIANT_STOCK),
    remove: useStatus(acts.REMOVE_VARIANT_STOCK),
  }

  const onOk = () => {
    if (typeof quantity !== 'number' || quantity <= 0) {
      message.error('Quantity is required to be greater than 0')
      return
    }
    const params = {
      osid,
      productId: id,
      variantId: variant.id,
      quantity,
      note,
    }
    let action;
    if (type === productConsts.stockChangeTypes.INCREASE.name) {
      action = dispatch(orgActs.addVariantStock(params))
    } else {
      action = dispatch(orgActs.removeVariantStock(params))
    }
    action.then(res => {
      if (typeof complete === 'function') {
        complete(res.stock)
      }
    })
  }

  const onCancel = () => {
    if (typeof complete === 'function') {
      complete()
    }
  }

  return (
    <Modal 
      title="Change stock"
      visible
      okText="Change"
      onOk={onOk}
      onCancel={onCancel}
    >
      <Attr name="Type">
        <div>
          <Select
            value={type}
            onChange={v => setType(v)}
          >
            <Option value={productConsts.stockChangeTypes.INCREASE.name}>
              {productConsts.stockChangeTypes.INCREASE.label}
            </Option>
            <Option value={productConsts.stockChangeTypes.DECREASE.name}>
              {productConsts.stockChangeTypes.DECREASE.label}
            </Option>
          </Select>
        </div>
      </Attr>
      <Attr name="Quantity">
        <div>
          <InputNumber
            name="quantity"
            placeholder="0"
            type="number"
            value={quantity}
            onChange={v => setQuantity(v)}
          />
        </div>
      </Attr>
      <Attr name="Note">
        <Input.TextArea
          name="note"
          value={note}
          onChange={e => setNote(e.target.value)}
        />
      </Attr>
    </Modal>
  )
}

const ShippingRequirement = ({ shippingRequired }) => {
  return shippingRequired ? 'Shipping required' : 'Shipping not required'
}

const VariantImages = ({ variant }) => {
  const theme = useContext(ThemeContext)
  const color = 'rgba(0, 0, 0, 0.25)'
  return (
    <div
      style={{
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        borderRadius: theme.br[2], 
        border: `1px solid ${color}`,
        height: '50px',
        width: '50px', // this is truly set by grid-template-columns
      }}
    >
      <CodeSandboxOutlined style={{ color, fontSize: '18px' }}/>
    </div>
  )
}

const EditProductVariantModal = ({ variant, complete }) => {
  const onCancel = () => {
    if (typeof complete === 'function') {
      complete()
    }
  }

  /* TODO finish this component */

  return ( 
    <Modal 
      title="Edit product variant"
      visible
      okText="Save"
      onCancel={onCancel}
    >
      <ProductVariantEditor variant={variant} />
    </Modal>
  )
}

const Variant = ({ variant, onChange }) => {
  const { osid } = useParams()
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const { id, product, name, description, priceCents, stockType, shippingRequired } = variant
  const [stock, setStock] = useState(null)
  const [changingStock, setChangingStock] = useState(false)
  const [editingProductVariant, setEditingProductVariant] = useState(false)
  const isLimitedStock = stockType === productConsts.stockTypes.LIMITED.name
  
  useEffect(() => {
    if (isLimitedStock) {
      dispatch(orgActs.fetchProductVariantStock({ osid, productId: product, variantId: id }))
        .then(res => setStock(res.stock))
    }
  }, [])

  const handleMenu = e => {
    if (e.key === 'edit') {
      setEditingProductVariant(true)
    } else if (e.key === 'change-stock') {
      setChangingStock(true)
    }
  }
  const actionMenu = (
    <Menu onClick={handleMenu}>
      <Menu.Item key="edit">Edit</Menu.Item>
      { isLimitedStock && <Menu.Item key="change-stock">Change stock</Menu.Item> }
    </Menu>
  )
  return (
    <>
      <Row>
        <Cell><VariantImages variant={variant} /></Cell>
        <Cell style={{display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'center'}}>
          <div>{name}</div>
          { description &&
            <small>
              <Paragraph
                style={{margin: 0, color: theme.font.color.secondary}}
                ellipsis={{rows: 2, expandable: true, symbol: 'Read more'}}
              >
                {description}
              </Paragraph>
            </small>
          }
        </Cell>
        <Cell>{formatUtils.centsToDollars(priceCents)}</Cell>
        <Cell><Stock stockType={stockType} stock={stock} /></Cell>
        <Cell><ShippingRequirement shippingRequired={shippingRequired} /></Cell>
        <Cell style={{textAlign: 'right'}}>
          <Dropdown overlay={actionMenu} trigger={['click']} placement="bottomRight">
              <Button style={{lineHeight: 0}}><EllipsisOutlined /></Button>
          </Dropdown>
        </Cell>
      </Row>
      { changingStock &&
          <ChangeStockModal
            variant={variant}
            complete={newStock => {
              if (typeof newStock === 'number') {
                setStock(newStock)
              }
              setChangingStock(false)
            }}
          />
      }
      { editingProductVariant &&
          <EditProductVariantModal
            variant={variant}
            complete={newVariant => {
              if (newVariant && typeof onChange === 'function') {
                onChange(newVariant)
              }
              setEditingProductVariant(false)
            }}
          />
      }
    </>
  )
}

const VariantList = ({ product }) => {
  const elems = product.variants.map(v => (
    <Variant key={v.id} variant={v} />
  ))
  return (
      <div style={{display: 'grid', gridTemplateColumns: '58px 1fr 1fr 1fr 1fr .1fr', gridAutoRows: 'max-content'}}>
      {elems}
    </div>
  )
}

const EditClubsModal = ({ product, complete }) => {
  const { osid } = useParams()
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const [clubs, setClubs] = useState(product.clubs.map(c => c.club))
  const completer = typeof complete === 'function' ? complete : () => {}

  const onRemoveClub = club => {
    setClubs(prev => prev.filter(c => c.id !== club.id))
  }

  const onAddClub = club => {
    setClubs(prev => {
      const next = Array.isArray(prev) ? [...prev, club] : [club]
      const existing = {}
      // only allow unique additions of clubs
      return next.filter(c => {
        if (existing.hasOwnProperty(c.id)) {
          return false
        }
        existing[c.id] = true
        return true
      })
    })
  }

  const onOk = () => {
    const clubIds = clubs.map(c => c.id)
    dispatch(orgActs.changeProductClubs({ osid, productId: product.id, clubIds }))
      .then(updatedProduct => completer(updatedProduct))
  }

  const onCancel = () => {
    completer()
  }

  return (
    <Modal
      title="Edit clubs"
      visible
      okText="Save"
      onOk={onOk}
      onCancel={onCancel}
    >
      <div style={{marginBottom: theme.spacing[3]}}>
        This product will appear in the selected club's shop
      </div>
      <Attr name="Club">
        <div>
          <ClubSelect
            value={null}
            onChange={onAddClub}
            style={{minWidth: '250px'}}
          />
          <div style={{marginTop: theme.spacing[3]}}>
            <ClubList
              clubs={clubs}
              onRemove={onRemoveClub}
            />
          </div>
        </div>
      </Attr>
    </Modal>
  )
}

const WalmartProductDetails = ({ product }) => {
  const dispatch = useDispatch()
  const [walmartProduct, setWalmartProduct] = useState(null)
  const status = useStatus(acts.WALMART_PRODUCT_LOOKUP)

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.WALMART_PRODUCT_LOOKUP))
    }
  }, [dispatch])

  useEffect(() => {
    if (product.walmartItemId) {
      dispatch(walmartProductLookup({ itemId: product.walmartItemId }))
        .then(prd => setWalmartProduct(prd))
    }
  }, [product])

  return (
    <WalmartProductPreview
      name={product.name}
      description={product.description}
      product={walmartProduct}
    />
  )
}

const ProductDetails = ({ product }) => {
  const theme = useContext(ThemeContext)

  if (product.type === productConsts.types.WALMART.name) {
    return (
      <WalmartProductDetails product={product} />
    )
  }

  const elems = product.images.sort((a, b) => b.order - a.order).map(img => (
    <ImagePreview
      asset={img.image}
      width="100px"
      height="100px"
      style={{
        borderRadius: theme.br[2],
        marginRight: theme.spacing[2],
        objectFit: 'cover',
      }}
    />
  ))
  let offsiteDisplay;
  if (product.type === productConsts.types.OFFSITE.name) {
    offsiteDisplay = (
      <div>
        <Attr name="Offsite URL">
          <div>
            <a target="_blank" href={product.offsiteUrl}>{product.offsiteUrl}</a>
          </div>
        </Attr>
        <Attr name="Offsite CTA">
          <div>
            <Noner none="No CTA">{product.offsiteCta}</Noner>
          </div>
        </Attr>
      </div>
    )
  }
  return (
    <>
      {offsiteDisplay}
      <Attr name="Images">
        <div>
          <Noner none="No images">{elems}</Noner>
        </div>
      </Attr>
    </>
  )
}

const Product = () => {
  const { osid, id } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const theme = useContext(ThemeContext)
  const [product, setProduct] = useState(null)
  const [editingVariants, setEditingVariants] = useState(null)
  const [editingClubs, setEditingClubs] = useState(null)
  const status = {
    fetch: useStatus(acts.FETCH_PRODUCT),
    delete: useStatus(acts.DELETE_PRODUCT),
  }

  useStatusMsg(status.fetch, {
    error: 'Failed to load product',
  })
  useStatusMsg(status.delete, {
    pending: 'Deleting product...',
    success: 'Product deleted',
    error: 'Failed to delete product',
  })

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.FETCH_PRODUCT))
      dispatch(clearStatus(acts.DELETE_PRODUCT))
    }
  }, [])

  useEffect(() => {
    dispatch(orgActs.fetchProduct({ osid, productId: id }))
      .then(prod => setProduct(prod))
  }, [osid, id])

  const deleteProduct = () => {
    dispatch(orgActs.deleteProduct({ osid, productId: id }))
      .then(() => history.push(paths.org.PRODUCTS(osid)))
  }

  let display = <LoadingOutlined />
  if (product) {
    const handleActionMenu = e => {
      if (e.key === 'edit') {
        history.push(paths.org.EDIT_PRODUCT(osid, id))
      } else if (e.key === 'delete') {
        Modal.confirm({
          title: `Delete product?`,
          content: `Deleting a product will remove it from all programs, clubs, etc. to which it is attached.`,
          okText: 'Delete',
          okButtonProps: {
            type: 'primary',
            danger: true,
          },
          onOk: () => deleteProduct(),
        })
      }
    }
    const actionMenu = (
      <Menu onClick={handleActionMenu}>
        <Menu.Item key="edit">Edit product</Menu.Item>
        <Menu.Item danger key="delete">Delete product</Menu.Item>
      </Menu>
    )
    display = (
      <div>
        <Head>
          <Title>{product.name}</Title>
          <div style={{flexGrow: 1}}></div>
          <Dropdown overlay={actionMenu} trigger={['click']} placement="bottomRight">
            <Cta>Actions <DownOutlined /></Cta>
          </Dropdown>
        </Head>
        <div style={{display: 'flex', marginBottom: theme.spacing[2]}}>
          <MiniInfo title="Type" info={productConsts.types[product.type].label} style={{marginLeft: 0}} />
          { product.type === productConsts.types.WALMART.name &&
             <>
              <Divider /> <MiniInfo title="Walmart Item ID" info={product.walmartItemId} />
             </>
          }
          { product.type !== productConsts.types.WALMART.name &&
              <>
                <Divider />
                <MiniInfo
                  title="Price"
                  info={
                    typeof product.priceCents === 'number' ?
                    formatUtils.centsToDollars(product.priceCents) :
                    <Noner none="No price">{product.priceCents}</Noner>
                  }
                 />
              </>
          }
          { product.type === productConsts.types.ONSITE.name &&
             <>
               <Divider />
               <MiniInfo
                 title="Stock"
                 info={product.stockType ? productConsts.stockTypes[product.stockType].label : null}
               />
             </>
          }
          <Divider />
          <MiniInfo title="Created" info={<span>{timeUtils.formatDate(product.createdAt)} by {product.creator.name}</span>} />
        </div>
        <MediumInfo
          title="Details"
          button={
            <Button onClick={() => history.push(paths.org.EDIT_PRODUCT(osid, id))}>
              <EditFilled /> Edit
            </Button>
          }
        >
          <ProductDetails product={product} />
        </MediumInfo>
        { productConsts.variantProductTypes.map(pt => pt.name).includes(product.type) &&
            <MediumInfo
              title="Variants"
              button={<Button onClick={() => setEditingVariants(true)}><EditFilled /> Edit variants</Button>}
            >
              <VariantList product={product} />
            </MediumInfo>
        }
        <MediumInfo title="Programs">
          <div style={{marginBottom: theme.spacing[3]}}>
            The programs where this product is purchasable as an addon
          </div>
          <i>Coming soon</i>
        </MediumInfo>
        <MediumInfo
          title="Clubs"
          button={<Button onClick={() => setEditingClubs(true)}><EditFilled /> Edit clubs</Button>}
        >
          <div style={{marginBottom: theme.spacing[3]}}>
            The clubs in which this product is purchasable
          </div>
          <ClubList clubs={product.clubs.map(c => c.club)} />
        </MediumInfo>
        <MediumInfo title="Orders">
        </MediumInfo>
      </div>
    )
  }

  return (
    <PaddedContainer>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={paths.org.PRODUCTS(osid)}>{features.product.name.plural}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{product ? product.name : id}</Breadcrumb.Item>
      </Breadcrumb>
      {display}
      { editingVariants &&
          <EditVariantsModal
            product={product}
            complete={updatedProduct => {
              if (updatedProduct) {
                setProduct(updatedProduct)
              }
              setEditingVariants(false)
            }}
          />
      }
      { editingClubs &&
          <EditClubsModal
            product={product}
            complete={updatedProduct => {
              if (updatedProduct) {
                setProduct(updatedProduct)
              }
              setEditingClubs(false)
            }}
          />
      }
    </PaddedContainer>
  )
}

export default Product
