import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const UnstyledLink = styled(Link)`
  margin: 0;
  padding: 0;
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  font-style: inherit;
  font-align: inherit;
  text-decoration: none;
  background-color: initial;
  border: 0;
  outline: none;
  cursor: pointer;

  &&:hover {
    text-decoration: none;
    color: inherit;
  }
`

export default UnstyledLink
