import React from 'react'
import { InputGroup, Title, Desc } from './step'
import { typeLabel } from '../../../util/program'
import { lower } from '../../../util/string'

const Finalize = ({ clinic }) => {
  return (
    <>
      <InputGroup>
        <Title>Congratulations!</Title>
        <Desc>
          You've completed all sections, now create your {lower(typeLabel(clinic))}!
        </Desc>
      </InputGroup>
    </>
  )
}

const step = Finalize

const complete = ({ draft }) => !draft

const applicable = () => true

export default { step, complete, applicable }
