import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { DatePicker } from 'antd'
import { InputGroup, SelectContainer, Title, Desc } from './step'
import { typeLabel } from '../../../util/program'
import Attr from '../../../components/Attr'

function disabledDate(current) {
  // Can not select days before today
  return current && current < dayjs().startOf('day')
}

const PublishDate = ({ onChange, clinic }) => {
  const [publishDate, setPublishDate] = useState(clinic.publishDate)

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange({ publishDate })
    }
  }, [publishDate])

  const tl = typeLabel(clinic)
  return (
    <>
      <InputGroup>
        <Title>Publish Date</Title>
        <Desc>
          When do you want this {tl && tl.toLowerCase()} to be available for booking on Break the Love?
        </Desc>
        <SelectContainer>
          <DatePicker
            size="large"
            placeholder="Select date"
            disabledDate={disabledDate}
            defaultValue={clinic.publishDate && dayjs(clinic.publishDate)}
            onChange={date => setPublishDate(date)}
          />
        </SelectContainer>
      </InputGroup>
    </>
  )
}

const Viewer = ({ program }) => {
  return (
    <Attr>{program.publishDate}</Attr>
  )
}

const step = PublishDate

const complete = ({ publishDate }) => !!publishDate

const applicable = () => true

export default { step, Viewer, complete, applicable }
