import React, { Component } from 'react'
import { useParams } from 'react-router-dom'
import { PlusCircleFilled } from '@ant-design/icons'
import styled from 'styled-components'
import { features, paths } from '../../constants'
import { Head, Title, CtaLink, PaddedContainer } from '../../components/common'
import ClassList from './components/ClassList'
import pluralize from 'pluralize'
import capitalize from 'capitalize'

const Programs = () => {
  const { osid } = useParams()
  return (
    <PaddedContainer>
      <Head>
        <Title>{features.program.name.plural}</Title>
        <CtaLink to={paths.org.CREATE_PROGRAM(osid)}>
          <PlusCircleFilled /> Create {features.program.name.singular.toLowerCase()}
        </CtaLink>
      </Head>
      <ClassList />
    </PaddedContainer>
  )
}

export default Programs
