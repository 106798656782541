import { actions as acts } from '../constants'
import { bookingStatusTypes } from '../constants/program'

const classes = (state = [], { type, payload }) => {
  switch(type) {
    case acts.FETCH_PROGRAMS_SUCCESS:
      return payload
    case acts.DELETE_PROGRAM_SUCCESS:
      return state.filter(program => program.id !== payload)
    case acts.CANCEL_PROGRAM_BOOKING.SUCCESS: {
      const programs = state.map(prg => {
        if (prg.id === payload.programId) {
          return {
            ...prg,
            bookings: prg.bookings.map(b => {
              if (b.id === payload.bookingId) {
                return { ...b, status: bookingStatusTypes.CANCELED.name }
              }
              return b
            }),
          }
        }
        return prg
      })
      return programs
    }
    case acts.CANCEL_PROGRAM_BOOKING.SUCCESS: {
      const programs = state.map(prg => {
        if (prg.id === payload.programId) {
          return {
            ...prg,
            bookings: prg.bookings.map(b => {
              if (b.id === payload.bookingId) {
                return { ...b, note: payload.note }
              }
              return b
            }),
          }
        }
        return prg
      })
      return programs
    }
    case acts.FETCH_LOCATIONS_SUCCESS: {
      return payload
    }
    default:
      return state
  }
}

export default classes
