import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { CloseOutlined } from '@ant-design/icons'
import { useStatus, useStatusMsg } from '../../../reducers'
import { Editor } from '@tinymce/tinymce-react'
import { tinyMceApiKey } from '../../../config'
import { useDropzone } from 'react-dropzone'
import * as urlUtils from '../../../util/url'
import { formatName } from '../../../util/user'
import { actions as acts } from '../../../constants'
import * as actions from '../../../actions/admin'
import { clearStatus } from '../../../actions/status'
import Attr from '../../../components/Attr'
import Dropzone from '../../../components/Dropzone'
import ImagePreview from '../../../components/ImagePreview'
import { Input, Modal, Form } from 'antd'

const AddProShopModal = ({ complete, proPage, existingShop, isEdit=false }) => {
  const dispatch = useDispatch()
  const id = isEdit ? existingShop.id : null
  const [name, setName] = useState(existingShop && existingShop.name)
  const [description, setDescription] = useState(existingShop && existingShop.description)
  const status = {
    create: useStatus(acts.CREATE_PRO_SHOP_PAGE),
    update: useStatus(acts.UPDATE_PRO_SHOP_PAGE),
  }

  useStatusMsg(status.create, {
    pending: 'Creating pro shop...',
    error: 'Failed to create pro shop',
    success: 'Pro shop created',
  })

  useStatusMsg(status.update, {
    pending: 'Updating pro shop...',
    error: 'Failed to update pro shop',
    success: 'Pro shop updated',
  })

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.CREATE_PRO_PAGE))
      dispatch(clearStatus(acts.UPDATE_PRO_PAGE))
    }
  }, [])

  const formComplete = [
    name && name.length > 0,
  ].reduce((acc, curr) => curr && acc, true)

  const onOk = async () => {
    if (isEdit) {
      const updatedShop = await dispatch(actions.updateProShop(proPage.id, name, description))
      complete(updatedShop)
    } else {
      const shop = await dispatch(actions.createProShop(proPage.id, name, description))
      complete(shop)
    }
  }

  return (
    <Modal
      title={`${isEdit ? `Update` : `Create`} pro shop`}
      visible
      okText={isEdit ? 'Save' : 'Create'}
      okButtonProps={{disabled: !formComplete}}
      onOk={onOk}
      onCancel={() => complete()}
      confirmLoading={status.create.pending || status.update.pending || false}
    >
      <Form>
        <Attr name="Name">
          <Input
            placeholder="Keenan's Pro Shop"
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </Attr>
        <Attr name="Description">
          <Input.TextArea
            placeholder="All the items Keenan recommends"
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
        </Attr>
      </Form>
    </Modal>
  )
}

export default AddProShopModal
