import { actions as acts } from '../constants'

const locations = (state = [], { type, payload }) => {
  switch(type) {
    case acts.FETCH_LOCATIONS_SUCCESS: {
      return payload
    }
    case acts.DELETE_LOCATION_SUCCESS: {
      return state.filter(loc => loc.id !== payload.id)
    }
    case acts.UPDATE_LOCATION_SUCCESS: {
      return state.map(loc => loc.id === payload.id ? payload : loc)
    }
    default:
      return state
  }
}

export default locations
