import { actions as acts } from '../constants'

export default function errors(state={}, {type, payload}) {
  if (type === acts.CLEAR_ERROR) {
    return {
      ...state,
      [payload]: null,
    }
  }

  const matches = /(.*)_(PENDING|FAILURE)/.exec(type);
  if (!matches) {
    return state;
  }
  const [, name, promiseState] = matches;
  return {
    ...state,
    // Store error
    // e.g. stores error when receiving ADD_ATTACHMENT_FAILURE
    //      else clear error when receiving ADD_ATTACHMENT_PENDING
    [name]: promiseState  === 'FAILURE' ? payload : null,
  };  
  return state
}

export function getError(state, actionName) {
  return state[actionName];
}
